import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../home_page/Footer';
const ChangeView = (props) => {
    return (
        <div id='company'>
            <button type="button" class="btn-close" id='company-close' aria-label="Close" onClick={props.handleCloseChangeView()}></button>
            <div className='row justify-content-center'>
                <div className='col-3 bg-light border pb-3'>
                    <p style={{ textAlign: 'center' }}>Change View</p>
                    <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />
                </div>
            </div>
            <div className='row justify-content-center' >
                <div className='col-6 shadow bg-light'>
                    <div className='title'>
                        <p>List of View</p>
                    </div>
                    <div className='container-1'>
                        <ul className='master-list'>
                            <li>
                                <Link to="/create">
                                    Show More
                                </Link>
                            </li>

                        </ul>
                        <hr />
                        <div className='container-2 row'>
                            <div className='col'>
                                <dl>
                                    <dt style={{ textAlign: 'left' }}>Views</dt>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Daily</dd>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Weekly</dd>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Fortnightly</dd>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Quarterly</dd>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Half yearly</dd>


                                    <dt style={{ textAlign: 'left' }}>Related Reports</dt>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Summary</dd>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Movement Analysis</dd>
                                    <dd style={{ textAlign: 'left', paddingLeft: 10 }}>Ageing Analysis</dd>
                                </dl>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter'><Footer /></div>
        </div>
    )
}

export default ChangeView
