import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { currentPeriodRedux } from "../../redux/actions/action-creator";

function Period_Home(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [date, setdate] = useState({ from: '', to: '', show_date: '' })
    const from_input_ref = useRef(null)
    const to_input_ref = useRef(null)
    const handlechange = (e) => {
        const { name, value } = e?.target;
        setdate((prevDate) => ({ ...prevDate, [name]: value }));
        if (name === 'from') {
            if (e?.key === 'Enter' && date?.from !== '') {
                e?.preventDefault();
                const match = date?.from?.match(/^(\d+)[.-\/](\d+)[.-\/]?(\d*)$/);
                if (match) {
                    const [, day, month, year_from] = match;
                    const currentDate = new Date();
                    const fiscalYearStartMonth = 4; // Assuming the financial year starts in April
                    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are zero-indexed
                    const currentYear = currentDate.getFullYear();
                    // const year = currentMonth < fiscalYearStartMonth ? currentYear+1 :currentYear;
                    const financialYearStart = currentYear
                    const financialYearEnd = currentYear + 1
                    if (year_from !== '') {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            let parsedYear;
                            if (year_from.length === 2) {
                                const currentYear = new Date().getFullYear();
                                const currentCentury = Math.floor(currentYear / 100) * 100;
                                parsedYear = parseInt(year_from, 10) + currentCentury;
                                if (parsedYear === financialYearStart) {
                                    if (parsedMonth > 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                            setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                                            to_input_ref.current.focus();
                                        } else {
                                            alert('invalid day')
                                        }
                                    } else {
                                        alert('Invalid month')
                                    }

                                } else if (parsedYear === financialYearEnd) {
                                    if (parsedMonth <= 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                            setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                                            to_input_ref.current.focus();
                                        } else {
                                            alert('invalid day')
                                        }
                                    } else {
                                        alert('Invalid month')
                                    }
                                } else if (parsedYear !== financialYearStart && parsedYear !== financialYearEnd) {
                                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                    const parsedDay = parseInt(day, 10);
                                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                        const monthNames = [
                                            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                        ];
                                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                        const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                        const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                        setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                                        to_input_ref.current.focus();
                                    } else {
                                        alert('invalid day')
                                    }
                                }
                            } else if (year_from.length === 4) {
                                parsedYear = parseInt(year_from, 10);
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                                to_input_ref.current.focus();
                            } else {
                                alert("Invalid Year");
                                return;
                            }
                        } else {
                            alert("Invalid Month")
                        }
                    } else {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                            const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                                const formattedDateString_send = `${startYear}-${month}-${day || '01'}`;
                                setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                                to_input_ref.current.focus();
                            } else {
                                alert('invalid Date')
                            }

                        } else {
                            alert('Invalid month')
                        }
                    }
                } else {
                    alert("Invalid date")
                }
            }
        }
        if (name === 'to') {
            if (e?.key === 'Enter' && date?.from !== '' && date?.to !== '') {
                e?.preventDefault();
                const match = date?.to?.match(/^(\d+)[.-\/](\d+)[.-\/]?(\d*)$/);
                if (match) {
                    const [, day, month, year_from] = match;
                    const from_date_check = new Date(date?.show_date)
                    const from_date_month = from_date_check?.getMonth() + 1
                    const from_date_day = from_date_check.getDate()
                    const from_date_year = from_date_check.getFullYear()
                    if (year_from !== '') {
                        const parsedMonth = parseInt(month, 10);
                        let parsedYear;
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            if (year_from.length === 2) {
                                const currentYear = new Date().getFullYear();
                                const currentCentury = Math.floor(currentYear / 100) * 100;
                                parsedYear = parseInt(year_from, 10) + currentCentury;
                                const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                const parsedDay = parseInt(day, 10);
                                if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                    const monthNames = [
                                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                    ];
                                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                    const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                                    const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                                    const date_validate = new Date(formattedDateString_send)
                                    const date_validate_from = new Date(date?.from)
                                    const day_show = date_validate?.toLocaleString('en-US', { weekday: 'long' })
                                    const day_show_from = date_validate_from?.toLocaleString('en-US', { weekday: 'long' })
                                    const currentDate = new Date();
                                    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index (0 for January)
                                    const currentYear = currentDate.getFullYear();
                                    const financialYearStartMonth = 4; // Assuming financial year starts from April
                                    const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
                                    const financialYearEndYear = financialYearStartYear + 1;
                                    alert(date_validate_from.getFullYear())
                                    const current_date_get =
                                        date_validate_from.getFullYear() <= financialYearStartYear ?
                                            date_validate.getFullYear() >= financialYearEndYear ?
                                                `${day_show_from},${date?.from}` :
                                                `${day_show},${formattedDateString}` :
                                            `${day_show_from},${date?.from}`
                                    if (from_date_check <= date_validate) {
                                        dispatch(currentPeriodRedux(
                                            {
                                                financl_year: `${date.from} To ${formattedDateString}`,
                                                start_fincl_year: date.from,
                                                end_fincl_year: formattedDateString,
                                                cur_date: current_date_get
                                            }))
                                        navigate('/')
                                    } else {
                                        alert('Invalid Date')
                                    }
                                } else {
                                    alert('invalid day')
                                }
                            }
                            else if (year_from.length === 4) {
                                parsedYear = parseInt(year_from, 10);
                                const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                const parsedDay = parseInt(day, 10);
                                if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                    const monthNames = [
                                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                    ];
                                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                    const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                                    const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                                    const date_validate = new Date(formattedDateString_send)
                                    const date_validate_from = new Date(date?.from)
                                    const day_show = date_validate?.toLocaleString('en-US', { weekday: 'long' })
                                    const day_show_from = date_validate_from?.toLocaleString('en-US', { weekday: 'long' })
                                    const currentDate = new Date();
                                    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index (0 for January)
                                    const currentYear = currentDate.getFullYear();
                                    const financialYearStartMonth = 4; // Assuming financial year starts from April
                                    const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
                                    const financialYearEndYear = financialYearStartYear + 1;
                                    alert(date_validate_from.getFullYear())
                                    const current_date_get =
                                        date_validate_from.getFullYear() <= financialYearStartYear ?
                                            date_validate.getFullYear() >= financialYearEndYear ?
                                                `${day_show_from},${date?.from}` :
                                                `${day_show},${formattedDateString}` :
                                            `${day_show_from},${date?.from}`
                                    if (from_date_check <= date_validate) {
                                        dispatch(currentPeriodRedux(
                                            {
                                                financl_year: `${date.from} To ${formattedDateString}`,
                                                start_fincl_year: date.from,
                                                end_fincl_year: formattedDateString,
                                                cur_date: current_date_get
                                            }))
                                        navigate('/')
                                    } else {
                                        alert('Invalid Date')
                                    }

                                } else {
                                    alert('invalid day')
                                }
                            } else {
                                alert('invalid year')
                            }
                        } else {
                            alert("Invalid Month")
                        }
                    } else {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            const daysInMonth = new Date(parsedMonth <= 3 ? from_date_year + 1 : from_date_year, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedMonth <= 3 ? from_date_year + 1 : from_date_year}`;
                                const formattedDateString_send = `${parsedMonth <= 3 ? from_date_year + 1 : from_date_year}-${month}-${day || '01'}`;
                                const date_validate = new Date(formattedDateString_send)
                                const day_show = date_validate?.toLocaleString('en-US', { weekday: 'long' })
                                if (from_date_check <= date_validate) {
                                    dispatch(currentPeriodRedux(
                                        {
                                            financl_year: `${date.from} To ${formattedDateString}`,
                                            start_fincl_year: date.from,
                                            end_fincl_year: formattedDateString,
                                            cur_date: `${day_show},${formattedDateString}`
                                        }))
                                    navigate('/')
                                } else {
                                    alert('Invalid Date')
                                }

                            } else {
                                alert('invalid day')
                            }

                        } else {
                            alert('Invalid month')
                        }
                    }
                } else {
                    alert("Invalid date")
                }
            }
        }
    }

    useEffect(() => {
        const handlekeydown = (e) => {
            if (e?.key === 'Escape') {
                e?.preventDefault();
                navigate('/')
                e?.stopPropagation();
            }
        }
        document.addEventListener('keydown', handlekeydown)
        return () => {
            document.removeEventListener('keydown', handlekeydown);
        };
    }, [props?.data, date])
    const { from, to } = date;
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { navigate('/') }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3' id='card-period'>
                <div className='row'>
                    <div className='col'>
                        <b className='border-bottom'>Current Period</b>
                    </div>
                </div>
                <div className='row'>
                    <form autoComplete='off' onSubmit={(e) => e.preventDefault()}>
                        <div className='col'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'>From Date</label>
                                <label>:</label>
                                <input type='text' autoFocus name='from' id='from' ref={from_input_ref} onChange={handlechange} onKeyUp={handlechange} value={from || ''} className='lab-right' />
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'>To</label>
                                <label>:</label>
                                <input type='text' name='to' id='to' ref={to_input_ref} onChange={handlechange} onKeyUp={handlechange} value={to || ''} className='lab-right' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}
export default Period_Home;