import React, { useState, useEffect, useRef, forwardRef } from 'react'
import Footer from '../../../../components/home_page/Footer';
const DispatchDetails = forwardRef((props, ref_name) => {
    const [state_dispatch_details, setstate_dispatch_details] = useState({
        delivery_note_no: '',
        dispatch_doc_no: '',
        dispatched_through: '',
        destination: '',
        carrier_name_agent: '',
        bill_of_lading_lr_rr_no: '',
        date: ''
    })
    const {
        delivery_note_no,
        dispatch_doc_no,
        dispatched_through,
        destination,
        carrier_name_agent,
        bill_of_lading_lr_rr_no,
        date
    } = state_dispatch_details

    // Create mutable object of all input to focus 
    const delivery_note_no_input_ref = useRef(null)
    const dispatch_doc_no_input_ref = useRef(null)
    const dispatched_through_input_ref = useRef(null)
    const destination_input_ref = useRef(null)
    const carrier_name_agent_input_ref = useRef(null)
    const bill_of_lading_lr_rr_no_input_ref = useRef(null)
    const date_input_ref = useRef(null)
    const handlechange = (e) => {
        const { name, value } = e.target;
        const capitalizeFirstLetter = (str) => {
            return str.toLowerCase().replace(/(^|\s)\S/g,
                (firstLetter) => firstLetter.toUpperCase());
        };
        // space key functionality
        if (e.code == 'Space') {
            e.preventDefault();
            const cursorPosition_start = e.target.selectionStart;
            if (cursorPosition_start == 1) {
                setstate_dispatch_details({ ...state_dispatch_details, [name]: '' });
                return;
            }
        }
        // Enter key functionality
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInputRef = name === 'delivery_note_no' ? dispatch_doc_no_input_ref :
                name === 'dispatch_doc_no' ? dispatched_through_input_ref :
                    name === 'dispatched_through' ? destination_input_ref :
                        name === 'destination' ? carrier_name_agent_input_ref :
                            name === 'carrier_name_agent' ? bill_of_lading_lr_rr_no_input_ref :
                                name === 'bill_of_lading_lr_rr_no' ? date_input_ref : null;
            if (name === 'date') {
                const isEmpty = Object.values(state_dispatch_details).every(value => value === '');
                if (isEmpty) {
                    props?.setData({ ...props?.data, dispatch_details: 'Not Available' });
                } else {

                }
                props.setshow_dispatch_details_modal(false)
                props?.ref_name.current?.focus()
            }
            if (nextInputRef) {
                nextInputRef.current.focus();
                if (nextInputRef.current) {
                    nextInputRef.current.setSelectionRange(0, nextInputRef?.current?.value?.length);
                }
            }
            return;
        }
        // Capitalize the first letter of each word for certain input fields
        const updatedValue = name === 'dispatch_doc_no' || name === 'dispatched_through' || name === 'destination' || name === 'carrier_name_agent' || name === 'bill_of_lading_lr_rr_no' ?
            capitalizeFirstLetter(value) : value;
        setstate_dispatch_details({ ...state_dispatch_details, [name]: updatedValue })

    }
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setshow_dispatch_details_modal(false);
                props?.ref_name.current?.focus()
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.setshow_dispatch_details_modal]);
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setshow_dispatch_details_modal(false), props?.ref_name.current?.focus()) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3 border border-dark' id='card-period' style={{ width: '60%', background: '#fff' }}>
                <div className='row'>
                    <div className='col border-bottom'>
                        <b>Dispatch Details</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p className='m-0 p-0 mb-1 border-bottom border-gray'>General Details</p>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Delivery Note No(s)</label>
                            <label>:</label>
                            <input
                                type='text'
                                autoFocus
                                name='delivery_note_no'
                                id='delivery_note_no'
                                ref={delivery_note_no_input_ref}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={delivery_note_no || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Dispatch Doc No.</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='dispatch_doc_no'
                                ref={dispatch_doc_no_input_ref}
                                id='dispatch_doc_no'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={dispatch_doc_no || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Dispatched through</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='dispatched_through'
                                id='dispatched_through'
                                ref={dispatched_through_input_ref}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={dispatched_through || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Destination</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='destination'
                                ref={destination_input_ref}
                                id='destination'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={destination || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Carrier Name/Agent</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='carrier_name_agent'
                                ref={carrier_name_agent_input_ref}
                                id='carrier_name_agent'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={carrier_name_agent || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Bill of Lading/LR-RR No.</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='bill_of_lading_lr_rr_no'
                                ref={bill_of_lading_lr_rr_no_input_ref}
                                id='bill_of_lading_lr_rr_no'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={bill_of_lading_lr_rr_no || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Date</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='date'
                                ref={date_input_ref}
                                id='date'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={date || ''}
                                className='lab-right' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
});

export default DispatchDetails
