import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../../../../components/home_page/Footer';
import { Link,useNavigate,useParams } from 'react-router-dom';
import { godownList,godownSingleData,godownUpdate } from '../../../../../redux/actions/action-creator';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css';
const  GoDown =()=> {
  const initialState = {
    godown_name: '',
    alise_name: '',
    under: '',
    under_id: '',
  }
  const { godown_data = null } = useSelector(state => state.common);
  const {id} = useParams();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const godown_name_input_ref = useRef(null);
  const alias_name_input_ref = useRef(null);
  const inputRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const [isGodownNameEntered, setisGodownNameEntered] = useState(false);
  const [hashtag, setHashtag] = useState(false)
  const [show_godown_name, setshow_godown_name] = useState(true);
  const [show_alise_name, setShow_alise_name] = useState(false);
  const [show_under, setShow_under] = useState(false);
  const [state, setState] = useState(initialState);
  useEffect(()=>{
    const formData = new FormData();
    formData.append('id',id)
    dispatch(godownSingleData(formData)).then((response) => {
      if (response.status) {
        setState({
          godown_name: response.data?.name,
          alise_name: response.data?.alias_name,
          under: response.data?.under,
        })
        // toast.success(response?.message || 'data found!', {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  },[id,dispatch])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    if (name === 'godown_name') {
      setisGodownNameEntered(!!value.trim());
      setState({
        ...state,
        godown_name: value.toLowerCase().replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase()),
      });
      if (!isGodownNameEntered && e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        godown_name_input_ref.current.focus();
        setShow_alise_name(false);
      }
      if (state.godown_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_alise_name(true);
        setTimeout(() => {
          alias_name_input_ref.current.focus();
        }, 50);
      }
    }
    
    if (name === 'alise_name') {
      setState({
        ...state, alise_name: value.toLowerCase()
          .replace(/(^|\s)\S/g,(firstLetter) =>firstLetter.toUpperCase())
      });
      if (state.godown_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_under(true)
        setTimeout(() => {
          inputRef.current.focus();
        }, 50);
      }
      const filteredSuggestions = Object.keys(godown_data).filter(key =>
      godown_data[key]?.name.toLowerCase().includes(state.under.toLowerCase()));
      setFilteredData(filteredSuggestions);
    }
    if (name === 'under') {
      const filteredSuggestions = Object.keys(godown_data).filter(key =>
        godown_data[key]?.name.toLowerCase().includes(state.under.toLowerCase()));
        setFilteredData(filteredSuggestions);
      if (state.godown_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setHashtag(true)
      }
    }
  }
  const handleInputFocus = (e) => {
    const { name, value } = e.target;
    if (name === 'godown_name') {
      setshow_godown_name(true);
    }
    if (name === 'alise_name'){
      setShow_alise_name(true);
    }
    if (name === 'under'){
      setShow_under(true);
      if (!showList) {
        setShowList(true);
      }
    }
  }

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    setshow_godown_name(false)
    setShow_alise_name(false)
    setShow_under(false)
  }
  // const displayList = (value) => {
  //   setState({ ...state, under: value })
  //   document.getElementById('under').value = value;
  //   document.getElementById("myDropdown-list").classList.remove("show-list");
  //   return false
  // }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hashtag) {
      const formData = new FormData();
      formData.append('name', state.godown_name)
      formData.append('alias_name', state.alise_name)
      formData.append('under', state.under_id)
      formData.append('id',id)
      dispatch(godownUpdate(formData)).then((response) => {
        if (response.status) {
          // toast.success(response?.message || 'group created!', {
          //   position: toast.POSITION.TOP_CENTER
          // });
          setState(
            {
              godown_name: '',
              alise_name: '',
              under: '',
              under_id: '',
            }
          )
          setHashtag(false)
          navigate('../chart-of-account-invmas-godown')
          
        }
        else {
          toast.warn(response?.message || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }).catch(error => {
        console.log(error)
        toast.error(error?.message || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
      })
    }
  }
  const {
    godown_name,
    alise_name,
    under,
  } = state;
  // useEffect(() => {
  //   document.addEventListener('click', handleClick);
  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, []);
  // const handleClick = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
  //     setShowList(false);
  //   }

  // };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showList === true) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink = godown_data[selectedItem]
          if (selectedLink) {
            setState({
              ...state, under: selectedLink.name,under_id:selectedLink.id})
            setShowList(false)
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, under, state, showList]);

  return (
    <div className='section'>
      <div className='detail border'>
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form autoComplete='off' onSubmit={handleSubmit}>
              <div className='col-6 p-3' style={{ background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Name</label>
                    <label> :</label>
                    <div className='d-flex align-item-center' style={{ width: '40%' }}>
                      {
                        show_godown_name ?
                          <input
                            type="text"
                            name='godown_name'
                            id='godown_name'
                            style={{ width: '100%' }}
                            className='lab-right'
                            onChange={handleChange}
                            onKeyUp={handleChange}
                            onBlur={handleInputBlur}
                            onFocus={handleInputFocus}
                            value={godown_name || ""}
                            ref={godown_name_input_ref}
                            autoFocus />
                          :
                          <div onClick={() => { setshow_godown_name(true) }}>
                            <p className='m-0 p-2 pb-0 pt-0'> {state.godown_name}</p>
                          </div>
                      }
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-item-center' id='alis'>
                    <label className='lab-1'> (alise) </label>
                    <label> :</label>
                    <div className='d-flex align-item-center' style={{ width: '40%' }}>
                      {
                        show_alise_name ?
                          <input
                            type="text"
                            name='alise_name'
                            id='alise_name'
                            style={{ width: '100%' }}
                            className={`lab-right ${isGodownNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onBlur={handleInputBlur}
                            onFocus={handleInputFocus}
                            ref={alias_name_input_ref}
                            value={alise_name || " "} 
                            disabled={state.godown_name===''}/>
                          :
                          <div style={{ width: '100%',height:20 }} onClick={() => {if(state.godown_name!==''){setShow_alise_name(true); 
                            setTimeout(() => {
                            alias_name_input_ref.current.focus();
                          }, 50);}else{
                            setshow_godown_name(true)
                            setTimeout(() => {
                              godown_name_input_ref.current.focus();
                            }, 50);}  }}>
                            <p className='m-0 p-2 pb-0 pt-0'> {state.alise_name}</p>
                          </div>
                      }
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Under </label>
                    <label> :</label>
                    <div className='d-flex align-item-center' style={{ width: '40%' }}>
                      {
                        show_under ?
                          <input
                            type='text'
                            id='under'
                            name="under"
                            ref={inputRef}
                            value={under ||""}
                            style={{ width: '100%'}}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onBlur={handleInputBlur}
                            onFocus={handleInputFocus}
                            className={`lab-right ${isGodownNameEntered ? '' : 'disable-field'}`}
                            disabled={state.godown_name===''} />
                          :
                          <div className='d-flex align-item-center' style={{ width: '100%',height:20 }} onClick={() => { setShow_under(true) }}>
                            <p className='m-0 p-2 pb-0 pt-0'> {state.under}</p>
                          </div>
                      }
                    </div>
                    <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                        List Of Group
                      </div>
                      <div className='col ' style={{ textAlign: 'right' }}>
                        <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                        <div className='border'></div>
                      </div>
                      <div id='data'>
                      {filteredData.length > 0 ? (
                        filteredData.map((key, number) => {
                          const name = godown_data[key]?.name;
                          const index = name.toLowerCase().indexOf(under.toLowerCase());
                          return (
                            <Link
                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                              id='list'
                              key={number}
                              // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedItem(number)
                                if (number === selectedItem) {
                                  setState({ ...state, under: name })
                                  setShowList(false)
                                }
                              }}
                            >
                              {index !== -1 ? (
                                < >
                                  {name.substring(0, index)}
                                  <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                  {name.substring(index + under.length)}
                                </>
                              ) : (
                                name
                              )}
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tomasterfooter'><Footer /></div>
            </form>
          </div>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default GoDown
