import React, { useEffect, useState } from 'react';
import './period.css'
import Footer from '../../../home_page/Footer';
const state = {
    name: '',
    Set_this_as_default_view_for_the_report: '',
    show_Additional_configuration: '',
    save_view_for: '',
    save_with_the_master_selected_to_open_the_report: '',
    save_with_the_selected_period: ''
}

const SaveView = (props) => {
    const [data, setData] = useState(state);
    const handleKeyUp = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    }
    const {
        name,
        Set_this_as_default_view_for_the_report,
        show_Additional_configuration,
        save_view_for,
        save_with_the_master_selected_to_open_the_report,
        save_with_the_selected_period
    } = data;
    useEffect(() => {

        if (data.show_Additional_configuration == 'Yes') {
            const ele1 = document.getElementById('save_view_for');
            const ele2 = document.getElementById('save_with_master');
            const ele3 = document.getElementById('save_with_the_period');
            ele1.classList.remove('d-none');
            ele2.classList.remove('d-none');
            ele3.classList.remove('d-none');
        } else {
            const ele1 = document.getElementById('save_view_for');
            const ele2 = document.getElementById('save_with_master');
            const ele3 = document.getElementById('save_with_the_period');
            ele1.classList.toggle('d-none');
            ele2.classList.toggle('d-none');
            ele3.classList.toggle('d-none');

        }
    }, [data.show_Additional_configuration])
    return (
        <div className='my-card' id='my-card'>
            <div className='container pb-3' id='card-save-view'>
                <div className='row'>
                    <div className='col'>
                        <b className='border-bottom'>Save View</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex justify-content-between'>
                            <label className='lab-1'>Name</label>
                            <label>:</label>
                            <input type='text' className='lab-right' name='name' value={name || " "} onKeyUp={handleKeyUp} onChange={handleKeyUp}  />
                        </div>
                        <div className='d-flex justify-content-between'>
                            <label className='lab-1'>Set this as default view for the report</label>
                            <label>:</label>
                            <input type='text'  list='stadvftr' name='Set_this_as_default_view_for_the_report' value={Set_this_as_default_view_for_the_report || " "} onKeyUp={handleKeyUp} onChange={handleKeyUp} className='lab-right' />
                            <datalist id='stadvftr' className='datalist'>
                                <option>Yes</option>
                                <option>No</option>
                            </datalist>
                        </div>
                        <div className='d-flex justify-content-between '>
                            <label className='lab-1'>Show additional Configuration</label>
                            <label>:</label>
                            <input type='text' list='sac' name='show_Additional_configuration' value={show_Additional_configuration || " "} onKeyUp={handleKeyUp} onChange={handleKeyUp} className='lab-right' />
                            <datalist id='sac' className='datalist'>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </datalist>
                        </div>
                        <div className='d-flex justify-content-between d-none' id='save_view_for'>
                            <label className='lab-1'>Save View for</label>
                            <label>:</label>
                            <input type='text' list='svf' name='save_view_for' value={save_view_for || " "} onKeyUp={handleKeyUp} onChange={handleKeyUp} className='lab-right' />
                            <datalist id='svf' className='datalist'>
                                <option>All Companies</option>
                                <option>This Company</option>
                            </datalist>
                        </div>
                        <div className='d-flex justify-content-between d-none' id='save_with_master' >
                            <label className='lab-1'>Save with the master selected to open the report</label>
                            <label>:</label>
                            <input type='text' list='swtmstor' name='save_with_the_master_selected_to_open_the_report' value={save_with_the_master_selected_to_open_the_report || " "} onKeyUp={handleKeyUp} onChange={handleKeyUp} className='lab-right' />
                            <datalist id='swtmstor' className='datalist'>
                                <option>Yes</option>
                                <option>No</option>
                            </datalist>
                        </div>
                        <div className='d-flex justify-content-between d-none' id='save_with_the_period'>
                            <label className='lab-1'>Save with the selected period</label>
                            <label>:</label>
                            <input type='text' list='swtsp' name='save_with_the_selected_period' value={save_with_the_selected_period || " "} onKeyUp={handleKeyUp} onChange={handleKeyUp} className='lab-right' />
                            <datalist id='swtsp' className='datalist'>
                                <option>Yes</option>
                                <option>No</option>
                            </datalist>
                        </div>

                    </div>
                </div>

                <button type="button" style={{width:'100%'}} class="btn btn-primary mt-3" onClick={props.handleCloseSaveView()}>Close</button>

            </div>
            <div className='tomasterfooter'><Footer /></div>
        </div>
    )
}

export default SaveView
