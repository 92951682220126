import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './period.css';
import { dateFormateSend } from '../../../date_formate/Index';
import Footer from '../../../home_page/Footer';

function Period(props) {
    const dispatch = useDispatch();
    const [date, setdate] = useState({ from: '', to: '', show_date: '' })
    const from_input_ref = useRef(null)
    const to_input_ref = useRef(null)
    const { company_session } = useSelector(state => state.common);
    const handlechange = (e) => {
        const { name, value } = e?.target;
        setdate((prevDate) => ({ ...prevDate, [name]: value }));
        if (name === 'from') {
            if (e?.key === 'Enter' && date?.from !== '') {
                e?.preventDefault();
                const match = date?.from?.match(/^(\d+)[.-\/](\d+)[.-\/]?(\d*)$/);
                if (match) {
                    const [, day, month, year_from] = match;
                    const year = new Date(company_session?.start_financial_year).getFullYear();
                    const financialYearStart = year;
                    const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
                    if (year_from !== '') {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            let parsedYear;
                            if (year_from.length === 2) {
                                const currentYear = new Date().getFullYear();
                                const currentCentury = Math.floor(currentYear / 100) * 100;
                                parsedYear = parseInt(year_from, 10) + currentCentury;
                                if (parsedYear === financialYearStart) {
                                    if (parsedMonth > 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                            setdate({ ...date, from: formattedDateString, show_date: formattedDateString })
                                            to_input_ref.current.focus();
                                        } else {
                                            alert('invalid day')
                                        }
                                    } else {
                                        alert('Invalid month')
                                    }

                                } else if (parsedYear === financialYearEnd) {
                                    if (parsedMonth <= 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                            setdate({ ...date, from: formattedDateString, show_date: formattedDateString })
                                            to_input_ref.current.focus();
                                        } else {
                                            alert('invalid day')
                                        }
                                    } else {
                                        alert('Invalid month')
                                    }
                                } else if (parsedYear !== financialYearStart && parsedYear !== financialYearEnd) {
                                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                    const parsedDay = parseInt(day, 10);
                                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                        const monthNames = [
                                            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                        ];
                                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                        const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                        const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                        setdate({ ...date, from: formattedDateString, show_date: formattedDateString })
                                        to_input_ref.current.focus();
                                    } else {
                                        alert('invalid day')
                                    }
                                }

                            } else if (year_from.length === 4) {
                                parsedYear = parseInt(year_from, 10);
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                                setdate({ ...date, from: formattedDateString_send, show_date: formattedDateString })
                                to_input_ref.current.focus();
                            } else {
                                alert("Invalid Year");
                                return;
                            }
                        } else {
                            alert("Invalid Month")
                        }
                    } else {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                            const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                                const formattedDateString_send = `${startYear}-${month}-${day || '01'}`;
                                setdate({ ...date, from: formattedDateString, show_date: formattedDateString })
                                to_input_ref.current.focus();
                            } else {
                                alert('invalid day')
                            }

                        } else {
                            alert('Invalid month')
                        }
                    }
                } else {
                    alert("Invalid date")
                }
            }
        }
        if (name === 'to') {
            if (e?.key === 'Enter' && date?.from !== '' && date?.to !== '') {
                e?.preventDefault();
                // props?.setsend_date({ from_date: '', to_date: '' })
                const match = date?.to?.match(/^(\d+)[.-\/](\d+)[.-\/]?(\d*)$/);
                if (match) {
                    const [, day, month, year_from] = match;
                    const from_date_check = new Date(date?.show_date)
                    const from_date_month = from_date_check?.getMonth() + 1
                    const from_date_day = from_date_check.getDate()
                    const from_date_year = from_date_check.getFullYear()
                    if (year_from !== '') {
                        const parsedMonth = parseInt(month, 10);
                        let parsedYear;
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            if (year_from.length === 2) {
                                const currentYear = new Date().getFullYear();
                                const currentCentury = Math.floor(currentYear / 100) * 100;
                                parsedYear = parseInt(year_from, 10) + currentCentury;
                                if (parsedYear == from_date_year) {
                                    if (parsedMonth >= from_date_month) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            if (parsedDay >= from_date_day) {
                                                const monthNames = [
                                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                                ];
                                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                                const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                                                const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                                                const show_to_ui = `${date?.show_date} To ${formattedDateString}`;
                                                props?.setDate({...props.date, show_from_date: date?.show_date, show_to_date: formattedDateString, send_from_date: dateFormateSend(date?.from), send_to_date: formattedDateString_send });
                                                props.setShow(false)
                                                props.setLoading(true)

                                            } else {
                                                alert('invalid day')
                                            }
                                        } else {
                                            alert('invalid day')
                                        }
                                    } else {
                                        alert('invalid month')

                                    }

                                } else if (parsedYear > from_date_year) {
                                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                    const parsedDay = parseInt(day, 10);
                                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                        const monthNames = [
                                            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                        ];
                                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                        const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                                        const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                                        const show_to_ui = `${date?.show_date} To ${formattedDateString}`;
                                        props?.setDate({...props.date, show_from_date: date?.show_date, show_to_date: formattedDateString, send_from_date: dateFormateSend(date?.from), send_to_date: formattedDateString_send });
                                        props.setShow(false)
                                        props.setLoading(true)


                                    } else {
                                        alert('invalid day')
                                    }

                                } else {
                                    alert('Invalid Year')
                                }
                            }
                            else if (year_from.length === 4) {
                                parsedYear = parseInt(year_from, 10);
                                if (parsedYear >= from_date_year) {
                                    if (parsedMonth >= from_date_month) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            if (parsedDay >= from_date_day) {
                                                const monthNames = [
                                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                                ];
                                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                                const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                                                const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                                                const show_to_ui = `${date?.show_date} To ${formattedDateString}`;
                                                props?.setDate({...props.date, show_from_date: date?.show_date, show_to_date: formattedDateString, send_from_date: dateFormateSend(date?.from), send_to_date: formattedDateString_send });
                                                props.setShow(false)
                                                props.setLoading(true)

                                            } else {
                                                alert('invalid day')
                                            }
                                        } else {
                                            alert('invalid day')
                                        }
                                    } else {
                                        alert('invalid month')
                                    }

                                } else {
                                    alert('Invalid Year')
                                }
                            } else {
                                alert('invalid year')
                            }
                        } else {
                            alert("Invalid Month")
                        }
                    } else {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            if (parsedMonth == from_date_month) {
                                const daysInMonth = new Date(from_date_year, parsedMonth, 0).getDate(); // Get the number of days in the month
                                const parsedDay = parseInt(day, 10);
                                if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                    if (day > from_date_day || day == from_date_day) {
                                        const monthNames = [
                                            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                        ];
                                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                        const formattedDateString = `${day || '01'}-${monthAbbreviation}-${from_date_year}`;
                                        const formattedDateString_send = `${from_date_year}-${month}-${day || '01'}`;
                                        const show_to_ui = `${date?.show_date} To ${formattedDateString}`;
                                        props?.setDate({...props.date, show_from_date: date?.show_date, show_to_date: formattedDateString, send_from_date: dateFormateSend(date?.from), send_to_date: formattedDateString_send });
                                        props.setShow(false)
                                        props.setLoading(true)
                                    } else {
                                        alert('invalid day')
                                    }
                                } else {
                                    alert('invalid day')
                                }
                            } else if (from_date_month <= 3 && parsedMonth <= 3) {
                                if (from_date_month === 2 && (parsedMonth === 1 || parsedMonth === 3)) {
                                    alert('Invalid Month')
                                } else if (from_date_month === 3 && (parsedMonth === 1 || parsedMonth === 2)) {
                                    alert("invalid month")
                                } else {
                                    const year = new Date(company_session?.start_financial_year).getFullYear();
                                    const financialYearStart = year;
                                    const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
                                    const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                                    const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                    const parsedDay = parseInt(day, 10);
                                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                        const monthNames = [
                                            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                        ];
                                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                        const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                                        const formattedDateString_send = `${startYear}-${month}-${day || '01'}`;
                                        const show_to_ui = `${date?.show_date} To ${formattedDateString}`;
                                        props?.setDate({...props.date, show_from_date: date?.show_date, show_to_date: formattedDateString, send_from_date: dateFormateSend(date?.from), send_to_date: formattedDateString_send });
                                        props.setShow(false)
                                        props.setLoading(true)
                                    } else {
                                        alert('invalid day')
                                    }
                                }
                            } else if (from_date_month >= 4) {
                                const year = new Date(company_session?.start_financial_year).getFullYear();
                                const financialYearStart = year;
                                const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
                                const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                                const formattedDateString_send = `${startYear}-${month}-${day || '01'}`;
                                const show_to_ui = `${date?.show_date} To ${formattedDateString}`;
                                props?.setDate({...props.date, show_from_date: date?.show_date, show_to_date: formattedDateString, send_from_date: dateFormateSend(date?.from), send_to_date: formattedDateString_send });
                                props.setShow(false)
                                props.setLoading(true)
                            } else {
                                alert('Invalid date you enter')
                            }

                        } else {
                            alert('Invalid month')
                        }
                    }
                } else {
                    alert("Invalid date")
                }
            }
        }
    }

    useEffect(() => {
        const handlekeydown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setShow(false)
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handlekeydown)
        return () => {
            document.removeEventListener('keydown', handlekeydown);
        };
    }, [props?.data, date])
    const { from, to } = date;
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setShow(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3' id='card-period'>
                <div className='row'>
                    <div className='col'>
                        <b className='border-bottom'>Voucher Date</b>
                    </div>
                </div>
                <div className='row'>
                    <form autoComplete='off' onSubmit={(e) => e.preventDefault()}>
                        <div className='col'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'>From Date</label>
                                <label>:</label>
                                <input
                                    type='text'
                                    autoFocus
                                    name='from'
                                    id='from'
                                    ref={from_input_ref}
                                    onChange={handlechange}
                                    onKeyUp={handlechange}
                                    value={from || ''}
                                    className='lab-right' />
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'>To</label>
                                <label>:</label>
                                <input type='text' name='to' id='to' ref={to_input_ref} onChange={handlechange} onKeyUp={handlechange} value={to || ''} className='lab-right' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}

export default Period
