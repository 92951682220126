import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../../../../components/home_page/Footer';
import { Link, useParams } from 'react-router-dom';
import { stockCompanySingleData, stockCompanyUpdate } from '../../../../../redux/actions/action-creator';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css';

function StockCompany() {
    const { id } = useParams();
    const initialState = {
        stock_company_name: '',
        alise_name: '',
        under: '',
    }
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const [showList, setShowList] = useState(false);
    const [state, setState] = useState(initialState);
    useEffect(() => {
        const formData = new FormData();
        formData.append('id', id)
        dispatch(stockCompanySingleData(formData)).then((response) => {
            if (response.status) {
                setState({
                    stock_company_name: response.data?.name,
                    alise_name: response.data?.alias_name,
                    under: response.data?.under,
                })
                // toast.success(response?.message || 'data found!', {
                //   position: toast.POSITION.TOP_CENTER
                // });
            }
            else {
                toast.warn(response?.message || 'Fail !', {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch(error => {
            console.log(error)
            toast.error(error?.Responsemessage || ' Failed!', {
                position: toast.POSITION.TOP_CENTER
            });
        })
    }, [id, dispatch])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    }
    // const displayList = (value) => {
    //   setState({ ...state, under: value })
    //   document.getElementById('under').value = value;
    //   document.getElementById("myDropdown-list").classList.remove("show-list");
    //   return false
    // }
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', state.stock_company_name)
        formData.append('alias_name', state.alise_name)
        formData.append('under', state.under)
        formData.append('id',id)
        dispatch(stockCompanyUpdate(formData)).then((response) => {
            if (response.status) {
                toast.success(response?.message || 'group created!', {
                    position: toast.POSITION.TOP_CENTER
                });
                setState(
                    {
                        stock_company_name: '',
                        alise_name: '',
                        under: '',
                    }
                )
            }
            else {
                toast.warn(response?.message || 'Fail !', {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }).catch(error => {
            console.log(error)
            toast.error(error?.message || ' Failed!', {
                position: toast.POSITION.TOP_CENTER
            });
        })
    }
    const {
        stock_company_name,
        alise_name,
        under,
    } = state;
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);
    const handleClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
            setShowList(false);
        }

    };
    const handleFocus = () => {
        if (!showList) {
            setShowList(true);
        }
    };


    return (
        <div className='section'>
            <div className='detail border'>
                <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
                    <div className='row'>
                        <form autoComplete='off' onSubmit={handleSubmit}>
                            <div className='col-6 p-3' style={{ background: '#fff' }}>
                                <div className='row mt-3 mb-4'>
                                    <div className='d-flex justify-content-between align-item-center'>
                                        <label className='lab-1'> Name</label>
                                        <label> :</label>
                                        <input type="text" name='stock_company_name' id='stock_company_name' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={stock_company_name || ""} />
                                    </div>
                                    <div className='d-flex justify-content-between align-item-center' id='alis'>
                                        <label className='lab-1'> (alise) </label>
                                        <label> :</label>
                                        <input type="text" name='alise_name' id='alise_name' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={alise_name || " "} />
                                    </div>
                                    <div className='d-flex justify-content-between align-item-center'>
                                        <label className='lab-1'> Under </label>
                                        <label> :</label>
                                        <input type='text' id='under' name="under" ref={inputRef} value={under || " "} onKeyUp={handleChange} onChange={handleChange} onFocus={handleFocus} className='lab-right' />
                                        <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                                List Of Group
                                            </div>
                                            <div className='col ' style={{ textAlign: 'right' }}>
                                                <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                                <div className='border'></div>
                                            </div>
                                            <div id='data'>
                                                Stock Category
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tomasterfooter'><Footer /></div>
                        </form>
                    </div>
                </div >
            </div >
            <div className='help'>
            </div>
        </div >
    )
}

export default StockCompany;
