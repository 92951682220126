import React from 'react';
import { useLocation } from 'react-router-dom';
import './footer.css';

const Footer = (props) => {
  const location = useLocation();
  const handleSubmit = (e) => {
    return (
      props.sethashtag(true),
      props.submit
    )

  }
  return (
    <div className='footer'>
      <div className='boxfooter'>
        <button className='button'> <strong> Q:Quite</strong></button>
      </div>
      <div className='boxfooter'>
        <button className='button'>
          {location.pathname === '/chart-of-account-accounting-master-ledger' ||
            location.pathname === '/chart-of-account-accounting-master-group' ||
            location.pathname === '/chart-of-account-accounting-master-voucherstype' ||
            location.pathname === '/chart-of-account-invmas-stockgroup' ||
            location.pathname === '/chart-of-account-invmas-stockcategory' ||
            location.pathname === '/chart-of-account-invmas-stockcompany' ||
            location.pathname === '/chart-of-account-invmas-stockitem' ||
            location.pathname === '/chart-of-account-invmas-unit' ||
            location.pathname === '/daybooktransaction' 
            ? <strong>Enter:Alter</strong> : null}</button>
      </div>
      <div className='boxfooter'>
        <button className='button'>
          {
          location.pathname === '/chart-of-account-accounting-master-ledger'||
          location.pathname === '/daybooktransaction'
           ? <strong>Space:Select</strong>: null}</button>
      </div>
      <div className='boxfooter'>
        <button type='submit' className='button' onClick={() => { return (handleSubmit()) }}>
          {location.pathname === '/create-master-group' ||
            location.pathname === '/create-master-ledger' ||
            location.pathname === '/create-master-vouchertype' ||
            location.pathname === '/create-master-invmas-stockgroup' ||
            location.pathname === '/create-master-invmas-stockcategory' ||
            location.pathname === '/create-master-invmas-stockitem' ||
            location.pathname === '/create-master-invmas-unit' ||
            location.pathname === '/voucherstransaction' ? <strong><u> <u>A</u></u>:Accept</strong> : null}</button>
      </div>
      <div className='boxfooter'>
        <button className='button' disabled={location.pathname === '/chart-of-account-accounting-master-ledger' ? 'disabled' : null}>
          {location.pathname === '/chart-of-account-accounting-master-ledger' ||
            location.pathname === '/chart-of-account-accounting-master-group' ||
            location.pathname === '/chart-of-account-accounting-master-voucherstype' ||
            location.pathname === '/chart-of-account-invmas-stockgroup' ||
            location.pathname === '/chart-of-account-invmas-stockcategory' ||
            location.pathname === '/chart-of-account-invmas-stockcompany' ||
            location.pathname === '/chart-of-account-invmas-stockitem' ||
            location.pathname === '/chart-of-account-invmas-unit' ? <strong><u>C</u>:Create Master</strong> : null}</button>
      </div>
      <div className='boxfooter'>
        <button className='button'></button>
      </div>
      <div className='boxfooter'>
        <button onClick={props.delete} className='button'>
          {location.pathname === '/chart-of-account-accounting-master-ledger' ||
            location.pathname === '/chart-of-account-accounting-master-group' ||
            location.pathname === '/chart-of-account-accounting-master-voucherstype' ||
            location.pathname === '/chart-of-account-invmas-stockgroup' ||
            location.pathname === '/chart-of-account-invmas-stockcategory' ||
            location.pathname === '/chart-of-account-invmas-stockcompany' ||
            location.pathname === '/chart-of-account-invmas-stockitem' ||
            location.pathname === '/chart-of-account-invmas-unit'||
            location.pathname === '/daybooktransaction'
             ? <strong> <u>D</u>:Delete</strong> : null}</button>
      </div>
      <div className='boxfooter'>
        <button className='button'></button>
      </div>
      <div className='boxfooter'>
        <button className='button'></button>
      </div>
      <div className='boxfooter'>
        <button className='button'></button>
      </div>
    </div>

  )
}

export default Footer;