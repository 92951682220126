import React from 'react'

function GST_Details() {
  return (
    <div>
        this is GST_Details
      
    </div>
  )
}

export default GST_Details
