import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function Banking(props) {

  return (
    <div className='card-container'>
      <div className='card-title'>
        <p className='title-of'>Banking</p>
      </div>
      <div className='company-detail'>
        <div className='list-1'>
          <h6 className='list-heading'> CHEQUE</h6>
          <ul className='list-item'>
            <li onClick={props.handleShowChequePrinting()}>
              <Link to=""><b>C</b>heque Printing</Link>
            </li>
            <li >
              <Link to="/checkregister">Cheque <b>R</b>egister</Link>
            </li>
            <li onClick={props.handleShowPostDatedSummary()}>
              <Link to="">Pos<b>T</b>-dated Summary</Link>
            </li>
          </ul>
        </div>
        <div className='list-1'>
          <h6 className='list-heading'> STATEMENTS</h6>
          <ul className='list-item'>
            <li onClick={props.handleShowDepositSlip()}>
              <Link to=""><b>D</b>eposit Slip</Link>
            </li>
            <li onClick={props.handleShowPaymentAdvice()}>
              <Link to=""><b>P</b>ayment Advice</Link>
            </li>
            <li onClick={props.handleShowBankReconciliation()}>
              <Link to=""><b>B</b>ank Reconciliation</Link>
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default Banking;
