import React, { useEffect, useState } from 'react';
import Footer from '../../components/home_page/Footer';
import country from '../../pages/K_Company/Country.json';
import State from '../../pages/K_Company/State.json';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormData from 'form-data';
import { getCompanyAlter } from '../../redux/actions/action-creator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import '../../components/model/modal.css';
import '../../components/login_form/registration.css';
const Alter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData = null } = useSelector(state => state.auth);
  const [data, setData] = useState({
    company_name: userData.company_name,
    mailing_name: userData.mailing_name,
    address: userData.address,
    city: userData.city,
    statename: userData.state,
    countryName: userData.country,
    pincode: userData.pincode,
    mobile: userData.mobile,
    telephone: userData.telephone,
    fax: userData.fax,
    email: userData.email,
    website: userData.website,
    fybfDate: userData.financial_year_begning_form,
    bbfDate: userData.books_being_date,
    username: userData.user_name,
    enable_tallyAudit_features: userData.enable_tally_audit_feature,
    maintain_multiple_branches_for_this_company: '',
    base_currency_symbol: userData.base_currancy_symbol,
    formal_name: userData.formal_name,
    suffix_symbol_to_amount: userData.suffex_symbol_amount,
    add_space_between_amount_and_symbol: userData.add_space_between_amount_symbol,
    number_of_decimal_places: userData.no_of_decimal_place,
    word_representing_amount_after_decimal: userData.word_reprenting_after_amount,
    number_fo_decimal_places_in_words: '2'

  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value })
  }
  const { company_name, mailing_name, address, fybfDate, bbfDate, username, enable_tallyAudit_features, city, statename, countryName, pincode, mobile,
    telephone, fax, email, website, base_currency_symbol, formal_name, suffix_symbol_to_amount, add_space_between_amount_and_symbol, number_of_decimal_places,
    word_representing_amount_after_decimal, number_fo_decimal_places_in_words
  } = data;

  const handleSubmit = (e1) => {
    e1.preventDefault();
    try {
      if (data.company_name != "") {
        if (data.mailing_name != "") {
          if (data.fybfDate != "") {
            if (data.bbfDate != "") {
              if (data.username != "") {
                if (data.city != "") {
                  if (data.statename != "") {
                    if (data.countryName != "") {
                      if (data.pincode != "") {
                        if (data.mobile != "") {
                          if (data.email != "") {
                            let data = new FormData();
                            data.append('id', userData.id);
                            data.append('user_name', username);
                            data.append('customer_code', userData.customer_code);
                            data.append('company_name', company_name);
                            data.append('mailing_name', mailing_name);
                            data.append('address', address);
                            data.append('city', city);
                            data.append('state', statename);
                            data.append('country', countryName);
                            data.append('pincode', pincode);
                            data.append('mobile', mobile);
                            data.append('telephone', telephone);
                            data.append('fax', fax);
                            data.append('email', email);
                            data.append('website', website);
                            data.append('formal_name', formal_name);
                            data.append('suffex_symbol_amount', suffix_symbol_to_amount);
                            data.append('base_currancy_symbol', base_currency_symbol);
                            data.append('add_space_between_amount_symbol', add_space_between_amount_and_symbol);
                            data.append('financial_year_begning_form', fybfDate);
                            data.append('books_being_date', bbfDate);
                            data.append('enable_tally_audit_feature', enable_tallyAudit_features);
                            data.append('no_of_decimal_place', number_of_decimal_places);
                            data.append('word_reprenting_after_amount', number_fo_decimal_places_in_words);
                            data.append('no_of_decimal_place_for_amount', '');
                            dispatch(getCompanyAlter(data)).then((response) => {

                              if (response.status) {
                                toast.success(response?.message || 'Update Success!', {
                                  position: toast.POSITION.TOP_CENTER
                                });

                                setTimeout(() => {
                                  navigate('/home')

                                }, 1000);

                              }
                              else {
                                toast.warn(response?.message || 'Update Fail !', {
                                  position: toast.POSITION.TOP_CENTER
                                });

                              }
                            }).catch(error => {
                              toast.error(error?.message || 'Update Failed!', {
                                position: toast.POSITION.TOP_CENTER
                              });
                            })

                          } else {
                            alert('please fill the email ')
                          }
                        } else {
                          alert('please fill the mobile number')
                        }
                      } else {
                        alert('please fill the pincode ')
                      }
                    } else {
                      alert('please fill the country name')
                    }
                  } else {
                    alert('please fill the state name')
                  }
                } else {
                  alert('please fill the city')
                }
           
              } else {
                alert('please fill the username')
              }

            } else {
              alert('please fill the book date')
            }
          } else {
            alert('Please Fill the date of finacial year')
          }

        } else {
          alert('Please Fill the maliling Name')
        }

      } else {
        alert('Pleas Fill the company name');
      }

    } catch (error) {
      alert(`error${error}`)
    }

  }


  return (
    <div className='section'>
      <div className='detail border'>

        <div className='container-fluid bg-light pb-2'>
          <ToastContainer />
          <div className='row mt-4'>
            <div className='col'>
              <form method='POST' autoComplete='off' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Company Name</label>
                      <label> :</label>
                      <input type="text" name='company_name' id='company_name' tabIndex="1" className='lab-right' value={company_name || " "} onKeyUp={handleChange} onChange={handleChange} required />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Mailing Name </label>
                      <label> :</label>
                      <input type="text" name='mailing_name' id='mailing_name' tabIndex="2" className='lab-right' value={mailing_name || " "} onKeyUp={handleChange} onChange={handleChange} required />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Address </label>
                      <label> :</label>
                      <input type="text" name='address' className='lab-right' tabIndex="3" value={address || " "} onKeyUp={handleChange} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Financial year beginning from  </label>
                      <label> :</label>
                      <input type="date" name='fybfDate' id='fybfDate' className='lab-right' tabIndex="4" value={fybfDate || " "} onKeyUp={handleChange} onChange={handleChange} required />

                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Books beginning from</label>
                      <label> :</label>
                      <input type="date" name='bbfDate' id='bbfDate' className='lab-right' tabIndex="5" value={bbfDate || " "} onKeyUp={handleChange} onChange={handleChange} required />

                    </div>
                    <p>Security</p>

                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Username (Administrator)</label>
                      <label> :</label>
                      <input type="text" name='username' id='username' className='lab-right' tabIndex="6" value={username || " "} onKeyUp={handleChange} onChange={handleChange} required />
                    </div>
            
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Enable TallyAudit Features </label>
                      <label> :</label>
                      <input type="text" list='etf' name='enable_tallyAudit_features' id='enable_tallyAudit_features' value={enable_tallyAudit_features || " "} className='lab-right' onKeyUp={handleChange} onChange={handleChange} />
                      <datalist id='etf'>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </datalist>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> City </label>
                      <label> :</label>
                      <input type="text" name='city' id='city' value={city || " "} className='lab-right' onKeyUp={handleChange} onChange={handleChange} required />
                    </div>
                    <div className='d-flex justify-content-between align-item-center' id='state'>
                      <label className='lab-1'> State </label>
                      <label> :</label>
                      <input type='text' list="state_list" name='statename' id='stateName' className='lab-right' value={statename || " "} onKeyUp={handleChange} onChange={handleChange} required />
                      <datalist id='state_list' className='lab-right'>
                        {
                          State.map((item, index) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );
                          })
                        }
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center' id='country'>
                      <label className='lab-1'> Country </label>
                      <label> :</label>
                      <input type='text' name='countryName' list='country_list' id='countryName' value={countryName || ""} className='lab-right' onKeyUp={handleChange} onChange={handleChange} required />
                      <datalist id='country_list' className='lab-right'>
                        {
                          country.map((item, index) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );

                          })
                        }
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Pincode </label>
                      <label> :</label>
                      <input type="number" name='pincode' id='pincode' value={pincode || " "} onKeyUp={handleChange} onChange={handleChange} className='lab-right' required />

                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Mobile </label>
                      <label> :</label>
                      <input type="number" name='mobile' id='mobile' value={mobile || " "} onKeyUp={handleChange} onChange={handleChange} className='lab-right' required />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'>  Telephone </label>
                      <label> :</label>
                      <input type="number" name='telephone' id='telephone' value={telephone || " "} onKeyUp={handleChange} onChange={handleChange} className='lab-right' />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Fax </label>
                      <label> :</label>
                      <input type="text" name='fax' id='fax' value={fax || " "} onKeyUp={handleChange} onChange={handleChange} className='lab-right' />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> E-Mail </label>
                      <label> :</label>
                      <input type="email" name='email' id='email' value={email || " "} onKeyUp={handleChange} onChange={handleChange} className='lab-right' required />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Website </label>
                      <label> :</label>
                      <input type="website" name='website' id='website' value={website || " "} onKeyUp={handleChange} onChange={handleChange} className='lab-right' />
                    </div>
                  </div>
                  <div className='border mt-2'></div>

                </div>
                <div className='row pt-3'>
                  <div className='col-lg-6'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'> Base Currency symbol </label>
                      <label> :</label>
                      <input type="text" name='base_currency_symbol' id='base_currency_symbol' value={base_currency_symbol || ""} onKeyUp={handleChange} onChange={handleChange} className="lab-right" />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Formal name </label>
                      <label> :</label>
                      <input type="text" name='formal_name' id='formal_name' value={formal_name || " "} onKeyUp={handleChange} onChange={handleChange} className="lab-right" />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Suffix symbol to amount </label>
                      <label> :</label>
                      <input type="text" list='ssta' name='suffix_symbol_to_amount' id='suffix_symbol_to_amount' value={suffix_symbol_to_amount || ""} onKeyUp={handleChange} onChange={handleChange} className='lab-right' />
                      <datalist id='ssta'>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'> Add space between amount and symbol </label>
                      <label> :</label>
                      <input type="text" list='asbaas' name='add_space_between_amount_and_symbol' id='add_space_between_amount_and_symbol' value={add_space_between_amount_and_symbol || ""} className='lab-right' onKeyUp={handleChange} onChange={handleChange} />
                      <datalist id='asbaas'>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </datalist>
                    </div>
                  </div>
                  <div className='col-lg-6'>

                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Number of decimal places </label>
                      <label> :</label>
                      <input type="number" name='number_of_decimal_places' id='number_of_decimal_places' value={number_of_decimal_places || ""} className="lab-right" onKeyUp={handleChange} onChange={handleChange} />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-nowrap text-truncate'> Word representing amount after decimal  </label>
                      <label> :</label>
                      <input type="text" name='word_representing_amount_after_decimal' id='word_representing_amount_after_decimal' value={word_representing_amount_after_decimal || ""} onChange={handleChange} onKeyUp={handleChange} className="lab-right" />

                    </div>
                    <div className='d-flex justify-content-between align-item-center mb-1'>
                      <label className='lab-1 text-nowrap text-truncate'> Number of decimal places for amount in words </label>
                      <label> :</label>
                      <input type="text" name='number_fo_decimal_places_in_words' id='number_fo_decimal_places_in_words' value={number_fo_decimal_places_in_words || ""} onKeyUp={handleChange} onChange={handleChange} className="lab-right" />
                    </div>
                  </div>

                </div>
                <div className='tomasterfooter'>
                  <Footer />
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
      <div className='help'>
        <div className='card'>
          <h6 className='card-header'><span>F3:</span>Company</h6>
        </div>
        <div className='card'>
          <h6 className='card-header'><span>F2:</span>Date</h6>
        </div>
      </div>
    </div>
  )
}
export default Alter;
