import React, { useState } from 'react';
import HelpCard from '../HelpCard';

const BalanceSheetHelp = (props) => {
    const fields = [
        { id: "1", name: "Period", shortcutKey: "F2", state: props.setShow },
        { id: "2", name: "", shortcutKey: "F4", state: props.setShowGroup },
        { id: "3", name: "", shortcutKey: "F5", state: props.setShowLedgerWise },
        { id: "4", name: "Valuaion", shortcutKey: "F8", state: props.setShowMonthly },
        { id: "5", name: "", shortcutKey: "F6", state: '' },
        { id: "6", name: "", shortcutKey: "F7", state: '' },
        { id: "7", name: "", shortcutKey: "F8", state: '' },
        { id: "8", name: "", shortcutKey: "F9", state: '' },
        { id: "9", name: "Basis of Value", shortcutKey: "B", state: props.setShowBasis },
        { id: "10", name: "Change View", shortcutKey: "H", state: props.setShowChange },
        { id: "11", name: "Exception Reposts", shortcutKey: "J", state: props.setShowReport },
        { id: "12", name: "Save View", shortcutKey: "L", state: props.setShowSave },
        { id: "13", name: "Apply Filter", shortcutKey: "L", state: props.setShowApply },
        { id: "14", name: "Filter Detail", shortcutKey: "L", state: '' },
        { id: "15", name: "New Column", shortcutKey: "L", state: '' },
        { id: "16", name: "Alter Column", shortcutKey: "L", state: '' },
        { id: "17", name: "Delete Column", shortcutKey: "L", state: '' },
        { id: "18", name: "Auto Column", shortcutKey: "L", state: '' },
    ]

    return (
        <>
            {
                fields.map((item, index) => {
                    return (
                        <HelpCard key={item.id} fields={item} />
                    )
                })
            }
            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <div className='help_button_card mb-1 ms-1' >
                    <button className={`button_padding`}>
                        <p className='card-header'>
                            <span>F12</span>:
                            Configure
                        </p>
                    </button>
                </div>
            </div>

        </>
    )
}

export default BalanceSheetHelp;
