import React from 'react'

function Scenarios() {
  return (
    <div>
        this is scenarios of chart of account
      
    </div>
  )
}

export default Scenarios
