import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { SuitDiamondFill } from 'react-bootstrap-icons';
import Footer from '../../../../../components/home_page/Footer';
const ItemWithGodown = forwardRef((props) => {
    const navigate = useNavigate();


    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props?.setModal(false)
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setModal(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div id="myDropdown-list" className={`dropdown-content-list ${props?.show_godown_dropdown ? 'show-list' : ''}`}>
                <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                    List of Godown
                </div>
                <div className='col ' style={{ textAlign: 'right' }}>
                    <Link to='/create-master-ledger' onClick={() => navigate('/create-master-ledger')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                    <div className='border'></div>
                </div>
                <div id='data'>
                    {
                        props?.filteredDataGodown ? (
                            Array.isArray(props?.filteredDataGodown) && props?.filteredDataGodown?.length > 0 ? (
                                Array.isArray(props?.filteredDataGodown) && props?.filteredDataGodown?.map((key, number) => {
                                    const name = key?.name
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(props?.modal_data[number]?.godown_name?.toLowerCase());
                                    return (
                                        <Link
                                            className={`list font-weight-bold ${number === props?.selectedItem ? 'selected' : ''}`}
                                            id='list'
                                            key={number}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                props?.setSelectedItem(number)
                                                if (number === props?.selectedItem) {
                                                    props?.setmodal_data((prevValueLoop) => {
                                                        const updatedValueLoop = [...prevValueLoop];
                                                        console.log("updatedValueLoop[number]['godown_name'] = ==", number)
                                                        updatedValueLoop[index]['godown_name'] = name;
                                                        updatedValueLoop[index]['godown_name_id'] = id;
                                                        return updatedValueLoop;
                                                    });
                                                    props?.quantity_input_ref.current[index].focus();
                                                    props?.setshow_godown_dropdown(false)
                                                }
                                            }}
                                        >
                                            {name === 'Not Applicable' ? (
                                                <>
                                                    <SuitDiamondFill /> &nbsp;
                                                    {name}
                                                </>
                                            ) : index !== -1 ? (
                                                < >
                                                    {name?.substring(0, index)}
                                                    <span style={{ color: 'red' }}>{name?.substring(index, index + props?.modal_data[number].godown_name?.length)}</span>
                                                    {name?.substring(index + props?.modal_data[number].godown_name?.length)}
                                                </>
                                            ) : (
                                                name
                                            )}
                                        </Link>
                                    );
                                })
                            ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                            )) : (
                            <p>loading...</p>
                        )
                    }

                </div>
            </div>

            <div className='container pb-3 border border-dark' id='card-period' style={{ width: '40%', background: '#fff' }}>
                <div className='row'>
                    <div className='col border-bottom'>
                        <b>Allocations of :{props?.state?.stock_name}</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col' style={{ height: 400 }}>
                        <p className='m-0 p-0 mb-1 border-bottom border-gray'>For :{props?.state?.item_quantity}</p>
                        <div className='row border-bottom'>
                            <div className='col-4'>Godown</div>
                            {
                                props?.state?.item_quantity !== '' ?
                                    <>
                                        <div className='col-2'>Quantity</div>
                                        <div className='col-2'>Rate</div>
                                        <div className='col-2'>Per</div>
                                    </> : <div className='col-6'></div>
                            }

                            <div className='col-2'>Amount</div>
                        </div>
                        <div className='row'>
                            {props?.modal_data.map((item, index) => {
                                return (
                                    <div key={index} className={`col-12 border-bottom border-dark`} style={{ height: 24, backgroundColor: 'transparent', }}>
                                        <div className='row' style={{ height: 24 }}>
                                            <div className={`col-4 m-0  p-0 container_of_voucher_dynamic_input`}>
                                                <input
                                                    autoComplete='off'
                                                    type='text'
                                                    name={`godown_name${index}`}
                                                    ref={(input) => {
                                                        if (input && props?.godown_name_input_ref.current) {
                                                            props.godown_name_input_ref.current[index] = input;
                                                        }
                                                    }}
                                                    className='voucher_entry_account__dynamic_input'
                                                    onKeyUp={(e) => props?.handleKeyUpItemEntry(index, 'godown_name', e.target.value, e)}
                                                    onChange={(e) => props?.handleChangeItemEntry(index, 'godown_name', e.target.value)}
                                                    autoFocus
                                                    onFocus={props?.handleItemFocus}
                                                    onBlur={props?.handleItemBlur}
                                                    value={props?.modal_data[index]?.godown_name || ''}
                                                />

                                            </div>
                                            {/* {
                                                state[index]?.godown_name !== '' ?
                                                    <> */}
                                            {
                                                props?.state?.item_quantity !== '' ?
                                                    <>
                                                        <div className={`col-2 m-0 p-0 font-weight-bold container_of_voucher_dynamic_input `}>
                                                            <input
                                                                type='text'
                                                                autoComplete='off'
                                                                // disabled={item_entry[index]?.is_unit_available === '' || data?.is_unit_available === 'Not Applicable'}
                                                                style={{ textAlign: 'right', paddingRight: 5 }}
                                                                name={`quantity${index}`}
                                                                ref={(input) => {
                                                                    if (input && props?.quantity_input_ref.current) {
                                                                        props.quantity_input_ref.current[index] = input;
                                                                    }
                                                                }}
                                                                className='voucher_entry_account__dynamic_input'
                                                                onKeyUp={(e) => props?.handleKeyUpItemEntry(index, 'quantity', e.target.value, e)}
                                                                onChange={(e) => props?.handleChangeItemEntry(index, 'quantity', e.target.value)}
                                                                value={props?.modal_data[index]?.quantity || ''}
                                                            />
                                                        </div>
                                                        <div className='col-2 m-0 container_of_voucher_dynamic_input p-0'>
                                                            <input
                                                                type='text'
                                                                // disabled={item_entry[index]?.is_unit_available === '' || data?.is_unit_available === 'Not Applicable'}
                                                                style={{ textAlign: 'right', paddingRight: 5 }}
                                                                name={`rate${index}`}
                                                                ref={(input) => {
                                                                    if (input && props?.rate_input_ref.current) {
                                                                        props.rate_input_ref.current[index] = input;
                                                                    }
                                                                }}
                                                                className='voucher_entry_account__dynamic_input'
                                                                onKeyUp={(e) => props?.handleKeyUpItemEntry(index, 'rate', e.target.value, e)}
                                                                onChange={(e) => props?.handleChangeItemEntry(index, 'rate', e.target.value)}
                                                                value={props?.modal_data[index]?.rate || ''}
                                                            />
                                                        </div>
                                                        <div className='col-2 m-0  container_of_voucher_dynamic_input  p-0'>
                                                            <input
                                                                type='text'
                                                                style={{ textAlign: 'right', paddingRight: 5 }}
                                                                name={`per${index}`}
                                                                ref={(input) => {
                                                                    if (input && props?.per_input_ref.current) {
                                                                        props.per_input_ref.current[index] = input;
                                                                    }
                                                                }}
                                                                className='voucher_entry_account__dynamic_input'
                                                                onKeyUp={(e) => props?.handleKeyUpItemEntry(index, 'per', e.target.value, e)}
                                                                onChange={(e) => props?.handleChangeItemEntry(index, 'per', e.target.value)}
                                                                value={props?.modal_data[index]?.per || ''}
                                                            />
                                                        </div>
                                                    </> : <div className='col-6'></div>
                                            }
                                            <div className='col-2 m-0 container_of_voucher_dynamic_input  p-0'>
                                                <input
                                                    type='text'
                                                    style={{ textAlign: 'left' }}
                                                    name={`amount${index}`}
                                                    ref={(input) => {
                                                        if (input && props?.amount_input_ref.current) {
                                                            props.amount_input_ref.current[index] = input;
                                                        }
                                                    }}
                                                    className='voucher_entry_account__dynamic_input'
                                                    onKeyUp={(e) => props?.handleKeyUpItemEntry(index, 'amount', e.target.value, e)}
                                                    onChange={(e) => props?.handleChangeItemEntry(index, 'amount', e.target.value)}
                                                    value={props?.modal_data[index]?.amount || ''}
                                                />
                                            </div>
                                            {/* </> : null
                                            } */}
                                        </div>

                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
});

export default ItemWithGodown;
