import React from 'react'

function TableLayout({children}) {

    return (
        <div className='container-fluid' id='reletive' style={{ height: '90vh' }}>
            <div className='row border-bottom border-dark' style={{ height: 40 }}>
                <div className='col-2 border-end border-start border-dark d-flex justify-content-start align-items-center table_header_font'>
                    <b style={{ wordSpacing: '30px' }}> Particular </b>
                </div>
                <div className='col-10 m-0 p-0'>
                    <div className='row m-0 p-0' style={{ width: '100%', height: 39.33 }}>
                        <div className='col-4  border-end border-dark m-0 p-0 table_header_font'>
                            <b>Inwards</b>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_header_font'>
                                    Quantity
                                </div>
                                <div className='col-4 table_header_font text-end p-0 m-0'>
                                    (Alt.Units)
                                </div>
                                <div className='col-4 text-end p-0 pe-1 m-0 table_header_font'>
                                    Value
                                </div>
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0 table_header_font'>
                            <b>Outwards</b>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_header_font' >
                                    Quantity
                                </div>
                                <div className='col-4 m-0 p-0 text-end table_header_font' >
                                    (Alt.Units)
                                </div>
                                <div className='col-4 text-end p-0 pe-1 m-0 table_header_font' >
                                    Value
                                </div>
                                {/* <div className='col-2 border border-dark text-end p-0 pe-1 m-0 font_size_10_px' >
                                    Gross Value
                                </div>
                                <div className='col-1 border border-dark text-end p-0 pe-1 m-0 font_size_10_px' >
                                    Comsumption
                                </div>
                                <div className='col-2 border border-dark text-end p-0 pe-1 m-0 font_size_10_px'>
                                    Gross Profit
                                </div>
                                <div className='col-1  border border-dark text-end p-0 pe-1 m-0 font_size_10_px' >
                                    Per%
                                </div> */}
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0 table_header_font'>
                            <b>Closing Balance</b>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_header_font' >
                                    Quantity
                                </div>
                                <div className='col-4 p-0 m-0 text-end table_header_font'>
                                    (Alt.Units)
                                </div>
                                <div className='col-4 text-end p-0 pe-1 m-0 table_header_font'>
                                    Value
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={` row  border-bottom border-dark postion-relative`} style={{ height: '76.5vh' }} >
                <div className='col-2 border-end border-start border-dark text-start table_body_font' style={{ height: '76.5vh' }} >
                </div>
                <div className='col-10 m-0 p-0' style={{ height: '76.5vh' }}>
                    <div className='row m-0 p-0 ' style={{ width: '100%', height: '76.5vh' }}>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-4 text-end m-0 p-0 table_body_font'>
                                </div>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                {/* <div className='col-2 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-2 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-2 text-end p-0 m-0 table_body_font'>
                                </div> */}
                                {/* <div className='col-1 text-end p-0 m-0 table_body_font'>
                                </div> */}

                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-4 text-end m-0 p-0 table_body_font'>
                                </div>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 p-0' style={{ position: 'absolute', width: '100%' }}>
                    <div className='col-12'>
                        {children}
                    </div>
                </div>
            </div>


            {/* <div className={`row border ${data?.length > 0 ? 'border-top-0' : ''}  border-end-0 border-dark`}>
                <div className='col-2 border-end border-dark text-start table_footer_font'>
                    Grand Total
                </div>
                <div className='col-10 m-0 p-0'>
                    <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                        <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-3 text-end p-0 m-0 table_footer_font' style={{ width: '25%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {`${total_opnening_quantity} ${unit_name}`}
                                </div>
                                <div className='col-3 text-end m-0 p-0 table_footer_font' style={{ width: '37.45%', overflow: 'hidden', }}>
                                </div>
                                <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%', overflow: 'hidden', whiteSpace: 'nowrap', wordSpacing: -7 }}>
                                    {total_opening_value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                </div>
                            </div>
                        </div>
                        <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-3  text-end p-0 m-0 table_footer_font' style={{ width: '25%' }}>
                                    {`${total_inwards_quantity} ${unit_name}`}
                                </div>
                                <div className='col-3  text-end table_footer_font p-0 m-0' style={{ width: '37.45%' }}>
                                </div>
                                <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%' }}>
                                    {total_inwards_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                </div>
                            </div>
                        </div>
                        <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-3  text-end p-0 m-0 table_footer_font' style={{ width: '25%' }}>
                                    {`${total_outwards_quantity} ${unit_name}`}
                                </div>
                                <div className='col-3  text-end m-0 p-0 table_footer_font' style={{ width: '37.45%' }}>
                                </div>
                                <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%' }}>
                                    {total_outwards_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                </div>
                            </div>
                        </div>
                        <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-3  text-end p-0 m-0 table_footer_font' style={{ width: '25%' }}>
                                    {`${total_closing_quantity} ${unit_name}`}
                                </div>
                                <div className='col-3 text-end m-0 p-0 table_footer_font' style={{ width: '37.45%' }}>
                                </div>
                                <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%' }}>
                                    {total_closing_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default TableLayout




