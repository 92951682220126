import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
function AnalysisVerification() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (event.key === 'Enter') {
                event.preventDefault();
                // You can navigate to the selected link's path here
                const selectedLink = accounting[selectedIndex];
                if (selectedLink) {
                    event.preventDefault();
                    navigate(selectedLink.to)
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);
    const accounting = [
        { to: '/createmasters', text: 'Data Analysis' },
        { to: '/daybooktransaction', text: 'Voucher Verification' },
        { to: '/chartofaccountmasters', text: 'Quit' },
    ];
    const totalLinks = accounting.length;
    return (
        <div className='card-container' id='card-container'>
            <div className='card-title'>
                <p className='title-of'>Analysis & Verification</p>
            </div>
            <div className='gateway_tally_card'>
                <div className='list-1'>
                    <ul className='list-item'>
                        {accounting.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>{link?.text}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AnalysisVerification;
