import React, { useState, useEffect, useRef, forwardRef } from 'react'
import Footer from '../../../../components/home_page/Footer';
const OrderDetails = forwardRef((props, ref_name) => {
    const [state_order_details, setstate_order_details] = useState({
        order_no: '',
        mode_terms_of_payment: '',
        other_references: '',
        terms_of_delivery: '',
    })
    const {
        order_no,
        mode_terms_of_payment,
        other_references,
        terms_of_delivery,
    } = state_order_details

    // Create mutable object of all input to focus 
    const order_no_input_ref = useRef(null)
    const mode_terms_of_payment_input_ref = useRef(null)
    const other_references_input_ref = useRef(null)
    const terms_of_delivery_input_ref = useRef(null)

    const handlechange = (e) => {
        const { name, value } = e.target;
        const capitalizeFirstLetter = (str) => {
            return str.toLowerCase().replace(/(^|\s)\S/g,
                (firstLetter) => firstLetter.toUpperCase());
        };
        // space key functionality
        if (e.code == 'Space') {
            e.preventDefault();
            const cursorPosition_start = e.target.selectionStart;
            if (cursorPosition_start == 1) {
                setstate_order_details({ ...state_order_details, [name]: '' });
                return;
            }
        }
        // Enter key functionality
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInputRef = name === 'order_no' ? mode_terms_of_payment_input_ref :
                name === 'mode_terms_of_payment' ? other_references_input_ref :
                    name === 'other_references' ? terms_of_delivery_input_ref : null;
            if (name === 'terms_of_delivery') {
                const isEmpty = Object.values(state_order_details).every(value => value === '');
                if (isEmpty) {
                    props?.setData({ ...props?.data, order_details: 'Not Available' });
                } else {

                }
                props.setshow_order_details_modal(false)
                props?.ref_name.current[0]?.focus()
            }
            if (nextInputRef) {
                nextInputRef.current.focus();
                if (nextInputRef.current) {
                    nextInputRef.current.setSelectionRange(0, nextInputRef?.current?.value?.length);
                }
            }
            return;
        }
        // Capitalize the first letter of each word for certain input fields
        const updatedValue = name === 'mode_terms_of_payment' || name === 'other_references' || name === 'terms_of_delivey' ?
            capitalizeFirstLetter(value) : value;
        setstate_order_details({ ...state_order_details, [name]: updatedValue })

    }
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setshow_order_details_modal(false);
                props?.ref_name.current?.focus()
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.setshow_order_details_modal]);
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setshow_order_details_modal(false), props?.ref_name.current?.focus()) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3 border border-dark' id='card-period' style={{ width: '60%', background: '#fff' }}>
                <div className='row'>
                    <div className='col border-bottom'>
                        <b>Order Details</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p className='m-0 p-0 mb-1 border-bottom border-gray'>General Details</p>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Order No(s)</label>
                            <label>:</label>
                            <input
                                type='text'
                                autoFocus
                                name='order_no'
                                id='order_no'
                                ref={order_no_input_ref}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={order_no || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Mode/Term of Payment</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='mode_terms_of_payment'
                                ref={mode_terms_of_payment_input_ref}
                                id='mode_terms_of_payment'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={mode_terms_of_payment || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Other References</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='other_references'
                                id='other_references'
                                ref={other_references_input_ref}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={other_references || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Term of Delivery</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='terms_of_delivery'
                                ref={terms_of_delivery_input_ref}
                                id='terms_of_delivery'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={terms_of_delivery || ''}
                                className='lab-right' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
});

export default OrderDetails
