import axios from 'axios';
export const baseURL = 'https://anomla.net/account-web/api/';
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
    "Accept": "application/json",
    'X-API-KEY': 'development101010102020203030',
  }
});

export default axiosInstance;