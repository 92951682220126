import React from 'react'
import FooterLayout from './FooterLayout'

function StockItemVoucherFooter(props) {
    const fields = [
        { id: "1", name: "Back", shortcutKey: "Q", state: props.setShow },
        { id: "2", name: "Alter", shortcutKey: "Enter", state: props.setStockGroup },
        { id: "3", name: "Select", shortcutKey: "Space", },
        { id: "4", name: "Add Voucher", shortcutKey: "A", state: props.setStockCate },
        { id: "5", name: "Duplicate Vch", shortcutKey: "2", state: props.setStockCompany },
        { id: "6", name: "Insert Vch", shortcutKey: "I", state: props.setGodownWise },
        { id: "7", name: "Delete", shortcutKey: "D", },
        { id: "8", name: "Cancel Vch", shortcutKey: "X", },
        { id: "9", name: "Remove Line", shortcutKey: "R", state: props.setValuation },
        { id: "10", name: "Restore Line", shortcutKey: "U", state: props.setBasic },
    ]
    return (
        <div className='tomasterfooter'>
            <div className='footer'>
                {
                    fields.map((item, index) => {
                        return (
                            <FooterLayout key={item.id} fields={item} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default StockItemVoucherFooter
