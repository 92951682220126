import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../../../components/home_page/Footer';
import './alter.css';

function Alter() {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % totalLinks);
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + totalLinks) % totalLinks);
      } else if (event.key === 'Enter') {
        // You can navigate to the selected link's path here
        const selectedLink = account_master[selectedIndex] || inventroy_master[selectedIndex]||statutory_details[selectedIndex];
        if (selectedLink) {
          // window.location.href = selectedLink.to;
          navigate(selectedLink.to)
        }
      } 
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex]);

  const account_master = [
    { to: "/alter-accounting-grouplist", text: "Group" },
    { to: "/alter_accounting_ledgerlist", text: "Ledger" },
    { to: "/alter-accounting-currency", text: "Currency" },
    { to: "/alter-accounting-vouchertype", text: "Vouchers Type" },
  ];
  const inventroy_master = [];
   inventroy_master[4]={ to: "/alter-inventory-stockgrouplist", text: "Stock Group" };
   inventroy_master[5]={ to: "/alter-inventory-stockcategorylist", text: "Stock Category"};
   inventroy_master[6]={ to: "/alter-inventory-stockcompanylist", text: "Stock Company" };
   inventroy_master[7]={ to: "/alter-inventory-stockitem", text: "Stock Item" };
   inventroy_master[8]={ to: "/alter-inventory-unitlist", text: "Unit" };
   inventroy_master[9]={ to: "/alter-inventory-godown", text: "Godown" };
  const statutory_details= [];
   statutory_details[10]={ to: "/alter-statutory-gstdetails", text: "GST Details" };
   statutory_details[11]= { to: "/alter-statutory-pan_details", text: "PAN/CIN Details" };

  const totalLinks = account_master.length+inventroy_master.length+statutory_details.length;
  return (
    <div className='section'>
      <div className='detail border'>
        <div className='container'>
          <div className='row d-flex justify-content-center align-content-center master_container' >
            <div className='col-4 border border-dark p-0 m-0 master_container_color'>
              <div className='card_header'>
                <p className='m-0 p-0 ps-1 text-start'>Master Alteration</p>
              </div>
              <div className='row mt-4'>
                <div className='col-12'>
                  <p className='m-0 p-0 ps-1 text-start text-info font_size'>Accounting Master</p>
                  <ul className='master_ul_link_list'>
                    {account_master.map((link, index) => (
                      <div key={index} >
                        <li className={index === selectedIndex ? 'selected' : ''}>
                          <Link to={link.to}>{link?.text}</Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className='col-12'>
                  <p className='m-0 p-0 ps-1 text-start text-info font_size'>Inventory Masters</p>
                  <ul className='master_ul_link_list'>
                    {inventroy_master.map((link, index) => (
                      <div key={index}>
                        <li  className={index === selectedIndex ? 'selected' : ''}>
                          <Link to={link.to}>{link?.text}</Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className='col-12'>
                  <p className='m-0 p-0 ps-1 text-start text-info font_size'>Statutory Details</p>
                  <ul className='master_ul_link_list'>
                    {statutory_details.map((link, index) => (
                      <div key={index}>
                        <li  className={index === selectedIndex ? 'selected' : ''}>
                          <Link to={link.to}>{link?.text == 'GST Details' ? 'GST Details' : null}</Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='tomasterfooter'>
          <Footer />
        </div>
      </div>
      <div className='help'></div>

    </div>
  )
}

export default Alter

