import React, { useState, useEffect, useCallback, useRef } from "react";
import Footer from "../../../home_page/Footer";
function Configure(props) {
    const [state, setState] = useState(
        {
            show_quantity: 'No',
            show_alternate_unit: 'No',
            show_tail_unit_of_compound_unit: 'No',
            show_rate: 'No',
            show_value: 'No',
            show_opening_balance: 'No',
            show_goods_inwards: 'No',
            show_goods_outwards: 'No',
            show_gross_value_of_outwards: 'No',
            show_comsumption_and_gross_profit: 'No',
            show_gross_profits_on_gross_value: 'No',
            show_closing_balance: 'No',
            show_stock_item_with_zero_qty_or_blc: 'No',
            exclude_detail_with_no_transaction: 'No',
            type_of_grouping: '',
            formate_of_report: 'Condensed',
            expand_all_level_in_details_formate: 'No',
            display_name: 'Name Only',
            sorting_method: 'Default',
        })
    const show_quantity_input_ref = useRef(null)
    const show_alternate_unit_input_ref = useRef(null)
    const show_tail_unit_of_compound_unit_input_ref = useRef(null)
    const show_rate_input_ref = useRef(null)
    const show_value_input_ref = useRef(null)
    const show_opening_balance_input_ref = useRef(null)
    const show_goods_inwards_input_ref = useRef(null)
    const show_goods_outwards_input_ref = useRef(null)
    const show_gross_value_of_outwards_input_ref = useRef(null)
    const show_comsumption_and_gross_profit_input_ref = useRef(null)
    const show_gross_profits_on_gross_value_input_ref = useRef(null)
    const show_closing_balance_input_ref = useRef(null)
    const show_stock_item_with_zero_qty_or_blc_input_ref = useRef(null)
    const exclude_detail_with_no_transaction_input_ref = useRef(null)
    const type_of_grouping_input_ref = useRef(null)
    const formate_of_report_input_ref = useRef(null)
    const expand_all_level_in_details_formate_input_ref = useRef(null)
    const display_name_input_ref = useRef(null)
    const sorting_method_input_ref = useRef(null)

    const getNextInputRef = (name) => {
        switch (name) {
            case 'show_quantity': return show_alternate_unit_input_ref;
            case 'show_alternate_unit': return show_tail_unit_of_compound_unit_input_ref;
            case 'show_tail_unit_of_compound_unit': return show_rate_input_ref;
            case 'show_rate': return show_value_input_ref;
            case 'show_value': return show_opening_balance_input_ref;
            case 'show_opening_balance': return show_goods_inwards_input_ref;
            case 'show_goods_inwards': return show_goods_outwards_input_ref;
            case 'show_goods_outwards': return show_gross_value_of_outwards_input_ref;
            case 'show_gross_value_of_outwards': return show_comsumption_and_gross_profit_input_ref;
            case 'show_comsumption_and_gross_profit': return show_gross_profits_on_gross_value_input_ref;
            case 'show_gross_profits_on_gross_value': return show_closing_balance_input_ref;
            case 'show_closing_balance': return show_stock_item_with_zero_qty_or_blc_input_ref;
            case 'show_stock_item_with_zero_qty_or_blc': return exclude_detail_with_no_transaction_input_ref;
            case 'exclude_detail_with_no_transaction': return type_of_grouping_input_ref;
            case 'type_of_grouping': return formate_of_report_input_ref;
            case 'formate_of_report': return expand_all_level_in_details_formate_input_ref;
            case 'expand_all_level_in_details_formate': return display_name_input_ref;
            case 'display_name': return sorting_method_input_ref;
            case 'sorting_method': return null;
            default: return null;
        }
    }
    const getCurrentInputRef = (name) => {
        switch (name) {
            case 'show_quantity': return show_quantity_input_ref;
            case 'show_alternate_unit': return show_alternate_unit_input_ref;
            case 'show_tail_unit_of_compound_unit': return show_tail_unit_of_compound_unit_input_ref;
            case 'show_rate': return show_rate_input_ref;
            case 'show_value': return show_value_input_ref;
            case 'show_opening_balance': return show_opening_balance_input_ref;
            case 'show_goods_inwards': return show_goods_inwards_input_ref;
            case 'show_goods_outwards': return show_goods_outwards_input_ref;
            case 'show_gross_value_of_outwards': return show_gross_value_of_outwards_input_ref;
            case 'show_comsumption_and_gross_profit': return show_comsumption_and_gross_profit_input_ref;
            case 'show_gross_profits_on_gross_value': return show_gross_profits_on_gross_value_input_ref;
            case 'show_closing_balance': return show_closing_balance_input_ref;
            case 'show_stock_item_with_zero_qty_or_blc': return show_stock_item_with_zero_qty_or_blc_input_ref;
            case 'exclude_detail_with_no_transaction': return exclude_detail_with_no_transaction_input_ref;
            case 'type_of_grouping': return type_of_grouping_input_ref;
            case 'formate_of_report': return formate_of_report_input_ref;
            case 'expand_all_level_in_details_formate': return expand_all_level_in_details_formate_input_ref;
            case 'display_name': return display_name_input_ref;
            case 'sorting_method': return sorting_method_input_ref;
            default: return null;
        }
    }
    const handleFocus = (e) => {
        const { name, value } = e.target;
        const ref1 = getCurrentInputRef(name)
        if (ref1) {
            setTimeout(() => {
                ref1.current.setSelectionRange(0, ref1.current.value.length)
            }, 50);
        }
    }
    const handleBlur = (e) => {
        const { name, value } = e.target;
    }
    const handlechange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        // space key functionality
        if (e.code == 'Space') {
            e.preventDefault();
            const cursorPosition_start = e.target.selectionStart;
            if (cursorPosition_start == 1) {
                setState({ ...state, [name]: '' });
                return;
            }
        }
        // Enter key functionality
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInputRef = getNextInputRef(name)
            if (name === 'sorting_method') {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    props.setState_configure(
                        {
                            show_quantity: state?.show_quantity,
                            show_alternate_unit: state?.show_alternate_unit,
                            show_tail_unit_of_compound_unit: state?.show_tail_unit_of_compound_unit,
                            show_rate: state?.show_rate,
                            show_value: state?.show_value,
                            show_opening_balance: state?.show_opening_balance,
                            show_goods_inwards: state?.show_goods_inwards,
                            show_goods_outwards: state?.show_goods_outwards,
                            show_gross_value_of_outwards: state?.show_gross_value_of_outwards,
                            show_comsumption_and_gross_profit: state?.show_comsumption_and_gross_profit,
                            show_gross_profits_on_gross_value: state?.show_gross_profits_on_gross_value,
                            show_closing_balance: state?.show_closing_balance,
                            show_stock_item_with_zero_qty_or_blc: state?.show_stock_item_with_zero_qty_or_blc,
                            exclude_detail_with_no_transaction: state?.exclude_detail_with_no_transaction,
                            type_of_grouping: state?.type_of_grouping,
                            formate_of_report: state?.formate_of_report,
                            expand_all_level_in_details_formate: state?.expand_all_level_in_details_formate,
                            display_name: state?.display_name,
                            sorting_method: state?.sorting_method,
                        }
                    )
                    props.setshow_configure(false)
                }
            }
            if (nextInputRef) {
                nextInputRef.current.focus();
                if (nextInputRef.current) {
                    nextInputRef.current.setSelectionRange(0, nextInputRef?.current?.value?.length);
                }
            }
            return;
        }
        // // Back space key functionality
        // if (e.key === 'Backspace') {
        //     e.preventDefault();
        //     const prevInputRef = name === 'formate_of_report' ? show_narrations_input_ref :
        //         name === 'show_bill_wise_details' ? formate_of_report_input_ref :
        //             name === 'show_inventory_details' ? show_bill_wise_details_input_ref :
        //                 name === 'show_mode_of_payment' ? show_inventory_details_input_ref :
        //                     name === 'show_additional_bank_details' ? show_mode_of_payment_input_ref :
        //                         name === 'display_name_for_ledgers' ? show_additional_bank_details_input_ref :
        //                             name === 'display_name_for_stock_items' ? display_name_for_ledgers_input_ref :
        //                                 name === 'sorting_method' ? display_name_for_stock_items_input_ref :
        //                                     name === 'show_running_balance' ? sorting_method_input_ref : null;
        //     const cursorPosition = e.target.selectionStart;
        //     if (cursorPosition === 0) {
        //         prevInputRef.current.focus();
        //         if (prevInputRef.current) {
        //             prevInputRef.current.setSelectionRange(0, 0);
        //         }
        //     }
        //     return;
        // }
    }
    const {
        show_quantity,
        show_alternate_unit,
        show_tail_unit_of_compound_unit,
        show_rate,
        show_value,
        show_opening_balance,
        show_goods_inwards,
        show_goods_outwards,
        show_gross_value_of_outwards,
        show_comsumption_and_gross_profit,
        show_gross_profits_on_gross_value,
        show_closing_balance,
        show_stock_item_with_zero_qty_or_blc,
        exclude_detail_with_no_transaction,
        type_of_grouping,
        formate_of_report,
        expand_all_level_in_details_formate,
        display_name,
        sorting_method,
    } = state;
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setshow_configure(false);
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.show_configure]);
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setshow_configure(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3 border border-dark' id='card-period' style={{ width: '60%', background: '#fff' }}>
                <div className='row'>
                    <div className='col border-bottom'>
                        <b>Configure</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col mt-1'>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Quantity</label>
                            <label>:</label>
                            <input
                                type='text'
                                autoFocus
                                name='show_quantity'
                                id='show_quantity'
                                ref={show_quantity_input_ref}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_quantity || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Alternate unit</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_alternate_unit'
                                ref={show_alternate_unit_input_ref}
                                id='show_alternate_unit'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_alternate_unit || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show tail unit of compound unit</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_tail_unit_of_compound_unit'
                                id='show_tail_unit_of_compound_unit'
                                ref={show_tail_unit_of_compound_unit_input_ref}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_tail_unit_of_compound_unit || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Rate</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_rate'
                                ref={show_rate_input_ref}
                                id='show_rate'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_rate || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Value</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_value'
                                ref={show_value_input_ref}
                                id='show_value'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_value || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show opening balance</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_opening_balance'
                                ref={show_opening_balance_input_ref}
                                id='show_opening_balance'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_opening_balance || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>show goods inwards</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_goods_inwards'
                                ref={show_goods_inwards_input_ref}
                                id='show_goods_inwards'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_goods_inwards || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>show goods outwards</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_goods_outwards'
                                ref={show_goods_outwards_input_ref}
                                id='show_goods_outwards'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_goods_outwards || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>show gross value of outwards</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_gross_value_of_outwards'
                                ref={show_gross_value_of_outwards_input_ref}
                                id='show_gross_value_of_outwards'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_gross_value_of_outwards || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>show comsumption and gross profit</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_comsumption_and_gross_profit'
                                ref={show_comsumption_and_gross_profit_input_ref}
                                id='show_comsumption_and_gross_profit'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_comsumption_and_gross_profit || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>show gross profits on gross value</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_gross_profits_on_gross_value'
                                ref={show_gross_profits_on_gross_value_input_ref}
                                id='show_gross_profits_on_gross_value'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_gross_profits_on_gross_value || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>show closing balance</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_closing_balance'
                                ref={show_closing_balance_input_ref}
                                id='show_closing_balance'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_closing_balance || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>show stock item with zero qty or blc</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='show_stock_item_with_zero_qty_or_blc'
                                ref={show_stock_item_with_zero_qty_or_blc_input_ref}
                                id='show_stock_item_with_zero_qty_or_blc'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={show_stock_item_with_zero_qty_or_blc || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>exclude detail with no transaction</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='exclude_detail_with_no_transaction'
                                ref={exclude_detail_with_no_transaction_input_ref}
                                id='exclude_detail_with_no_transaction'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={exclude_detail_with_no_transaction || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>type of grouping</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='type_of_grouping'
                                ref={type_of_grouping_input_ref}
                                id='type_of_grouping'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={type_of_grouping || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>formate of report</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='formate_of_report'
                                ref={formate_of_report_input_ref}
                                id='formate_of_report'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={formate_of_report || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>expand all level in details formate</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='expand_all_level_in_details_formate'
                                ref={expand_all_level_in_details_formate_input_ref}
                                id='expand_all_level_in_details_formate'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={expand_all_level_in_details_formate || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>display name</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='display_name'
                                ref={display_name_input_ref}
                                id='display_name'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={display_name || ''}
                                className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>sorting method</label>
                            <label>:</label>
                            <input
                                type='text'
                                name='sorting_method'
                                ref={sorting_method_input_ref}
                                id='sorting_method'
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                value={sorting_method || ''}
                                className='lab-right' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}
export default Configure