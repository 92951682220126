import React from 'react'
import FooterLayout from './FooterLayout'

function TrialBalanceFooter(props) {
    const fields = [
        { id: "1", name: "Quit", shortcutKey: "Q", state: props.setShow },
        { id: "2", name: "", shortcutKey: "", state: props.setStockGroup },
        { id: "3", name: "Select", shortcutKey: "Space", },
        { id: "4", name: "", shortcutKey: "", state: props.setStockCate },
        { id: "5", name: "", shortcutKey: "", state: props.setStockCompany },
        { id: "6", name: "", shortcutKey: "", state: props.setGodownWise },
        { id: "7", name: "", shortcutKey: "", },
        { id: "8", name: "", shortcutKey: "", },
        { id: "9", name: "Remove Line", shortcutKey: "R", state: props.setValuation },
        { id: "10", name: "Restore Line", shortcutKey: "U", state: props.setBasic },
    ]
    return (
        <div className='tomasterfooter'>
            <div className='footer'>
                {
                    fields.map((item, index) => {
                        return (
                            <FooterLayout key={item.id} fields={item} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default TrialBalanceFooter
