import React, { useEffect, useState, useCallback } from 'react';
import './balancesheet.css';
import TableLayoutProfitBalance from '../../../components/Table/TableLayoutProfitBalance';
import NavLayout from '../../../components/NavNameOfComponent/NavLayout';
import BalanceSheetFooter from '../../../components/Footer/BalanceSheetFooter';
import BalanceSheetHelp from '../../../components/help/BalanceSheet/BalanceSheetHelp';
import { useNavigate } from 'react-router-dom';

function BalanceSheet() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedItem1, setSelectedItem1] = useState()
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItems1, setSelectedItems1] = useState([]);
  const data = [
    { groupname: 'Current Liabilities', amount: '9,99,99,99,999.00 Cr' },
    { groupname: 'Purchase Account', amount: '9,99,99,99,999.00 Dr' },
    { groupname: 'Sales Account', amount: '9,99,99,99,999.00 Dr' },
    { groupname: 'Suspense Account', amount: '9,99,99,99,999.00 Dr' },
    { groupname: 'Capital Account', amount: '9,99,99,99,999.00 Dr' }
  ]
  const data1 = [
    { groupname: 'Current Asset', amount: '9,99,99,99,999.00 Cr' },
    { groupname: 'Bank Account', amount: '9,99,99,99,999.00 Dr' },
    { groupname: 'Loan and Advance', amount: '9,99,99,99,999.00 Dr' },
    { groupname: 'Stock in Hand', amount: '9,99,99,99,999.00 Dr' },
  ]
  const handleKeyDown = useCallback((e) => {
    if (selectedItem !== null && selectedItem !== undefined) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, data?.length - 1));
        } else
          if (e.key === 'ArrowRight') {
            setSelectedItem()
            setSelectedItem1(0)
          } else if (e.key === 'ArrowLeft') {
            setSelectedItem(0)
            setSelectedItem1()
          }

      if (e.key === 'Enter') {
        e.preventDefault();
        const selectedList = data[selectedItem]
        if (selectedList) {
          navigate(`../group_summary`)
        }
      }
    }
    if (selectedItem1 !== null && selectedItem1 !== undefined) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem1(prevState => Math.max(prevState - 1, 0));
      } else
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem1(prevState => Math.min(prevState + 1, data1?.length - 1));
        } else
          if (e.key === 'ArrowRight') {
            setSelectedItem()
            setSelectedItem1(0)
          } else if (e.key === 'ArrowLeft') {
            setSelectedItem(0)
            setSelectedItem1()
          }
      if (e.key === 'Enter') {
        e.preventDefault();
        const selectedList = data1[selectedItem1]
        if (selectedList) {
          navigate(`../group_summary`)
        }
      }
    }
  }, [selectedItem, selectedItem1, data1, data])
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown])
  return (
    <div className='section'>
      <div className='detail'>
        <div className='container-fluid'>
          <NavLayout>
            Balance Sheet  <span>1-Apr-2024 To 31-March-2024</span>
          </NavLayout>
        </div>
        <TableLayoutProfitBalance
          table_heading={'Liabilities'}
          table_heading1={'Assets'}
        >
          <div className='col-md-6'>
            {
              // loading ? <div>Loading...</div> :
              data?.map((item, index) => {
                return (
                  <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                    <div className={`row pe-0 ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                      <div className={`col-8 text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                        {item.groupname}
                      </div>
                      <div className={`col-4 text-end table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                        {item.amount}
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className='col-md-6'>
            {
              // loading ? <div>Loading...</div> :
              data1?.map((item, index) => {
                return (
                  <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                    <div className={`row pe-0 ${index === selectedItem1 ? selectedItems1.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                      <div className={`col-8 text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                        {item.groupname}
                      </div>
                      <div className={`col-4 text-end table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                        {item.amount}
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </TableLayoutProfitBalance>
        <BalanceSheetFooter />
      </div>
      <div className='help' style={{ position: 'relative' }}>
        <BalanceSheetHelp />
      </div>
    </div>
  )
}

export default BalanceSheet
