import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { stockItemList } from "../../../../redux/actions/action-creator";
function StockItem(props) {
  const str = '\u2666';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedItemRow, setSelectedItemRow] = useState([])
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsName, setSelectedItemsName] = useState([]);
  const [all_data, setall_data] = useState([])
  const [state, setState] = useState({ search_input: '', })
  const [filteredData, setFilteredData] = useState([])
  const [selectedItem, setSelectedItem] = useState(0);
  const [loading, setloading] = useState(true)

  const search_input_ref = useRef(null)
  const boxRef = useRef(null)

  const { search_input } = state;
  const { userData = null } = useSelector(state => state.auth);

  // Triger function whenever the change input value 
  const handlechange = (e) => {
    const { name, value } = e.target;
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'search_input':
        const group = all_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group) {
          setState({ ...state, [name]: value })
        }
        break;
      default: return

    }
  }
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    if (all_data[0]?.name === 'Primary') {
      // Do Not Add Any Data 
    } else {
      all_data?.unshift({ id: '', name: 'Primary' });
    }
    const filteredSuggestions = all_data?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.search_input?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.search_input !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredData(state.search_input !== '' ? filteredSuggestions : all_data);
    if (filteredSuggestions?.length > 0 && search_input_ref?.current?.selectionStart > 0) {
      setSelectedItem(0);
    }
  }, [search_input, all_data])

  // Api call to get data of ledger book
  useEffect(() => {
    const data = new FormData();
    data.append('company_id', userData?.id)
    dispatch(stockItemList(data)).then((response) => {
      if (response.status) {
        setall_data(response.data)
        setloading(false)
      }
    })
  }, [])

  // scroll functionality ---------------------------
  useEffect(() => {
    if (boxRef.current) {
      // setClick(parseInt((boxRef.current.scrollHeight - boxRef.current.offsetHeight - boxRef.current.scrollTop) / 19))
      const selectedElement = boxRef.current.querySelector('.selected');
      if (selectedElement) {
        const boxHeight = boxRef.current.offsetHeight;
        const elementBottom = selectedElement.offsetTop - 60;
        const elementBottom1 = selectedElement.offsetTop + selectedElement.offsetHeight;
        if (elementBottom > boxHeight) {
          // setClick(parseInt((boxRef.current.scrollHeight - boxRef.current.offsetHeight - boxRef.current.scrollTop) / 20))
          boxRef.current.scrollTop = elementBottom - boxHeight;
        } else {
          if (elementBottom1 > boxHeight) {
            boxRef.current.scrollTop = elementBottom - boxHeight;
          }
        }
      }
    }
  }, [selectedItem]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedItem(prevState => Math.max(prevState - 1, 0));
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const selectedLink = filteredData[selectedItem]
      if (selectedLink) {
        // props.setDate({ ...props.date, location_id: selectedItems.length > 1 ? selectedItems.join(',') : selectedLink.id, name_nav: selectedItemsName.length > 1 ? selectedItemsName.join(', ') : selectedLink.name })
        props.setDate({...props.date,filter_name:'Group-Wise'})
        props.setStockItem(false)
        // navigate(`../stocksummary/${selectedLink?.id}`)
      }
    } else
      if (e.key === 'Escape') {
        e.preventDefault();
        if (props?.stockGroup) {
          e.preventDefault();
          props.setStockItem(false)
          e.stopPropagation();

        }
      }
      else if (e.key === ' ') { // Handling space key press for selecting items
        e.preventDefault();
        const selectedLink = filteredData[selectedItem]
        toggleSelection(selectedLink.id, selectedItem, selectedLink.name);
      }
  }, [selectedItem, selectedItems, filteredData, props?.stockGroup])
  // keyboard funtionality
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const toggleSelection = (id, index, name) => {
    const selectedIndex = selectedItems.indexOf(id);
    let newSelected = [];
    let newSelectedName = [];
    let newSelectedRow = []

    if (selectedIndex === -1) {
      // Add the item to the selection if not already selected
      newSelected = newSelected.concat(selectedItems, id);
      newSelectedName = newSelectedName.concat(selectedItemsName, name);
      newSelectedRow = newSelectedRow.concat(selectedItemRow, index);
    } else if (selectedIndex === 0) {
      // Remove the item from the selection if already selected
      newSelected = newSelected.concat(selectedItems.slice(1));
      newSelectedName = newSelectedName.concat(selectedItemsName.slice(1));
      newSelectedRow = newSelectedRow.concat(selectedItemRow.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
      newSelectedName = newSelectedName.concat(selectedItemsName.slice(0, -1));
      newSelectedRow = newSelectedRow.concat(selectedItemRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, id),
        selectedItems.slice(id + 1)
      );
      newSelectedName = newSelectedName.concat(
        selectedItemsName.slice(0, id),
        selectedItemsName.slice(id + 1)
      );
      newSelectedRow = newSelectedRow.concat(
        selectedItemRow.slice(0, index),
        selectedItemRow.slice(index + 1)
      );
    }

    setSelectedItems(newSelected);
    setSelectedItemsName(newSelectedName);
    setSelectedItemRow(newSelectedRow);
  };

  if (loading) { return }
  return (
    <div className='my-card'>
      <div className='cross_button' onClick={() => { return (props.setStockItem(false)) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <div className='container'>
        <div className="row justify-content-center mt-4">
          <div className='col-12'>
            <div className='row justify-content-center'>
              <div className="col-4 border bg-light border-dark" style={{ height: '85vh', overflowY: 'scroll' }}>
                <div className="row">
                  <div className="col-12" style={{ maxHeight: '75vh', overflowY: 'scroll' }}>
                    <div className='row background' style={{ height: 19.5 }}>
                      <div className='col-12 text-left'>
                        <p className='text-start'> List of Stock Item</p>
                      </div>
                    </div>
                    <div className='row ps-1 pe-1 pt-1' style={{ height: 19.5 }}>
                      <input
                        autoFocus
                        autoComplete="off"
                        className='voucher_entry_account_input'
                        type="text"
                        id='search_input'
                        name='search_input'
                        style={{ margin: 0, padding: 0, paddingLeft: 5 }}
                        onKeyUp={handlechange}
                        onChange={handlechange}
                        ref={search_input_ref}
                        value={search_input || ""} />
                    </div>
                    <div className='row mt-1 border-top border-secondary' ref={boxRef} >
                      {filteredData?.length > 0 ? (
                        filteredData?.map((key, number) => {
                          const name = key?.name === 'Primary' ? `${str} ${key?.name}` : key?.name;
                          const id = key?.id
                          const index = name?.toLowerCase()?.indexOf(search_input?.toLowerCase());
                          return (
                            <div
                              className={`col-12 p-0 dropdown_link ${selectedItemRow?.includes(number) ? 'selected_multi' : ''}`}
                              key={number}
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedItem(number)
                                if (number === selectedItem) {
                                  props.setDate({ ...props.date, location_id: id, name_nav: name })
                                  props.setGodownWise(false)
                                }
                              }}
                            >
                              <Link className={` ps-2 ${number === selectedItem ? selectedItemRow?.includes(number) ? 'selected_multi_selected' : 'selected' : ''}`} >
                                {index !== -1 ? (
                                  < >
                                    {name?.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name?.substring(index, index + search_input.length)}</span>
                                    {name?.substring(index + search_input.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            </div>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='tomasterfooter'>
        <div className='footer'>
          <div className='boxfooter'>
            <button className='button'>
              Q:Quite
            </button>
          </div>
          <div className='boxfooter'>
            <button disabled className='button'>

            </button>
          </div>
          <div className='boxfooter'>
            <button disabled className='button'>

            </button>
          </div>
          <div className='boxfooter'>
            <button type='submit' className='button' onClick={() => { return (navigate(`../ledgervoucher/${filteredData[selectedItem]?.id}`)) }}>
              <u> <u>A</u></u>:Accept
            </button>
          </div>
          <div className='boxfooter'>
            <button disabled className='button'>
              <u></u>
            </button>
          </div>
          <div className='boxfooter'>
            <button disabled className='button'></button>
          </div>
          <div className='boxfooter'>
            <button disabled className='button'>
              <u>D</u>:Delete
            </button>
          </div>
          <div className='boxfooter'>
            <button className='button'>

            </button>
          </div>
          <div className='boxfooter'>
            <button disabled className='button'></button>
          </div>
          <div className='boxfooter'>
            <button disabled className='button'></button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default StockItem;