import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { groupDelete, groupList } from '../../../../../redux/actions/action-creator';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import './group.css'
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
    },
};
const Group = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [all_data, setall_data] = useState([]);
    const [click, setClick] = useState(0)
    const { userData = null } = useSelector(state => state.auth);
    const scrollSpeed = 3000;
    let isScrolling = false;
    const boxRef = useRef(null);
    // fetch data -----------------------------
    useEffect(() => {
        const fetchData = async () => {
            try {
                const formdata = new FormData();
                formdata.append('company_id', userData?.id);
                const response = await dispatch(groupList(formdata));
                const array_list = [];

                const processSubGroups = async (subGroups, type) => {
                    if (subGroups && subGroups.length > 0) {
                        for (const data of subGroups) {
                            array_list.push({ type, data });
                            await processSubGroups(data.sub_primary_group1, type + 1);
                        }
                    }
                };

                if (response?.data && response?.data?.length > 0) {
                    for (const data of response?.data) {
                        const ledgerLength = data?.ledger ? data?.ledger?.length : 0;
                        const groupLength = data?.groupName ? data?.groupName?.length : 0;
                        array_list.push({ type: 'primary', data,ledgerLength,groupLength });
                        await processSubGroups(data?.sub_primary_group1, 'secondary');
                    }
                }

                setall_data(array_list);
            } catch (error) {
                console.error('Error fetching group data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [isDeleteModalOpen])
    // keyboard functionality -----------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!isDeleteModalOpen) {
                if (event.key === 'ArrowDown') {
                    setSelectedIndex((prevIndex) => {
                        return Math.min(prevIndex + 1, all_data.length - 1);
                    });

                } else if (event.key === 'ArrowUp') {
                    setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                } else if (event.key === 'Enter') {
                    const selectedLink = all_data[selectedIndex];
                    if (selectedLink) {
                        navigate(`../alter_accounting_groupupdate/${selectedLink?.data?.id}`)
                    }
                } else if (event.ctrlKey && event.key === 'd') {
                    event.preventDefault();
                    setIsDeleteModalOpen(true);
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex, all_data]);
    // scroll functionality ---------------------------
    useEffect(() => {
        if (boxRef.current) {
            setClick(parseInt((boxRef.current.scrollHeight - boxRef.current.offsetHeight - boxRef.current.scrollTop) / 19))
            const selectedElement = boxRef.current.querySelector('.selected');
            if (selectedElement) {
                const boxHeight = boxRef.current.offsetHeight;
                const elementBottom = selectedElement.offsetTop - 13;
                const elementBottom1 = selectedElement.offsetTop + selectedElement.offsetHeight;
                if (elementBottom > boxHeight) {
                    setClick(parseInt((boxRef.current.scrollHeight - boxRef.current.offsetHeight - boxRef.current.scrollTop) / 20))
                    boxRef.current.scrollTop = elementBottom - boxHeight;
                } else {
                    if (elementBottom1 > boxHeight) {
                        boxRef.current.scrollTop = elementBottom - boxHeight;
                    }
                }
            }
        }
        const handleWheel = (event) => {
            if (isScrolling) return;
            isScrolling = true;
            setTimeout(() => {
                const box = document.getElementById('box'); // Replace 'yourBoxId' with the ID of your box
                const boxHeight = box.clientHeight;
                if (event.deltaY > 0 && selectedIndex < all_data.length) {
                    setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, all_data.length - 1));
                } else if (event.deltaY < 0 && selectedIndex > 0) {
                    setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                }
                isScrolling = false;
            }, scrollSpeed);
        };
        window.addEventListener('wheel', handleWheel);
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, [selectedIndex]);
    //---------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (isDeleteModalOpen) {
                if (e.key === 'a' && e.ctrlKey) {
                    e.preventDefault();
                    yPressToDelete();
                }
                if (e.key === 'y' || e.key === 'Y') {
                    e.preventDefault();
                    yPressToDelete();
                }
                if (e.key === 'n' || e.key === 'N') {
                    e.preventDefault();
                    setIsDeleteModalOpen(false)
                }
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isDeleteModalOpen])

    const yPressToDelete = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('id', all_data[selectedIndex]?.data?.id);
        formData.append('company_id', userData?.id);
        dispatch(groupDelete(formData))
            .then((response) => {
                if (response.status) {
                    setIsDeleteModalOpen(false);
                    toast.success(response?.message || 'group delete successfully!', {
                        position: toast.POSITION.TOP_CENTER
                    });

                    setLoading(false);
                } else {
                    toast.warn(response?.message || 'Fail !', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setIsDeleteModalOpen(false);
                    setLoading(false);
                }
            })
            .catch((error) => {
                toast.error(error?.message || ' Failed!', {
                    position: toast.POSITION.TOP_CENTER
                });
                setIsDeleteModalOpen(false);
                setLoading(false);
            });
    };

    return (
        <div className='section' >
            <div className='detail border'>
                <Modal
                    isOpen={isDeleteModalOpen}
                    style={customStyles}
                >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <p className='modal_message'>
                                    Accept ?
                                </p>
                            </div>
                            <div className='col-5'>
                                <Link className="modal_link" onClick={yPressToDelete} >Yes</Link>
                            </div>
                            <div className='col-2'>or</div>
                            <div className='col-5'>
                                <Link className='modal_link' onClick={() => { return (setIsDeleteModalOpen(false)) }}>No</Link>
                            </div>
                        </div>
                    </div>
                </Modal>
                <form>
                    <div className='container-fluid p-0'>
                        <div className='row' id='ledger-form'>
                            <div className='row m-0' id='ledger-chart-of-head'>
                                <div className='col'><p className='font-weight-bold p-2' style={{ textAlign: 'left' }}>List Of Ledger</p></div>
                                <div className='col'><p className='font-weight-bold p-2' style={{ textAlign: 'right' }}>For 1-Apr-22</p></div>
                            </div>
                            {
                                loading ?
                                    <div className='row' ref={boxRef} style={{ overflowY: 'auto', marginTop: '2.80%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', maxHeight: '70vh' }}>
                                        <div className="spinner-border text-success" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div> :
                                    <div className='row' id='box' ref={boxRef} style={{ overflowY: 'auto', marginTop: '2.80%', maxHeight: '70vh' }}>
                                        <div className='col' style={{ paddingRight: 0 }}>
                                            {
                                                all_data.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={index === selectedIndex ? 'selected' : ''}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'flex-start',
                                                                textAlign: 'left',
                                                                marginLeft: item.type === 'primary' ? 0 : item.type.startsWith('secondary') ? (item.type.length - 6) * 5 : 0
                                                            }}
                                                        >
                                                            <Link
                                                                onClick={() => setSelectedIndex(index)}
                                                                className={'group_list_chart_of_account'}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {item.data.name}
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                            }

                            <div className='row m-0 border-top' id='ledger-chart-of-footer'>
                                <div className='col'>
                                    <p className='font-weight-bold p-2' style={{ textAlign: 'left' }}>{all_data?.length} Group(s) invisible Group:{click}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tomasterfooter'>
                        <div className='footer'>
                            <div className='boxfooter'>
                                <button className='button'>
                                    <strong> Q:Quite</strong>
                                </button>
                            </div>
                            <div className='boxfooter'>
                                <button className='button'>
                                    <strong>Enter:Alter</strong>
                                </button>
                            </div>
                            <div className='boxfooter'>
                                <button disabled className='button'>
                                    <strong></strong>
                                </button>
                            </div>
                            <div className='boxfooter'>
                                <button disabled type='submit' className='button'>
                                    <strong></strong>
                                </button>
                            </div>
                            <div className='boxfooter'>
                                <button className='button'>
                                    <strong><u>C</u>:Create</strong>
                                </button>
                            </div>
                            <div className='boxfooter'>
                                <button disabled className='button'></button>
                            </div>
                            <div className='boxfooter'>
                                <button className='button' onClick={(e) => {
                                    return (
                                        e.preventDefault(),
                                        setIsDeleteModalOpen(true))
                                }}>
                                    <strong> <u>D</u>:Delete</strong>
                                </button>
                            </div>
                            <div className='boxfooter'>
                                <button className='button'>
                                    <strong> </strong>
                                </button>
                            </div>
                            <div className='boxfooter'>
                                <button disabled className='button'></button>
                            </div>
                            <div className='boxfooter'>
                                <button disabled className='button'></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className='help'></div>
        </div >
    )
}
export default Group;
