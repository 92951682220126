import React from 'react'

function FooterLayout(props) {
    const { id, name, shortcutKey, state } = props.fields
    const handleClick = () => {
        if (typeof state === 'function') {
            state(true);
        }
    }
    return (
        <div className='boxfooter'>
            <button
                className='button ps-1'
                onClick={handleClick}>
                <span>{shortcutKey}</span>{name?':':null} {name}
            </button>
        </div>
    )
}

export default FooterLayout
