import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../../../../components/home_page/Footer';
import '../alter.css';

function UnitList() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const { unit_data = null } = useSelector(state => state.common);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => (prevIndex + 1) % totalLinks);
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => (prevIndex - 1 + totalLinks) % totalLinks);
            } else if (event.key === 'Enter') {
                // You can navigate to the selected link's path here
                const selectedLink = unit_data[selectedIndex];
                if (selectedLink) {
                    // window.location.href = selectedLink.to;
                    navigate(selectedLink.to)
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);

    const totalLinks = unit_data.length;
    return (
        <div className='section'>
            <div className='detail border'>
                <div className='company-list-data'>
                    <div className='select-company-search-box'>
                        <p>Master Altreration</p>
                        <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />
                    </div>
                    <div className='list-of-company' >
                        <div className='title'>
                            <p style={{ textAlign: 'left', color: '#fff' }}>List of Masters</p>
                        </div>
                        <div className='container-1'>
                            <ul className='master-list'>
                                <li>
                                    <Link to="/masterchange">
                                        Change Company
                                    </Link>
                                </li>
                                <li>
                                    <Link to="alter-expand-all">
                                        Expand All
                                    </Link>
                                </li>
                                <li>
                                    <Link to="mastershowmore">
                                        Show More
                                    </Link>
                                </li>
                            </ul>
                            <hr />
                            <div className='container-2 row' id='conditional_rendering'>
                                <div className='col d-block'>
                                    
                                    <div style={{display:'flex',flexDirection:'column',justifyContent:'flex-start',background:'#000'}}>
                                    {
                                        Object.keys(unit_data).map((item, index) => {
                                            return (
                                                <Link style={{textDecoration:'none',textAlign:'left',color:'#000',paddingLeft:10}} to={`../alter_inventory_unitupdate/${unit_data[item]?.id}`} key={index} className={index === selectedIndex ? 'selected' : ''}>{unit_data[item]?.formal_name}</Link>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tomasterfooter'>
                    <Footer />
                </div>
            </div>
            <div className='help'>
                <div className='card'>
                    <h6 className='card-header'><span>F3:</span>Company</h6>
                </div>
                <div className='card'>
                    <h6 className='card-header'><span>F2:</span>Date</h6>
                </div>

            </div>

        </div>
    )
}

export default UnitList;


