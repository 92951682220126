import React from 'react';

function Home_Help(props) {
  return (
    <>
      {
        props.chequePrinting ? null :
          props.postDatedSummary ? null :
            props.depositSlip ? null :
              props.paymentAdvice ? null :
                props.bankReconciliation ? null :
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', transition: 1 }}>
                   
                  </div>
      }

    </>
  )
}

export default Home_Help;
