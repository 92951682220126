import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../../../../../components/home_page/Footer';
import Home_Help from '../../../../../components/help/home/Home_Help';
import Stock_Summary_Help from '../../../../../components/help/stock_summary/Stock_Summary_Help';
import './chequeregister.css';

const CheckRegister = () => {
    const location = useLocation();
    return (
        <div className='section'>
            <div className='detail'>
                <div className='title d-flex justify-content-between align-items-center text-light'>
                    <p>Cheque Register</p>
                    <p>Abc Pvt Ltd</p>
                    <button type="button" class="btn-close" aria-label="Close"></button>
                </div>
                {/* {
                    show ? <Period handleClose={() => { return (handleClose) }} /> : null
                }
                {
                    company ? <Company handleCloseCompany={() => { return (handleCloseCompany) }} /> : null
                }
                {
                    stockGroup ? <Stock_Group handleCloseStockGroup={() => { return (handleCloseStockGroup) }} /> : null
                }
                {
                    basic ? <BasicValue handleCloseBasicValue={() => { return (handleCloseBasicValue) }} /> : null
                }
                {
                    changeView ? <ChangeView handleCloseChangeView={() => { return (handleCloseChangeView) }} /> : null
                }
                {
                    exceptionReports ? <ExceptionReports handleCloseExceptionReports={() => { return (handleCloseExceptionReports) }} /> : null
                }
                {
                    saveView ? <SaveView handleCloseSaveView={() => { return (handleCloseSaveView) }} /> : null
                }
                {
                    valuation ? <Valuation handleCloseValuation={() => { return (handleCloseValuation) }} /> : null
                } */}
                <div className='container-fluid' id='reletive'>

                    <div>
                        <div className='row'>
                            <div className='col-12 p-3' style={{ textAlign: 'left' }}>
                                <b>Bank Wise Register</b>
                            </div>
                            <div className='col-12 border'>
                                <div className='row'>
                                    <div className='col-4 border'>
                                    <b>Particular</b>
                                    </div>
                                    <div className='col-1 border'>
                                    <b> </b>Availabel Cheque
                                    </div>
                                    <div className='col-5 border'>
                                    <b>For 1-Apr-22</b>
                                    </div>
                                    <div className='col-1 border'>
                                    <b> Out of Period</b>
                                    </div>
                                    <div className='col-1 border'>
                                    <b> Total Cheques</b>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                      


                    </div>
                </div>
                <div className='tomasterfooter'><Footer /></div>
            </div>
            <div className='help'>
                {
                    location.pathname == '/stocksummary' ? <Stock_Summary_Help
                    // handleShow={() => { return (handleShow) }}
                    // show={show}
                    // handleShowCompany={() => { return (handleShowCompany) }}
                    // handleShowStockGroup={() => { return (handleShowStockGroup) }}
                    // company={company}
                    // handleShowBasicValue={() => { return (handleShowBasicValue) }}
                    // basic={basic}
                    // handleShowChangeView={() => { return (handleShowChangeView) }}
                    // changeView={changeView}
                    // handleShowExceptionReports={() => { return (handleShowExceptionReports) }}
                    // exceptionReports={exceptionReports}
                    // handleShowSaveView={() => { return (handleShowSaveView) }}
                    // saveView={saveView}
                    // handleShowValuation={() => { return (handleShowValuation) }}
                    // valuation={valuation}
                    /> : <Home_Help />

                }
            </div>
        </div>
    )
}

export default CheckRegister
