import React from 'react'

function HelpCard(props) {
    const { id, name, shortcutKey, state } = props.fields
    const handleClick = () => {
        if (typeof state === 'function') {
            state(true);
        }
    }
    return (
        <div className='help_button_card' key={id}>
            <button
                className={`button_padding`}
                onClick={handleClick}
            >
                <p className='card-header'>
                    <span>{shortcutKey}</span>: {name}
                </p>
            </button>
        </div>
    )
}

export default HelpCard
