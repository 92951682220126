import React from 'react';
import RegistrationPage from '../components/login_form/RegistrationPage';
import Login from '../components/login_form/Login';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Branch_Login from '../components/login_form/Branch_Login';

const AuthRoute = ({setProgress}) => {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Login setProgress={setProgress} />} />
                <Route path='/signUp' element={<RegistrationPage />} />
                <Route path='/branch_login' element={<Branch_Login />} />
            </Routes>
        </div>
    )
}

export default AuthRoute

