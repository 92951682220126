import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../home_page/Footer';

const BasicValue = (props) => {
  return (
    <div id='company'>
      <button type="button" class="btn-close" id='company-close' aria-label="Close" onClick={props.handleCloseBasicValue()}></button>
      <div className='row justify-content-center'>
        <div className='col-3 bg-light pb-3'>
          <p style={{ textAlign: 'center' }}>Basic of Values </p>
          <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />

        </div>
      </div>
      <div className='row justify-content-center' >
        <div className='col-6 shadow bg-light'>
          <div className='title'>
            <p>List of Configuration</p>
          </div>
          <div className='container-1'>

            jayant

          </div>
        </div>
      </div>
      <div className='tomasterfooter'><Footer /></div>
    </div>
  )
}

export default BasicValue
