import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../../../../components/home_page/Footer';
import { Link,useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css';
import Modal from 'react-modal';
import { unitsSingleData, unitsUpdate, uqcCreate, uqcDelete, uqcListing } from '../../../../../redux/actions/action-creator';
import { Trash, PencilSquare } from 'react-bootstrap-icons';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
// const uqc_code_array = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'BAG-BAGS' }, { id: 3, name: 'BAL-BALE' }, { id: 4, name: 'BDL-BUNDLES' },
// { id: 5, name: 'BKL-BUCKLES' }, { id: 6, name: 'BOU-BILLION OF UNITS' }, { id: 7, name: 'BOX-BOX' }, { id: 8, name: 'BTL-BOTTLES' }, { id: 9, name: 'BUN-BUNCHES' },
// { id: 10, name: 'CAN-CANS' }, { id: 11, name: 'CBM-CUBIC METERS' }, { id: 12, name: 'CCM-CUBIC CENTIMETERS' }, { id: 13, name: 'CMS-CENTIMETERS' }, { id: 14, name: 'CTN-CARTONS' }, { id: 15, name: 'DOZ-DOZENS' }, { id: 16, name: 'DRM-DRUMS' }, { id: 17, name: 'GGK-GREAT GROSS' }, { id: 18, name: 'GMS-GRAMMES' }, { id: 19, name: 'GRS-GROSS' }, { id: 20, name: 'GYD-GROSS YARDS' },
// { id: 21, name: 'KGS-KILOGRAMS' }, { id: 22, name: 'KLR-KILOLITER' }, { id: 23, name: 'KME-KILOMETER' }, { id: 24, name: 'LTR-LITRES' }, { id: 25, name: 'MLT-MILILITRE' }, { id: 26, name: 'MTR-METERS' },
// { id: 27, name: 'MTS-METRIC TON' }, { id: 28, name: 'NOS-NUMBERS' }, { id: 29, name: 'OTH-OTHERS' }, { id: 30, name: 'PAC-PACKS' }, { id: 31, name: 'PCS-PIECES' }, { id: 32, name: 'PRS-PAIRS' }, { id: 33, name: 'QTL-QUINTAL' },
// { id: 34, name: 'ROL-ROLLS' }, { id: 35, name: 'SET-SETS' }, { id: 36, name: 'SQF-SQUARE FEET' }, { id: 37, name: 'SQM-SQUARE METERS' }, { id: 38, name: 'SQY-SQUARE YARDS' }, { id: 39, name: 'TBS-TABLETS' },
// { id: 40, name: 'TGM-TEN GROSS' }, { id: 41, name: 'THD-THOUSANDS' }, { id: 42, name: 'TON-TONNES' }, { id: 43, name: 'TUB-TUBES' }, { id: 44, name: 'UGS-US GALLONS' }, { id: 45, name: 'UNT-UNITS' }, { id: 46, name: 'YDS-YARDS' }]
const type_of_unit_array = [{ id: 1, name: 'Simple' }, { id: 2, name: 'Compound' }]
function Unit() {
  const {id} = useParams();
  const initialState = {
    type: 'Simple',
    symbol: '',
    formal_name: '',
    unit_quantity_code: 'Not Applicable',
    uqc_id: '',
    number_of_decimal_places: '0',
    first_unit: '',
    conversions: '',
    secound_unit: '',
    uqc_code_create_name: '',
  }
  const { uqc_code_response = null } = useSelector(state => state.common);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(uqcListing())
  }, []
  )

  const dropdownRef = useRef(null);
  const dropdownRefType = useRef(null);
  const dropdownRefFirst = useRef(null);
  const dropdownRefSecound = useRef(null);
  const inputRef = useRef(null);
  const inputRefFirst = useRef(null);
  const inputRefSecound = useRef(null);
  const inputRefType = useRef(null);
  const [showList, setShowList] = useState(false);
  const [showListType, setShowListType] = useState(false);
  const [showListFirst, setShowListFirst] = useState(false);
  const [showListSecound, setShowListSecound] = useState(false);
  const [state, setState] = useState(initialState);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  useEffect(()=>{
    const formData = new FormData();
    formData.append('id',id)
    dispatch(unitsSingleData(formData)).then((response) => {
      if (response.status) {
        setState({
          type: response.data?.type,
          symbol: response.data?.symbol,
          formal_name: response.data?.formal_name,
          unit_quantity_code: response.data?.uqc_name,
          uqc_id: response.data?.uqc_id,
          number_of_decimal_places: response.data?.no_decimal_places,
        })
        // toast.success(response?.message || 'data found!', {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  },[id,dispatch])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
  }
  const displayList = (value, id) => {
    setState({ ...state, unit_quantity_code: value, unit_id: id })
    // setState({ ...state, unit_id: id })
    document.getElementById('unit_quantity_code').value = value;
    setShowList(false)
    return false
  }
  const displayListType = (value) => {
    setState({ ...state, type: value })
    document.getElementById('type').value = value;
    document.getElementById("myDropdown-list").classList.remove("show-list");
    return false
  }
  const displayListFirst = (value) => {
    setState({ ...state, first_unit: value })
    document.getElementById('first_unit').value = value;
    document.getElementById("myDropdown-list").classList.remove("show-list");
    return false
  }
  const displayListSecound = (value) => {
    setState({ ...state, secound_unit: value })
    document.getElementById('secound_unit').value = value;
    document.getElementById("myDropdown-list").classList.remove("show-list");
    return false
  }
  const unitSubmit = (e) => {
    e.preventDefault();
    // Validation checks
    if (!state.type) {
      toast.error('Please select a type.', {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }

    if (!state.formal_name) {
      toast.error('Please enter a formal name.', {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }

    // if (!state.uqc_id) {
    //   toast.error('Please select a UQC code.', {
    //     position: toast.POSITION.TOP_CENTER
    //   });
    //   return;
    // }
    const formData = new FormData();
    formData.append('type', state.type)
    formData.append('symbol', state.symbol)
    formData.append('formal_name', state.formal_name)
    formData.append('uqc_id', state.unit_id)
    formData.append('no_decimal_places', state.number_of_decimal_places)
    formData.append('id',id)
    dispatch(unitsUpdate(formData)).then((response) => {
      if (response.status) {
        toast.success(response?.message || 'group created!', {
          position: toast.POSITION.TOP_CENTER
        });
        setState({
          type: 'Simple',
          symbol: '',
          formal_name: '',
          unit_quantity_code: 'Not Applicable',
          uqc_id: '',
          number_of_decimal_places: '0',
        })
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.message || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }
  const handleModalOpen = () => {
    setIsDeleteModalOpen(true);
    setShowList(false)
  }
  const handleCreate = (e) => {
    e.preventDefault();
    if (!state.uqc_code_create_name) {
      // Display an error message or handle the validation error as needed
      toast.error('Please enter a valid UQC code.', {
        position: toast.POSITION.TOP_CENTER
      });
      return; // Exit the function if validation fails
    }
    const formData = new FormData();
    formData.append('name', state.uqc_code_create_name)
    dispatch(uqcCreate(formData))
      .then((response) => {
        if (response.status) {
          // Handle success and close modal
          setIsDeleteModalOpen(false);
          dispatch(uqcListing())
          setState({ ...state, uqc_code_create_name: '' })
        }
      })
      .catch((error) => {
        // Handle error and close modal
        setIsDeleteModalOpen(false);
        // ... other logic
        toast.error(error?.responseMessage || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
      });


  };
  const handleDeleteFun = (id) => {
    const formData = new FormData();
    formData.append('id', id);
    dispatch(uqcDelete(formData))
      .then((response) => {
        if (response.status) {
          // Handle success and close modal
          // ... other logic
          toast.success(response?.message || 'group delete successfully!', {
            position: toast.POSITION.TOP_CENTER
          });
          dispatch(uqcListing())
        } else {
          // Handle failure
          // ... other logic
          toast.warn(response?.message || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
        }
      })
      .catch((error) => {
        // Handle error and close modal
        setIsDeleteModalOpen(false);
        // ... other logic
        toast.error(error?.responseMessage || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
      });
  }
  const {
    type,
    symbol,
    formal_name,
    unit_quantity_code,
    number_of_decimal_places,
    first_unit,
    conversions,
    secound_unit,
    uqc_code_create_name
  } = state;
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const handleClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
      setShowList(false);
    }
    if (dropdownRefType.current && !dropdownRefType.current.contains(event.target) && !inputRefType.current.contains(event.target)) {
      setShowListType(false);
    }
    if (dropdownRefFirst.current && !dropdownRefFirst.current.contains(event.target) && !inputRefFirst.current.contains(event.target)) {
      setShowListFirst(false);
    }
    if (dropdownRefSecound.current && !dropdownRefSecound.current.contains(event.target) && !inputRefSecound.current.contains(event.target)) {
      setShowListSecound(false);
    }

  };
  const handleFocus = () => {
    if (!showList) {
      setShowList(true);
    }
  };
  const handleFocusType = () => {
    if (!showListType) {
      setShowListType(true);
    }
  };
  const handleFocusFirst = () => {
    if (!showListFirst) {
      setShowListFirst(true);
    }
  };
  const handleFocusSecound = () => {
    if (!showListSecound) {
      setShowListSecound(true);
    }
  };
  if (typeof uqc_code_response === 'undefined' || uqc_code_response === null) {
    return null; // or return a loading indicator, error message, or fallback component
  }
  return (
    <div className='section'>
      <div className='detail border'>
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          contentLabel="Confirm Delete"
          style={customStyles}
        >
          <form autoComplete='off'>
            <input type="text" name='uqc_code_create_name' id='uqc_code_create_name' onKeyUp={handleChange} onChange={handleChange} value={uqc_code_create_name || " "} />
            <div className='border'></div>
            <div className='modal-footer'>
              <button className='btn btn-primary m-2' onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
              <button className='btn btn-danger  m-2' onClick={handleCreate}>Create</button>
            </div>
          </form>
        </Modal>
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form autoComplete='off' onSubmit={unitSubmit}>
              <div className='col-6 p-3' style={{ background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'>Type</label>
                    <label> :</label>
                    <input type="text" name='type' id='type' className='lab-right' ref={inputRefType} onKeyUp={handleChange} onChange={handleChange} onFocus={handleFocusType} value={type || ""} />
                    <div id="myDropdown-list" className={`dropdown-content-list ${showListType ? 'show-list' : ''}`} ref={dropdownRefType}>
                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                        Type of Units
                      </div>
                      <div id='data'>
                        {Object.keys(type_of_unit_array).map((key) =>
                          <Link className='list font-weight-bold' id='list' key={key} onClick={(e) => { displayListType(type_of_unit_array[key]?.name, type_of_unit_array[key]?.id); }} >{type_of_unit_array[key]?.name}</Link>
                        )}
                      </div>
                    </div>
                  </div>
                  {
                    type === 'Simple' ?
                      <>
                        <div className='d-flex justify-content-between align-item-center' id='alis'>
                          <label className='lab-1'> Symbol </label>
                          <label> :</label>
                          <input type="text" name='symbol' id='symbol' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={symbol || " "} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' id='alis'>
                          <label className='lab-1'> Formal Name </label>
                          <label> :</label>
                          <input type="text" name='formal_name' id='formal_name' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={formal_name || " "} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Unit Quantity Code (UQC) </label>
                          <label> :</label>
                          <input type='text' id='unit_quantity_code' name="unit_quantity_code" ref={inputRef} value={unit_quantity_code || " "} onKeyUp={handleChange} onChange={handleChange} onFocus={handleFocus} className='lab-right' />
                          <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              List Of UQCs
                            </div>
                            <div className='col ' style={{ textAlign: 'right' }}>
                              <Link style={{ textAlign: 'right', paddingRight: 10 }} onClick={() => { return (handleModalOpen()) }}>New UQC</Link>
                              <div className='border'></div>
                            </div>
                            <div id='data'>
                              {
                                Object.keys(uqc_code_response).map((item) => {
                                  return (
                                    <div key={item} className='list_highlight' style={{ paddingLeft: 10, paddingBottom: 5, paddingRight: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', textAlign: 'left' }}>
                                      <Link className={'ledger-list-chart-created'} onClick={() => { return (displayList(uqc_code_response[item].name, uqc_code_response[item].id)) }}>{uqc_code_response[item]?.name}</Link>
                                      <div className='row'>
                                        <div className='col'>
                                          <button type="button" onClick={() => { return (handleDeleteFun(uqc_code_response[item].id)) }} ><Trash /></button>
                                        </div>
                                        <div className='col'>
                                          <button type="button" onClick={() => { return (handleModalOpen()) }} ><PencilSquare /></button>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' id='alis'>
                          <label className='lab-1'>Number of Decimal Places </label>
                          <label> :</label>
                          <input type="text" name='number_of_decimal_places' id='number_of_decimal_places' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={number_of_decimal_places || " "} />
                        </div>
                      </> :
                      <div className='row'>
                        <div className='col-12'>
                          <p><strong><u>Units with multiplier Factors</u></strong></p>
                          <p>(example: kgs of 1000 gms)</p>
                        </div>
                        <div className='col-4'>
                          <label className='lab-1'>First Unit </label>
                          <input type="text" name='first_unit' id='first_unit' className='lab-right' ref={inputRefFirst} onKeyUp={handleChange} onChange={handleChange} onFocus={handleFocusFirst} value={first_unit || " "} />
                          <div id="myDropdown-list" className={`dropdown-content-list ${showListFirst ? 'show-list' : ''}`} ref={dropdownRefFirst}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Units
                            </div>
                            <div className='col ' style={{ textAlign: 'right' }}>
                              <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                              <div className='border'></div>
                            </div>
                            <div id='data'>
                              Jayant
                            </div>
                          </div>
                        </div>
                        <div className='col-4'>
                          <label className='lab-1'><strong>Of</strong>Conversions </label>
                          <input type="text" name='conversions' id='conversions' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={conversions || " "} />
                        </div>
                        <div className='col-4'>
                          <label className='lab-1'>Secound Unit </label>
                          <input type="text" name='secound_unit' id='secound_unit' className='lab-right' ref={inputRefSecound} onKeyUp={handleChange} onChange={handleChange} onFocus={handleFocusSecound} value={secound_unit || " "} />
                          <div id="myDropdown-list" className={`dropdown-content-list ${showListSecound ? 'show-list' : ''}`} ref={dropdownRefSecound}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Units
                            </div>
                            <div className='col ' style={{ textAlign: 'right' }}>
                              <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                              <div className='border'></div>
                            </div>
                            <div id='data'>
                              Game chnager
                            </div>
                          </div>
                        </div>

                      </div>
                  }

                </div>
              </div>
              <div className='tomasterfooter'><Footer /></div>
            </form>
          </div>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default Unit
