import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../../../../components/home_page/Footer'
import { Link } from 'react-router-dom'
const Configure =(props)=> {
    const [state, setState] = useState(
        {
            use_dr_cr_by_to_by: 'Yes',
            warn_on_nagative_balance: 'Yes',
            skip_date_field: 'Yes',
            show_cur_balance_ledger: 'Yes',
            show_balance_on_voucher_date: 'Yes',
            show_final_balance_ledger: 'No',
            show_emply_detail: 'No',
            use_def_bank_allo: 'No',
            use_auto_check_numbering: 'Yes',
            select_check_rage: 'Yes',
            set_ledger_wise_bank_allo_during_voucher_cre: 'Yes',
            print_check_after_saving: 'Yes',
            show_check_detail_bfr_printing: 'Yes',
            provide_denomination: 'Yes',
            remove_bank_date_while: 'Yes'

        })
    const use_dr_cr_by_to_by_input_ref = useRef(null);
    const warn_on_nagative_balance_input_ref = useRef(null);
    const skip_date_field_input_ref = useRef(null);
    const show_cur_balance_ledger_input_ref = useRef(null);
    const show_balance_on_voucher_date_input_ref = useRef(null);
    const show_final_balance_ledger_input_ref = useRef(null);
    const show_emply_detail_input_ref = useRef(null);
    const use_def_bank_allo_input_ref = useRef(null);
    const use_auto_check_numbering_input_ref = useRef(null);
    const select_check_rage_input_ref = useRef(null);
    const set_ledger_wise_bank_allo_during_voucher_cre_input_ref = useRef(null);
    const print_check_after_saving_input_ref = useRef(null);
    const show_check_detail_bfr_printing_input_ref = useRef(null);
    const provide_denomination_input_ref = useRef(null);
    const remove_bank_date_whil_input_ref = useRef(null);
    const handlechange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        if (name === 'use_dr_cr_by_to_by') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    warn_on_nagative_balance_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'warn_on_nagative_balance') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    skip_date_field_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'skip_date_field') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    show_cur_balance_ledger_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'show_cur_balance_ledger') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    show_balance_on_voucher_date_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'show_balance_on_voucher_date') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    show_final_balance_ledger_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'show_final_balance_ledger') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    show_emply_detail_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'show_emply_detail') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    use_def_bank_allo_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'use_def_bank_allo') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    use_auto_check_numbering_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'use_auto_check_numbering') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    select_check_rage_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'select_check_rage') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    set_ledger_wise_bank_allo_during_voucher_cre_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'set_ledger_wise_bank_allo_during_voucher_cre') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    print_check_after_saving_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'print_check_after_saving') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    show_check_detail_bfr_printing_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'show_check_detail_bfr_printing') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    provide_denomination_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'provide_denomination') {
            if (e.key === 'Enter') {
                e.preventDefault();
                setTimeout(() => {
                    remove_bank_date_whil_input_ref.current.focus();
                }, 50);
            }
        }
        if (name === 'remove_bank_date_while') {
            if (e.key === 'Enter') {
                e.preventDefault();
                props.setshow_configure(false)
            }
        }
    }
    const {
        use_dr_cr_by_to_by,
        warn_on_nagative_balance,
        skip_date_field,
        show_cur_balance_ledger,
        show_balance_on_voucher_date,
        show_final_balance_ledger,
        show_emply_detail,
        use_def_bank_allo,
        use_auto_check_numbering,
        select_check_rage,
        set_ledger_wise_bank_allo_during_voucher_cre,
        print_check_after_saving,
        show_check_detail_bfr_printing,
        provide_denomination,
        remove_bank_date_while
    } = state;
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setshow_configure(false);
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.show_configure]);
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setshow_configure(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3 border border-dark' id='card-period' style={{ width: '60%', background: '#fff' }}>
                <div className='row'>
                    <div className='col border-bottom'>
                        <b>Configure</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p className='m-0 p-0 mb-1 border-bottom border-gray'>General Details</p>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Use Cr/Dr instead of To/By during voucher entry</label>
                            <label>:</label>
                            <input type='text' autoFocus name='use_dr_cr_by_to_by' id='use_dr_cr_by_to_by' ref={use_dr_cr_by_to_by_input_ref} onChange={handlechange} onKeyUp={handlechange} value={use_dr_cr_by_to_by || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Warn on negative Cash Balance</label>
                            <label>:</label>
                            <input type='text' name='warn_on_nagative_balance' ref={warn_on_nagative_balance_input_ref} id='warn_on_nagative_balance' onChange={handlechange} onKeyUp={handlechange} value={warn_on_nagative_balance || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Skip the Date field during voucher creation</label>
                            <label>:</label>
                            <input type='text' name='skip_date_field' id='skip_date_field' ref={skip_date_field_input_ref} onChange={handlechange} onKeyUp={handlechange} value={skip_date_field || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Current Balance of Ledgers</label>
                            <label>:</label>
                            <input type='text' name='show_cur_balance_ledger' ref={show_cur_balance_ledger_input_ref} id='show_cur_balance_ledger' onChange={handlechange} onKeyUp={handlechange} value={show_cur_balance_ledger || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Balance as on Voucher date</label>
                            <label>:</label>
                            <input type='text' name='show_balance_on_voucher_date' ref={show_balance_on_voucher_date_input_ref} id='show_balance_on_voucher_date' onChange={handlechange} onKeyUp={handlechange} value={show_balance_on_voucher_date || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show final Ledger Balance</label>
                            <label>:</label>
                            <input type='text' name='show_final_balance_ledger' ref={show_final_balance_ledger_input_ref} id='show_final_balance_ledger' onChange={handlechange} onKeyUp={handlechange} value={show_final_balance_ledger || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Employee details</label>
                            <label>:</label>
                            <input type='text' name='show_emply_detail' ref={show_emply_detail_input_ref} id='show_emply_detail' onChange={handlechange} onKeyUp={handlechange} value={show_emply_detail || ''} className='lab-right' />
                        </div>
                        <p className='m-0 p-0 mb-1 border-bottom border-gray'>Bank Details</p>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Use default Bank Allocations</label>
                            <label>:</label>
                            <input type='text' name='use_def_bank_allo' ref={use_def_bank_allo_input_ref} id='use_def_bank_allo' onChange={handlechange} onKeyUp={handlechange} value={use_def_bank_allo || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Use Auto Cheque Numbering</label>
                            <label>:</label>
                            <input type='text' name='use_auto_check_numbering' ref={use_auto_check_numbering_input_ref} id='use_auto_check_numbering' onChange={handlechange} onKeyUp={handlechange} value={use_auto_check_numbering || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Select Cheque Range</label>
                            <label>:</label>
                            <input type='text' name='select_check_rage' ref={select_check_rage_input_ref} id='select_check_rage' onChange={handlechange} onKeyUp={handlechange} value={select_check_rage || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1' style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'clip', flexShrink: 1 }}>Set Ledger-wise Bank Allocations during voucher entry</label>
                            <label>:</label>
                            <input type='text' name='set_ledger_wise_bank_allo_during_voucher_cre' ref={set_ledger_wise_bank_allo_during_voucher_cre_input_ref} id='set_ledger_wise_bank_allo_during_voucher_cre' onChange={handlechange} onKeyUp={handlechange} value={set_ledger_wise_bank_allo_during_voucher_cre || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Print Cheque after saving Voucher</label>
                            <label>:</label>
                            <input type='text' name='print_check_after_saving' ref={print_check_after_saving_input_ref} id='print_check_after_saving' onChange={handlechange} onKeyUp={handlechange} value={print_check_after_saving || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Show Cheque details before printing</label>
                            <label>:</label>
                            <input type='text' name='show_check_detail_bfr_printing' ref={show_check_detail_bfr_printing_input_ref} id='show_check_detail_bfr_printing' onChange={handlechange} onKeyUp={handlechange} value={show_check_detail_bfr_printing || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Provide Cash Denomination details</label>
                            <label>:</label>
                            <input type='text' name='provide_denomination' id='provide_denomination' ref={provide_denomination_input_ref} onChange={handlechange} onKeyUp={handlechange} value={provide_denomination || ''} className='lab-right' />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Remove Bank Date while altering Reconciled</label>
                            <label>:</label>
                            <input type='text' name='remove_bank_date_while' ref={remove_bank_date_whil_input_ref} id='remove_bank_date_while' onChange={handlechange} onKeyUp={handlechange} value={remove_bank_date_while || ''} className='lab-right' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}
export default Configure;