import React from 'react'

function TableLayoutItem({ children }) {

    return (
        <div className='container-fluid' id='reletive' style={{ height: '90vh' }}>
            <div className='row border-bottom border-dark' style={{ height: 40 }}>
                <div className='col-5 border-end border-start border-dark  table_header_font'>
                    <div className='row'>
                        <div className='col-2 m-0 p-0 text-end '>Date</div>
                        <div className='col-6 m-0 p-0 ps-1 text-start '>Particular</div>
                        <div className='col-2 m-0 p-0 text-end'>Vch Type</div>
                        <div className='col-2 m-0 p-0 pe-1 text-end'>Vch No</div>
                    </div>
                </div>
                <div className='col-7 m-0 p-0'>
                    <div className='row m-0 p-0' style={{ width: '100%', height: 39.33 }}>
                        <div className='col-4  border-end border-dark m-0 p-0 table_header_font'>
                            <b>Inwards</b>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_header_font'>
                                    Quantity
                                </div>
                                <div className='col-3 table_header_font text-end p-0 m-0'>
                                    (Alt.Units)
                                </div>
                                <div className='col-5 text-end p-0 pe-1 m-0 table_header_font'>
                                    Value
                                </div>
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0 table_header_font'>
                            <b>Outwards</b>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_header_font' >
                                    Quantity
                                </div>
                                <div className='col-3 m-0 p-0 text-end table_header_font' >
                                    (Alt.Units)
                                </div>
                                <div className='col-5 text-end p-0 pe-1 m-0 table_header_font' >
                                    Value
                                </div>
                                {/* <div className='col-2 border border-dark text-end p-0 pe-1 m-0 font_size_10_px' >
                                    Gross Value
                                </div>
                                <div className='col-1 border border-dark text-end p-0 pe-1 m-0 font_size_10_px' >
                                    Comsumption
                                </div>
                                <div className='col-2 border border-dark text-end p-0 pe-1 m-0 font_size_10_px'>
                                    Gross Profit
                                </div>
                                <div className='col-1  border border-dark text-end p-0 pe-1 m-0 font_size_10_px' >
                                    Per%
                                </div> */}
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0 table_header_font'>
                            <b>Closing Balance</b>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_header_font' >
                                    Quantity
                                </div>
                                <div className='col-3 p-0 m-0 text-end table_header_font'>
                                    (Alt.Units)
                                </div>
                                <div className='col-5 text-end p-0 pe-1 m-0 table_header_font'>
                                    Value
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={` row  border-bottom border-dark postion-relative`} style={{ height: '76.5vh' }} >
                <div className='col-5 border-end border-start border-dark text-start table_body_font' style={{ height: '76.5vh' }} >
                </div>
                <div className='col-7 m-0 p-0' style={{ height: '76.5vh' }}>
                    <div className='row m-0 p-0 ' style={{ width: '100%', height: '76.5vh' }}>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-3 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-5 text-end p-0 m-0 table_body_font'>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-3 text-end m-0 p-0 table_body_font'>
                                </div>
                                <div className='col-5 text-end p-0 m-0 table_body_font'>
                                </div>
                                {/* <div className='col-2 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-2 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-2 text-end p-0 m-0 table_body_font'>
                                </div> */}
                                {/* <div className='col-1 text-end p-0 m-0 table_body_font'>
                                </div> */}

                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_body_font'>
                                </div>
                                <div className='col-3 text-end m-0 p-0 table_body_font'>
                                </div>
                                <div className='col-5 text-end p-0 m-0 table_body_font'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row m-0 p-0' style={{ position: 'absolute', width: '100%' }}>
                    <div className='col-12'>
                        {children}
                    </div>
                </div>
            </div>


            <div className={`row  border-0`}>
                <div className='col-5 border-end border-dark text-start table_footer_font'>
                    Grand Total
                </div>
                <div className='col-7 m-0 p-0'>
                    <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4  text-end p-0 m-0 table_footer_font' >
                                    {/* {`${total_inwards_quantity} ${unit_name}`} */}
                                </div>
                                <div className='col-3  text-end table_footer_font p-0 m-0' >
                                </div>
                                <div className='col-5 text-end p-0 pe-1 m-0 table_footer_font' >
                                    {/* {total_inwards_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")} */}
                                    10000
                                </div>
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4  text-end p-0 m-0 table_footer_font' >
                                    {/* {`${total_outwards_quantity} ${unit_name}`} */}
                                </div>
                                <div className='col-3  text-end m-0 p-0 table_footer_font'>
                                </div>
                                <div className='col-5 text-end p-0 pe-1 m-0 table_footer_font' >
                                    {/* {total_outwards_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")} */}
                                   10000
                                </div>
                            </div>
                        </div>
                        <div className='col-4 border-end border-dark m-0 p-0'>
                            <div className='row m-0 p-0' style={{ width: '100%' }}>
                                <div className='col-4  text-end p-0 m-0 table_footer_font' >
                                    {/* {`${total_closing_quantity} ${unit_name}`} */}
                                </div>
                                <div className='col-3 text-end m-0 p-0 table_footer_font' >
                                </div>
                                <div className='col-5 text-end p-0 pe-1 m-0 table_footer_font'>
                                    {/* {total_closing_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")} */}
                                   100000
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableLayoutItem




