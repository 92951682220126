import React from 'react'

function PAN_Details() {
  return (
    <div>
        this is PAN_Details of alter 
      
    </div>
  )
}

export default PAN_Details
