import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../../../../components/home_page/Footer';
import '../alter.css';

function ListOfLedger() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const bankAccount = useSelector(state => state.common);
    const capitalAccount = useSelector(state => state.common.capitalAccount);
    const branchDivision = useSelector(state => state.common.branchDivision);
    const reserveAndSurplus = useSelector(state => state.common.reserveAndSurplus);
    const stockInHnad = useSelector(state => state.common.stockInHnad);
    const cashInHand = useSelector(state => state.common.cashInHand);
    const currentAssets = useSelector(state => state.common.currentAssets);
    const depositsAssets = useSelector(state => state.common.depositsAssets);
    const bankOdAc = useSelector(state => state.common.bankOdAc);
    const loanAndAdvanceAssets = useSelector(state => state.common.loanAndAdvanceAssets);
    const currentLiabilities = useSelector(state => state.common.currentLiabilities);
    const directExpenses = useSelector(state => state.common.directExpenses);
    const directIncomes = useSelector(state => state.common.directIncomes);
    const dutiesAndTaxes = useSelector(state => state.common.dutiesAndTaxes);
    const fixedAssets = useSelector(state => state.common.fixedAssets);
    const indirectIncomes = useSelector(state => state.common.indirectIncomes);
    const provisions = useSelector(state => state.common.provisions);
    const miscExpenses = useSelector(state => state.common.miscExpenses);
    const sundryCreditors = useSelector(state => state.common.sundryCreditors);
    const sundryDebtors = useSelector(state => state.common.sundryDebtors);
    const indirectExpenses = useSelector(state => state.common.indirectExpenses);
    const investment = useSelector(state => state.common.investment);
    const loanLiability = useSelector(state => state.common.loanLiability);
    const purchaseAccount = useSelector(state => state.common.purchaseAccount);
    const saleAccount = useSelector(state => state.common.saleAccount);
    const securedLoan = useSelector(state => state.common.securedLoan);
    const suspenseAccount = useSelector(state => state.common.suspenseAccount);
    const unsecuredLoan = useSelector(state => state.common.unsecuredLoan);
    const arrayListOfLedger = [bankAccount, capitalAccount, branchDivision,
        reserveAndSurplus, stockInHnad, cashInHand,
        currentAssets, depositsAssets, bankOdAc,
        loanAndAdvanceAssets, currentLiabilities,
        directExpenses, directIncomes, dutiesAndTaxes,
        fixedAssets, indirectIncomes, provisions, miscExpenses,
        sundryCreditors, sundryDebtors, indirectExpenses,
        investment, loanLiability, purchaseAccount, saleAccount,
        securedLoan, suspenseAccount, unsecuredLoan]
    const mergedData = arrayListOfLedger.reduce((merged, item) => {
        if (Array.isArray(item)) {
            return merged.concat(item);
        } else if (typeof item === 'object' && Object.keys(item).length > 0) {
            merged.push(item);
        }
        return merged;
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => (prevIndex + 1) % totalLinks);
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => (prevIndex - 1 + totalLinks) % totalLinks);
            } else if (event.key === 'Enter') {
                // You can navigate to the selected link's path here
                const selectedLink = mergedData[selectedIndex];
                if (selectedLink) {
                    // window.location.href = selectedLink.to;
                    navigate(selectedLink.to)
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);

    const totalLinks = mergedData.length;
    return (
        <div className='section'>
            <div className='detail border'>
                <div className='company-list-data'>
                    <div className='select-company-search-box'>
                        <p>Master Altreration</p>
                        <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />
                    </div>
                    <div className='list-of-company' >
                        <div className='title'>
                            <p style={{ textAlign: 'left', color: '#fff' }}>List of Masters</p>
                        </div>
                        <div className='container-1'>
                            <ul className='master-list'>
                                <li>
                                    <Link to="/masterchange">
                                        Change Company
                                    </Link>
                                </li>
                                <li>
                                    <Link to="alter-expand-all">
                                        Expand All
                                    </Link>
                                </li>
                                <li>
                                    <Link to="mastershowmore">
                                        Show More
                                    </Link>
                                </li>
                            </ul>
                            <hr />
                            <div className='container-2 row' id='conditional_rendering'>
                                <div className='col d-block'>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                    {
                                        Object.keys(mergedData).map((item, index) => {
                                            return (
                                                <Link style={{textDecoration:'none',textAlign:'left',color:'#000',paddingLeft:10}} to={`../alter_accounting_ledgerupdate/${mergedData[item]?.id}/${mergedData[item]?.group_id}`} key={index} className={index === selectedIndex ? 'selected' : ''}>{mergedData[item]?.ledger_name}</Link>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tomasterfooter'>
                    <Footer />
                </div>
            </div>
            <div className='help'>
                <div className='card'>
                    <h6 className='card-header'><span>F3:</span>Company</h6>
                </div>
                <div className='card'>
                    <h6 className='card-header'><span>F2:</span>Date</h6>
                </div>

            </div>

        </div>
    )
}

export default ListOfLedger

