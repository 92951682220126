import { createStore,applyMiddleware,compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from './rootReducer';
import { loadState, saveState } from '../LocalStorage';
const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk)) // Use composeEnhancers to wrap applyMiddleware
);
// export const store = createStore(rootReducer,applyMiddleware(thunk))
store.subscribe(() => {
    saveState(store.getState());
  });
  export default store;