import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../../../../components/home_page/Footer'
import { Link } from 'react-router-dom'
const ChangeMode=(props)=> {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const account_master = [
        { to: "Single Mode", text: "Single Mode" },
        { to: "Double Mode", text: "Double Mode" },

    ];
    useEffect(() => {
        const handlekeydown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setchange_mode(false)
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handlekeydown)
        return () => {
            document.removeEventListener('keydown', handlekeydown);
        };
    }, [])
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, account_master.length - 1));
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (event.key === 'Enter') {
                event.preventDefault();
                // You can navigate to the selected link's path here
                const selectedLink = account_master[selectedIndex];
                if (selectedLink) {
                    props.setmode(selectedLink?.text);
                    props.setchange_mode(false)

                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setchange_mode(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3 border border-dark' id='card-period'>
                <div className='row'>
                    <div className='col-12 m-0 p-0'>
                        <div className='card_header'>
                            <p className='paragroup'>Change Voucher Mode</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 m-0 p-0'>
                        <ul className='master_ul_link_list'>
                            {account_master.map((link, index) => (
                                <div key={index}>
                                    <li className={index === selectedIndex ? 'selected' : ''}>
                                        <Link >{link?.text}</Link>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}
export default ChangeMode;