import {
    SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
    SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
    GET_Company_Alter_REQUEST, GET_Company_Alter_SUCCESS, GET_Comapny_Alter_FAILURE,
    LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE,
} from '../../actions/action-types';


const initialState = {
    userData: null,
    registration_response:[],
    SignIn: false,


}

export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // For SIGN_IN
        case SIGN_IN_REQUEST:
            return {
                ...state, userData: [], SignIn: false
            };
        case SIGN_IN_SUCCESS:
            return {
                ...state, userData: payload?.data || [], SignIn: true,

            };
        case SIGN_IN_FAILURE:
            return {
                ...state, userData: [], SignIn: false,
            };

        // For Sign Up
        case SIGN_UP_REQUEST:
            return {
                ...state, registration_response: []
            }
        case SIGN_UP_SUCCESS:
            return {
                ...state, registration_response: payload?.data || [],
            }
        case SIGN_UP_FAILURE:
            return {
                ...state, registration_response: []
            }
        // For Logout
        case LOGOUT_REQUEST:
            return {
                ...state,
            }
        case LOGOUT_SUCCESS:
            return {
                ...state, userData: [], SignIn: false,
            }
        case LOGOUT_FAILURE:
            return {
                ...state,userData: [], SignIn: false,
                
            }

        // GET_Company_Alter
        case GET_Company_Alter_REQUEST:
            return {
                ...state, userData: payload?.data
            }
        case GET_Company_Alter_SUCCESS:
            return {
                ...state, userData: payload?.data
            }
        case GET_Comapny_Alter_FAILURE:
            return {
                ...state, userData: [], SignIn: false,
            }
        default:
            return state
    }
}