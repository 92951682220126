import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../../../../components/home_page/Footer';
import { Link, useNavigate, Outlet,useParams } from 'react-router-dom';
import FormData from 'form-data';
import '../accounting_masters/ledger.css'
import { stockItemSingleData, stockItemUpdate } from '../../../../../redux/actions/action-creator';
import { toast } from 'react-toastify';
import '../../../../../App.css'
function StockItem() {
  const initialState = {
    stock_name: '',
    alise_name: '',
    part_no: '',
    description: '',
    notes: '',
    set_modify_default_ledger_for_invoice: 'No',
    under: 'Primary',
    under_id: '',
    category: 'Not Applicable',
    category_id: '',
    units: 'Not Applicable',
    unit_id: '',
    alternate_unit: '',
    alternate_unit_id: '',
    first_unit_count: '0',
    secound_unit_count: '0',
    weight_in_kg: '',
    per_unit_kg: '',
    per_alternate_unit_kg: '',
    company_name_of_product: '',
    is_gst_applicable: 'Applicable',
    hsn_sac_details: 'As per Company/Stock Group',
    source_of_details: 'Not Applicable',
    hsn_sac: '',
    description_hsn_sac: '',
    gst_rate_details: 'As per Company/Stock Group',
    taxability_type: '',
    igst_rate: '0',
    cgst_rate: '0',
    sgst_utst_rate: '0',
    cess_valuation_type: 'Not Applicable',
    cess_rate: '0',
    applicable_for_reverse_charge: 'No',
    eligible_for_tax_input_credit: 'No',
    type_of_supply: '',
    opening_balance: '',
    default_unit: '',
    default_unit_id: '',
    default_unit_for_rate: '',
    set_standard_rates: '',
    costing_method: '',
    market_valuation_method: '',
    provide_behaviour_options: '',
    ignore_diffrence_due_to_physical_counting: 'No',
    ignore_nagative_balance: 'No',
    treat_all_sales_as_new_manufacture: 'No',
    treat_all_purchased_as_comsumed: 'No',
    treat_all_rejections_inward_as_scrap: 'No',
    packing_size: ''
  }
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  // Search suggestion filter data state-------------------------------------------------------------------------------
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataUnit, setFilteredDataUnit] = useState([]);
  const [filteredDataCategory, setFilteredDataCategory] = useState([]);
  const [filteredDataDefaultUnit, setFilteredDataDefaultUnit] = useState([]);
  const [filteredDataAlternateUnit, setFilteredDataAlternateUnit] = useState([]);
  // dropdown selection state ------------------------------------------------------------------------------------------
  const [selectedItem, setSelectedItem] = useState(0);
  // Name input field validation to  check value not empty'state -------------------------------------------------------
  const [isStockItemNameEntered, setIsStockItemNameEntered] = useState(false);
  const [hashTag, setHashTag] = useState(false);
  // Create mutable object of all input to focus ---------------------------------------------------------------------------
  const stock_name_input_ref = useRef(null);
  const alise_name_input_ref = useRef(null);
  const part_no_input_ref = useRef(null);
  const description_input_ref = useRef(null);
  const notes_input_ref = useRef(null);
  const set_modify_default_ledger_for_invoice_input_ref = useRef(null);
  const under_input_ref = useRef(null);
  const category_input_ref = useRef(null);
  const is_gst_applicable_input_ref = useRef(null);
  const units_input_ref = useRef(null);
  const alternate_unit_input_ref = useRef(null);
  const first_unit_count_input_ref = useRef(null);
  const secound_unit_count_input_ref = useRef(null);
  const weight_in_kg_input_ref = useRef(null);
  const per_unit_kg_input_ref = useRef(null);
  const per_alternate_unit_kg_input_ref = useRef(null);
  const company_name_of_product_input_ref = useRef(null);
  const hsn_sac_details_input_ref = useRef(null);
  const source_of_details_input_ref = useRef(null);
  const hsn_sac_input_ref = useRef(null);
  const description_hsn_sac_input_ref = useRef(null);
  const gst_rate_details_input_ref = useRef(null);
  const taxability_type_input_ref = useRef(null);
  const igst_rate_input_ref = useRef(null);
  const cgst_rate_input_ref = useRef(null);
  const sgst_utst_rate_input_ref = useRef(null);
  const cess_valuation_type_input_ref = useRef(null);
  const cess_rate_input_ref = useRef(null);
  const applicable_for_reverse_charge_input_ref = useRef(null);
  const eligible_for_tax_input_credit_input_ref = useRef(null);
  const type_of_supply_input_ref = useRef(null);
  const opening_balance_input_ref = useRef(null);
  const default_unit_input_ref = useRef(null);
  const default_unit_for_rate_input_ref = useRef(null);
  const set_standard_rates_input_ref = useRef(null);
  const costing_method_input_ref = useRef(null);
  const market_valuation_method_input_ref = useRef(null);
  const provide_behaviour_options_input_ref = useRef(null);
  const ignore_diffrence_due_to_physical_counting_input_ref = useRef(null);
  const ignore_nagative_balance_input_ref = useRef(null);
  const treat_all_sales_as_new_manufacture_input_ref = useRef(null);
  const treat_all_purchased_as_comsumed_input_ref = useRef(null);
  const treat_all_rejections_inward_as_scrap_input_ref = useRef(null);
  // Mutable object create to user click outside when we use it to dropdown --------------------------------------------------
  const dropdownRef = useRef(null);
  const dropdownCategory = useRef(null);
  const dropdownUnits = useRef(null);
  const dropdownAlternateUnit = useRef(null);
  const dropdownDefaultUnit = useRef(null);
  const inputCategoryRef = useRef(null);
  // state for open dropdowns--------------------------------------------------------------------------------------------
  const [showList, setShowList] = useState(false);
  const [showListCategory, setShowListCategory] = useState(false);
  const [showListUnits, setShowLIstUnits] = useState(false);
  const [showListAlternateUnit, setShowLIstAlternateUnit] = useState(false);
  const [showListDefaultUnit, setShowListDefaultUnit] = useState(false);
  // State for manage all input visible and invisible ----------------------------------------------------------------------
  const [whereInputField, setShowWhereInputField] = useState(false)
  const [show_stock_name, setShow_stock_name] = useState(true);
  const [show_alise_name, setShow_alise_name] = useState(false);
  const [show_part_no, setShow_part_no] = useState(false);
  const [show_description, setShow_description] = useState(false);
  const [show_notes, setShow_notes] = useState(false);
  const [show_set_modify_default_ledger, setShow_set_modify_default_ledger] = useState(false);
  const [show_under, setShow_under] = useState(false);
  const [show_category, setShow_category] = useState(false);
  const [show_units, setShow_units] = useState(false);
  const [show_alternate_unit, setShow_alternate_unit] = useState(false);
  const [show_first_unit_count, setShow_first_unit_count] = useState(false);
  const [show_secound_unit_count, setShow_secound_unit_count] = useState(false);
  const [show_weight_in_kg, setShow_weight_in_kg] = useState(false);
  const [show_per_unit_kg, setShow_per_unit_kg] = useState(false);
  const [show_per_alternate_unit_kg, setShow_per_alternate_unit_kg] = useState(false);
  const [show_company_name_of_product, setShow_company_name_of_product] = useState(false);
  const [show_is_gst_applicable, setShow_is_gst_applicable] = useState(false);
  const [show_hsn_sac_details, setShow_hsn_sac_details] = useState(false);
  const [show_description_hsn_sac, setShow_description_hsn_sac] = useState(false);
  const [show_source_of_details, setShow_source_of_details] = useState(false);
  const [show_hsn_sac, setShow_hsn_sac] = useState(false);
  const [show_gst_rate_details, setShow_gst_rate_details] = useState(false);
  const [show_taxability_type, setShow_taxability_type] = useState(false);
  const [show_igst_rate, setShow_igst_rate] = useState(false);
  const [show_cgst_rate, setShow_cgst_rate] = useState(false);
  const [show_sgst_utst_rate, setShow_sgst_utst_rate] = useState(false);
  const [show_cess_valuation_type, setShow_cess_valuation_type] = useState(false);
  const [show_cess_rate, setShow_cess_rate] = useState(false);
  const [show_applicable_for_reverse_charge, setShow_applicable_for_reverse_charge] = useState(false);
  const [show_eligible_for_tax_input_credit, setShow_eligible_for_tax_input_credit] = useState(false);
  const [show_type_of_supply, setShow_type_of_supply] = useState(false);
  const [show_opening_balance, setShow_opening_balance] = useState(false);
  const [show_default_unit, setShow_default_unit] = useState(false);
  const [show_default_unit_for_rate, setShow_default_unit_for_rate] = useState(false);
  const [show_set_standard_rates, setShow_set_standard_rates] = useState(false);
  const [show_costing_method, setShow_costing_method] = useState(false);
  const [show_market_valuation_method, setShow_market_valuation_method] = useState(false);
  const [show_provide_behaviour_options, setShow_provide_behaviour_options] = useState(false);
  const [show_ignore_diffrence_due_to_physical_counting, setShow_ignore_diffrence_due_to_physical_counting] = useState(false);
  const [show_ignore_negative_balance, setShow_ignore_negative_balance] = useState(false);
  const [show_treat_all_sales_as_new_manufacture, setShow_treat_all_sales_as_new_manufacture] = useState(false);
  const [show_treat_all_purchased_as_consumed, setShow_treat_all_purchased_as_consumed] = useState(false);
  const [show_treat_all_rejections_inward_as_scrap, setShow_treat_all_rejections_inward_as_scrap] = useState(false);
  // get state data from redux ------------------------------------------------------------------------------------------
  const { unit_data = null } = useSelector(state => state.common);
  const { stock_category_data = null } = useSelector(state => state.common);
  const { stock_group_data = null } = useSelector(state => state.common);
  // Triger function whenever the change input value ----------------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    if (name === 'stock_name') {
      setIsStockItemNameEntered(!!value.trim());
      setState({
        ...state, stock_name: value.toLowerCase()
          .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase())
      });
      if (!isStockItemNameEntered && e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
      }
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_alise_name(true);
        setTimeout(() => {
          alise_name_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'alise_name') {
      setState({
        ...state, alise_name: value.toLowerCase()
          .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase())
      });
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_part_no(true);
        setTimeout(() => {
          part_no_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'part_no') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_description(true);
        setTimeout(() => {
          description_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'description') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_notes(true);
        setTimeout(() => {
          notes_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'notes') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_set_modify_default_ledger(true);
        setTimeout(() => {
          set_modify_default_ledger_for_invoice_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'set_modify_default_ledger_for_invoice') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_under(true);
        setTimeout(() => {
          under_input_ref.current.focus();
        }, 50);
        const filteredSuggestions = Object.keys(stock_group_data).filter(key =>
          stock_group_data[key]?.name.toLowerCase().includes(state.under.toLowerCase()));
        setFilteredData(filteredSuggestions);
      }
    }
    if (name === 'under') {
      const filteredSuggestions = Object.keys(stock_group_data).filter(key =>
        stock_group_data[key]?.name.toLowerCase().includes(state.under.toLowerCase()));
      setFilteredData(filteredSuggestions);
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_category(true);
        setTimeout(() => {
          inputCategoryRef.current.focus();
        }, 50);
        setShowList(false)
      }
    }
    if (name === 'category') {
      const filteredSuggestionsCatogory = Object.keys(stock_category_data).filter(key =>
        stock_category_data[key]?.name.toLowerCase().includes(state.category.toLowerCase()));
      setFilteredDataCategory(filteredSuggestionsCatogory);
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_company_name_of_product(true);
        setTimeout(() => {
          company_name_of_product_input_ref.current.focus();
        }, 50);
        setShowListCategory(false)
      }
    }
    if (name === 'company_name_of_product') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_is_gst_applicable(true);
        setTimeout(() => {
          is_gst_applicable_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'provide_behaviour_options') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        if (state.provide_behaviour_options === 'Yes') {
          setShow_ignore_diffrence_due_to_physical_counting(true);
          setTimeout(() => {
            ignore_diffrence_due_to_physical_counting_input_ref.current.focus();
          }, 50);
        }
        else if (state.provide_behaviour_options === 'No') {
          e.preventDefault();
          setShow_opening_balance(true);
          setTimeout(() => {
            opening_balance_input_ref.current.focus();
          }, 100);
        }
      }
    }
    if (name === 'ignore_diffrence_due_to_physical_counting') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_ignore_negative_balance(true);
        setTimeout(() => {
          ignore_nagative_balance_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'ignore_nagative_balance') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_treat_all_sales_as_new_manufacture(true);
        setTimeout(() => {
          treat_all_sales_as_new_manufacture_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'treat_all_sales_as_new_manufacture') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_treat_all_purchased_as_consumed(true);
        setTimeout(() => {
          treat_all_purchased_as_comsumed_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'treat_all_purchased_as_comsumed') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_treat_all_rejections_inward_as_scrap(true);
        setTimeout(() => {
          treat_all_rejections_inward_as_scrap_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'treat_all_rejections_inward_as_scrap') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_opening_balance(true);
        setTimeout(() => {
          opening_balance_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'is_gst_applicable') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_hsn_sac_details(true);
        setTimeout(() => {
          hsn_sac_details_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'hsn_sac_details') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        if (state.hsn_sac_details === 'As per Company/Stock Group' || state.hsn_sac_details === 'Specify in Voucher') {
          setShow_gst_rate_details(true)
          setTimeout(() => {
            gst_rate_details_input_ref.current.focus();
          }, 50);
        } else if (state.hsn_sac_details === 'Specify Details Here') {
          e.preventDefault();
          setShow_hsn_sac(true);
          setTimeout(() => {
            hsn_sac_input_ref.current.focus();
          }, 50);
        }
      }
    }
    if (name === 'hsn_sac') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_description_hsn_sac(true);
        setTimeout(() => {
          description_hsn_sac_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'description_hsn_sac') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_gst_rate_details(true);
        setTimeout(() => {
          gst_rate_details_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'gst_rate_details') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        if (state.gst_rate_details === 'As per Company/Stock Group' || state.gst_rate_details === 'Specify in Voucher') {
          setShow_type_of_supply(true)
          setTimeout(() => {
            type_of_supply_input_ref.current.focus();
          }, 50);
        } else if (state.gst_rate_details === 'Specify Details Here') {
          e.preventDefault();
          setShow_taxability_type(true);
          setTimeout(() => {
            taxability_type_input_ref.current.focus();
          }, 50);
        }
      }
    }
    if (name === 'source_of_details') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_taxability_type(true);
        setTimeout(() => {
          taxability_type_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'taxability_type') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_igst_rate(true);
        setTimeout(() => {
          igst_rate_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'igst_rate') {
      const cleanedValue = value.replace(/^0+/, '');
      const igstRate = parseInt(cleanedValue, 10) || 0; // Assuming you want to convert it to an integer
      setState((prevState) => ({
        ...prevState,
        igst_rate: cleanedValue.toString(),
        cgst_rate: igstRate / 2,
        sgst_utst_rate: igstRate / 2,
      }));
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_cgst_rate(true);
        setTimeout(() => {
          cgst_rate_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'cgst_rate') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_sgst_utst_rate(true);
        setTimeout(() => {
          sgst_utst_rate_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'sgst_utst_rate') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_cess_valuation_type(true);
        setTimeout(() => {
          cess_valuation_type_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'cess_valuation_type') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_cess_rate(true);
        setTimeout(() => {
          cess_rate_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'cess_rate') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_applicable_for_reverse_charge(true);
        setTimeout(() => {
          applicable_for_reverse_charge_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'applicable_for_reverse_charge') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_eligible_for_tax_input_credit(true);
        setTimeout(() => {
          eligible_for_tax_input_credit_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'eligible_for_tax_input_credit') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_type_of_supply(true);
        setTimeout(() => {
          type_of_supply_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'type_of_supply') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_units(true);
        setTimeout(() => {
          units_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'units') {
      const filteredSuggestions = Object.keys(unit_data).filter(key =>
        unit_data[key]?.symbol.toLowerCase().includes(state.units.toLowerCase()));
      setFilteredDataUnit(filteredSuggestions);
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_alternate_unit(true);
        setTimeout(() => {
          alternate_unit_input_ref.current.focus();
        }, 50);
        setShowLIstUnits(false)
      }
    }
    if (name === 'alternate_unit') {
      const filteredSuggestions = Object.keys(unit_data).filter(key =>
        unit_data[key]?.symbol.toLowerCase().includes(state.alternate_unit.toLowerCase()));
      setFilteredDataAlternateUnit(filteredSuggestions);
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShowWhereInputField(true)
        setShow_first_unit_count(true);
        setTimeout(() => {
          first_unit_count_input_ref.current.focus();
        }, 50);
        setShowLIstAlternateUnit(false)
      }
    }
    if (name === 'first_unit_count') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_secound_unit_count(true);
        setTimeout(() => {
          secound_unit_count_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'secound_unit_count') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_default_unit(true);
        setTimeout(() => {
          default_unit_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'default_unit') {
      const filteredSuggestions = Object.keys(unit_data).filter(key =>
        unit_data[key]?.symbol.toLowerCase().includes(state.default_unit.toLowerCase()));
      setFilteredDataDefaultUnit(filteredSuggestions);
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_default_unit_for_rate(true);
        setTimeout(() => {
          default_unit_for_rate_input_ref.current.focus();
        }, 50);
        setShowListDefaultUnit(false)
      }
    }
    if (name === 'default_unit_for_rate') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_weight_in_kg(true);
        setTimeout(() => {
          weight_in_kg_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'weight_in_kg') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        if (state.weight_in_kg === 'Not Available') {
          setShow_set_standard_rates(true)
          setTimeout(() => {
            set_standard_rates_input_ref.current.focus();
          }, 50);
        } else if (state.weight_in_kg === 'Available') {
          e.preventDefault();
          setShow_per_unit_kg(true);
          setTimeout(() => {
            per_unit_kg_input_ref.current.focus();
          }, 50);
        }

      }
    }
    if (name === 'per_unit_kg') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_per_alternate_unit_kg(true);
        setTimeout(() => {
          per_alternate_unit_kg_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'per_alternate_unit_kg') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_set_standard_rates(true);
        setTimeout(() => {
          set_standard_rates_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'set_standard_rates') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_costing_method(true);
        setTimeout(() => {
          costing_method_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'costing_method') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_market_valuation_method(true);
        setTimeout(() => {
          market_valuation_method_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'market_valuation_method') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setShow_provide_behaviour_options(true);
        setTimeout(() => {
          provide_behaviour_options_input_ref.current.focus();
        }, 50);
      }
    }
    if (name === 'opening_balance') {
      if (state.stock_name !== '' && e.key === 'Enter') {
        e.preventDefault();
        setHashTag(true)

      }
    }
  }
  // Triggered when an element receives focus
  const handleInputFocus = (e) => {
    
    const { name, value } = e.target;
    if (name === 'stock_name') {
      setShow_stock_name(true);
    }
    if (name === 'alise_name') {
      setShow_alise_name(true);
    }
    if (name === 'part_no') {
      setShow_part_no(true);
    }
    if (name === 'description') {
      setShow_description(true);
    }
    if (name === 'notes') {
      setShow_notes(true);
    }
    if (name === 'set_modify_default_ledger_for_invoice') {
      setShow_set_modify_default_ledger(true);
      set_modify_default_ledger_for_invoice_input_ref.current.setSelectionRange(0, 0);
    }
    if (name === 'under') {
      setShow_under(true);
      if (!showList) {
        setShowList(true);
      }
    }
    if (name === 'category') {
      setShow_category(true);
      if (!showListCategory) {
        setShowListCategory(true);
      }
    }
    if (name === 'company_name_of_product') {
      setShow_company_name_of_product(true);
    }
    if (name === 'provide_behaviour_options') {
      setShow_provide_behaviour_options(true);
    }
    if (name === 'ignore_diffrence_due_to_physical_counting') {
      setShow_ignore_diffrence_due_to_physical_counting(true)

    }
    if (name === 'ignore_nagative_balance') {
      setShow_ignore_negative_balance(true)
    }
    if (name === 'treat_all_sales_as_new_manufacture') {
      setShow_treat_all_sales_as_new_manufacture(true)
    }
    if (name === 'treat_all_purchased_as_comsumed') {
      setShow_treat_all_purchased_as_consumed(true)
    }
    if (name === 'treat_all_rejections_inward_as_scrap') {
      setShow_treat_all_rejections_inward_as_scrap(true)
    }
    if (name === 'is_gst_applicable') {
      setShow_is_gst_applicable(true)
    }
    if (name === 'hsn_sac_details') {
      setShow_hsn_sac_details(true)
    }
    if (name === 'hsn_sac') {
      setShow_hsn_sac(true)
    }
    if (name === 'description_hsn_sac') {
      setShow_description_hsn_sac(true)
    }
    if (name === 'gst_rate_details') {
      setShow_gst_rate_details(true)
    }
    if (name === 'source_of_details') {
      setShow_source_of_details(true)
    }
    if (name === 'taxability_type') {
      setShow_taxability_type(true)
    }
    if (name === 'igst_rate') {
      setShow_igst_rate(true)
    }
    if (name === 'cgst_rate') {
      setShow_cgst_rate(true)
    }
    if (name === 'sgst_utst_rate') {
      setShow_sgst_utst_rate(true)
    }
    if (name === 'cess_valuation_type') {
      setShow_cess_valuation_type(true)
    }
    if (name === 'cess_rate') {
      setShow_cess_rate(true)
    }
    if (name === 'applicable_for_reverse_charge') {
      setShow_applicable_for_reverse_charge(true)
    }
    if (name === 'eligible_for_tax_input_credit') {
      setShow_eligible_for_tax_input_credit(true)
    }
    if (name === 'type_of_supply') {
      setShow_type_of_supply(true)
    }
    if (name === 'units') {
      setShow_units(true)
      if (!showListUnits) {
        setShowLIstUnits(true);
      }
    }
    if (name === 'alternate_unit') {
      setShow_alternate_unit(true)
      if (!showListAlternateUnit) {
        setShowLIstAlternateUnit(true);
      }
    }
    if (name === 'first_unit_count') {
      setShow_first_unit_count(true)
    }
    if (name === 'second_unit_count') {
      setShow_secound_unit_count(true)
    }
    if (name === 'default_unit') {
      setShow_default_unit(true)
      if (!showListDefaultUnit) {
        setShowListDefaultUnit(true);
      }
    }
    if (name === 'default_unit_for_rate') {
      setShow_default_unit_for_rate(true)
    }
    if (name === 'weight_in_kg') {
      setShow_weight_in_kg(true)
    }
    if (name === 'per_unit_kg') {
      setShow_per_unit_kg(true)
    }
    if (name === 'per_alternate_unit_kg') {
      setShow_per_alternate_unit_kg(true)
    }
    if (name === 'set_standard_rates') {
      setShow_set_standard_rates(true)
    }
    if (name === 'costing_method') {
      setShow_costing_method(true)
    }
    if (name === 'market_valuation_method') {
      setShow_market_valuation_method(true)
    }
    if (name === 'opening_balance') {
      setShow_opening_balance(true)
    }
  }
  // Triggered when an element loses focus--------------------------------------------------------------------------------
  const handleInputBlur = (e) => {
    setShow_stock_name(false)
    setShow_alise_name(false)
    setShow_part_no(false)
    setShow_description(false)
    setShow_notes(false)
    setShow_set_modify_default_ledger(false)
    setShow_under(false)
    setShow_category(false)
    setShow_company_name_of_product(false)
    setShow_provide_behaviour_options(false)
    setShow_ignore_diffrence_due_to_physical_counting(false)
    setShow_ignore_negative_balance(false);
    setShow_treat_all_purchased_as_consumed(false);
    setShow_treat_all_sales_as_new_manufacture(false)
    setShow_treat_all_rejections_inward_as_scrap(false)
    setShow_is_gst_applicable(false)
    setShow_hsn_sac_details(false)
    setShow_hsn_sac(false)
    setShow_description_hsn_sac(false)
    setShow_description(false)
    setShow_gst_rate_details(false)
    setShow_source_of_details(false)
    setShow_taxability_type(false)
    setShow_igst_rate(false)
    setShow_cgst_rate(false)
    setShow_sgst_utst_rate(false)
    setShow_cess_valuation_type(false)
    setShow_cess_rate(false)
    setShow_applicable_for_reverse_charge(false)
    setShow_eligible_for_tax_input_credit(false)
    setShow_type_of_supply(false)
    setShow_units(false)
    setShow_alternate_unit(false)
    setShow_first_unit_count(false)
    setShow_secound_unit_count(false)
    setShow_default_unit(false)
    setShow_default_unit_for_rate(false)
    setShow_weight_in_kg(false)
    setShow_per_unit_kg(false)
    setShow_per_alternate_unit_kg(false)
    setShow_set_standard_rates(false)
    setShow_costing_method(false)
    setShow_market_valuation_method(false)
    setShow_opening_balance(false)
  }
  // get data from api ---------------------------------------------------------------------------------------------------
  useEffect(() => {
    const formData = new FormData();
    formData.append('id', id)
    dispatch(stockItemSingleData(formData)).then((response) => {
      if (response.status) {
        setState({
          stock_name: response.data?.name,
          alise_name: response.data?.alias_name,
          part_no: response.data?.name,
          description: response.data?.item_desc,
          notes: response.data?.notes,
          set_modify_default_ledger_for_invoice: response.data?.set_default_ledger,
          under: response.data?.under,
          under_id: response.data?.under,
          category: response.data?.stock_category_name,
          category_id: response.data?.stock_cat_id,
          units: response.data?.unit_name,
          unit_id: response.data?.unit_id,
          alternate_unit: response.data?.unit_name,
          alternate_unit_id: response.data?.alt_unit_id,
          first_unit_count: response.data?.where_unit,
          secound_unit_count: response.data?.where_alternate_unit,
          weight_in_kg: response.data?.weight,
          per_unit_kg: response.data?.per_unit,
          per_alternate_unit_kg: response.data?.per_alt_unit,
          company_name_of_product: response.data?.stock_company,
          is_gst_applicable: response.data?.GST_applicable,
          hsn_sac_details: response.data?.HSN_SAC_details,
          source_of_details: response.data?.stock_group_name,
          hsn_sac: response.data?.HSN_SAC,
          description_hsn_sac: response.data?.description,
          gst_rate_details: response.data?.GST_rate_details,
          taxability_type: response.data?.taxability_type,
          igst_rate: response.data?.IGST_rate,
          cgst_rate: response.data?.CGST_rate,
          sgst_utst_rate: response.data?.SGST_UTGST_rate,
          cess_valuation_type: response.data?.cess_validation,
          cess_rate: response.data?.cess_rate,
          applicable_for_reverse_charge: response.data?.application_of_reverse,
          eligible_for_tax_input_credit: response.data?.eligible_tax_credit,
          type_of_supply: response.data?.type_of_supply,
          opening_balance: response.data?.opening_balance,
          default_unit: response.data?.default_unit_name,
          default_unit_for_rate: response.data?.default_unit_rate,
          set_standard_rates: response.data?.set_standard_rate,
          costing_method: response.data?.costing_method,
          market_valuation_method: response.data?.market_valuation,
          provide_behaviour_options: response.data?.provide_behaviour_opt,
          ignore_diffrence_due_to_physical_counting: response.data?.ignore_diff_physical,
          ignore_nagative_balance: response.data?.ignore_negative_balance,
          treat_all_sales_as_new_manufacture: response.data?.treat_sale_manufacture,
          treat_all_purchased_as_comsumed: response.data?.treat_purchased_consumed,
          treat_all_rejections_inward_as_scrap: response.data?.treat_rejection_scrap,
        })
        // toast.success(response?.message || 'data found!', {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || 'Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [id, dispatch])
  // when user click outside to close dropdowns---------------------------------------------------------------------------
  // useEffect(() => {
  //   document.addEventListener('click', handleClick);
  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // }, []);

  // const handleClick = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !under_input_ref.current.contains(event.target)) {
  //     setShowList(false);
  //   }
  //   if (dropdownCategory.current && !dropdownCategory.current.contains(event.target) && !inputCategoryRef.current.contains(event.target)) {
  //     setShowListCategory(false);
  //   }
  //   if (dropdownUnits.current && !dropdownUnits.current.contains(event.target) && !inputUnitsRef.current.contains(event.target)) {
  //     setShowLIstUnits(false);
  //   }
  //   if (dropdownAlternateUnit.current && !dropdownAlternateUnit.current.contains(event.target) && !inputAlternateUnitRef.current.contains(event.target)) {
  //     setShowLIstAlternateUnit(false);
  //   }
  //   if (dropdownDefaultUnit.current && !dropdownDefaultUnit.current.contains(event.target) && !inputDefaultUnitRef.current.contains(event.target)) {
  //     setShowListDefaultUnit(false);
  //   }
  // };
  // Destructuring object from state-------------------------------------------------------------------------------
  const { stock_name, alise_name, part_no, description, notes,set_modify_default_ledger_for_invoice, under, 
    category, units, alternate_unit, first_unit_count, secound_unit_count, weight_in_kg, per_unit_kg, 
    per_alternate_unit_kg, company_name_of_product,
    is_gst_applicable,hsn_sac_details, source_of_details,hsn_sac,description_hsn_sac,gst_rate_details,
    taxability_type,igst_rate,cgst_rate,sgst_utst_rate,cess_valuation_type,cess_rate,applicable_for_reverse_charge,
    eligible_for_tax_input_credit,type_of_supply, opening_balance,
    default_unit,default_unit_for_rate, set_standard_rates, costing_method, market_valuation_method, 
    provide_behaviour_options, ignore_diffrence_due_to_physical_counting, ignore_nagative_balance,
    treat_all_sales_as_new_manufacture, treat_all_purchased_as_comsumed, treat_all_rejections_inward_as_scrap,
  } = state;
  // Keyboad functionality in dropdown select and enter -------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showList === true) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink = stock_group_data[selectedItem]
          if (selectedLink) {
            setState({
              ...state,
              under: selectedLink.name,
              under_id: selectedLink.id,
              hsn_sac: selectedLink.HSN_SAC,
              description_hsn_sac: selectedLink.description,
              taxability_type: selectedLink.taxability_type,
              igst_rate: selectedLink.IGST_rate,
              cgst_rate: selectedLink.CGST_rate,
              sgst_utst_rate: selectedLink.SGST_UTGST_rate,
              source_of_details: selectedLink.name
            })
            setShowList(false)
          }
        } 
      }
      if (showListCategory) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredDataCategory.length - 1));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink = stock_category_data[selectedItem]
          if (selectedLink) {
            setState({
              ...state,
              category: selectedLink.name,
              category_id: selectedLink.id
            })
            setShowListCategory(false)
          }
        }
      }
      if (showListUnits) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredDataUnit.length - 1));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink = unit_data[selectedItem]
          if (selectedLink) {
            setState({
              ...state,
              units: selectedLink.symbol,
              unit_id: selectedLink.id,
            })
            setShowLIstUnits(false)
          }
        }
      }
      if (showListAlternateUnit) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredDataAlternateUnit.length - 1));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink = unit_data[selectedItem]
          if (selectedLink) {
            setState({
              ...state,
              alternate_unit: selectedLink.symbol,
              alternate_unit_id: selectedLink.id,
            })
            setShowLIstAlternateUnit(false)
          }
        }
      }
      if (showListDefaultUnit) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredDataAlternateUnit.length - 1));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink = unit_data[selectedItem]
          if (selectedLink) {
            setState({
              ...state,
              default_unit: selectedLink.symbol,
              default_unit_id: selectedLink.id,
            })
            setShowLIstAlternateUnit(false)
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, under, category, state, showList, showListCategory, showListUnits]);
  // Triggered when a form is submitted ----------------------------------------------------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hashTag === true) {
      let data = new FormData();
      data.append('name', state.stock_name);
      data.append('alias_name', state.alise_name);
      data.append('under', state.under_id);
      data.append('item_desc', state.description);
      data.append('notes', state.notes);
      data.append('set_default_ledger', state.set_modify_default_ledger_for_invoice);
      data.append('unit_id', state.unit_id);
      data.append('alt_unit_id', state.alternate_unit_id);
      data.append('stock_cat_id', state.category_id);
      data.append('stock_company', state.company_name_of_product);
      data.append('provide_behaviour_opt', state.provide_behaviour_options);
      data.append('ignore_diff_physical', state.ignore_diffrence_due_to_physical_counting);
      data.append('ignore_negative_balance', state.ignore_nagative_balance);
      data.append('treat_sale_manufacture', state.treat_all_sales_as_new_manufacture);
      data.append('treat_purchased_consumed', state.treat_all_purchased_as_comsumed);
      data.append('treat_rejection_scrap', state.treat_all_rejections_inward_as_scrap);
      data.append('set_standard_rate', state.set_standard_rates);
      data.append('costing_method', state.costing_method);
      data.append('market_valuation', state.market_valuation_method);
      data.append('HSN_SAC_details', state.hsn_sac_details);
      data.append('source_details', state.source_of_details);
      data.append('HSN_SAC', state.hsn_sac);
      data.append('description', state.description_hsn_sac);
      data.append('GST_rate_details', state.gst_rate_details);
      data.append('source_of_details', state.source_of_details);
      data.append('taxability_type', state.taxability_type);
      data.append('IGST_rate', state.igst_rate);
      data.append('CGST_rate', state.cgst_rate);
      data.append('SGST_UTGST_rate', state.sgst_utst_rate);
      data.append('cess_validation', state.cess_valuation_type);
      data.append('cess_rate', state.cess_rate);
      data.append('application_of_reverse', state.applicable_for_reverse_charge);
      data.append('eligible_tax_credit', state.eligible_for_tax_input_credit);
      data.append('GST_applicable', state.is_gst_applicable);
      data.append('rate_of_Duty', '');
      data.append('default_unit', state.default_unit);
      data.append('default_unit_rate', state.default_unit_for_rate);
      data.append('weight', state.weight_in_kg);
      data.append('per_unit', state.per_unit_kg);
      data.append('per_alt_unit', state.per_alternate_unit_kg);
      data.append('where_unit', state.first_unit_count);
      data.append('where_alternate_unit', state.secound_unit_count);
      data.append('opening_balance', state.opening_balance);
      data.append('type_of_supply', state.type_of_supply);
      data.append('id',id);
      dispatch(stockItemUpdate(data)).then((response) => {
        if (response.status) {
          toast.success(response?.message || 'group created!', {
            position: toast.POSITION.TOP_CENTER
          });
          navigate('/chart-of-account-invmas-stockitem')
          setState(
            {
              stock_name: '',
              alise_name: '',
              part_no: '',
              description: '',
              notes: '',
              set_modify_default_ledger_for_invoice: 'No',
              under: 'Primary',
              under_id: '',
              category: 'Not Applicable',
              category_id: '',
              units: 'Not Applicable',
              unit_id: '',
              alternate_unit: '',
              alternate_unit_id: '',
              first_unit_count: '',
              secound_unit_count: '',
              weight_in_kg: '',
              per_unit_kg: '',
              per_alternate_unit_kg: '',
              company_name_of_product: '',
              is_gst_applicable: 'Applicable',
              hsn_sac_details: 'As per Company/Stock Group',
              source_of_details: 'Not Applicable',
              hsn_sac: '',
              description: '',
              gst_rate_details: 'As per Company/Stock Group',
              taxability_type: '',
              igst_rate: '0',
              cgst_rate: '0',
              sgst_utst_rate: '0',
              cess_valuation_type: 'Not Applicable',
              cess_rate: '0',
              applicable_for_reverse_charge: 'No',
              eligible_for_tax_input_credit: 'No',
              type_of_supply: '',
              opening_balance: '',
              default_unit: '',
              default_unit_for_rate: '',
              set_standard_rates: '',
              costing_method: '',
              market_valuation_method: '',
              provide_behaviour_options: '',
              ignore_diffrence_due_to_physical_counting: 'No',
              ignore_nagative_balance: 'No',
              treat_all_sales_as_new_manufacture: 'No',
              treat_all_purchased_as_comsumed: 'No',
              treat_all_rejections_inward_as_scrap: 'No',
            }
          )
        }
        else {
          toast.warn(response?.message || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }).catch(error => {
        console.log(error)
        toast.error(error?.message || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
      })
    }
  }
  return (
    <div className='section'>
      <div className='detail border'>
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <form autoComplete='off' onSubmit={handleSubmit}>
            <div className='row' style={{ width: '95%', background: '#fff', }}>
              <div className='row border m-0'>
                <div className='col-6 '>
                  <div className='row mt-2 mb-1'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab_stock_item'> Name</label>
                      <label> :</label>
                      <div className='d-flex align-item-center' style={{ width: '90%' }}>
                        {
                          show_stock_name ?
                            <input
                              type="text" autoFocus
                              name='stock_name'
                              id='stock_name'
                              style={{ width: '100%' }}
                              className={`input_stock_item`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              ref={stock_name_input_ref}
                              value={stock_name || ""} />
                            :
                            <div style={{ width: '100%', height: 20 }} onClick={() => { setShow_stock_name(true) }}>
                              <p className='m-0 p-2 pb-0 pt-0'> {state.stock_name}</p>
                            </div>
                        }
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab_stock_item'> (alise) </label>
                      <label> :</label>
                      <div className='d-flex align-item-center' style={{ width: '90%' }}>
                        {
                          show_alise_name ?
                            <input
                              type="text"
                              name='alise_name'
                              id='alise_name'
                              style={{ width: '100%' }}
                              className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              ref={alise_name_input_ref}
                              value={alise_name || ""} />
                            :
                            <div onClick={() => { setShow_alise_name(true) }}>
                              <p className='m-0 p-2 pb-0 pt-0'> {state.alise_name}</p>
                            </div>
                        }
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab_stock_item'> Part No. </label>
                      <label> :</label>
                      <div className='d-flex align-item-center' style={{ width: '90%' }}>
                        {
                          show_part_no ?
                            <input
                              type="text"
                              name='part_no'
                              id='part_no'
                              style={{ width: '100%' }}
                              className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              ref={part_no_input_ref}
                              value={part_no || ""} />
                            :
                            <div onClick={() => { setShow_part_no(true) }}>
                              <p className='m-0 p-2 pb-0 pt-0'> {state.part_no}</p>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <div className='row mt-2 mb-1'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-stock' style={{ width: '7%' }}> Description </label>
                      <label> :</label>
                      <div className='d-flex align-item-center' style={{ width: '70%' }}>
                        {
                          show_description ?
                            <input
                              type="text"
                              name='description'
                              id='description'
                              style={{ width: '100%' }}
                              className={`lab-right-stock ${isStockItemNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              ref={description_input_ref}
                              value={description || ""} />
                            :
                            <div style={{ width: '100%', height: 20 }} onClick={() => {
                              if (state.stock_name !== '') {
                                setShow_description(true);
                                setTimeout(() => {
                                  description_input_ref.current.focus();
                                }, 50);
                              } else {
                                setShow_stock_name(true)
                                setTimeout(() => {
                                  stock_name_input_ref.current.focus();
                                }, 50);
                              }
                            }}>
                              <p className='m-0 p-2 pb-0 pt-0'> {state.description}</p>
                            </div>
                        }
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-stock' style={{ width: '7%' }}> Notes </label>
                      <label> :</label>
                      <div className='d-flex align-item-center' style={{ width: '70%' }}>
                        {
                          show_notes ?
                            <input
                              type="text"
                              name='notes'
                              id='notes'
                              style={{ width: '100%' }}
                              className={`lab-right-stock ${isStockItemNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              ref={notes_input_ref}
                              value={notes || ""} />
                            :
                            <div style={{ width: '100%', height: 20 }} onClick={() => {
                              if (state.stock_name !== '') {
                                setShow_notes(true);
                                setTimeout(() => {
                                  notes_input_ref.current.focus();
                                }, 50);
                              } else {
                                setShow_stock_name(true)
                                setTimeout(() => {
                                  stock_name_input_ref.current.focus();
                                }, 50);
                              }
                            }}>
                              <p className='m-0 p-2 pb-0 pt-0'> {state.notes}</p>
                            </div>
                        }
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-stock' style={{ width: '70%' }}> Set modify default ledgers for Invoice </label>
                      <label> :</label>
                      <div className='d-flex align-item-center' style={{ width: '70%' }}>
                        {
                          show_set_modify_default_ledger ?
                            <input
                              type="text"
                              name='set_modify_default_ledger_for_invoice'
                              id='set_modify_default_ledger_for_invoice'
                              style={{ width: '20%' }}
                              className={`lab-right-stock ${isStockItemNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleInputFocus}
                              onBlur={handleInputBlur}
                              ref={set_modify_default_ledger_for_invoice_input_ref}
                              value={set_modify_default_ledger_for_invoice || ""} />
                            :
                            <div style={{ width: '100%', height: 20 }} onClick={() => {
                              if (state.stock_name !== '') {
                                setShow_set_modify_default_ledger(true);
                                setTimeout(() => {
                                  set_modify_default_ledger_for_invoice_input_ref.current.focus();
                                }, 50);
                              } else {
                                setShow_stock_name(true)
                                setTimeout(() => {
                                  stock_name_input_ref.current.focus();
                                }, 50);
                              }
                            }}>
                              <p className='m-0 p-2 pb-0 pt-0'> {state.set_modify_default_ledger_for_invoice}</p>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row  m-0 pt-1' style={{ height: 380, width: '100%', overflowY: 'scroll' }} >
                <div className='col-6'>
                  <div className='row border-right'>
                    <div className='col' >
                      <div className='d-flex justify-content-between align-item-center'>
                        <label className='lab_stock_item'> Technical Name of Product </label>
                        <label> :</label>
                        <div className='d-flex align-item-center' style={{ width: '90%' }}>
                          {
                            show_under ?
                              <input
                                type="text"
                                name='under'
                                id='under'
                                style={{ width: '100%' }}
                                className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                ref={under_input_ref}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                value={under || ""} />
                              :
                              <div style={{ width: '100%', height: 20 }} onClick={() => {
                                if (state.stock_name !== '') {
                                  setShow_under(true);
                                  setTimeout(() => {
                                    under_input_ref.current.focus();
                                  }, 50);
                                } else {
                                  setShow_stock_name(true)
                                  setTimeout(() => {
                                    stock_name_input_ref.current.focus();
                                  }, 50);
                                }
                              }}>
                                <p className='m-0 p-2 pb-0 pt-0'> {state.under}</p>
                              </div>
                          }
                        </div>
                        <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                          <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                            List Of Group
                          </div>
                          <div className='col ' style={{ textAlign: 'right' }}>
                            <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                            <div className='border'></div>
                          </div>
                          <div id='data'>
                            {filteredData.length > 0 ? (
                              filteredData.map((key, number) => {
                                const name = stock_group_data[key]?.name;
                                const index = name.toLowerCase().indexOf(under.toLowerCase());
                                return (
                                  <Link
                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                    id='list'
                                    key={number}
                                    // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedItem(number)
                                      if (number === selectedItem) {
                                        setState({ ...state, under: name })
                                        setShowList(false)
                                        setShow_category(true)
                                        setTimeout(() => {
                                          inputCategoryRef.current.focus();
                                        }, 50);
                                      }
                                    }}
                                  >
                                    {index !== -1 ? (
                                      < >
                                        {name.substring(0, index)}
                                        <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                        {name.substring(index + under.length)}
                                      </>
                                    ) : (
                                      name
                                    )}
                                  </Link>
                                );
                              })
                            ) : (
                              <p style={{ color: 'red' }}>No matching items found</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-item-center'>
                        <label className='lab_stock_item'> Category </label>
                        <label> :</label>
                        <div className='d-flex align-item-center' style={{ width: '90%' }}>
                          {
                            show_category ?
                              <input
                                type="text"
                                name='category'
                                id='category'
                                style={{ width: '100%' }}
                                className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                ref={inputCategoryRef}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                // onFocus={categoryFocus} 
                                value={category || ""} />
                              :
                              <div style={{ width: '100%', height: 20 }} onClick={() => {
                                if (state.stock_name !== '') {
                                  setShow_category(true);
                                  setTimeout(() => {
                                    inputCategoryRef.current.focus();
                                  }, 50);
                                } else {
                                  setShow_stock_name(true)
                                  setTimeout(() => {
                                    stock_name_input_ref.current.focus();
                                  }, 50);
                                }
                              }}>
                                <p className='m-0 p-2 pb-0 pt-0'> {state.category}</p>
                              </div>
                          }
                        </div>
                        <div id="myDropdown-list" className={`dropdown-content-list ${showListCategory ? 'show-list' : ''}`} ref={dropdownCategory}>
                          <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                            List Of Category
                          </div>
                          <div className='col ' style={{ textAlign: 'right' }}>
                            <Link to='/create-master-invmas-stockcategory' onClick={() => navigate('/create-master-invmas-stockcategory')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                            <div className='border'></div>
                          </div>
                          <div id='data'>
                            {filteredDataCategory.length > 0 ? (
                              filteredDataCategory.map((key, number) => {
                                const name = stock_category_data[key]?.name;
                                const index = name.toLowerCase().indexOf(category.toLowerCase());
                                return (
                                  <Link
                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                    id='list'
                                    key={number}
                                    // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedItem(number)
                                      if (number === selectedItem) {
                                        setState({ ...state, category: name })
                                        setShowListCategory(false)
                                        setShow_company_name_of_product(true)
                                        setTimeout(() => {
                                          company_name_of_product_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}
                                  >
                                    {index !== -1 ? (
                                      < >
                                        {name.substring(0, index)}
                                        <span style={{ color: 'red' }}>{name.substring(index, index + category.length)}</span>
                                        {name.substring(index + category.length)}
                                      </>
                                    ) : (
                                      name
                                    )}
                                  </Link>
                                );
                              })
                            ) : (
                              <p style={{ color: 'red' }}>No matching items found</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-item-center'>
                        <label className='lab_stock_item'>Company Name of Product </label>
                        <label> :</label>
                        <div className='d-flex align-item-center' style={{ width: '90%' }}>
                          {
                            show_company_name_of_product ?
                              <input
                                type="text"
                                name='company_name_of_product'
                                id='company_name_of_product'
                                style={{ width: '100%' }}
                                className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                ref={company_name_of_product_input_ref}
                                value={company_name_of_product || ""} />
                              :
                              <div style={{ width: '100%', height: 20 }} onClick={() => {
                                if (state.stock_name !== '') {
                                  setShow_company_name_of_product(true);
                                  setTimeout(() => {
                                    company_name_of_product_input_ref.current.focus();
                                  }, 50);
                                } else {
                                  setShow_stock_name(true)
                                  setTimeout(() => {
                                    stock_name_input_ref.current.focus();
                                  }, 50);
                                }
                              }}>
                                <p className='m-0 p-2 pb-0 pt-0'> {state.company_name_of_product}</p>
                              </div>
                          }
                        </div>
                      </div>


                      <div className='d-flex justify-content-between align-item-center'>
                        <label className='lab_stock_item'>Units </label>
                        <label> :</label>
                        <div className='d-flex align-item-center' style={{ width: '90%' }}>
                          {
                            show_units ?
                              <input
                                type="text"
                                name='units'
                                style={{ width: '100%' }}
                                id='units'
                                className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                ref={units_input_ref}
                                value={units || ""} />
                              :
                              <div style={{ width: '100%', height: 20 }} onClick={() => {
                                if (state.stock_name !== '') {
                                  setShow_units(true);
                                  setTimeout(() => {
                                    units_input_ref.current.focus();
                                  }, 50);
                                } else {
                                  setShow_stock_name(true)
                                  setTimeout(() => {
                                    stock_name_input_ref.current.focus();
                                  }, 50);
                                }
                              }}>
                                <p className='m-0 p-2 pb-0 pt-0'> {state.units}</p>
                              </div>
                          }
                        </div>
                        <div id="myDropdown-list" className={`dropdown-content-list ${showListUnits ? 'show-list' : ''}`} ref={dropdownUnits}>
                          <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                            List Of Unit
                          </div>
                          <div className='col' style={{ textAlign: 'right' }}>
                            <Link to='/create-master-invmas-unit' onClick={() => navigate('/create-master-invmas-unit')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                            <div className='border'></div>
                          </div>
                          <div id='data'>
                            {filteredDataUnit.length > 0 ? (
                              filteredDataUnit.map((key, number) => {
                                const name = unit_data[key]?.symbol;
                                const index = name.toLowerCase().indexOf(units.toLowerCase());
                                return (
                                  <Link
                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                    id='list'
                                    key={number}
                                    // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedItem(number)
                                      if (number === selectedItem) {
                                        setState({ ...state, units: name, unit_id: unit_data[key].id })
                                        setShow_units(false)
                                        setShow_alternate_unit(true)
                                        setTimeout(() => {
                                          alternate_unit_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}
                                  >
                                    {index !== -1 ? (
                                      < >
                                        {name.substring(0, index)}
                                        <span style={{ color: 'red' }}>{name.substring(index, index + units.length)}</span>
                                        {name.substring(index + units.length)}
                                      </>
                                    ) : (
                                      name
                                    )}
                                  </Link>
                                );
                              })
                            ) : (
                              <p style={{ color: 'red' }}>No matching items found</p>
                            )}
                          </div>
                        </div>
                      </div>
                      {
                        units != '' && units != 'Not Applicable' ?
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab_stock_item'>Alternate Unit </label>
                            <label> :</label>
                            <div className='d-flex align-item-center' style={{ width: '90%' }}>
                              {
                                show_alternate_unit ?
                                  <input
                                    type="text"
                                    name='alternate_unit'
                                    style={{ width: '100%' }}
                                    id='alternate_unit'
                                    className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                    ref={alternate_unit_input_ref}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    // onFocus={alternateUnitFocus}
                                    value={alternate_unit || ""} />
                                  :
                                  <div style={{ width: '100%', height: 20 }} onClick={() => {
                                    if (state.stock_name !== '') {
                                      setShow_alternate_unit(true);
                                      setTimeout(() => {
                                        alternate_unit_input_ref.current.focus();
                                      }, 50);
                                    } else {
                                      setShow_stock_name(true)
                                      setTimeout(() => {
                                        stock_name_input_ref.current.focus();
                                      }, 50);
                                    }
                                  }}>
                                    <p className='m-0 p-2 pb-0 pt-0'> {state.alternate_unit}</p>
                                  </div>
                              }
                            </div>
                            <div id="myDropdown-list" className={`dropdown-content-list ${showListAlternateUnit ? 'show-list' : ''}`} ref={dropdownAlternateUnit}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of Units
                              </div>
                              <div className='col' style={{ textAlign: 'right' }}>
                                <Link to='/create-master-invmas-unit' onClick={() => navigate('/create-master-invmas-unit')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                <div className='border'></div>
                              </div>
                              <div id='data'>
                                <div className='col d-block'>
                                  {filteredDataAlternateUnit.length > 0 ? (
                                    filteredDataAlternateUnit.map((key, number) => {
                                      const name = unit_data[key]?.symbol;
                                      const index = name.toLowerCase().indexOf(alternate_unit.toLowerCase());
                                      return (
                                        <Link
                                          className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                          id='list'
                                          key={number}
                                          // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedItem(number)
                                            if (number === selectedItem) {
                                              setState({ ...state, alternate_unit: name, alternate_unit_id: unit_data[key].id })
                                              setShowLIstAlternateUnit(false)
                                              setShow_default_unit(true)
                                              setTimeout(() => {
                                                default_unit_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}
                                        >
                                          {index !== -1 ? (
                                            < >
                                              {name.substring(0, index)}
                                              <span style={{ color: 'red' }}>{name.substring(index, index + alternate_unit.length)}</span>
                                              {name.substring(index + alternate_unit.length)}
                                            </>
                                          ) : (
                                            name
                                          )}
                                        </Link>
                                      );
                                    })
                                  ) : (
                                    <p style={{ color: 'red' }}>No matching items found</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          : null}
                      {
                        alternate_unit == units ?
                          <div className='d-flex justify-content-between align-item-center'>
                            <strong style={{ color: 'red' }}>Can not use same Units in Basic and Alternate Unit</strong>
                          </div>
                          : whereInputField === true && units !== '' && alternate_unit != '' ?
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Where</label>
                              <label> :</label>
                              <div style={{ width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '49%' }}>
                                  <div className='d-flex align-item-center' style={{ width: '50%' }}>
                                    {
                                      show_first_unit_count ?
                                        <input
                                          type="number"
                                          name='first_unit_count'
                                          style={{ width: '100%', height: 20 }}
                                          id='first_unit_count'
                                          className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                          onKeyUp={handleChange}
                                          onChange={handleChange}
                                          onFocus={handleInputFocus}
                                          onBlur={handleInputBlur}
                                          ref={first_unit_count_input_ref}
                                          value={first_unit_count || ""} />
                                        :
                                        <div className='d-flex align-item-center justify-content-end' style={{ width: '50%', height: 20 }} onClick={() => {
                                          if (state.stock_name !== '') {
                                            setShow_first_unit_count(true);
                                            setTimeout(() => {
                                              first_unit_count_input_ref.current.focus();
                                            }, 50);
                                          } else {
                                            setShow_stock_name(true)
                                            setTimeout(() => {
                                              stock_name_input_ref.current.focus();
                                            }, 50);
                                          }
                                        }}>
                                          <p className='m-0 p-2 pb-0 pt-0'> {state.first_unit_count}</p>
                                        </div>
                                    }
                                  </div>
                                  <p className='text-right'>{state.alternate_unit}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', paddingRight: 20 }}>
                                  <h6 className='m-0 p-0'>=</h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '49%' }}>
                                  <div className='d-flex align-item-center' style={{ width: '50%' }}>
                                    {
                                      show_secound_unit_count ?
                                        <input
                                          type="number"
                                          name='secound_unit_count'
                                          style={{ width: '100%', height: 20 }}
                                          id='secound_unit_count'
                                          className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                          onKeyUp={handleChange}
                                          onChange={handleChange}
                                          onFocus={handleInputFocus}
                                          onBlur={handleInputBlur}
                                          ref={secound_unit_count_input_ref}
                                          value={secound_unit_count || ""} />
                                        :
                                        <div style={{ width: '45%', height: 20 }} onClick={() => {
                                          if (state.stock_name !== '') {
                                            setShow_secound_unit_count(true);
                                            setTimeout(() => {
                                              secound_unit_count_input_ref.current.focus();
                                            }, 50);
                                          } else {
                                            setShow_stock_name(true)
                                            setTimeout(() => {
                                              stock_name_input_ref.current.focus();
                                            }, 50);
                                          }
                                        }}>
                                          <p className='m-0 p-2 pb-0 pt-0'> {state.secound_unit_count}</p>
                                        </div>
                                    }
                                  </div>
                                  <p>{state.units != 'Not Applicable' ? state.units : null}</p>
                                </div>
                              </div>
                            </div>
                            : null
                      }
                      {
                        state.units !== '' && state.units !== 'Not Applicable' ?
                          <div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Default Unit</label>
                              <label> :</label>
                              <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                {
                                  show_default_unit ?
                                    <input
                                      type="text"
                                      name='default_unit'
                                      style={{ width: '100%' }}
                                      id='default_unit'
                                      // ref={inputDefaultUnitRef}
                                      className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleInputFocus}

                                      onBlur={handleInputBlur}
                                      // onFocus={defaultUnitFocus}
                                      ref={default_unit_input_ref}
                                      value={default_unit || ""} />
                                    :
                                    <div style={{ width: '100%', height: 20 }} onClick={() => {
                                      if (state.stock_name !== '') {
                                        setShow_default_unit(true);
                                        setTimeout(() => {
                                          default_unit_input_ref.current.focus();
                                        }, 50);
                                      } else {
                                        setShow_stock_name(true)
                                        setTimeout(() => {
                                          stock_name_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}>
                                      <p className='m-0 p-2 pb-0 pt-0'> {state.default_unit}</p>
                                    </div>
                                }
                              </div>
                              <div id="myDropdown-list" className={`dropdown-content-list ${showListDefaultUnit ? 'show-list' : ''}`} ref={dropdownDefaultUnit}>
                                <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                  List Of Units
                                </div>
                                <div className='col' style={{ textAlign: 'right' }}>
                                  <Link to='/create-master-invmas-unit' onClick={() => navigate('/create-master-invmas-unit')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                  <div className='border'></div>
                                </div>
                                <div id='data'>
                                  {filteredDataDefaultUnit.length > 0 ? (
                                    filteredDataDefaultUnit.map((key, number) => {
                                      const name = unit_data[key]?.symbol;
                                      const index = name.toLowerCase().indexOf(default_unit.toLowerCase());
                                      return (
                                        <Link
                                          className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                          id='list'
                                          key={number}
                                          // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedItem(number)
                                            if (number === selectedItem) {
                                              setState({ ...state, default_unit: name })
                                              setShowListDefaultUnit(false)
                                              setShow_default_unit_for_rate(true)
                                              setTimeout(() => {
                                                default_unit_for_rate_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}
                                        >
                                          {index !== -1 ? (
                                            < >
                                              {name.substring(0, index)}
                                              <span style={{ color: 'red' }}>{name.substring(index, index + default_unit.length)}</span>
                                              {name.substring(index + default_unit.length)}
                                            </>
                                          ) : (
                                            name
                                          )}
                                        </Link>
                                      );
                                    })
                                  ) : (
                                    <p style={{ color: 'red' }}>No matching items found</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Default Unit for Rate</label>
                              <label> :</label>
                              <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                {
                                  show_default_unit_for_rate ?

                                    <input
                                      type="text"
                                      name='default_unit_for_rate'
                                      style={{ width: '100%' }}
                                      id='default_unit_for_rate'
                                      className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleInputFocus}
                                      onBlur={handleInputBlur}
                                      ref={default_unit_for_rate_input_ref}
                                      value={default_unit_for_rate || ""} />
                                    :
                                    <div style={{ width: '100%', height: 20 }} onClick={() => {
                                      if (state.stock_name !== '') {
                                        setShow_default_unit_for_rate(true);
                                        setTimeout(() => {
                                          default_unit_for_rate_input_ref.current.focus();
                                        }, 50);
                                      } else {
                                        setShow_stock_name(true)
                                        setTimeout(() => {
                                          stock_name_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}>
                                      <p className='m-0 p-2 pb-0 pt-0'> {state.default_unit_for_rate}</p>
                                    </div>
                                }
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Weight(In Kg)</label>
                              <label> :</label>
                              <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                {
                                  show_weight_in_kg ?

                                    <input
                                      type="text"
                                      list='wik'
                                      style={{ width: '100%' }}
                                      name='weight_in_kg'
                                      id='weight_in_kg'
                                      className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleInputFocus}
                                      onBlur={handleInputBlur}
                                      ref={weight_in_kg_input_ref}
                                      value={weight_in_kg || ""} />
                                    :
                                    <div style={{ width: '100%', height: 20 }} onClick={() => {
                                      if (state.stock_name !== '') {
                                        setShow_weight_in_kg(true);
                                        setTimeout(() => {
                                          weight_in_kg_input_ref.current.focus();
                                        }, 50);
                                      } else {
                                        setShow_stock_name(true)
                                        setTimeout(() => {
                                          stock_name_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}>
                                      <p className='m-0 p-2 pb-0 pt-0'> {state.weight_in_kg}</p>
                                    </div>
                                }
                              </div>
                              <datalist id='wik' >
                                <option value="Available">Available</option>
                                <option value="Not Available">Not Available </option>
                              </datalist>
                            </div>
                            {
                              weight_in_kg == 'Available' ?
                                <div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab_stock_item'>Per {state.units != 'Not Applicable' ? state.units : null} </label>
                                    <label> :</label>
                                    <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                      {
                                        show_per_unit_kg ?

                                          <input
                                            type="text"
                                            name='per_unit_kg'
                                            style={{ width: '100%' }}
                                            id='per_unit_kg'
                                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            ref={per_unit_kg_input_ref}
                                            value={per_unit_kg || ""} />
                                          :
                                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                                            if (state.stock_name !== '') {
                                              setShow_per_unit_kg(true);
                                              setTimeout(() => {
                                                per_unit_kg_input_ref.current.focus();
                                              }, 50);
                                            } else {
                                              setShow_stock_name(true)
                                              setTimeout(() => {
                                                stock_name_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}>
                                            <p className='m-0 p-2 pb-0 pt-0'> {state.per_unit_kg}</p>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab_stock_item'>Per {state.alternate_unit != 'Not Applicable' ? state.alternate_unit : null}</label>
                                    <label> :</label>
                                    <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                      {
                                        show_per_alternate_unit_kg ?

                                          <input
                                            type="text"
                                            name='per_alternate_unit_kg'
                                            style={{ width: '100%' }}
                                            id='per_alternate_unit_kg'
                                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            ref={per_alternate_unit_kg_input_ref}
                                            value={per_alternate_unit_kg || ""} />
                                          :
                                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                                            if (state.stock_name !== '') {
                                              setShow_per_alternate_unit_kg(true);
                                              setTimeout(() => {
                                                per_alternate_unit_kg_input_ref.current.focus();
                                              }, 50);
                                            } else {
                                              setShow_stock_name(true)
                                              setTimeout(() => {
                                                stock_name_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}>
                                            <p className='m-0 p-2 pb-0 pt-0'> {state.per_alternate_unit_kg}</p>
                                          </div>
                                      }
                                    </div>
                                  </div>
                                </div> : null
                            }
                            < p className='m-0 p-0 text-info'>Additional Details</p>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Set Standard Rates</label>
                              <label> :</label>
                              <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                {
                                  show_set_standard_rates ?

                                    <input
                                      type="text"
                                      name='set_standard_rates'
                                      id='set_standard_rates'
                                      style={{ width: '100%' }}
                                      className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleInputFocus}
                                      onBlur={handleInputBlur}
                                      ref={set_standard_rates_input_ref}
                                      value={set_standard_rates || ""} />
                                    :
                                    <div style={{ width: '100%', height: 20 }} onClick={() => {
                                      if (state.stock_name !== '') {
                                        setShow_set_standard_rates(true);
                                        setTimeout(() => {
                                          set_standard_rates_input_ref.current.focus();
                                        }, 50);
                                      } else {
                                        setShow_stock_name(true)
                                        setTimeout(() => {
                                          stock_name_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}>
                                      <p className='m-0 p-2 pb-0 pt-0'> {state.set_standard_rates}</p>
                                    </div>
                                }
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Costing Method</label>
                              <label> :</label>
                              <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                {
                                  show_costing_method ?

                                    <input
                                      type="text"
                                      name='costing_method'
                                      id='costing_method'
                                      style={{ width: '100%' }}
                                      className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleInputFocus}
                                      onBlur={handleInputBlur}
                                      ref={costing_method_input_ref}
                                      value={costing_method || ""} />
                                    :
                                    <div style={{ width: '100%', height: 20 }} onClick={() => {
                                      if (state.stock_name !== '') {
                                        setShow_costing_method(true);
                                        setTimeout(() => {
                                          costing_method_input_ref.current.focus();
                                        }, 50);
                                      } else {
                                        setShow_stock_name(true)
                                        setTimeout(() => {
                                          stock_name_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}>
                                      <p className='m-0 p-2 pb-0 pt-0'> {state.costing_method}</p>
                                    </div>
                                }
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Market Valuation Method</label>
                              <label> :</label>
                              <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                {
                                  show_market_valuation_method ?

                                    <input
                                      type="text"
                                      name='market_valuation_method'
                                      id='market_valuation_method'
                                      style={{ width: '100%' }}
                                      className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleInputFocus}
                                      onBlur={handleInputBlur}
                                      ref={market_valuation_method_input_ref}
                                      value={market_valuation_method || ""} />
                                    :
                                    <div style={{ width: '100%', height: 20 }} onClick={() => {
                                      if (state.stock_name !== '') {
                                        setShow_market_valuation_method(true);
                                        setTimeout(() => {
                                          market_valuation_method_input_ref.current.focus();
                                        }, 50);
                                      } else {
                                        setShow_stock_name(true)
                                        setTimeout(() => {
                                          stock_name_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}>
                                      <p className='m-0 p-2 pb-0 pt-0'> {state.market_valuation_method}</p>
                                    </div>
                                }
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab_stock_item'>Provide Behaviour Options</label>
                              <label> :</label>
                              <div className='d-flex align-item-center' style={{ width: '90%' }}>
                                {
                                  show_provide_behaviour_options ?
                                    <input
                                      type="text"
                                      list='pbo'
                                      style={{ width: '100%' }}
                                      name='provide_behaviour_options'
                                      id='provide_behaviour_options'
                                      className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleInputFocus}
                                      onBlur={handleInputBlur}
                                      ref={provide_behaviour_options_input_ref}
                                      value={provide_behaviour_options || ""} />
                                    :
                                    <div style={{ width: '100%', height: 20 }} onClick={() => {
                                      if (state.stock_name !== '') {
                                        setShow_provide_behaviour_options(true);
                                        setTimeout(() => {
                                          provide_behaviour_options_input_ref.current.focus();
                                        }, 50);
                                      } else {
                                        setShow_stock_name(true)
                                        setTimeout(() => {
                                          stock_name_input_ref.current.focus();
                                        }, 50);
                                      }
                                    }}>
                                      <p className='m-0 p-2 pb-0 pt-0'> {state.provide_behaviour_options}</p>
                                    </div>
                                }
                              </div>
                              <datalist id='pbo'>
                                <option>Yes</option>
                                <option>No</option>
                              </datalist>
                            </div>
                            {
                              provide_behaviour_options == 'Yes' ?
                                <div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab_stock_item'>Ignore Diffrence Due to Physical Counting</label>
                                    <label> :</label>
                                    <div className='d-flex align-item-center' style={{ width: '50%' }}>
                                      {
                                        show_ignore_diffrence_due_to_physical_counting ?
                                          <input
                                            type="text"
                                            list='iddtpc'
                                            style={{ width: '100%' }}
                                            name='ignore_diffrence_due_to_physical_counting'
                                            id='ignore_diffrence_due_to_physical_counting'
                                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            ref={ignore_diffrence_due_to_physical_counting_input_ref}
                                            value={ignore_diffrence_due_to_physical_counting || ""} />
                                          :
                                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                                            if (state.stock_name !== '') {
                                              setShow_ignore_diffrence_due_to_physical_counting(true);
                                              setTimeout(() => {
                                                ignore_diffrence_due_to_physical_counting_input_ref.current.focus();
                                              }, 50);
                                            } else {
                                              setShow_stock_name(true)
                                              setTimeout(() => {
                                                stock_name_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}>
                                            <p className='m-0 p-2 pb-0 pt-0'> {state.ignore_diffrence_due_to_physical_counting}</p>
                                          </div>
                                      }
                                    </div>
                                    <datalist id='iddtpc'>
                                      <option>Yes</option>
                                      <option>No</option>
                                    </datalist>
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab_stock_item'>Ignore Negative Balance</label>
                                    <label> :</label>
                                    <div className='d-flex align-item-center' style={{ width: '50%' }}>
                                      {
                                        show_ignore_negative_balance ?
                                          <input
                                            type="text"
                                            list='inb'
                                            style={{ width: '100%' }}
                                            name='ignore_nagative_balance'
                                            id='ignore_nagative_balance'
                                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            ref={ignore_nagative_balance_input_ref}
                                            value={ignore_nagative_balance || ""} />
                                          :
                                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                                            if (state.stock_name !== '') {
                                              setShow_ignore_negative_balance(true);
                                              setTimeout(() => {
                                                ignore_nagative_balance_input_ref.current.focus();
                                              }, 50);
                                            } else {
                                              setShow_stock_name(true)
                                              setTimeout(() => {
                                                stock_name_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}>
                                            <p className='m-0 p-2 pb-0 pt-0'> {state.ignore_nagative_balance}</p>
                                          </div>
                                      }
                                    </div>
                                    <datalist id='inb'>
                                      <option>Yes</option>
                                      <option>No</option>
                                    </datalist>
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab_stock_item'>Treat all Sales as new Manufacture</label>
                                    <label> :</label>
                                    <div className='d-flex align-item-center' style={{ width: '50%' }}>
                                      {
                                        show_treat_all_sales_as_new_manufacture ?
                                          <input
                                            type="text"
                                            list='tasanm'
                                            style={{ width: '100%' }}
                                            name='treat_all_sales_as_new_manufacture'
                                            id='treat_all_sales_as_new_manufacture'
                                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            ref={treat_all_sales_as_new_manufacture_input_ref}
                                            value={treat_all_sales_as_new_manufacture || ""} />
                                          :
                                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                                            if (state.stock_name !== '') {
                                              setShow_treat_all_sales_as_new_manufacture(true);
                                              setTimeout(() => {
                                                treat_all_sales_as_new_manufacture_input_ref.current.focus();
                                              }, 50);
                                            } else {
                                              setShow_stock_name(true)
                                              setTimeout(() => {
                                                stock_name_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}>
                                            <p className='m-0 p-2 pb-0 pt-0'> {state.treat_all_sales_as_new_manufacture}</p>
                                          </div>
                                      }
                                    </div>

                                    <datalist id='tasanm'>
                                      <option>Yes</option>
                                      <option>No</option>
                                    </datalist>
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab_stock_item'>Treat all Purchased as Comsumed</label>
                                    <label> :</label>
                                    <div className='d-flex align-item-center' style={{ width: '50%' }}>
                                      {
                                        show_treat_all_purchased_as_consumed ?
                                          <input type="text"
                                            list='tapac'
                                            style={{ width: '100%' }}
                                            name='treat_all_purchased_as_comsumed'
                                            id='treat_all_purchased_as_comsumed'
                                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            ref={treat_all_purchased_as_comsumed_input_ref}
                                            value={treat_all_purchased_as_comsumed || ""} />
                                          :
                                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                                            if (state.stock_name !== '') {
                                              setShow_treat_all_purchased_as_consumed(true);
                                              setTimeout(() => {
                                                treat_all_purchased_as_comsumed_input_ref.current.focus();
                                              }, 50);
                                            } else {
                                              setShow_stock_name(true)
                                              setTimeout(() => {
                                                stock_name_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}>
                                            <p className='m-0 p-2 pb-0 pt-0'> {state.treat_all_purchased_as_comsumed}</p>
                                          </div>
                                      }
                                    </div>
                                    <datalist id='tapac'>
                                      <option>Yes</option>
                                      <option>No</option>
                                    </datalist>
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab_stock_item'>Treat all rejections inward as scrap</label>
                                    <label> :</label>
                                    <div className='d-flex align-item-center' style={{ width: '50%' }}>
                                      {
                                        show_treat_all_rejections_inward_as_scrap ?
                                          <input
                                            type="text"
                                            list='tarias'
                                            style={{ width: '100%' }}
                                            name='treat_all_rejections_inward_as_scrap'
                                            id='treat_all_rejections_inward_as_scrap'
                                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                            onBlur={handleInputBlur}
                                            ref={treat_all_rejections_inward_as_scrap_input_ref}
                                            value={treat_all_rejections_inward_as_scrap || ""} />
                                          :
                                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                                            if (state.stock_name !== '') {
                                              setShow_treat_all_rejections_inward_as_scrap(true);
                                              setTimeout(() => {
                                                treat_all_rejections_inward_as_scrap_input_ref.current.focus();
                                              }, 50);
                                            } else {
                                              setShow_stock_name(true)
                                              setTimeout(() => {
                                                stock_name_input_ref.current.focus();
                                              }, 50);
                                            }
                                          }}>
                                            <p className='m-0 p-2 pb-0 pt-0'> {state.treat_all_rejections_inward_as_scrap}</p>
                                          </div>
                                      }
                                    </div>
                                    <datalist id='tarias'>
                                      <option>Yes</option>
                                      <option>No</option>
                                    </datalist>
                                  </div>

                                </div> : null
                            }
                          </div>
                          : null
                      }
                    </div>
                  </div>
                </div>
                <div className='col-6'>
                  <p className='m-0 text-info'><u>Statutory Details</u></p>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab_stock_item'> Is GST applicable </label>
                    <label> :</label>
                    <div className='d-flex align-item-center' style={{ width: '90%' }}>
                      {
                        show_is_gst_applicable ?
                          <input
                            type="text"
                            list='gstapplcble'
                            style={{ width: '100%' }}
                            name='is_gst_applicable'
                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            ref={is_gst_applicable_input_ref}
                            value={is_gst_applicable || ""} />
                          :
                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                            if (state.stock_name !== '') {
                              setShow_is_gst_applicable(true);
                              setTimeout(() => {
                                is_gst_applicable_input_ref.current.focus();
                              }, 50);
                            } else {
                              setShow_stock_name(true)
                              setTimeout(() => {
                                stock_name_input_ref.current.focus();
                              }, 50);
                            }
                          }}>
                            <p className='m-0 p-2 pb-0 pt-0'> {state.is_gst_applicable}</p>
                          </div>
                      }
                    </div>
                    <datalist id='gstapplcble' >
                      <option value="Applicable">Applicable</option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Undefined">Undefined</option>
                    </datalist>
                  </div>
                  {
                    is_gst_applicable === 'Applicable' ?

                      <div>
                        <p className='m-0 text-info'><u>HSN/SAC & Related Details</u></p>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab_stock_item'> HSN/SAC Details </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_hsn_sac_details ?
                                <input
                                  type="text"
                                  list='hsd'
                                  style={{ width: '100%' }}
                                  name='hsn_sac_details'
                                  id='hsn_sac_details'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={hsn_sac_details_input_ref}
                                  value={hsn_sac_details || ""} />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_hsn_sac_details(true);
                                    setTimeout(() => {
                                      hsn_sac_details_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.hsn_sac_details}</p>
                                </div>
                            }
                          </div>
                          <datalist id='hsd' >
                            <option value="As per Company/Stock Group">As per Company/Group</option>
                            <option value="Specify Details Here">Specify Details Here</option>
                            <option value="Specify in Voucher">Specify in Voucher</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>HSN/SAC </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_hsn_sac ?
                                <input
                                  type="text"
                                  name='hsn_sac'
                                  style={{ width: '100%' }}
                                  id='hsn_sac'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  value={hsn_sac || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={hsn_sac_input_ref}
                                  onChange={handleChange} disabled={hsn_sac_details !== 'Specify Details Here'} />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_hsn_sac(true);
                                    setTimeout(() => {
                                      hsn_sac_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.hsn_sac}</p>
                                </div>
                            }
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Description </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_description_hsn_sac ?
                                <input
                                  type="text"
                                  name='description_hsn_sac'
                                  style={{ width: '100%' }}
                                  id='description_hsn_sac'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  value={description_hsn_sac || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={description_hsn_sac_input_ref}
                                  onChange={handleChange} disabled={hsn_sac_details !== 'Specify Details Here'} />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_description(true);
                                    setTimeout(() => {
                                      description_hsn_sac_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.description_hsn_sac}</p>
                                </div>
                            }
                          </div>
                        </div>
                      </div> : null}
                  {
                    is_gst_applicable === 'Applicable' ?

                      <div>
                        <p className='m-0 text-info'><u>GST Rate & Related Details</u></p>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab_stock_item'> GST Rate Details </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_gst_rate_details ?
                                <input
                                  type="text"
                                  list='hsd'
                                  style={{ width: '100%' }}
                                  name='gst_rate_details'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={gst_rate_details_input_ref}
                                  value={gst_rate_details || ""} />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_gst_rate_details(true);
                                    setTimeout(() => {
                                      gst_rate_details_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.gst_rate_details}</p>
                                </div>
                            }
                          </div>
                          <datalist id='hsd' >
                            <option value="As per Company/Group">As per Company/Group</option>
                            <option value="Specify Details Here">Specify Details Here</option>
                            <option value="Use GST classification">Use GST classification</option>
                            <option value="Specify Slab-Based Rates">Specify Slab-Based Rates</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Source of Details </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_source_of_details ?
                                <input
                                  type="text"
                                  name='source_of_details'
                                  style={{ width: '100%' }}
                                  id='source_of_details'
                                  className='input_stock_item custom_input'
                                  value={source_of_details || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={source_of_details_input_ref}
                                  onChange={handleChange} disabled />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_source_of_details(true);
                                    setTimeout(() => {
                                      source_of_details_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.source_of_details}</p>
                                </div>
                            }
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Taxability Type </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_taxability_type ?
                                <input
                                  type="text" name='taxability_type'
                                  id='taxability_type'
                                  style={{ width: '100%' }}
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  value={taxability_type || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={taxability_type_input_ref}
                                  onChange={handleChange} />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_taxability_type(true);
                                    setTimeout(() => {
                                      taxability_type_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.taxability_type}</p>
                                </div>
                            }
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>IGST Rate </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_igst_rate ?
                                <div className='d-flex align-item-center justify-content-start'>
                                  <input
                                    type="text"
                                    name='igst_rate'
                                    id='igst_rate'
                                    style={{ width: '20%' }}
                                    className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                    value={igst_rate || ""}
                                    onKeyUp={handleChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    ref={igst_rate_input_ref}
                                    onChange={handleChange}
                                    onKeyPress={(e) => {
                                      if (!/\d/.test(e.key)) {
                                        e.preventDefault();
                                      }
                                    }} />
                                  <p className='m-0'> %</p>
                                </div>
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_igst_rate(true);
                                    setTimeout(() => {
                                      igst_rate_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.igst_rate}% </p>
                                </div>
                            }

                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>CGST Rate </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_cgst_rate ?
                                <div className='d-flex align-item-center justify-content-start'>
                                  <input
                                    type="text"
                                    name='cgst_rate'
                                    style={{ width: '20%' }}
                                    id='cgst_rate'
                                    className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                    value={cgst_rate || igst_rate / 2}
                                    onKeyUp={handleChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    ref={cgst_rate_input_ref}
                                    onChange={handleChange} />
                                  <p className='m-0'> %</p>
                                </div>
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_cgst_rate(true);
                                    setTimeout(() => {
                                      cgst_rate_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.cgst_rate}%</p>
                                </div>
                            }
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>SGST/UTGST Rate </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_sgst_utst_rate ?
                                <div className='d-flex align-item-center justify-content-start'>
                                  <input
                                    type="text"
                                    name='sgst_utst_rate'
                                    id='sgst_utst_rate'
                                    style={{ width: '20%' }}
                                    className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                    value={sgst_utst_rate || igst_rate / 2}
                                    onKeyUp={handleChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    ref={sgst_utst_rate_input_ref}
                                    onChange={handleChange} />
                                  <p className='m-0'> %</p>
                                </div>
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_sgst_utst_rate(true);
                                    setTimeout(() => {
                                      sgst_utst_rate_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.sgst_utst_rate}%</p>
                                </div>
                            }
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Cess Valuation Type </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_cess_valuation_type ?
                                <input
                                  type="text"
                                  name='cess_valuation_type'
                                  style={{ width: '100%' }}
                                  id='cess_valuation_type'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  value={cess_valuation_type || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={cess_valuation_type_input_ref}
                                  onChange={handleChange} />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_cess_valuation_type(true);
                                    setTimeout(() => {
                                      cess_valuation_type_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.cess_valuation_type}</p>
                                </div>
                            }
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Cess Rate </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_cess_rate ?
                                <div className='d-flex align-item-center justify-content-start'>
                                  <input
                                    type="text"
                                    name='cess_rate'
                                    style={{ width: '20%' }}
                                    id='cess_rate'
                                    className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                    value={cess_rate || ""}
                                    onKeyUp={handleChange}
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                    ref={cess_rate_input_ref}
                                    onChange={handleChange} disabled />
                                  <p className='m-0'> %</p>
                                </div>
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_cess_rate(true);
                                    setTimeout(() => {
                                      cess_rate_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.cess_rate}%</p>
                                </div>
                            }
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Applicable For Reverse Charge </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_applicable_for_reverse_charge ?
                                <input
                                  type="text"
                                  list='afrc'
                                  style={{ width: '100%' }}
                                  name='applicable_for_reverse_charge'
                                  id='applicable_for_reverse_charge'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  value={applicable_for_reverse_charge || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={applicable_for_reverse_charge_input_ref}
                                  onChange={handleChange} disabled />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_applicable_for_reverse_charge(true);
                                    setTimeout(() => {
                                      applicable_for_reverse_charge_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.applicable_for_reverse_charge}</p>
                                </div>
                            }
                          </div>
                          <datalist id='afrc' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Eligible for tax input credit </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_eligible_for_tax_input_credit ?
                                <input
                                  type="text"
                                  list='eftic'
                                  style={{ width: '100%' }}
                                  name='eligible_for_tax_input_credit'
                                  id='eligible_for_tax_input_credit'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  value={eligible_for_tax_input_credit || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={eligible_for_tax_input_credit_input_ref}
                                  onChange={handleChange} disabled />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_eligible_for_tax_input_credit(true);
                                    setTimeout(() => {
                                      eligible_for_tax_input_credit_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.eligible_for_tax_input_credit}</p>
                                </div>
                            }
                          </div>
                          <datalist id='eftic' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab_stock_item'>Type of Supply </label>
                          <label> :</label>
                          <div className='d-flex align-item-center' style={{ width: '90%' }}>
                            {
                              show_type_of_supply ?
                                <input
                                  type="text"
                                  list='tos'
                                  style={{ width: '100%' }}
                                  name='type_of_supply'
                                  id='type_of_supply'
                                  className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                                  value={type_of_supply || ""}
                                  onKeyUp={handleChange}
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputBlur}
                                  ref={type_of_supply_input_ref}
                                  onChange={handleChange} />
                                :
                                <div style={{ width: '100%', height: 20 }} onClick={() => {
                                  if (state.stock_name !== '') {
                                    setShow_type_of_supply(true);
                                    setTimeout(() => {
                                      type_of_supply_input_ref.current.focus();
                                    }, 50);
                                  } else {
                                    setShow_stock_name(true)
                                    setTimeout(() => {
                                      stock_name_input_ref.current.focus();
                                    }, 50);
                                  }
                                }}>
                                  <p className='m-0 p-2 pb-0 pt-0'> {state.type_of_supply}</p>
                                </div>
                            }
                          </div>
                          <datalist id='tos' >
                            <option value="Capital Goods">Capital Goods</option>
                            <option value="Goods">Goods</option>
                            <option value="Services">Services</option>
                          </datalist>
                        </div>
                      </div>
                      : null
                  }
                </div>
              </div>
              <div className='row m-0 pt-1 border-top'>
                <div className='col-6'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab_stock_item'> opening balance (1-Apr-23) </label>
                    <label> :</label>
                    <div className='d-flex align-item-center' style={{ width: '90%' }}>
                      {
                        show_opening_balance ?
                          <input
                            type="number"
                            name='opening_balance'
                            style={{ width: '100%' }}
                            className={`input_stock_item ${isStockItemNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            ref={opening_balance_input_ref}
                            value={opening_balance || ""} />
                          :
                          <div style={{ width: '100%', height: 20 }} onClick={() => {
                            if (state.stock_name !== '') {
                              setShow_opening_balance(true);
                              setTimeout(() => {
                                opening_balance_input_ref.current.focus();
                              }, 50);
                            } else {
                              setShow_stock_name(true)
                              setTimeout(() => {
                                stock_name_input_ref.current.focus();
                              }, 50);
                            }
                          }}>
                            <p className='m-0 p-2 pb-0 pt-0'> {state.opening_balance}</p>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='tomasterfooter'><Footer /></div>
          </form>
        </div >
      </div >
      <div className='help'>
      </div>
      <Outlet />
    </div >
  )
}

export default StockItem
