import React, { useCallback, useEffect, useState } from 'react';
import Footer from '../../../components/home_page/Footer';
import './stocksummary.css';
import { useLocation,useNavigate,useNavigation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Stock_Summary_Help from '../../../components/help/stock_summary/Stock_Summary_Help';
import Home_Help from '../../../components/help/home/Home_Help';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis } from 'recharts';
import Period from '../../../components/help/stock_summary/stock_summary_component/Period';
import Configure from '../../../components/help/stock_summary/stock_summary_component/Configure';
import Company from '../../../components/help/stock_summary/stock_summary_component/Company';
import Stock_Group from '../../../components/help/stock_summary/stock_summary_component/Stock_Group';
import BasicValue from '../../../components/help/stock_summary/stock_summary_component/BasicValue';
import ChangeView from '../../../components/help/stock_summary/stock_summary_component/ChangeView';
import ExceptionReports from '../../../components/help/stock_summary/stock_summary_component/ExceptionReports';
import SaveView from '../../../components/help/stock_summary/stock_summary_component/SaveView';
import Valuation from '../../../components/help/stock_summary/stock_summary_component/Valuation';
import { stockSummaryList } from '../../../redux/actions/action-creator';
import { dateFormateSend, dateFormateShow } from '../../../components/date_formate/Index';
import GodownFilter from '../../../components/help/stock_summary/stock_summary_component/GodownFilter';
import { logDOM } from '@testing-library/react';
import Stock_Category from '../../../components/help/stock_summary/stock_summary_component/Stock_Category';
function StockSummary() {
  const str = '\u2666';
  const [removedItems, setRemovedItems] = useState([])
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { company_session } = useSelector(state => state.common);
  const [date, setDate] = useState({
    show_from_date: dateFormateShow(company_session?.start_financial_year),
    show_to_date: dateFormateShow(company_session?.current_date),
    send_from_date: company_session?.start_financial_year,
    send_to_date: company_session?.current_date,
    location_id: '',
    filter_name: 'Item-Wise',
    name_nav: `${str} Primary`,
  })
  const [state_configure, setState_configure] = useState(
    {
      show_quantity: 'No',
      show_alternate_unit: 'No',
      show_tail_unit_of_compound_unit: 'No',
      show_rate: 'No',
      show_value: 'No',
      show_opening_balance: 'No',
      show_goods_inwards: 'No',
      show_goods_outwards: 'No',
      show_gross_value_of_outwards: 'No',
      show_comsumption_and_gross_profit: 'No',
      show_gross_profits_on_gross_value: 'No',
      show_closing_balance: 'No',
      show_stock_item_with_zero_qty_or_blc: 'No',
      exclude_detail_with_no_transaction: 'No',
      type_of_grouping: '',
      formate_of_report: 'Condensed',
      expand_all_level_in_details_formate: 'No',
      display_name: 'Name Only',
      sorting_method: 'Default',
    })
  const [show_configure, setshow_configure] = useState(false)
  const [stockGroup, setStockGroup] = useState(false);
  const [stockCate, setStockCate] = useState(false)
  const [stockCompany, setStockCompany] = useState(false)
  const [godownWise, setGodownWise] = useState(false)
  const [basic, setBasic] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const [exceptionReports, setExceptionReports] = useState(false);
  const [saveView, setSaveView] = useState(false);
  const [valuation, setValuation] = useState(false);
  const [data, setData] = useState([])
  const [rawData, setRawData] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedItems, setSelectedItems] = useState([]);
  const { userData = null } = useSelector(state => state.auth);
  const { stock_summary_data } = useSelector(state => state.common);
  const data1 = useSelector(state => state);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('fromDate', dateFormateSend(date.send_from_date));
        formData.append('toDate', dateFormateSend(date.send_to_date));
        formData.append('company_id', userData.id);
        formData.append('location_id', date.location_id);
        const response = await dispatch(stockSummaryList(formData));
        if (response && response.status) {
          setRawData(response.data);
        } else {
          setRawData(response.data);
        }
      } catch (error) {
        setRawData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [date])
  const filterDataCoonfigure = useCallback(() => {
    const newData = rawData?.map(item => {
      const godownData = item?.godown_data;
      return state_configure?.formate_of_report === 'Condensed' ? [item] : [item, ...godownData]
    }).flat();
    setData(newData)
  }, [state_configure, rawData])

  useEffect(() => {
    filterDataCoonfigure();
  }, [filterDataCoonfigure]);

  const detailFormate = useCallback((e) => {
    if ((e.altKey && e.key === 'F1') || (e.altKey && e.key === 'F5')) {
      e.preventDefault();
      setState_configure(prevState => ({
        ...prevState,
        formate_of_report: prevState.formate_of_report === 'Condensed' ? 'Detailed' : 'Condensed'
      }));
    }
    if (e.key === 'F12') {
      e.preventDefault();
      setshow_configure(true)
    }
  }, [state_configure])
  useEffect(() => {
    window.addEventListener('keydown', detailFormate);
    return () => {
      window.removeEventListener('keydown', detailFormate);
    };

  }, [detailFormate])
  const handleKeyDown = useCallback((e) => {
    if (show || godownWise || stockGroup || basic || exceptionReports || saveView || valuation) {

    } else {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, data?.length - 1));
        }
      if (e.key === 'Enter') {
        e.preventDefault();
        const selectedList = data[selectedItem]
        if (selectedList) {
           navigate(`../stock_item_summary`)
        }
      } else if (e.key === ' ') { // Handling space key press for selecting items
        e.preventDefault();
        toggleSelection(selectedItem);
      }
    }
  }, [selectedItem, data, godownWise, show, stockGroup, basic, changeView, exceptionReports, saveView, valuation])
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown])
  // Function to toggle selection of an item
  const toggleSelection = (index) => {
    const selectedIndex = selectedItems.indexOf(index);
    let newSelected = [];
    if (selectedIndex === -1) {
      // Add the item to the selection if not already selected
      newSelected = newSelected.concat(selectedItems, index);
    } else if (selectedIndex === 0) {
      // Remove the item from the selection if already selected
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }
    setSelectedItems(newSelected);
  };

  // Function to remove selected items
  const removeSelectedItems = () => {
    const updatedData = data.filter((_, index) => !selectedItems?.includes(index));
    const removedItemsList = data.filter((_, index) => selectedItems?.includes(index));
    setData(updatedData);
    const removedItemsObjects = selectedItems.map((index) => ({
      index: index,
      items: removedItemsList[index]
    }));
    // Update the removedItems state with the new objects
    setRemovedItems([...removedItems, ...removedItemsObjects]);
    setSelectedItems([]);
  };
  // Function to restore selected items
  const restoreSelectedItems = () => {
    const lastRemovedItem = removedItems[removedItems.length - 1];
    const itemsArray = Array.isArray(lastRemovedItem.items) ? lastRemovedItem.items : [lastRemovedItem.items];
    const updatedRemovedItems = [...removedItems.slice(0, -1)];
    const updatedData = [
      ...data.slice(0, lastRemovedItem.index),
      ...itemsArray,
      ...data.slice(lastRemovedItem.index),
    ];
    setData(updatedData);
    setRemovedItems(updatedRemovedItems);
  };
  let total_opnening_quantity = 0;
  let total_inwards_quantity = 0;
  let total_outwards_quantity = 0;
  let total_closing_quantity = 0;
  let total_opening_value = 0;
  let total_inwards_value = 0;
  let total_outwards_value = 0;
  let total_closing_value = 0;
  let unit_name = ''
  const location = useLocation();
  return (
    <div className='section'>
      <div className='detail'>
        {
          show ?
            <Period
              setShow={setShow}
              setDate={setDate}
              date={date}
              setLoading={setLoading}
            /> : null
        }
        {
          stockGroup ?
            <Stock_Group
              date={date}
              setDate={setDate}
              stockGroup={stockGroup}
              setStockGroup={setStockGroup}
            /> : null
        }
        {
          godownWise ?
            <GodownFilter
              godownWise={godownWise}
              date={date}
              setGodownWise={setGodownWise}
              setDate={setDate}
            /> : null
        }
        {
          stockCate ?
            <Stock_Category
              stockCate={stockCate}
              date={date}
              setStockCate={setStockCate}
              setDate={setDate}
            /> : null
        }
        {
          stockCompany ?
            <Company
              stockCompany={stockCompany}
              date={date}
              setStockCompany={setStockCompany}
              setDate={setDate}
            /> : null
        }
        {
          show_configure ?
            <Configure
              setshow_configure={setshow_configure}
              show_configure={show_configure}
              setState_configure={setState_configure}
            /> : null
        }
        {
          basic ? <BasicValue setBasic={() => { return (setBasic) }} /> : null
        }
        {
          changeView ? <ChangeView setChangeView={() => { return (setChangeView) }} /> : null
        }
        {
          exceptionReports ? <ExceptionReports setExceptionReports={() => { return (setExceptionReports) }} /> : null
        }
        {
          saveView ? <SaveView setSaveView={() => { return (setSaveView) }} /> : null
        }
        {
          valuation ? <Valuation setValuation={() => { return (setValuation) }} /> : null
        }
        <div className='container-fluid' id='reletive' style={{ height: '90vh' }}>
          {/* <div className='row'>
            <div className='col-12 p-0'>
              <table style={{ width: '100%', height: '85.5vh' }}>
                <thead>
                  <tr style={{ width: '100%' }}>
                    <th rowSpan={3} style={{ width: '15%' }}>Particulars </th>
                    <th colSpan={10}>
                      {userData?.company_name}
                    </th>
                    <th colSpan={2}>
                      {`${date?.show_from_date} To ${date?.show_to_date} `}
                    </th>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <th colSpan={3} style={{ width: '25%' }}>Opening Balance</th>
                    <th colSpan={3} style={{ width: '25%' }}>Inwards</th>
                    <th colSpan={3} style={{ width: '25%' }}>Outwards</th>
                    <th colSpan={3} style={{ width: '25%' }}>Closing</th>
                  </tr>
                  <tr style={{ width: '100%' }}>
                    <th style={{ width: '8.3%' }}>Quantity</th>
                    <th style={{ width: '8.3%' }}>Rate</th>
                    <th style={{ width: '8.3%' }}>Value</th>
                    <th style={{ width: '8.3%' }}>Quantity</th>
                    <th style={{ width: '8.3%' }}>Rate</th>
                    <th style={{ width: '8.3%' }}>Value</th>
                    <th style={{ width: '8.3%' }}>Quantity</th>
                    <th style={{ width: '8.3%' }}>Rate</th>
                    <th style={{ width: '8.3%' }}>Value</th>
                    <th style={{ width: '8.3%' }}>Quantity</th>
                    <th style={{ width: '8.3%' }}>Rate</th>
                    <th style={{ width: '8.3%' }}>Value</th>
                  </tr>
                </thead>
                <tbody style={{ width: '100%' }}>
                  {
                    data?.map((item, index) => {
                      total_opening_value += parseFloat(item?.grandOpeningData?.value)
                      total_inwards_value += parseFloat(item?.grandInwardData?.value)
                      total_outwards_value += parseFloat(item?.grandOutwardData?.value)
                      total_closing_value += parseFloat(item?.grandClosingData?.value)
                      const parts = item?.grandOpeningData?.quantity.split(' ');
                      const parts_1 = item?.grandInwardData?.quantity.split(' ');
                      const parts_2 = item?.grandOutwardData?.quantity.split(' ');
                      const parts_3 = item?.grandClosingData?.quantity.split(' ');
                      unit_name = parts[1]
                      total_opnening_quantity += parseFloat(parts[0])
                      total_inwards_quantity += parseFloat(parts_1[0])
                      total_outwards_quantity += parseFloat(parts_2[0])
                      total_closing_quantity += parseFloat(parts_3[0])
                      return (
                        <tr key={index} className={`${index === selectedItem ? 'selected' : ''}`} style={{ height: 24, width: '100%', }} >
                          <td className='table_text_align_left table_text_padding table_td_border'>{item?.stock_item?.item_name}</td>
                          <td className='table_text_align_right table_text_padding'>{item?.grandOpeningData?.quantity}</td>
                          <td className='table_text_align_right table_text_padding '>{item?.grandOpeningData?.rate?.toFixed(userData?.no_of_decimal_place)}</td>
                          <td className='table_text_align_right table_text_padding '>{item?.grandOpeningData?.value?.toFixed(userData?.no_of_decimal_place)}</td>
                          <td className='table_text_align_right table_text_padding table_td_left_border'>{item?.grandInwardData?.quantity}</td>
                          <td className='table_text_align_right table_text_padding'>{item?.grandInwardData?.rate?.toFixed(userData?.no_of_decimal_place)}</td>
                          <td className='table_text_align_right table_text_padding'>{item?.grandInwardData?.value?.toFixed(userData?.no_of_decimal_place)}</td>
                          <td className='table_text_align_right table_text_padding table_td_left_border'>{item?.grandOutwardData?.quantity}</td>
                          <td className='table_text_align_right table_text_padding'>{item?.grandOutwardData?.rate?.toFixed(userData?.no_of_decimal_place)}</td>
                          <td className='table_text_align_right table_text_padding'>{item?.grandOutwardData?.value?.toFixed(userData?.no_of_decimal_place)}</td>
                          <td className='table_text_align_right table_text_padding table_td_left_border'>{item?.grandClosingData?.quantity}</td>
                          <td className='table_text_align_right table_text_padding'>{item?.grandClosingData?.rate?.toFixed(userData?.no_of_decimal_place)}</td>
                          <td className='table_text_align_right table_text_padding '>{item?.grandClosingData?.value?.toFixed(userData?.no_of_decimal_place)}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
                <tfoot className='border-top border-dark' style={{ width: '100%', background: 'red' }}>
                  <tr style={{ height: 24, width: '100%' }}>
                    <th>Grand Total</th>
                    <th>
                      {`${total_opnening_quantity} ${unit_name}`}
                    </th>
                    <th></th>
                    <th>{total_opening_value?.toFixed(userData?.no_of_decimal_place)}</th>
                    <th> {`${total_inwards_quantity} ${unit_name}`}</th>
                    <th></th>
                    <th>{total_inwards_value?.toFixed(userData?.no_of_decimal_place)}</th>
                    <th> {`${total_outwards_quantity} ${unit_name}`}</th>
                    <th></th>
                    <th>{total_outwards_value?.toFixed(userData?.no_of_decimal_place)}</th>
                    <th> {`${total_closing_quantity} ${unit_name}`}</th>
                    <th></th>
                    <th>{total_closing_value?.toFixed(userData?.no_of_decimal_place)}</th>
                  </tr>
                </tfoot>
              </table>
            </div>

          </div> */}
          <div className='row border border-dark table_date_header' >
            <div className='col-12  d-flex justify-content-between  table_header_font'>
              Stock Summary : {date?.filter_name} {date?.name_nav ? '(Godown: ' : null} {date?.name_nav}{date?.name_nav ? ')' : null}
              <span>  {`${date?.show_from_date} To ${date?.show_to_date} `}</span>
            </div>
          </div>
          <div className='row border-bottom border-dark' style={{ height: 40 }}>
            <div className='col-2 border-end border-start border-dark d-flex justify-content-start align-items-center table_header_font'>
              <b style={{ wordSpacing: '30px' }}> Particular </b>
            </div>
            <div className='col-10 m-0 p-0'>
              <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                <div className='col-3 d-flex flex-column  border-end border-dark m-0 p-0 table_header_font' style={{ width: '25%' }}>
                  <b>Opening Balance</b>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_header_font' style={{ width: '25%' }}>
                      Quantity
                    </div>
                    <div className='col-3 table_header_font p-0 m-0 text-end' style={{ width: '37.45%' }}>
                      Rate
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_header_font' style={{ width: '37.45%' }}>
                      Value
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0 table_header_font' style={{ width: '25%' }}>
                  <b>Inwards</b>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_header_font' style={{ width: '25%' }}>
                      Quantity
                    </div>
                    <div className='col-3 table_header_font text-end p-0 m-0' style={{ width: '37.45%' }}>
                      Rate
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_header_font' style={{ width: '37.45%' }}>
                      Value
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0 table_header_font' style={{ width: '25%' }}>
                  <b>Outwards</b>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_header_font' style={{ width: '25%' }}>
                      Quantity
                    </div>
                    <div className='col-3 m-0 p-0 text-end table_header_font' style={{ width: '37.45%' }}>
                      Rate
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_header_font' style={{ width: '37.45%' }}>
                      Value
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0 table_header_font' style={{ width: '25%' }}>
                  <b>Closing Balance</b>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_header_font' style={{ width: '25%' }}>
                      Quantity
                    </div>
                    <div className='col-3 p-0 m-0 text-end table_header_font' style={{ width: '37.45%' }}>
                      Rate
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_header_font' style={{ width: '37.45%' }}>
                      Value
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {data.map((item, index) => (
            <div key={index} className="row">
              <div className="col-2 border-end border-dark text-start m-0 p-0">
                {item?.stock_item?.item_name}
              </div>
              <div className="col-10 m-0 p-0" >
                <div className='row m-0 p-0'>
                  <div className='col-3 border-end border-dark'>
                    <div className='row'>
                      <div className='col text-start p-0 m-0'>
                        {item?.grandOpeningData?.quantity}
                      </div>
                      <div className='col p-0 m-0'>
                        {item?.grandOpeningData?.rate?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                      <div className='col text-end p-0 m-0'>
                        {item?.grandOpeningData?.value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                    </div>
                  </div>
                  <div className='col-3 border-end border-dark'>
                    <div className='row'>
                      <div className='col text-start p-0 m-0'>
                        {item?.grandInwardData?.quantity}
                      </div>
                      <div className='col p-0 m-0'>
                        {item?.grandInwardData?.rate?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                      <div className='col text-end p-0 m-0'>
                        {item?.grandInwardData?.value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                    </div>
                  </div>
                  <div className='col-3 border-end border-dark'>
                    <div className='row'>
                      <div className='col text-start p-0 m-0'>
                        {item?.grandOutwardData?.quantity}
                      </div>
                      <div className='col p-0 m-0'>
                        {item?.grandOutwardData?.rate?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                      <div className='col text-end p-0 m-0'>
                        {item?.grandOutwardData?.value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                    </div>
                  </div>
                  <div className='col-3 border-end border-dark'>
                    <div className='row'>
                      <div className='col text-start p-0 m-0'>
                        {item?.grandClosingData?.quantity}
                      </div>
                      <div className='col p-0 m-0'>
                        {item?.grandClosingData?.rate?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                      <div className='col text-end p-0 m-0'>
                        {item?.grandClosingData?.value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))} */}
          <div className={` row  border-bottom border-dark postion-relative`} style={{ height: '76.5vh' }} >
            <div className='col-2 border-end border-start border-dark text-start table_body_font' style={{ height: '76.5vh' }} >
            </div>
            <div className='col-10 m-0 p-0' style={{ height: '76.5vh' }}>
              <div className='row m-0 p-0 ' style={{ width: '100%', height: '76.5vh' }}>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%', overflow: 'hidden', wordSpacing: -4, whiteSpace: 'nowrap' }}>
                    </div>
                    <div className='col-3 m-0 p-0 text-end table_body_font' style={{ width: '37.45%', overflow: 'hidden', }}>
                    </div>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '37.45%', overflow: 'hidden', whiteSpace: 'nowrap', wordSpacing: -16 }}>
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%' }}>
                    </div>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '37.45%' }}>
                    </div>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '37.45%' }}>
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%' }}>
                    </div>
                    <div className='col-3 text-end m-0 p-0 table_body_font' style={{ width: '37.45%' }}>
                    </div>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '37.45%' }}>
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%' }}>
                    </div>
                    <div className='col-3 text-end m-0 p-0 table_body_font' style={{ width: '37.45%' }}>
                    </div>
                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '37.45%' }}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 p-0' style={{ position: 'absolute', width: '100%' }}>
              <div className='col-12'>
                {
                  loading ? <div>Loading...</div> :
                    data?.map((item, index) => {
                      // console.log('this is data :----', data)
                      total_opening_value += parseFloat(item?.stock_item ? item?.grandOpeningData?.value : 0)
                      total_inwards_value += parseFloat(item?.stock_item ? item?.grandInwardData?.value : 0)
                      total_outwards_value += parseFloat(item?.stock_item ? item?.grandOutwardData?.value : 0)
                      total_closing_value += parseFloat(item?.stock_item ? item?.grandClosingData?.value : 0)
                      const parts = item?.grandOpeningData?.quantity?.split(' ');
                      const parts_1 = item?.grandInwardData?.quantity?.split(' ');
                      const parts_2 = item?.grandOutwardData?.quantity?.split(' ');
                      const parts_3 = item?.grandClosingData?.quantity?.split(' ');
                      // unit_name = parts[1]
                      // unit_name = 'Nos'
                      const unit_name = parts && parts.length > 1 ? parts[1] : '';
                      total_opnening_quantity += parseFloat(item?.stock_item && parts ? parts[0] : 0)
                      total_inwards_quantity += parseFloat(item?.stock_item && parts_1 ? parts_1[0] : 0)
                      total_outwards_quantity += parseFloat(item?.stock_item && parts_2 ? parts_2[0] : 0)
                      total_closing_quantity += parseFloat(item?.stock_item && parts_3 ? parts_3[0] : 0)
                      return (
                        <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                          <div className={`row ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                            <div className={`col-2 border-end border-start border-dark text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                              {item?.stock_item?.item_name} {item?.location_name}
                            </div>
                            <div className='col-10 m-0 p-0'>
                              <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                      {item?.stock_item ? item?.grandOpeningData?.quantity : item?.grandOpeningData?.quantity}
                                    </div>
                                    <div className='col-3 m-0 p-0 text-end table_body_font' style={{ width: '37.45%', overflow: 'hidden', }}>
                                      {item?.stock_item ? parseFloat(item?.grandOpeningData?.rate)?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : parseFloat(item?.grandOpeningData?.rate)?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                    <div className='col-3 text-end p-0 m-0 pe-1 table_body_font' style={{ width: '37.45%', overflow: 'hidden', whiteSpace: 'nowrap', wordSpacing: -7 }}>
                                      {item?.stock_item ? item?.grandOpeningData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandOpeningData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                  </div>
                                </div>
                                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%' }}>
                                      {item?.stock_item ? item?.grandInwardData?.quantity : item?.grandInwardData?.quantity}
                                    </div>
                                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '37.45%' }}>
                                      {item?.stock_item ? item?.grandInwardData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandInwardData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                    <div className='col-3 text-end p-0 pe-1 m-0 table_body_font' style={{ width: '37.45%' }}>
                                      {item?.stock_item ? item?.grandInwardData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandInwardData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                  </div>
                                </div>
                                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%' }}>
                                      {item?.stock_item ? item?.grandOutwardData?.quantity : item?.grandOutwardData?.quantity}
                                    </div>
                                    <div className='col-3 text-end m-0 p-0 table_body_font' style={{ width: '37.45%' }}>
                                      {item?.stock_item ? item?.grandOutwardData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandOutwardData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                    <div className='col-3 text-end p-0 pe-1 m-0 table_body_font' style={{ width: '37.45%' }}>
                                      {item?.stock_item ? item?.grandOutwardData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandOutwardData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                  </div>
                                </div>
                                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                                    <div className='col-3 text-end p-0 m-0 table_body_font' style={{ width: '25%' }}>
                                      {item?.stock_item ? item?.grandClosingData?.quantity : item?.grandClosingData?.quantity}
                                    </div>
                                    <div className='col-3 text-end m-0 p-0 table_body_font' style={{ width: '37.45%' }}>
                                      {item?.stock_item ? item?.grandClosingData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandClosingData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                    <div className='col-3 text-end p-0 pe-1 m-0 table_body_font' style={{ width: '37.45%' }}>
                                      {item?.stock_item ? item?.grandClosingData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandClosingData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                }

              </div>
            </div>
          </div>


          <div className={`row border ${data?.length > 0 ? 'border-top-0' : ''}  border-end-0 border-dark`}>
            <div className='col-2 border-end border-dark text-start table_footer_font'>
              Grand Total
            </div>
            <div className='col-10 m-0 p-0'>
              <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3 text-end p-0 m-0 table_footer_font' style={{ width: '25%', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                      {`${total_opnening_quantity} ${unit_name}`}
                    </div>
                    <div className='col-3 text-end m-0 p-0 table_footer_font' style={{ width: '37.45%', overflow: 'hidden', }}>
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%', overflow: 'hidden', whiteSpace: 'nowrap', wordSpacing: -7 }}>
                      {total_opening_value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3  text-end p-0 m-0 table_footer_font' style={{ width: '25%' }}>
                      {`${total_inwards_quantity} ${unit_name}`}
                    </div>
                    <div className='col-3  text-end table_footer_font p-0 m-0' style={{ width: '37.45%' }}>
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%' }}>
                      {total_inwards_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3  text-end p-0 m-0 table_footer_font' style={{ width: '25%' }}>
                      {`${total_outwards_quantity} ${unit_name}`}
                    </div>
                    <div className='col-3  text-end m-0 p-0 table_footer_font' style={{ width: '37.45%' }}>
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%' }}>
                      {total_outwards_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                    </div>
                  </div>
                </div>
                <div className='col-3 border-end border-dark m-0 p-0' style={{ width: '25%' }}>
                  <div className='row m-0 p-0' style={{ width: '100%' }}>
                    <div className='col-3  text-end p-0 m-0 table_footer_font' style={{ width: '25%' }}>
                      {`${total_closing_quantity} ${unit_name}`}
                    </div>
                    <div className='col-3 text-end m-0 p-0 table_footer_font' style={{ width: '37.45%' }}>
                    </div>
                    <div className='col-3 text-end p-0 pe-1 m-0 table_footer_font' style={{ width: '37.45%' }}>
                      {total_closing_value?.toFixed(userData?.no_of_decimal_place).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          {/* <div className='row' id='stock-summary-chart'>
            <div className='col'>
              <ResponsiveContainer width="100%" aspect={6}>
                <BarChart data={array} width="100%" height={100}>
                  <XAxis dataKey="name" />
                  <YAxis dataKey='student' />
                  <Bar />

                </BarChart>
              </ResponsiveContainer>
            </div>
          </div> */}




        </div>
        <div className='tomasterfooter'>
          <div className='footer'>
            <div className='boxfooter'>
              <button className='button'>
                Q:Quite
              </button>
            </div>
            <div className='boxfooter'>
              <button className='button'>
                {/* <strong>Enter:Alter</strong> */}
              </button>
            </div>
            <div className='boxfooter'>
              <button className='button'>
                {/* <strong>Space:Select</strong> */}
              </button>
            </div>
            <div className='boxfooter'>
              <button type='submit' className='button'>
                {/* <strong> <u>A</u>:Add Vch</strong> */}
              </button>
            </div>
            <div className='boxfooter'>
              <button className='button'>
                {/* <strong><u>2</u>: Duplicate Vch</strong> */}
              </button>
            </div>
            <div className='boxfooter'>
              <button className='button'>
                {/* <strong><u>I</u>: Insert Vch</strong> */}
              </button>
            </div>
            <div className='boxfooter'>
              <button className='button'>
                {/* <strong> <u>D</u>:Delete</strong> */}
              </button>
            </div>
            <div className='boxfooter'>
              <button className='button'>
                {/* <strong> <u>X</u>:Cancel</strong> */}
              </button>
            </div>
            <div className='boxfooter'>
              <button className='button' onClick={removeSelectedItems}>
                <u><u>R</u></u>: Remove Line
              </button>
            </div>
            <div className='boxfooter' onClick={restoreSelectedItems}>
              <button disabled={removedItems.length <= 0} className='button'>
                <u><u>U</u></u> : Restore Line
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='help' style={{ position: 'relative' }}>
        {godownWise || stockGroup || stockCate||stockCompany ? null :
          location.pathname == '/stocksummary' ? <Stock_Summary_Help
            setShow={setShow}
            show={show}
            setGodownWise={setGodownWise}
            setshow_configure={()=>{return(setshow_configure)}}
            setStockCate={setStockCate}
            setStockCompany={setStockCompany}

            setStockGroup={setStockGroup}
            setBasic={setBasic}
            basic={basic}
            setChangeView={setChangeView}
            changeView={changeView}
            setExceptionReports={setExceptionReports}
            exceptionReports={exceptionReports}
            setSaveView={setSaveView}
            saveView={saveView}
            setValuation={setValuation}
            valuation={valuation}
          /> : <Home_Help />

        }
      </div>
    </div>
  )
}

export default StockSummary
