import React, { useCallback, useEffect, useState } from 'react';
import Footer from '../../components/home_page/Footer'
import '../gatewayoftally/master/create/accounting_masters/ledger.css'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { ToastContainer, toast } from 'react-toastify';
import { Trash } from 'react-bootstrap-icons';
import Modal from 'react-modal';
const customStyles = {
  content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
  },
};

function BranchList() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedLedgerId, setSelectedLedgerId] = useState({
    ledger_id: '',
    table_name: ''
  });
  const [deleteResponse, setDeleteResponse] = useState(null)





  // Memoized fetchData function--------------------------------------------------
  const fetchData = useCallback(async () => {
    try {
      const formData = new FormData();
    } catch (err) {
      console.log(err);
      throw err; // Rethrow the error to be caught in the parent useEffect
    }
  }, [dispatch]);

  useEffect(() => {
    setLoading(true); // Set loading state when data is being fetched
    setError(null); // Clear any previous errors
    let isMounted = true;
    fetchData().then(() => { if (isMounted) { setLoading(false); } }).catch((err) => {
      if (isMounted) {
        setLoading(false);
        setError('Error occurred during API calls');
        console.log("this is errorr----------", err);
        toast.error(err?.message || 'An error occurred while fetching data!', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
    return () => { isMounted = false; };
  }, [fetchData, deleteResponse]);
  if (loading) {
    return (
      <div className='container'>
        <div className='row justify-content-center align-items-center' id='row'>
          <div className="spinner-border text-success" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      </div>
    );
  }
  if (error) { return <div>Error: {error}</div>; }

  const handleDeleteFun = (ledger_id) => {
    setSelectedLedgerId({
      ledger_id: ledger_id,
      table_name: 'common_ledger_table'
    });
    setIsDeleteModalOpen(true);
  }
  const handleConfirmDelete = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('id', selectedLedgerId?.ledger_id);
    formData.append('table', selectedLedgerId?.table_name); // Update with the appropriate table name
    // dispatch(ledgerDelete(formData))
    //   .then((response) => {
    //     if (response.status) {
    //       // Handle success and close modal
    //       setIsDeleteModalOpen(false);
    //       setDeleteResponse(response)
    //       // ... other logic
    //       toast.success(response?.message || 'Ledger delete successfully!', {
    //         position: toast.POSITION.TOP_CENTER
    //       });
    //       setSelectedLedgerId({
    //         ledger_id: '',
    //         table_name: ''
    //       });
    //       setLoading(false);
    //     } else {
    //       // Handle failure
    //       // ... other logic
    //       toast.warn(response?.message || 'Fail !', {
    //         position: toast.POSITION.TOP_CENTER
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     // Handle error and close modal
    //     setIsDeleteModalOpen(false);
    //     // ... other logic
    //     toast.error(error?.responseMessage || ' Failed!', {
    //       position: toast.POSITION.TOP_CENTER
    //     });
    //   });
  };
  return (
    <div className='section' >
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete"
        style={customStyles}
      >
        <h2 className='modal-title'>Confirm To Delete</h2>
        <p className='modal-body'>Are you sure you want to delete this ledger entry?</p>
        <div className='border'></div>
        <div className='modal-footer'>
          <button className='btn btn-primary m-2' onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
          <button className='btn btn-danger  m-2' onClick={handleConfirmDelete}>Delete</button>
        </div>
      </Modal>
      <div className='detail border'>
        <form>
          <div className='container-fluid p-0'>
            <div className='row m-0' id='ledger-chart-of-head'>
              <div className='col'><p className='font-weight-bold p-2' style={{ textAlign: 'left' }}>List Of Branch</p></div>
            </div>
            <div className='row' id='ledger-form-listing'>


            </div>
            <div className='row m-0 border-top' id='ledger-chart-of-footer'>
              <div className='col'>
                <p className='font-weight-bold p-2' style={{ textAlign: 'left' }}> Branch(s)</p>
              </div>
            </div>
          </div>
          <div className='tomasterfooter'><Footer /></div>
        </form>
      </div>
      <div className='help'></div>
    </div >
  )
}

export default BranchList