import React, { useEffect, useState, useRef } from 'react';
import Country1 from '../../../../K_Company/Country.json';
import State from '../../../../K_Company/State.json';
import Footer from '../../../../../components/home_page/Footer';
import { useSelector, useDispatch } from 'react-redux';
import './ledger.css';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { groupListCreate, ledgerCreate } from '../../../../../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
const initialState = {
  ledger_name: '',
  alise_name: '',
  under: 'Capital Account',
  group_id: '',
  effective_date_for_reconciliation: '',
  set_od_limit: '',
  maintain_balance_bill_by_bill: 'No',
  default_credit_period: '',
  specify_credit_limit: '',
  inventry_values_are_affected: 'No',
  account_holder_name: '',
  account_number: '',
  ifsc_code: '',
  swift_code: '',
  bank_name: 'Not Applicable',
  branch: '',
  set_alter_range_for_cheque_book: 'No',
  enable_cheque_printing: 'No',
  set_alter_cheque_printing_configuration: 'No',
  use_as_national_bank_for_post_dated_vouchers: 'No',
  behave_as_duties_and_taxes_ledger: '',
  type_of_duty_tax: '',
  tax_type: '',
  valuation_type: '',
  rate_per_unit: '',
  percentage_of_calculation: '',
  rounding_method: '',
  rounding_limit: '',
  is_gst_applicable: 'Not Applicable',
  set_alter_gst_details: '',
  include_in_assessable_value_calculation_for: 'Not Applicable',
  type_of_ledger: 'Not Applicable',
  hsn_sac_details: '',
  source_of_details: '',
  hsn_sac: '',
  description: '',
  taxability_type: '',
  nature_of_transaction: '',
  igst_rate: '',
  cgst_rate: '',
  sgst_utst_rate: '',
  cess_valuation_type: '',
  cess_rate: '',
  applicable_for_reverse_charge: '',
  type_of_supply: '',
  check_for_credit_days_during_voucher_entry: '',
  method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice: 'Not Applicable',
  mailing_name: '',
  address1: '',
  state_name: '',
  country_name: 'India',
  pincode: '',
  mobile: '',
  provide_contact_details: 'No',
  provide_bank_details: 'No',
  pan_ItNo: '',
  registration_type: '',
  assessee_of_other_territory: '',
  gstin_uin: '',
  use_ledger_as_common_party: '',
  set_alter_additional_gst_details: '',
  ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: '',
  opening_balance: ''
}

const Ledger = () => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const inputMaintainRef = useRef(null);
  const inputTypeOfLedgerRef = useRef(null);
  const inputStateRef = useRef(null);
  const inputCountryRef = useRef(null);
  const inputRegistrationTypeRef = useRef(null);
  const inputCheckForCreditRef = useRef(null);
  const inputInventoryRef = useRef(null);
  const dropdownMaintainRef = useRef(null);
  const dropdownTypeOfLedgerRef = useRef(null);
  const dropdownStateRef = useRef(null);
  const dropdownCountryRef = useRef(null);
  const dropdownRegistrationTypeRef = useRef(null);
  const dropdownCheckForCreditRef = useRef(null);
  const dropdownInventoryRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const [showListMaintain, setShowListMaintain] = useState(false);
  const [showListTypeOfLedger, setShowListTypeOfLedger] = useState(false);
  const [showListState, setShowListState] = useState(false);
  const [showListCountry, setShowListCountry] = useState(false);
  const [showListRegistrationType, setShowListRegistrationType] = useState(false);
  const [showListCheckForCredit, setShowListCheckForCredit] = useState(false);
  const [showListInventory, setShowListInventory] = useState(false);
  const [isLedgerNameEnterd, setIsLedgerNameEntered] = useState(false);
  const [error, setError] = useState(false);
  const [state, setState] = useState(initialState);
  const [bankAccountRendering, setBankAccountRendering] = useState(false);
  const [bankOccAcRendering, setBankOccAcRendering] = useState(false);
  const [bankOdAcRendering, setBankOdAcRendering] = useState(false);
  const [branchDivisionRendering, setBranchDivisionRendering] = useState(false);
  const [capitalAccountRendering, setCapitalAccountRendering] = useState(false);
  const [cashInHandRendering, setCashInHandRendering] = useState(false);
  const [currentAssetsRendering, setCurrentAssetsRendering] = useState(false);
  const [currentLiabilitiesRendering, setCurrentLiabilitiesRendering] = useState(false);
  const [depositsAssetsRendering, setDepositsAssetsRendering] = useState(false);
  const [directExpensesRendering, setDirectExpensesRendering] = useState(false);
  const [directIncomesRendering, setDirectIncomesRendering] = useState(false);
  const [dutiesAndTaxesRendering, setDutiesAndTaxesRendering] = useState(false);
  const [fixedAssetsRendering, setFixedAssetsRendering] = useState(false);
  const [indirectExpensesRendering, setIndirectExpensesRendering] = useState(false);
  const [indirectIncomesRendering, setIndirectIncomesrendering] = useState(false);
  const [investmentsRendering, setInvestmentsRendering] = useState(false);
  const [loanAndAdvanceAssetRendering, setLoanAndAdvanceAssetRendering] = useState(false);
  const [loanLiabilityRendering, setLoanLiabilityRendering] = useState(false)
  const [miscExpensesRendering, setMiscExpensesRendering] = useState(false);
  const [provisionRendering, setProvisionRendering] = useState(false);
  const [purchaseAccountsRendering, setPurchaseAccountsRendering] = useState(false);
  const [reservesAndSurplusRendering, setReservesAndSurplusRendering] = useState(false);
  const [salesAccountsRendering, setSalesAccountsRendering] = useState(false);
  const [securedLoansRendering, setSecuredLoansRendering] = useState(false);
  const [stockInHandRendering, setStockInHandRendering] = useState(false);
  const [sundryCreditorsRendering, setSundryCreditorsRendering] = useState(false);
  const [sundryDebtorsRendering, setSundryDebtorsRendering] = useState(false);
  const [suspenseAcRendering, setSuspenseAcRendering] = useState(false);
  const [unsecuredLoansRendering, setUnsecuredLoansRendering] = useState(false);
  // get data of company -----------------------------------
  const { userData = null } = useSelector(state => state.auth);
  // get data of group list ---------------------------------
  const data = useSelector(state => state.common.data);
  const { group_all_list = [] } = useSelector(state => state.common);
  const [errors, setErrors] = useState({
    pincode: '',
    mobile: '',
    pan_no: ''
  });
  const [selectedItem, setSelectedItem] = useState(0);
  const aliseInputRef = useRef();
  useEffect(() => {
    if (state.under == 'Bank account') {
      setBankAccountRendering(true); setBankOccAcRendering(false); setBankOdAcRendering(false); setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false); setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false); setFixedAssetsRendering(false); setIndirectExpensesRendering(false); setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false); setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false); setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false); setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Bank OCC A/C') {
      setBankAccountRendering(false); setBankOccAcRendering(true); setBankOdAcRendering(false); setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false); setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false); setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false); setIndirectExpensesRendering(false); setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false); setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false); setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false); setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Bank OD A/C') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(true);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Branch/Divisions') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(true); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Capital Account') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(true); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Cash-in-Hand') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(true);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Current Assets') {

      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(true); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Current Liabilities') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(true); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Deposits(Asset)') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(true);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Direct Expenses') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(true); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Direct Incomes') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(true); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Duties & Taxes') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(true);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Expenses(Direct)') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Expenses(Indirect)') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Fixed Assets') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(true);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Income(Direct)') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Income(indirect)') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Indirect Expenses') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(true);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Indirect Incomes') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(true); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Investments') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(true); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Loan & Advance (Asset)') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(true);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Misc. Expenses') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(true); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Provisions') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(true); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Purchase Accounts') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(true);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Reserves & Surplus') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(true);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Retained Earnings') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false); setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Sales Accounts') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(true);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Secured Loans') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(true); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Stock-in-Hand') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(true); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Sundry Creditors') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(true);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Sundry Debtors') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(true); setSuspenseAcRendering(false); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Suspense A/C') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false);; setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(true); setUnsecuredLoansRendering(false);
    } else if (state.under == 'Unsecured Loans') {
      setBankAccountRendering(false); setBankOccAcRendering(false); setBankOdAcRendering(false);
      setBranchDivisionRendering(false); setCapitalAccountRendering(false); setCashInHandRendering(false);
      setCurrentAssetsRendering(false); setCurrentLiabilitiesRendering(false); setDepositsAssetsRendering(false);
      setDirectExpensesRendering(false); setDirectIncomesRendering(false); setDutiesAndTaxesRendering(false);
      setFixedAssetsRendering(false);
      setIndirectExpensesRendering(false);
      setIndirectIncomesrendering(false); setInvestmentsRendering(false); setLoanAndAdvanceAssetRendering(false);
      setMiscExpensesRendering(false); setProvisionRendering(false); setPurchaseAccountsRendering(false);
      setReservesAndSurplusRendering(false); setSalesAccountsRendering(false);
      setSecuredLoansRendering(false); setStockInHandRendering(false); setSundryCreditorsRendering(false);
      setSundryDebtorsRendering(false); setSuspenseAcRendering(false); setUnsecuredLoansRendering(true);
    }
  }, [state.under])

  useEffect(() => {
    const formdata = new FormData();
    formdata.append('company_id', userData?.id)
    dispatch(groupListCreate(formdata))
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const handleClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
      setShowList(false);
    }
    if (dropdownMaintainRef.current && !dropdownMaintainRef.current.contains(event.target) && !inputMaintainRef.current.contains(event.target)) {
      setShowListMaintain(false);
    }
    if (dropdownTypeOfLedgerRef.current && !dropdownTypeOfLedgerRef.current.contains(event.target) && !inputTypeOfLedgerRef.current.contains(event.target)) {
      setShowListTypeOfLedger(false);
    }
    if (dropdownStateRef.current && !dropdownStateRef.current.contains(event.target) && !inputStateRef.current.contains(event.target)) {
      setShowListState(false);
    }
    if (dropdownCountryRef.current && !dropdownCountryRef.current.contains(event.target) && !inputCountryRef.current.contains(event.target)) {
      setShowListCountry(false);
    }
    if (dropdownRegistrationTypeRef.current && !dropdownRegistrationTypeRef.current.contains(event.target) && !inputRegistrationTypeRef.current.contains(event.target)) {
      setShowListRegistrationType(false);
    }
    if (dropdownCheckForCreditRef.current && !dropdownCheckForCreditRef.current.contains(event.target) && !inputCheckForCreditRef.current.contains(event.target)) {
      setShowListCheckForCredit(false);
    }
    if (dropdownInventoryRef.current && !dropdownInventoryRef.current.contains(event.target) && !inputInventoryRef.current.contains(event.target)) {
      setShowListInventory(false);
    }
  };
  const handleFocus = () => {
    if (!showList) {
      setShowList(true);
    }
  };
  const maintainBalanceBillFocus = () => {
    if (!showListMaintain) {
      setShowListMaintain(true);
    }
  };
  const typeOfLedgerFocus = () => {
    if (!showListTypeOfLedger) {
      setShowListTypeOfLedger(true);
    }
  };
  const stateFocus = () => {
    if (!showListState) {
      setShowListState(true);
    }
  };
  const countryFocus = () => {
    if (!showListCountry) {
      setShowListCountry(true);
    }
  };
  const registrationTypeFocus = () => {
    if (!showListRegistrationType) {
      setShowListRegistrationType(true);
    }
  };
  const checkForCreditFocus = () => {
    if (!showListCheckForCredit) {
      setShowListCheckForCredit(true);
    }
  };
  const inventoryFocus = () => {
    if (!showListInventory) {
      setShowListInventory(true);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    if (name === 'ledger_name') {
      setIsLedgerNameEntered(!!value.trim());
      setState({
        ...state, ledger_name: value.toLowerCase()
          .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase())
      });
    }
    if (name === 'under') {
      const filteredSuggestions = group_all_list.filter(key =>
        key?.name.toLowerCase().includes(state.under.toLowerCase())
      );
      setFilteredData(filteredSuggestions);
    }
  }
  // let updatedErrors = { ...errors };
  // if(state.pincode === 'pincode') {
  //   updatedErrors.pincode = !validatePincode(value) ? 'Invalid pincode' : '';
  // } else if (name === 'mobile') {
  //   updatedErrors.mobile = !validateMobile(value) ? 'Invalid mobile number' : '';
  // }
  //  else if (name === 'pan_ItNo') {
  //   updatedErrors.pan_no = !validatePan(value) ? 'Invalid pan number' : '';
  // }
  // setErrors(updatedErrors);
  const validatePincode = (pincode) => {
    return /^\d{6}$/.test(pincode);
  };

  const validateMobile = (mobile) => {
    return /^[0-9]{10}$/.test(mobile);
  };
  const validatePan = (pan_ItNo) => {
    return /^[A-Z]{5}\d{4}[A-Z]$/.test(pan_ItNo);
  };

  const displayTypeOfLedgerFun = (value) => {
    setState({ ...state, type_of_ledger: value })
    document.getElementById("myDropdown-list-type").classList.remove("show-list");
    inputTypeOfLedgerRef.current.blur();
  }
  const displayMaintainYesNo = (value) => {
    setState({ ...state, maintain_balance_bill_by_bill: value })
    document.getElementById("myDropdown-list-maintain").classList.remove("show-list");
    inputMaintainRef.current.blur();
  }
  const displayRegistrationType = (value) => {
    setState({ ...state, registration_type: value })
    document.getElementById("myDropdown-list-registrationType").classList.remove("show-list");
    inputRegistrationTypeRef.current.blur();
  }
  const displayCheckForCrditDay = (value) => {
    setState({ ...state, check_for_credit_days_during_voucher_entry: value })
    document.getElementById("myDropdown-list-checkForCredit").classList.remove("show-list");
    inputCheckForCreditRef.current.blur();
  }
  const displayInventory = (value) => {
    setState({ ...state, inventry_values_are_affected: value })
    document.getElementById("myDropdown-list-inventory").classList.remove("show-list");
    inputInventoryRef.current.blur();
  }
  // for get value in method appropriation  input field of method of ledger list -----------
  const displayMethodListFun = (value) => {
    setState({ ...state, method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice: value })
    document.getElementById("myDropdown-list-method").classList.remove("show-list");
  }
  // for get value in state input field of state list -----------
  const displayStateFun = (value) => {
    setState({ ...state, state_name: value })
    document.getElementById("myDropdown-list-state").classList.remove("show-list");
  }
  // for get value in country input field of country list -----------

  const displayCountryFun = (value) => {
    setState({ ...state, country_name: value })
    document.getElementById("myDropdown-list-country").classList.remove("show-list");
  }

  const {
    ledger_name, alise_name, under,
    group_id, effective_date_for_reconciliation, set_od_limit,
    maintain_balance_bill_by_bill, default_credit_period, specify_credit_limit,
    inventry_values_are_affected, account_holder_name, account_number,
    ifsc_code,
    swift_code,
    bank_name,
    branch,
    set_alter_range_for_cheque_book,
    enable_cheque_printing,
    set_alter_cheque_printing_configuration,
    use_as_national_bank_for_post_dated_vouchers,
    behave_as_duties_and_taxes_ledger,
    type_of_duty_tax,
    tax_type,
    valuation_type,
    rate_per_unit,
    percentage_of_calculation,
    rounding_method,
    rounding_limit,
    is_gst_applicable,
    set_alter_gst_details,
    include_in_assessable_value_calculation_for,
    type_of_ledger,
    hsn_sac_details,
    source_of_details,
    hsn_sac,
    description,
    taxability_type,
    nature_of_transaction,
    igst_rate,
    cgst_rate,
    sgst_utst_rate,
    cess_valuation_type,
    cess_rate,
    applicable_for_reverse_charge,
    type_of_supply,
    check_for_credit_days_during_voucher_entry,
    method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice,
    mailing_name,
    address1,
    state_name,
    country_name,
    pincode,
    mobile,
    provide_contact_details,
    provide_bank_details,
    pan_ItNo,
    registration_type,
    assessee_of_other_territory,
    gstin_uin,
    use_ledger_as_common_party,
    set_alter_additional_gst_details,
    ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation,
    opening_balance
  } = state;
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showList == true) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        }
        else if (e.key === 'Enter') {
          e.preventDefault();
          // You can navigate to the selected link's path here
          const selectedLink = group_all_list[selectedItem]
          if (selectedLink) {
            setState({ ...state, under: selectedLink.name, group_id: selectedLink.id })
            inputRef.current.blur();
            setShowList(false)
            inputMaintainRef.current.focus();
          }
        }
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (state.ledger_name !== '') {
          aliseInputRef.current.focus();
          if (alise_name && state.ledger_name !== '') {
            if (e.key === 'Enter') {
              aliseInputRef.current.blur();
              inputRef.current.focus();
            }
          }
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, under, state]);

  const ledgerSubmit = async (e1) => {
    e1.preventDefault();
    let hasErrors = false;
    if (state.ledger_name !== '') { hasErrors = true; }
    if (hasErrors) {
      let data = new FormData();
      data.append('company_id', userData?.id);
      data.append('ledger_name', state.ledger_name);
      data.append('alise_name', state.alise_name);
      data.append('group_id', state.group_id);
      data.append('address', state.address1);
      data.append('mailing_name', state.mailing_name);
      data.append('effective_date_for_reconciliation', state.effective_date_for_reconciliation);
      data.append('ac_holders_name', state.account_holder_name);
      data.append('ac_no', state.account_number);
      data.append('ifs_code', state.ifsc_code);
      data.append('swift_code', state.swift_code);
      data.append('bank_name', state.bank_name);
      data.append('branch', state.branch);
      data.append('set_alter_range_for_checque_books', state.set_alter_range_for_cheque_book);
      data.append('enable_check_printing', state.enable_cheque_printing);
      data.append('set_alter_cheque_printing_configuration', state.set_alter_cheque_printing_configuration);
      data.append('use_as_notional_bank_for_post_dated_vouchers', state.use_as_national_bank_for_post_dated_vouchers);
      data.append('state', state.state_name);
      data.append('country', state.country_name);
      data.append('pincode', state.pincode);
      data.append('mobile_no', state.mobile);
      data.append('provide_contact_details', state.provide_contact_details);
      data.append('set_od_limit', state.set_od_limit);
      data.append('provide_bank_details', state.provide_bank_details);
      data.append('pan_it_no', state.pan_ItNo);
      data.append('registration_type', state.registration_type);
      data.append('assessee_of_other_territory', state.assessee_of_other_territory);
      data.append('gst_in_uin', state.gstin_uin);
      data.append('use_ledger_as_common_party', state.use_ledger_as_common_party);
      data.append('set_alter_additional_gst_details', state.set_alter_additional_gst_details);
      data.append('ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation', state.ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation);
      data.append('maintain_balance_bill_by_bill', state.maintain_balance_bill_by_bill);
      data.append('default_credit_period', state.default_credit_period);
      data.append('check_for_credit_days_duriing_voucher_entry', state.check_for_credit_days_during_voucher_entry);
      data.append('specify_credit_limit', state.specify_credit_limit);
      data.append('override_credit_limit_using_post_dated_transaction', '');
      data.append('inventory_values_are_affected', state.inventry_values_are_affected);
      data.append('method_of_appropriation_to_allocate_additional_consts_in_purchas', state.method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice);
      data.append('include_in_assessable_value_calculation', state.include_in_assessable_value_calculation_for);
      data.append('appropriate_to', state.voucher_type_name);
      data.append('method_of_calculation', state.voucher_type_name);
      data.append('gst_applicability', state.is_gst_applicable);
      data.append('hsn_sac_details', state.hsn_sac_details);
      data.append('source_of_details', state.source_of_details);
      data.append('hsn_sac', state.hsn_sac);
      data.append('description', state.description);
      data.append('gst_rate_details', state.voucher_type_name);
      data.append('taxability_type', state.taxability_type);
      data.append('nature_of_transaction', state.nature_of_transaction);
      data.append('igst_rate', state.igst_rate);
      data.append('cgst_rate', state.cgst_rate);
      data.append('sgst_utgst_rate', state.sgst_utst_rate);
      data.append('cess_valution_type', state.cess_valuation_type);
      data.append('cess_rate', state.cess_rate);
      data.append('applicable_for_reverse_charge', state.applicable_for_reverse_charge);
      data.append('eligible_for_input_tax_credit', state.voucher_type_name);
      data.append('classification', state.voucher_type_name);
      data.append('type_of_supply', state.type_of_supply);
      data.append('type_of_ledger', state.type_of_ledger);
      data.append('rounding_method', state.rounding_method);
      data.append('rounding_limit', state.rounding_limit);
      data.append('percentage_of_calculation', state.percentage_of_calculation);
      data.append('rate_per_unit', state?.rate_per_unit);
      data.append('valuation_type', state?.valuation_type);
      data.append('opening_balance', state.opening_balance);
      dispatch(ledgerCreate(data)).then((response) => {
        if (response.status) {
          toast.success(response?.responseMessage || 'Ledger create successfully!', {
            position: toast.POSITION.TOP_CENTER
          });

        }
        else {
          toast.warn(response?.responseMessage || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }).catch(error => {
        console.log(error)
        toast.error(error?.responseMessage || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
      })
    }
  }
  if (typeof data === 'undefined' || data === null) {
    return null; // or return a loading indicator, error message, or fallback component
  }
  const typesOfLederList = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'Discount' }, { id: 3, name: 'Invoice Rounding' }]
  const registrationType = [{ id: 1, name: 'Unkown' }, { id: 2, name: 'Composition' }, { id: 3, name: 'Regular' }, { id: 4, name: 'Unregistered' }]
  const listOfMethod = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'Appropriate by Qty' }, { id: 3, name: 'Appropriate by Value' }]
  const yesNo = [{ id: 1, name: 'No' }, { id: 2, name: 'Yes' }]
  return (
    <div className='section'>
      <div className='detail border'>
        <ToastContainer />
        <div className='container-fluid ' >
          <form method='POST' id='ledger-form-form' autoComplete='off' onSubmit={ledgerSubmit}>
            <div className='row' id='ledger-form'>
              <div className='col-12'>
                <div className='row'>
                  <div className=' col-lg-6 mt-1'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label for='ledger_name' className='lab-1'> Name</label>
                      <label> :</label>
                      <input
                        type="text"
                        name='ledger_name'
                        id='ledger_name'
                        tabIndex={1}
                        autoFocus
                        className='lab-right'
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        value={ledger_name || ""}
                        autoCapitalize='capitalize' />
                    </div>
                    <div className='d-flex justify-content-between align-item-center' id='alis'>
                      <label className='lab-1'> (alise) </label>
                      <label> :</label>
                      <input type="text" name='alise_name' ref={aliseInputRef} id='alise_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} tabIndex={2} onKeyUp={handleChange} onChange={handleChange} value={alise_name || ""} />
                    </div>
                  </div>

                  <div className=' col-lg-6 ' >

                  </div>
                </div>
              </div>
              <hr />
              <div className='row mt-2' id='conditional_rendering'>
                <div className=' col-lg-6'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label for='under' className='lab-1'> Under </label>
                    <label> :</label>
                    <input type='text' id='under' name="under" tabIndex={3} ref={inputRef} value={under || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={handleFocus} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} />
                    <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                        List Of Group
                      </div>
                      <div className='col ' style={{ textAlign: 'right' }}>
                        <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                        <div className='border'></div>
                      </div>
                      <div id='data'>
                        {filteredData.length > 0 ? (
                          filteredData.map((key, number) => {
                            const name = key?.name;
                            const id = key?.id;
                            const index = name.toLowerCase().indexOf(under.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={() => {
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    setState({ ...state, under: name, group_id: id })
                                    inputRef.current.blur();
                                    setShowList(false)
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                    {name.substring(index + under.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {bankAccountRendering || bankOccAcRendering || bankOdAcRendering ?
                    <div>
                      <div className='d-flex justify-content-between align-item-center'>
                        <label className='lab-1'>Effective Date for Reconciliation </label>
                        <label> :</label>
                        <input type="text" name='effective_date_for_reconciliation' tabIndex={4} id='effective_date_for_reconciliation' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={effective_date_for_reconciliation || ""} />
                      </div>
                      {bankOdAcRendering ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Set OD Limit </label>
                          <label> :</label>
                          <input type="text" name='set_od_limit' id='set_od_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_od_limit || ""} />
                        </div> : null}
                      <div>
                        <p>Bank Account Details</p>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'>A/c Holder's Name </label>
                          <label> :</label>
                          <input type="text" name='account_holder_name' id='account_holder_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={account_holder_name || ""} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'>A/c No. </label>
                          <label> :</label>
                          <input type="number" name='account_number' id='account_number' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={account_number || ""} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'>IFSC Code </label>
                          <label> :</label>
                          <input type="text" name='ifsc_code' id='ifsc_code' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={ifsc_code || ""} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'>SWIFT Code </label>
                          <label> :</label>
                          <input type="text" name='swift_code' id='swift_code' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={swift_code || ""} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'>Bank Name </label>
                          <label> :</label>
                          <input type="text" name='bank_name' id='bank_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={bank_name || ""} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'>Branch </label>
                          <label> :</label>
                          <input type="text" name='branch' id='branch' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={branch || ""} />
                        </div>
                        <p>Bank Configuration</p>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Set/Alter range for Cheque Books </label>
                          <label> :</label>
                          <input type="text" list='sarfcb' name='set_alter_range_for_cheque_book' id='set_alter_range_for_cheque_book' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_range_for_cheque_book || ""} />
                          <datalist id='sarfcb' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Enable Cheque Printing </label>
                          <label> :</label>
                          <input type="text" list='ecp' name='enable_cheque_printing' id='enable_cheque_printing' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={enable_cheque_printing || ""} />
                          <datalist id='ecp' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        {
                          enable_cheque_printing == 'Yes' ?
                            <div className='d-flex justify-content-between align-item-center' id='Enable_Cheque_printing'>
                              <label className='lab-1'>Set/Alter Cheque Printing configuration </label>
                              <label> :</label>
                              <input type="text" list='sacpc' name='set_alter_cheque_printing_configuration' id='set_alter_cheque_printing_configuration' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_cheque_printing_configuration || ""} />
                              <datalist id='sacpc' >
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </datalist>
                            </div>
                            : null
                        }
                        <div className='d-flex justify-content-between align-item-center' id='Enable_Cheque_printing'>
                          <label className='lab-1'>Use as National Bank for Post-Dated Vouchers </label>
                          <label> :</label>
                          <input type="text" list='sacpc' name='use_as_national_bank_for_post_dated_vouchers' id='use_as_national_bank_for_post_dated_vouchers' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={use_as_national_bank_for_post_dated_vouchers || ""} />
                          <datalist id='sacpc' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option></datalist>
                        </div>
                      </div>
                    </div> : null
                  }
                  {
                    capitalAccountRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} tabIndex={4} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>

                      </div> : null
                  }
                  {
                    branchDivisionRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?
                                <div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Default Credit period </label>
                                    <label> :</label>
                                    <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center position-relative'>
                                    <label className='lab-1'> check for credit days during voucher entry </label>
                                    <label> :</label>
                                    <input type="text" name='check_for_credit_days_during_voucher_entry' id='check_for_credit_days_during_voucher_entry' ref={inputCheckForCreditRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={checkForCreditFocus} value={check_for_credit_days_during_voucher_entry || ""} />
                                    <div id="myDropdown-list-checkForCredit" className={`dropdown-content-list-for-small-component ${showListCheckForCredit ? 'show-list' : ''}`} ref={dropdownCheckForCreditRef}>
                                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                        Yes/No
                                      </div>

                                      <div id='data'>
                                        {yesNo.map((item, index) =>
                                          <Link className='list' id='list' key={index} onClick={(e) => { displayCheckForCrditDay(item?.name, item?.id); }} >{item?.name}</Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div> : null}
                          </div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Specify Credit Limit  </label>
                            <label> :</label>
                            <input type="text" name='specify_credit_limit' id='specify_credit_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={specify_credit_limit || ""} />
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    currentAssetsRendering || currentLiabilitiesRendering ?
                      <div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Behave as Duties & Taxes Ledger </label>
                          <label> :</label>
                          <input type="text" list='badtl' name='behave_as_duties_and_taxes_ledger' id='behave_as_duties_and_taxes_ledger' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={behave_as_duties_and_taxes_ledger || ""} />
                          <datalist id='badtl' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          behave_as_duties_and_taxes_ledger == 'Yes' ?
                            <div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Type of Duty/Tax </label>
                                <label> :</label>
                                <input type="text" list='todt' name='type_of_duty_tax' id='type_of_duty_tax' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={type_of_duty_tax || ""} />
                                <datalist id='todt' >
                                  <option value="GST">GST</option>
                                  <option value="Others">Others</option>
                                </datalist>
                              </div>
                              {
                                type_of_duty_tax === 'GST' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Tax Type </label>
                                    <label> :</label>
                                    <input type="text" list='taxtype' name='tax_type' id='tax_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={tax_type || ""} />
                                    <datalist id='taxtype' >
                                      <option value="IGST">IGST</option>
                                      <option value="CGST">CGST</option>
                                      <option value="SGST/UTGST">SGST/UTGST</option>
                                      <option value="Cess">Cess</option>
                                    </datalist>
                                  </div> : null
                              }
                              {
                                tax_type === 'Cess' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Valuation Type </label>
                                    <label> :</label>
                                    <input type="text" list='vt' name='valuation_type' id='valuation_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={valuation_type || ""} />
                                    <datalist id='vt' >
                                      <option value="Any">Any</option>
                                      <option value="Based On Quantity">Based On Quantity</option>
                                      <option value="Based On Value">Based On Value</option>
                                    </datalist>
                                  </div> : null
                              }
                              {valuation_type === 'Based On Quantity' ?
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Rate per Unit </label>
                                  <label> :</label>
                                  <input type="text" name='rate_per_unit' id='rate_per_unit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rate_per_unit || ""} />
                                </div> : null
                              }
                              {
                                valuation_type === 'Based On Quantity' ? null :
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Percentage of Calculation </label>
                                    <label> :</label>
                                    <input type="number" name='percentage_of_calculation' id='percentage_of_calculation' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={percentage_of_calculation || ""} />
                                  </div>
                              }
                              {
                                percentage_of_calculation || rate_per_unit > 0 ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Rounding Method </label>
                                    <label> :</label>
                                    <input type="text" list='rd' name='rounding_method' id='rounding_method' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_method || ""} />
                                    <datalist id='rd' >
                                      <option value="Not Applicable">Not Applicable</option>
                                      <option value="Downward Rounding">Downward Rounding</option>
                                      <option value="Normal Rounding">Normal Rounding</option>
                                      <option value="Upward Rounding">Upward Rounding</option>
                                    </datalist>
                                  </div> : null
                              }
                              {
                                rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Rounding Limit </label>
                                    <label> :</label>
                                    <input type="number" name='rounding_limit' id='rounding_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_limit || ""} />
                                  </div> : null
                              }
                            </div> : null}
                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    depositsAssetsRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>

                      </div> : null
                  }
                  {
                    directExpensesRendering ?
                      <div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Behave as Duties & Taxes Ledger </label>
                          <label> :</label>
                          <input type="text" list='badtl' name='behave_as_duties_and_taxes_ledger' id='behave_as_duties_and_taxes_ledger' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={behave_as_duties_and_taxes_ledger || ""} />
                          <datalist id='badtl' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          behave_as_duties_and_taxes_ledger == 'Yes' ?
                            <div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Type of Duty/Tax </label>
                                <label> :</label>
                                <input type="text" list='todt' name='type_of_duty_tax' id='type_of_duty_tax' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={type_of_duty_tax || ""} />
                                <datalist id='todt' >
                                  <option value="GST">GST</option>
                                  <option value="Others">Others</option>
                                </datalist>
                              </div>
                              {
                                type_of_duty_tax === 'GST' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Tax Type </label>
                                    <label> :</label>
                                    <input type="text" list='taxtype' name='tax_type' id='tax_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={tax_type || ""} />
                                    <datalist id='taxtype' >
                                      <option value="IGST">IGST</option>
                                      <option value="CGST">CGST</option>
                                      <option value="SGST/UTGST">SGST/UTGST</option>
                                      <option value="Cess">Cess</option>
                                    </datalist>
                                  </div> : null
                              }
                              {
                                tax_type === 'Cess' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Valuation Type </label>
                                    <label> :</label>
                                    <input type="text" list='vt' name='valuation_type' id='valuation_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={valuation_type || ""} />
                                    <datalist id='vt' >
                                      <option value="Any">Any</option>
                                      <option value="Based On Quantity">Based On Quantity</option>
                                      <option value="Based On Value">Based On Value</option>
                                    </datalist>
                                  </div> : null
                              }
                              {valuation_type === 'Based On Quantity' ?
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Rate per Unit </label>
                                  <label> :</label>
                                  <input type="text" name='rate_per_unit' id='rate_per_unit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rate_per_unit || ""} />
                                </div> : null

                              }
                              {
                                valuation_type === 'Based On Quantity' ? null :
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Percentage of Calculation </label>
                                    <label> :</label>
                                    <input type="number" name='percentage_of_calculation' id='percentage_of_calculation' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={percentage_of_calculation || ""} />
                                  </div>
                              }
                              {
                                percentage_of_calculation || rate_per_unit > 0 ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Rounding Method </label>
                                    <label> :</label>
                                    <input type="text" list='rd' name='rounding_method' id='rounding_method' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_method || ""} />
                                    <datalist id='rd' >
                                      <option value="Not Applicable">Not Applicable</option>
                                      <option value="Downward Rounding">Downward Rounding</option>
                                      <option value="Normal Rounding">Normal Rounding</option>
                                      <option value="Upward Rounding">Upward Rounding</option>
                                    </datalist>
                                  </div> : null
                              }
                              {
                                rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Rounding Limit </label>
                                    <label> :</label>
                                    <input type="number" name='rounding_limit' id='rounding_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_limit || ""} />
                                  </div> : null

                              }

                            </div> : null}
                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    directIncomesRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Type of Ledger </label>
                          <label> :</label>
                          <input type="text" name='type_of_ledger' id='type_of_ledger' ref={inputTypeOfLedgerRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={typeOfLedgerFocus} value={type_of_ledger || ""} />
                          <div id="myDropdown-list-type" className={`dropdown-content-list-for-small-component ${showListTypeOfLedger ? 'show-list' : ''}`} ref={dropdownTypeOfLedgerRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Types of Ledger
                            </div>

                            <div id='data'>
                              {typesOfLederList.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayTypeOfLedgerFun(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          type_of_ledger === 'Invoice Rounding' ?
                            <div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Rounding Limit </label>
                                <label> :</label>
                                <input type="number" name='rounding_limit' id='rounding_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_limit || ""} />
                              </div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Rounding Method </label>
                                <label> :</label>
                                <input type="text" list='rd' name='rounding_method' id='rounding_method' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_method || ""} />
                                <datalist id='rd' >
                                  <option value="Not Applicable">Not Applicable</option>
                                  <option value="Downward Rounding">Downward Rounding</option>
                                  <option value="Normal Rounding">Normal Rounding</option>
                                  <option value="Upward Rounding">Upward Rounding</option>
                                </datalist>
                              </div>
                            </div> : null
                        }
                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    dutiesAndTaxesRendering ?
                      <div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Type of Duty/Tax </label>
                          <label> :</label>
                          <input type="text" list='todt' name='type_of_duty_tax' id='type_of_duty_tax' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={type_of_duty_tax || ""} />
                          <datalist id='todt' >
                            <option value="GST">GST</option>
                            <option value="Others">Others</option>
                          </datalist>
                        </div>
                        {
                          type_of_duty_tax === 'GST' ?
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'> Tax Type </label>
                              <label> :</label>
                              <input type="text" list='taxtype' name='tax_type' id='tax_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={tax_type || ""} />
                              <datalist id='taxtype' >
                                <option value="IGST">IGST</option>
                                <option value="CGST">CGST</option>
                                <option value="SGST/UTGST">SGST/UTGST</option>
                                <option value="Cess">Cess</option>
                              </datalist>
                            </div> : null
                        }
                        {
                          tax_type === 'Cess' ?
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'> Valuation Type </label>
                              <label> :</label>
                              <input type="text" list='vt' name='valuation_type' id='valuation_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={valuation_type || ""} />
                              <datalist id='vt' >
                                <option value="Any">Any</option>
                                <option value="Based On Quantity">Based On Quantity</option>
                                <option value="Based On Value">Based On Value</option>
                              </datalist>
                            </div> : null
                        }
                        {valuation_type === 'Based On Quantity' ?
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Rate per Unit </label>
                            <label> :</label>
                            <input type="text" name='rate_per_unit' id='rate_per_unit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rate_per_unit || ""} />
                          </div> : null

                        }
                        {
                          valuation_type === 'Based On Quantity' ? null :
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'> Percentage of Calculation </label>
                              <label> :</label>
                              <input type="number" name='percentage_of_calculation' id='percentage_of_calculation' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={percentage_of_calculation || ""} />
                            </div>
                        }
                        {
                          percentage_of_calculation || rate_per_unit > 0 ?
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'> Rounding Method </label>
                              <label> :</label>
                              <input type="text" list='rd' name='rounding_method' id='rounding_method' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_method || ""} />
                              <datalist id='rd' >
                                <option value="Not Applicable">Not Applicable</option>
                                <option value="Downward Rounding">Downward Rounding</option>
                                <option value="Normal Rounding">Normal Rounding</option>
                                <option value="Upward Rounding">Upward Rounding</option>
                              </datalist>
                            </div> : null
                        }
                        {
                          rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'> Rounding Limit </label>
                              <label> :</label>
                              <input type="number" name='rounding_limit' id='rounding_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_limit || ""} />
                            </div> : null

                        }
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    fixedAssetsRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>

                      </div> : null
                  }
                  {
                    indirectExpensesRendering || purchaseAccountsRendering ?
                      <div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Behave as Duties & Taxes Ledger </label>
                          <label> :</label>
                          <input type="text" list='badtl' name='behave_as_duties_and_taxes_ledger' id='behave_as_duties_and_taxes_ledger' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={behave_as_duties_and_taxes_ledger || ""} />
                          <datalist id='badtl' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          behave_as_duties_and_taxes_ledger == 'Yes' ?
                            <div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Type of Duty/Tax </label>
                                <label> :</label>
                                <input type="text" list='todt' name='type_of_duty_tax' id='type_of_duty_tax' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={type_of_duty_tax || ""} />
                                <datalist id='todt' >
                                  <option value="GST">GST</option>
                                  <option value="Others">Others</option>
                                </datalist>
                              </div>
                              {
                                type_of_duty_tax === 'GST' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Tax Type </label>
                                    <label> :</label>
                                    <input type="text" list='taxtype' name='tax_type' id='tax_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={tax_type || ""} />
                                    <datalist id='taxtype' >
                                      <option value="IGST">IGST</option>
                                      <option value="CGST">CGST</option>
                                      <option value="SGST/UTGST">SGST/UTGST</option>
                                      <option value="Cess">Cess</option>
                                    </datalist>
                                  </div> : null
                              }
                              {
                                tax_type === 'Cess' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Valuation Type </label>
                                    <label> :</label>
                                    <input type="text" list='vt' name='valuation_type' id='valuation_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={valuation_type || ""} />
                                    <datalist id='vt' >
                                      <option value="Any">Any</option>
                                      <option value="Based On Quantity">Based On Quantity</option>
                                      <option value="Based On Value">Based On Value</option>
                                    </datalist>
                                  </div> : null
                              }
                              {valuation_type === 'Based On Quantity' ?
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Rate per Unit </label>
                                  <label> :</label>
                                  <input type="text" name='rate_per_unit' id='rate_per_unit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rate_per_unit || ""} />
                                </div> : null

                              }
                              {
                                valuation_type === 'Based On Quantity' ? null :
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Percentage of Calculation </label>
                                    <label> :</label>
                                    <input type="number" name='percentage_of_calculation' id='percentage_of_calculation' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={percentage_of_calculation || ""} />
                                  </div>
                              }
                              {
                                percentage_of_calculation || rate_per_unit > 0 ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Rounding Method </label>
                                    <label> :</label>
                                    <input type="text" list='rd' name='rounding_method' id='rounding_method' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_method || ""} />
                                    <datalist id='rd' >
                                      <option value="Not Applicable">Not Applicable</option>
                                      <option value="Downward Rounding">Downward Rounding</option>
                                      <option value="Normal Rounding">Normal Rounding</option>
                                      <option value="Upward Rounding">Upward Rounding</option>
                                    </datalist>
                                  </div> : null
                              }
                              {
                                rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Rounding Limit </label>
                                    <label> :</label>
                                    <input type="number" name='rounding_limit' id='rounding_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_limit || ""} />
                                  </div> : null

                              }

                            </div> : null}
                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    indirectIncomesRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Type of Ledger </label>
                          <label> :</label>
                          <input type="text" name='type_of_ledger' id='type_of_ledger' ref={inputTypeOfLedgerRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={typeOfLedgerFocus} value={type_of_ledger || ""} />
                          <div id="myDropdown-list-type" className={`dropdown-content-list-for-small-component ${showListTypeOfLedger ? 'show-list' : ''}`} ref={dropdownTypeOfLedgerRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Types of Ledger
                            </div>

                            <div id='data'>
                              {typesOfLederList.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayTypeOfLedgerFun(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          type_of_ledger === 'Invoice Rounding' ?
                            <div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Rounding Limit </label>
                                <label> :</label>
                                <input type="number" name='rounding_limit' id='rounding_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_limit || ""} />
                              </div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Rounding Method </label>
                                <label> :</label>
                                <input type="text" list='rd' name='rounding_method' id='rounding_method' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_method || ""} />
                                <datalist id='rd' >
                                  <option value="Not Applicable">Not Applicable</option>
                                  <option value="Downward Rounding">Downward Rounding</option>
                                  <option value="Normal Rounding">Normal Rounding</option>
                                  <option value="Upward Rounding">Upward Rounding</option>
                                </datalist>
                              </div>
                            </div> : null
                        }
                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    investmentsRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>


                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    loanAndAdvanceAssetRendering || loanLiabilityRendering || miscExpensesRendering || provisionRendering || securedLoansRendering || unsecuredLoansRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>


                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    reservesAndSurplusRendering || suspenseAcRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    salesAccountsRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?

                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                </div> : null}
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Type of Ledger </label>
                          <label> :</label>
                          <input type="text" name='type_of_ledger' id='type_of_ledger' ref={inputTypeOfLedgerRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={typeOfLedgerFocus} value={type_of_ledger || ""} />
                          <div id="myDropdown-list-type" className={`dropdown-content-list-for-small-component ${showListTypeOfLedger ? 'show-list' : ''}`} ref={dropdownTypeOfLedgerRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Types of Ledger
                            </div>

                            <div id='data'>
                              {typesOfLederList.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayTypeOfLedgerFun(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                        {
                          type_of_ledger === 'Invoice Rounding' ?
                            <div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Rounding Limit </label>
                                <label> :</label>
                                <input type="number" name='rounding_limit' id='rounding_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_limit || ""} />
                              </div>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Rounding Method </label>
                                <label> :</label>
                                <input type="text" list='rd' name='rounding_method' id='rounding_method' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={rounding_method || ""} />
                                <datalist id='rd' >
                                  <option value="Not Applicable">Not Applicable</option>
                                  <option value="Downward Rounding">Downward Rounding</option>
                                  <option value="Normal Rounding">Normal Rounding</option>
                                  <option value="Upward Rounding">Upward Rounding</option>
                                </datalist>
                              </div>
                            </div> : null
                        }
                        <div>
                          <p>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input type="text" list='gstapplcble' name='is_gst_applicable' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                            <datalist id='gstapplcble' >
                              <option value="Applicable">Applicable</option>
                              <option value="Not Applicable">Not Applicable</option>
                              <option value="Undefined">Undefined</option>
                            </datalist>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input type="text" name='set_alter_gst_details' id='set_alter_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input type="number" name='include_in_assessable_value_calculation_for' id='include_in_assessable_value_calculation_for' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={include_in_assessable_value_calculation_for || ""} onKeyUp={handleChange} onChange={handleChange} />
                          </div>
                        </div>
                        {
                          type_of_ledger === 'Not Applicable' ?
                            <div>
                              <p>HSN/SAC & Related Details</p>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> HSN/SAC Details </label>
                                <label> :</label>
                                <input type="text" list='hsd' name='hsn_sac_details' id='hsn_sac_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={hsn_sac_details || ""} />
                                <datalist id='hsd' >
                                  <option value="As per Company/Group">As per Company/Group</option>
                                  <option value="Specify Details Here">Specify Details Here</option>
                                  <option value="Use GST classification">Use GST classification</option>
                                  <option value="Specify in Voucher">Specify in Voucher</option>
                                </datalist>
                              </div>
                              {
                                hsn_sac_details === 'Specify Details Here' ? null :
                                  <div className='d-flex justify-content-between align-item-center' >
                                    <label className='lab-1'>Source of Details </label>
                                    <label> :</label>
                                    <input type="text" name='source_of_details' id='source_of_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={source_of_details || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                                  </div>
                              }
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>HSN/SAC </label>
                                <label> :</label>
                                <input type="text" name='hsn_sac' id='hsn_sac' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={hsn_sac || ""} onKeyUp={handleChange} onChange={handleChange} disabled={hsn_sac_details !== 'Specify Details Here'} />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Description </label>
                                <label> :</label>
                                <input type="text" name='description' id='description' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={description || ""} onKeyUp={handleChange} onChange={handleChange} disabled={hsn_sac_details !== 'Specify Details Here'} />
                              </div>
                              <p>GST Rate & Related Details</p>
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> GST Rate Details </label>
                                <label> :</label>
                                <input type="text" list='hsd' name='hsn_sac_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={hsn_sac_details || ""} />
                                <datalist id='hsd' >
                                  <option value="As per Company/Group">As per Company/Group</option>
                                  <option value="Specify Details Here">Specify Details Here</option>
                                  <option value="Use GST classification">Use GST classification</option>
                                  <option value="Specify Slab-Based Rates">Specify Slab-Based Rates</option>
                                </datalist>
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Source of Details </label>
                                <label> :</label>
                                <input type="text" name='source_of_details' id='source_of_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={source_of_details || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Taxability Type </label>
                                <label> :</label>
                                <input type="text" name='taxability_type' id='taxability_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={taxability_type || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Nature of Transaction </label>
                                <label> :</label>
                                <input type="text" name='nature_of_transaction' id='nature_of_transaction' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={nature_of_transaction || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>IGST Rate </label>
                                <label> :</label>
                                <input type="number" name='igst_rate' id='igst_rate' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={igst_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>CGST Rate </label>
                                <label> :</label>
                                <input type="text" name='cgst_rate' id='cgst_rate' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={cgst_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>SGST/UTGST Rate </label>
                                <label> :</label>
                                <input type="text" name='sgst_utst_rate' id='sgst_utst_rate' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={sgst_utst_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Cess Valuation Type </label>
                                <label> :</label>
                                <input type="text" name='cess_valuation_type' id='cess_valuation_type' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={cess_valuation_type || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Cess Rate </label>
                                <label> :</label>
                                <input type="text" name='cess_rate' id='cess_rate' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={cess_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Applicable For Reverse Charge </label>
                                <label> :</label>
                                <input type="text" list='afrc' name='applicable_for_reverse_charge' id='applicable_for_reverse_charge' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={applicable_for_reverse_charge || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                                <datalist id='afrc' >
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </datalist>
                              </div>
                              <div className='d-flex justify-content-between align-item-center' >
                                <label className='lab-1'>Type of Supply </label>
                                <label> :</label>
                                <input type="text" list='tos' name='type_of_supply' id='type_of_supply' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={type_of_supply || ""} onKeyUp={handleChange} onChange={handleChange} />
                                <datalist id='tos' >
                                  <option value="Capital Goods">Capital Goods</option>
                                  <option value="Goods">Goods</option>
                                  <option value="Services">Services</option>
                                </datalist>
                              </div>
                            </div> : null
                        }
                      </div> : null
                  }
                  {
                    sundryCreditorsRendering || sundryDebtorsRendering ?
                      <div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label for='Maintainbalance' className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input type="text" list='maintain' ref={inputMaintainRef} name='maintain_balance_bill_by_bill' id='Maintainbalance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={maintainBalanceBillFocus} value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-small-component ${showListMaintain ? 'show-list' : ''}`} ref={dropdownMaintainRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Yes/No
                              </div>
                              <div id='data'>
                                {yesNo.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayMaintainYesNo(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {error && (
                            <div style={{ color: 'red' }}>
                              Please enter a valid input value: 'yes' or 'no'
                            </div>
                          )}
                          <div>
                            {
                              maintain_balance_bill_by_bill == 'Yes' ?
                                <div>
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Default Credit period </label>
                                    <label> :</label>
                                    <input type="text" name='default_credit_period' id='default-credit-period' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_credit_period || ""} />
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center position-relative'>
                                    <label className='lab-1'> check for credit days during voucher entry </label>
                                    <label> :</label>
                                    <input type="text" name='check_for_credit_days_during_voucher_entry' id='check_for_credit_days_during_voucher_entry' ref={inputCheckForCreditRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={checkForCreditFocus} value={check_for_credit_days_during_voucher_entry || ""} />
                                    <div id="myDropdown-list-checkForCredit" className={`dropdown-content-list-for-small-component ${showListCheckForCredit ? 'show-list' : ''}`} ref={dropdownCheckForCreditRef}>
                                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                        Yes/No
                                      </div>

                                      <div id='data'>
                                        {yesNo.map((item, index) =>
                                          <Link className='list' id='list' key={index} onClick={(e) => { displayCheckForCrditDay(item?.name, item?.id); }} >{item?.name}</Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div> : null}
                          </div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'> Specify Credit Limit </label>
                            <label> :</label>
                            <input type="text" name='specify_credit_limit' id='specify_credit_limit' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={specify_credit_limit || ""} />
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> Inventory values are affected </label>
                          <label> :</label>
                          <input type="text" name='inventry_values_are_affected' id='inventry_values_are_affected' ref={inputInventoryRef} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} onFocus={inventoryFocus} value={inventry_values_are_affected || ""} />
                          <div id="myDropdown-list-inventory" className={`dropdown-content-list-for-small-component ${showListInventory ? 'show-list' : ''}`} ref={dropdownInventoryRef}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Yes/No
                            </div>
                            <div id='data'>
                              {yesNo.map((item, index) =>
                                <Link className='list' id='list' key={index} onClick={(e) => { displayInventory(item?.name, item?.id); }} >{item?.name}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div> : null
                  }


                </div>
                <div className='col-lg-6'>
                  {
                    bankAccountRendering || bankOdAcRendering ?
                      <div>
                        <p>Mailing Details</p>
                        <div className='d-flex justify-content-between align-item-center' id='name'>
                          <label className='lab-1'>Name </label>
                          <label> :</label>
                          <input type="text" name='mailing_name' id='mailing_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={mailing_name || ledger_name} contentEditable="true" />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' id='address'>
                          <label className='lab-1'>Address</label>
                          <label> :</label>
                          <input type="text" name='address1' id='address1' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={address1 || ""} />
                        </div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='state'>
                            <label className='lab-1'> State </label>
                            <label> :</label>
                            <input type='text' name='state_name' id='state_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} ref={inputStateRef} value={state_name || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={stateFocus} />
                            <div id="myDropdown-list-state" className={`dropdown-content-list-for-state-component ${showListState ? 'show-list' : ''}`} ref={dropdownStateRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of States
                              </div>

                              <div id='data'>
                                {State.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayStateFun(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='country'>
                            <label className='lab-1'> Country </label>
                            <label> :</label>
                            <input type='text' name='country_name' list='country_list' ref={inputCountryRef} id='country_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={country_name || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={countryFocus} />
                            <div id="myDropdown-list-country" className={`dropdown-content-list-for-state-component ${showListCountry ? 'show-list' : ''}`} ref={dropdownCountryRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of country
                              </div>

                              <div id='data'>
                                {Country1.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayCountryFun(item?.name); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' id='pincode'>
                            <label className='lab-1'> Pincode </label>
                            <label> :</label>
                            <input type="text" name='pincode' id='pincode' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={pincode || ""} />
                          </div>
                          {errors.pincode && <span style={{ color: 'red' }}>{errors.pincode}</span>}
                        </div>

                        <div className='d-flex justify-content-between align-item-center' id='pincode'>
                          <label className='lab-1'> Mobile </label>
                          <label> :</label>
                          <input type="number" name='mobile' id='mobile' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={mobile || ""} />
                        </div>
                        {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                        <div className='d-flex justify-content-between align-item-center' id='provide-bank-details'>
                          <label className='lab-1'> Provide Contact details </label>
                          <label> :</label>
                          <input type="text" name='provide_contact_details' id='provide_contact_details' list='pbd' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={provide_contact_details || ""} />
                          <datalist id='pbd' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>

                        <p id='tex-registration-details'>Tax Registration Details</p>
                      </div>
                      : null
                  }
                  {
                    capitalAccountRendering || branchDivisionRendering || currentAssetsRendering || currentLiabilitiesRendering || depositsAssetsRendering || directExpensesRendering || directIncomesRendering || fixedAssetsRendering || indirectExpensesRendering || indirectIncomesRendering || investmentsRendering || loanAndAdvanceAssetRendering || loanLiabilityRendering || securedLoansRendering || sundryCreditorsRendering || sundryDebtorsRendering || unsecuredLoansRendering ?
                      <div>
                        <div>
                          <p>Mailing Details</p>
                          <div className='d-flex justify-content-between align-item-center' id='name'>
                            <label className='lab-1'>Name </label>
                            <label> :</label>
                            <input type="text" name='mailing_name' id='mailing_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={mailing_name || ledger_name} contentEditable="true" />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' id='address'>
                            <label className='lab-1'>Address</label>
                            <label> :</label>
                            <input type="text" name='address1' id='address1' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={address1 || ""} />
                          </div>
                          <div>
                            <div className='d-flex justify-content-between align-item-center position-relative' id='state'>
                              <label className='lab-1'> State </label>
                              <label> :</label>
                              <input type='text' name='state_name' id='state_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} ref={inputStateRef} value={state_name || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={stateFocus} />
                              <div id="myDropdown-list-state" className={`dropdown-content-list-for-state-component ${showListState ? 'show-list' : ''}`} ref={dropdownStateRef}>
                                <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                  List Of States
                                </div>

                                <div id='data'>
                                  {State.map((item, index) =>
                                    <Link className='list' id='list' key={index} onClick={(e) => { displayStateFun(item?.name, item?.id); }} >{item?.name}</Link>
                                  )}
                                </div>
                              </div>

                            </div>
                            <div className='d-flex justify-content-between align-item-center position-relative' id='country'>
                              <label className='lab-1'> Country </label>
                              <label> :</label>
                              <input type='text' name='country_name' list='country_list' ref={inputCountryRef} id='country_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} value={country_name || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={countryFocus} />
                              <div id="myDropdown-list-country" className={`dropdown-content-list-for-state-component ${showListCountry ? 'show-list' : ''}`} ref={dropdownCountryRef}>
                                <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                  List Of country
                                </div>

                                <div id='data'>
                                  {Country1.map((item, index) =>
                                    <Link className='list' id='list' key={index} onClick={(e) => { displayCountryFun(item?.name); }} >{item?.name}</Link>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-item-center' id='pincode'>
                              <label className='lab-1'> Pincode </label>
                              <label> :</label>
                              <input type="text" name='pincode' id='pincode' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={pincode || ""} />
                            </div>
                            {errors.pincode && <span style={{ color: 'red' }}>{errors.pincode}</span>}
                          </div>
                          <div className='d-flex justify-content-between align-item-center' id='pincode'>
                            <label className='lab-1'> Mobile </label>
                            <label> :</label>
                            <input type="number" name='mobile' id='mobile' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={mobile || ""} />
                          </div>
                          {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                          <div className='d-flex justify-content-between align-item-center' id='provide-bank-details'>
                            <label className='lab-1'> Provide Contact details </label>
                            <label> :</label>
                            <input type="text" name='provide_contact_details' id='provide_contact_details' list='pbd' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={provide_contact_details || ""} />
                            <datalist id='pbd' >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </datalist>
                          </div>
                          <p id='banking-details'>Banking Details</p>
                          <div className='d-flex justify-content-between align-item-center' id='provide-bank-details'>
                            <label className='lab-1'> Provide bank details </label>
                            <label> :</label>
                            <input type="text" name='provide_bank_details' id='provide_bank_details' list='pbd' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={provide_bank_details || ""} />
                            <datalist id='pbd' >
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </datalist>
                          </div>
                          <p id='tex-registration-details'>Tax Registration Details</p>

                          <div>
                            <div className='d-flex justify-content-between align-item-center' id='pan_it_no'>
                              <label className='lab-1'> PAN/IT No. </label>
                              <label> :</label>
                              <input type="text" name='pan_ItNo' id='pan_ItNo' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={pan_ItNo || ""} />
                            </div>
                            {errors.pan_no && <span style={{ color: 'red' }}>{errors.pan_no}</span>}
                          </div>

                          <div>
                            <div className='d-flex justify-content-between align-item-center position-relative' id='registration-type'>
                              <label className='lab-1'> Registration Type </label>
                              <label> :</label>
                              <input type='text' list="registration" name="registration_type" ref={inputRegistrationTypeRef} id='registration_type' value={registration_type || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={registrationTypeFocus} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} />
                              <div id="myDropdown-list-registrationType" className={`dropdown-content-list-for-state-component ${showListRegistrationType ? 'show-list' : ''}`} ref={dropdownRegistrationTypeRef}>
                                <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                  Registration Type
                                </div>
                                <div id='data'>
                                  {registrationType.map((item, index) =>
                                    <Link className='list' id='list' key={index} onClick={(e) => { displayRegistrationType(item?.name, item?.id); }} >{item?.name}</Link>
                                  )}
                                </div>
                              </div>
                            </div>
                            {
                              registration_type == 'Unkown' || 'Composition' || 'Regular' || 'Unregistered' ?
                                <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                  <label className='lab-1'> Assessee of Other Territory </label>
                                  <label> :</label>
                                  <input type="text" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} name='assessee_of_other_territory' id='assessee_of_other_territory' onKeyUp={handleChange} onChange={handleChange} value={assessee_of_other_territory || ""} />
                                </div> : null
                            }
                            {
                              registration_type == 'Unregistered' ?
                                <div>
                                  <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                    <label className='lab-1'> GSTIN/UIN </label>
                                    <label> :</label>
                                    <input type="number" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} name='gstin_uin' id='gstin_uin' onKeyUp={handleChange} onChange={handleChange} value={gstin_uin || ""} />
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                    <label className='lab-1'> Use Ledger as common Party </label>
                                    <label> :</label>
                                    <input type="text" name='use_ledger_as_common_party' id='use_ledger_as_common_party' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={use_ledger_as_common_party || ""} />
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center' id='set_alter-gst-details'>
                                    <label className='lab-1'> Set/Alter Additional GST Details </label>
                                    <label> :</label>
                                    <input type="text" list='gst' name='set_alter_additional_gst_details' id='set_alter_additional_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_additional_gst_details || ""} />
                                    <datalist id="gst" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}>
                                      <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="Yes">Yes</option>
                                      <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="No">No</option>
                                    </datalist>
                                  </div>
                                  <div className='d-flex justify-content-between align-item-center' id='set_alter-gst-details'>
                                    <label className='lab-1'> Ignore prefixes and suffixes in doc No. for reconciliation </label>
                                    <label> :</label>
                                    <input type="text" list='gst' name='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation' id='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation || ""} />
                                    <datalist id="gst" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}>
                                      <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="Yes">Yes</option>
                                      <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="No">No</option>
                                    </datalist>
                                  </div>
                                </div> : null
                            }
                          </div>
                        </div>
                      </div> : null
                  }
                  {
                    cashInHandRendering || dutiesAndTaxesRendering || miscExpensesRendering || provisionRendering || purchaseAccountsRendering || reservesAndSurplusRendering || salesAccountsRendering || stockInHandRendering || suspenseAcRendering ?
                      <div>
                        <p>Mailing Details</p>
                        <div className='d-flex justify-content-between align-item-center' id='name'>
                          <label className='lab-1'>Name </label>
                          <label> :</label>
                          <input type="text" name='mailing_name' id='mailing_name' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={mailing_name || ledger_name} contentEditable="true" />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' id='address'>
                          <label className='lab-1'>Address</label>
                          <label> :</label>
                          <input type="text" name='address1' id='address1' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={address1 || ""} />
                        </div>

                        <div className='d-flex justify-content-between align-item-center' id='pincode'>
                          <label className='lab-1'> Mobile </label>
                          <label> :</label>
                          <input type="number" name='mobile' id='mobile' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={mobile || ""} />
                        </div>
                        {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                        <div className='d-flex justify-content-between align-item-center' id='provide-bank-details'>
                          <label className='lab-1'> Provide Contact details </label>
                          <label> :</label>
                          <input type="text" name='provide_contact_details' id='provide_contact_details' list='pbd' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={provide_contact_details || ""} />
                          <datalist id='pbd' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <p id='banking-details'>Banking Details</p>
                        <div className='d-flex justify-content-between align-item-center' id='provide-bank-details'>
                          <label className='lab-1'> Provide bank details </label>
                          <label> :</label>
                          <input type="text" name='provide_bank_details' id='provide_bank_details' list='pbd' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={provide_bank_details || ""} />
                          <datalist id='pbd' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <p id='tex-registration-details'>Tax Registration Details</p>
                        <div>
                          <div className='d-flex justify-content-between align-item-center' id='pan_it_no'>
                            <label className='lab-1'> PAN/IT No. </label>
                            <label> :</label>
                            <input type="text" name='pan_ItNo' id='pan_ItNo' className='lab-right text-uppercase' onKeyUp={handleChange} onChange={handleChange} value={pan_ItNo || ""} />
                          </div>
                          {errors.pan_no && <span style={{ color: 'red' }}>{errors.pan_no}</span>}
                        </div>
                        <div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='registration-type'>
                            <label className='lab-1'> Registration Type </label>
                            <label> :</label>
                            <input type='text' list="registration" name="registration_type" ref={inputRegistrationTypeRef} id='registration_type' value={registration_type || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={registrationTypeFocus} className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} />
                            <div id="myDropdown-list-registrationType" className={`dropdown-content-list-for-state-component ${showListRegistrationType ? 'show-list' : ''}`} ref={dropdownRegistrationTypeRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Registration Type
                              </div>
                              <div id='data'>
                                {registrationType.map((item, index) =>
                                  <Link className='list' id='list' key={index} onClick={(e) => { displayRegistrationType(item?.name, item?.id); }} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            registration_type == 'Unkown' || 'Composition' || 'Regular' || 'Unregistered' ?
                              <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                <label className='lab-1'> Assessee of Other Territory </label>
                                <label> :</label>
                                <input type="text" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} name='assessee_of_other_territory' id='assessee_of_other_territory' onKeyUp={handleChange} onChange={handleChange} value={assessee_of_other_territory || ""} />
                              </div> : null
                          }
                          {
                            registration_type == 'Unregistered' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                  <label className='lab-1'> GSTIN/UIN </label>
                                  <label> :</label>
                                  <input type="number" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} name='gstin_uin' id='gstin_uin' onKeyUp={handleChange} onChange={handleChange} value={gstin_uin || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                  <label className='lab-1'> Use Ledger as common Party </label>
                                  <label> :</label>
                                  <input type="text" name='use_ledger_as_common_party' id='use_ledger_as_common_party' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={use_ledger_as_common_party || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center' id='set_alter-gst-details'>
                                  <label className='lab-1'> Set/Alter Additional GST Details </label>
                                  <label> :</label>
                                  <input type="text" list='gst' name='set_alter_additional_gst_details' id='set_alter_additional_gst_details' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_additional_gst_details || ""} />
                                  <datalist id="gst" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}>
                                    <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="Yes">Yes</option>
                                    <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="No">No</option>
                                  </datalist>
                                </div>
                                <div className='d-flex justify-content-between align-item-center' id='set_alter-gst-details'>
                                  <label className='lab-1'> Ignore prefixes and suffixes in doc No. for reconciliation </label>
                                  <label> :</label>
                                  <input type="text" list='gst' name='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation' id='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation || ""} />
                                  <datalist id="gst" className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}>
                                    <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="Yes">Yes</option>
                                    <option style={{ textAlign: 'left', position: 'absolute', top: 50 }} name='under' value="No">No</option>
                                  </datalist>
                                </div>
                              </div> : null
                          }
                        </div>
                      </div> : null
                  }
                </div>
              </div>
              <hr />
              <div className='row mt-1 mb-1'>
                <div className='col-lg-6'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> opening balance (1-Apr-23) </label>
                    <label> :</label>
                    <input type="number" name='opening_balance' className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={opening_balance || ""} />
                  </div>
                </div>
              </div>
            </div>
            <div className='tomasterfooter'><Footer /></div>
          </form>
        </div>
      </div>
      <div className='help'>
        <div className='card'>
          <h6 className='card-header'><span>F3:</span>Company</h6>
        </div>
        <div className='card'>
          <h6 className='card-header'><span>F2:</span>Date</h6>
        </div>
      </div>
    </div>
  )
}
export default Ledger;
