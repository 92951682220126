import React from 'react'

const YesNoModal = ({ children }) => {
    return (
        <div style={{ position: 'absolute', bottom: 24, right: 1, width: '10%', height: '100px', borderWidth: 1, borderColor: 'black', borderStyle: 'solid', background: '#e0d2b1', zIndex: 1000,display:'flex',justifyContent:'center',alignItems:'center' }}>
            {children}
        </div>
    )
}

export default YesNoModal
