import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import Footer from '../../../../components/home_page/Footer'
import { Link } from 'react-router-dom'
import { numberSeriesList,serialNumberList,storeVoucherEntryData } from '../../../../redux/actions/action-creator'
const VoucherTypeNameSelect =(props)=> {
    const [state, setState] = useState({
        voucher_name: '',
        voucher_name_id: '',
        number_series_name: ''
    })
    const dispatch = useDispatch();
    const number_series_data = useSelector(state => state.common.number_series_data)
    const filter_voucher = useSelector(state => state.common.filter_voucher)
    console.log('this is filter voucher :----',filter_voucher)
    const { userData = null } = useSelector(state => state.auth)
    const [filteredData, setFilteredData] = useState([])
    const [selectedItem, setSelectedItem] = useState(0);
    const voucher_name_input_ref = useRef(null)
    const number_series_name_input_ref = useRef(null)
    const { voucher_name, number_series_name } = state;
    const [show_name_dropdown, setshow_name_dropdown] = useState(false)
    const [show_number_dropdown, setshow_number_dropdown] = useState(false)
    const [validation_num_field, setvalidation_num_field] = useState(false)
    const [num_data, setnum_data] = useState([])
    const handlechange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        if (name === 'number_series_name') {
            if (e.key === 'Enter' && state.voucher_name !== '') {
                e.preventDefault();
                props.setData({ ...props.data, entry_name: voucher_name })
                props.setshow_voucher_type(false)
            }
        }
    }
    const handleFocus = (e) => {
        const { name } = e.target;
        if (name === 'voucher_name') {
            setshow_name_dropdown(true)
        }
        if (name === 'number_series_name') {
            setshow_number_dropdown(true)
        }
    }
    useEffect(() => {
        const filteredSuggestions = filter_voucher?.filter((item, index) => {
            const lowercaseLedgerName = item?.voucher_type_name?.toLowerCase();
            const lowercaseSearchInput = state?.voucher_name?.toLowerCase();
            const matchesSearchInput = lowercaseLedgerName?.includes(state.voucher_name !== '' ? lowercaseSearchInput : '');
            return matchesSearchInput;
        })
        setFilteredData(state.voucher_name !== '' ? voucher_name_input_ref.current?.selectionStart > 0 ? filteredSuggestions : filter_voucher : filter_voucher);
    }, [state.voucher_name, filter_voucher, voucher_name_input_ref?.current?.selectionStart])
    useEffect(() => {
        const filteredSuggestions = number_series_data?.filter((item, index) => {
            const lowercaseLedgerName = item?.series_name?.toLowerCase();
            const lowercaseSearchInput = state?.number_series_name?.toLowerCase();
            const matchesSearchInput = lowercaseLedgerName?.includes(state.number_series_name !== '' ? lowercaseSearchInput : '');
            return matchesSearchInput;
        })
        setFilteredData(state.number_series_name !== '' ? number_series_name_input_ref.current?.selectionStart > 0 ? filteredSuggestions : number_series_data : number_series_data);
    }, [state.number_series_name, number_series_data, number_series_name_input_ref?.current?.selectionStart])
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (show_name_dropdown === true || show_number_dropdown === true) {
                if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    setSelectedItem(prevState => Math.max(prevState - 1, 0));
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
                } else if (e.key === 'Enter') {
                    e.preventDefault();
                    const selectedLink = filteredData[selectedItem]
                    if (selectedLink) {
                        if (show_name_dropdown) {
                            const formdata = new FormData();
                            formdata.append('voucher_type_name', selectedLink.voucher_type_name)
                            formdata.append('company_id', userData?.id)
                            dispatch(numberSeriesList(formdata)).then((response) => { console.log("this i s= response :------", response) })
                            dispatch(storeVoucherEntryData(
                                {
                                    voucher_type_name: selectedLink.voucher_type_name,
                                    voucher_type_name_id: selectedLink.id,
                                    number_series_id: selectedLink?.vaucher_number_series_data[0]?.id
                                }))
                            localStorage.setItem('voucher_type_name', selectedLink.voucher_type_name);
                            localStorage.setItem('voucher_type_name_id', selectedLink.id);
                            setState({
                                ...state, voucher_name: selectedLink.voucher_type_name, voucher_name_id: selectedLink?.id
                            })
                            setshow_name_dropdown(false)
                            if (selectedLink?.vaucher_number_series_data?.length > 1) {
                                e.preventDefault();
                                setTimeout(() => {
                                    setvalidation_num_field(true);
                                }, 100)
                                setTimeout(() => {
                                    number_series_name_input_ref.current.focus();
                                }, 150)
                            } else {
                                e.preventDefault();
                                const serial = new FormData();
                                localStorage.setItem('number_series_id', selectedLink.vaucher_number_series_data[0]?.id);
                                serial.append('voucher_type_name', selectedLink.voucher_type_name)
                                serial.append('voucher_number_series_id', selectedLink?.vaucher_number_series_data[0]?.id)
                                serial.append('company_id', userData?.id)
                                dispatch(serialNumberList(serial)).then((response) => {
                                    if (response.status) {
                                        props.setData({ ...props.data, entry_name: selectedLink.voucher_type_name, entry_name_id: selectedLink?.id, serial_no: response.new_serial_no, check_num_series_manual_automatic: response?.data?.method_of_voucher_numbering })
                                    }
                                })
                                props.setshow_voucher_type(false)
                            }

                        }
                        if (show_number_dropdown) {
                            setState({
                                ...state, number_series_name: selectedLink.series_name
                            })
                            const serial = new FormData();
                            localStorage.setItem('number_series_id', selectedLink?.id);
                            serial.append('voucher_type_name', state?.voucher_name)
                            serial.append('voucher_number_series_id', selectedLink?.id)
                            serial.append('company_id', userData?.id)
                            dispatch(serialNumberList(serial)).then((response) => {
                                if (response.status) {
                                    props.setData({ ...props.data, entry_name: state?.voucher_name, entry_name_id: state?.voucher_name_id, serial_no: response.new_serial_no, check_num_series_manual_automatic: response?.data?.method_of_voucher_numbering })
                                }
                            })
                            props.setshow_voucher_type(false)
                        }

                    }
                }
            }
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setshow_voucher_type(false);
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handlechange, selectedItem, validation_num_field, number_series_name, show_name_dropdown, voucher_name, props.show_voucher_type]);
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setshow_voucher_type(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3' id='card-period'>
                <div className='row'>
                    <div className='col'>
                        <b className='border-bottom'>Voucher Type</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Name</label>
                            <label>:</label>
                            <input
                                type='text'
                                autoComplete='off'
                                autoFocus
                                name='voucher_name'
                                id='voucher_name'
                                ref={voucher_name_input_ref}
                                onFocus={handleFocus}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={voucher_name || ''}
                                className='lab-right' />
                            <div className={`dropdown_number_series ${show_name_dropdown ? 'show-list' : ''}`}>
                                <div className='col background' style={{ position: 'sticky', top: 0 }}>
                                    List of voucher type
                                </div>
                                <div id='data'>
                                    {filteredData?.length > 0 ? (
                                        filteredData?.map((key, number) => {
                                            const name = key?.voucher_type_name;
                                            const index = name?.toLowerCase()?.indexOf(voucher_name?.toLowerCase());
                                            return (
                                                <Link
                                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                    id='list'
                                                    key={number}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedItem(number)
                                                        if (number === selectedItem) {
                                                            setState({ ...state, voucher_name: name })
                                                            setshow_name_dropdown(false)
                                                        }
                                                    }}
                                                >
                                                    {index !== -1 ? (
                                                        < >
                                                            {name?.substring(0, index)}
                                                            <span style={{ color: 'red' }}>{name?.substring(index, index + voucher_name.length)}</span>
                                                            {name?.substring(index + voucher_name.length)}
                                                        </>
                                                    ) : (
                                                        name
                                                    )}
                                                </Link>
                                            );
                                        })
                                    ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {
                            validation_num_field ?
                                <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'>Series Name</label>
                                    <label>:</label>
                                    <input
                                        type='text'
                                        name='number_series_name'
                                        id='number_series_name'
                                        ref={number_series_name_input_ref}
                                        onChange={handlechange}
                                        onKeyUp={handlechange}
                                        onFocus={handleFocus}
                                        value={number_series_name || ''}
                                        className='lab-right' />
                                    <div className={`dropdown_number_series_select ${show_number_dropdown ? 'show-list' : ''}`}>
                                        <div className='col background' style={{ position: 'sticky', top: 0 }}>
                                            List of Voucher Series
                                        </div>
                                        <div id='data'>
                                            {filteredData?.length > 0 ? (
                                                filteredData?.map((key, number) => {
                                                    const name = key?.series_name;
                                                    const index = name?.toLowerCase()?.indexOf(number_series_name?.toLowerCase());
                                                    return (
                                                        <Link
                                                            className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                            id='list'
                                                            key={number}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setSelectedItem(number)
                                                                if (number === selectedItem) {
                                                                    setState({ ...state, voucher_name: name })
                                                                    setshow_name_dropdown(false)
                                                                }
                                                            }}
                                                        >
                                                            {index !== -1 ? (
                                                                < >
                                                                    {name?.substring(0, index)}
                                                                    <span style={{ color: 'red' }}>{name?.substring(index, index + number_series_name.length)}</span>
                                                                    {name?.substring(index + number_series_name.length)}
                                                                </>
                                                            ) : (
                                                                name
                                                            )}
                                                        </Link>
                                                    );
                                                })
                                            ) : (
                                                <p style={{ color: 'red' }}>No matching items found</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}
export default VoucherTypeNameSelect;