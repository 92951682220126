import React, { useState, useEffect } from 'react'
import Footer from '../../components/home_page/Footer';
import { EyeSlash, Eye } from 'react-bootstrap-icons';
function BranchCreate() {
  const initialState = {
    branch_name: '',
    address: '',
    pincode:'',
    phone_no: '',
    e_mail: '',
    user_name: '',
    password: '',
    confirm_password: ''

  }
  const [state, setState] = useState(initialState);
  const [visible, setVisible] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    let updatedErrors = { ...errors };

    if (name === 'user_name') {
        updatedErrors.username = !validateUsername(value) ? 'Username should be at least 6 characters long' : '';
    } else if (name === 'password') {
        updatedErrors.password = !validatePassword(value) ? 'Password should be at least 6 characters long' : '';
    } else if (name === 'pincode') {
        updatedErrors.pincode = !validatePincode(value) ? 'Invalid pincode' : '';
    } else if (name === 'phone_no') {
        updatedErrors.mobile = !validateMobile(value) ? 'Invalid mobile number' : '';
    } else if (name === 'e_mail') {
        updatedErrors.email = !validateEmail(value) ? 'Invalid email' : '';
    }

    setErrors(updatedErrors);
  }
  const [errors, setErrors] = useState({
    pincode: '',
    mobile: '',
    email: '',
    username: '',
    password: ''
});

const validatePincode = (pincode) => {
    return /^\d{6}$/.test(pincode);
};

const validateMobile = (mobile) => {
    return /^[0-9]{10}$/.test(mobile);
};

const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const validateUsername = (username) => {
    return username.length >= 6; // Adjust as needed
};

const validatePassword = (password) => {
    return password.length >= 6; // Adjust as needed
};
  const {
    branch_name, address,pincode, phone_no, e_mail, user_name, password, confirm_password
  } = state;
  const handleClick = () => {
    setVisible(!visible);
  };
  const handleSubmit = () => {
    alert('hello')
  }
  return (
    <div className='section'>
      <div className='detail border'>
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form autoComplete='off' onSubmit={handleSubmit}>
              <div className='col-6 p-3' style={{ background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'>Branch Name</label>
                    <label> :</label>
                    <input type="text" name='branch_name' id='branch_name' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={branch_name || ""} />
                  </div>
                </div>
                <div className='border'></div>
                <div className='row mt-5'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Address </label>
                    <label> :</label>
                    <input type="text" name='address' id='address' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={address || ""} />
                  </div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Pincode </label>
                    <label> :</label>
                    <input type="text" name='pincode' id='pincode' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={pincode || ""} />
                  </div>
                  {errors.pincode && <span style={{ color: 'red' }}>{errors.pincode}</span>}
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Phone No</label>
                    <label> :</label>
                    <input type="number" name='phone_no' id='phone_no' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={phone_no || ""} />
                  </div>
                  {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>E-Mail </label>
                    <label> :</label>
                    <input type="email" name='e_mail' id='e_mail' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={e_mail || ""} />
                  </div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Username </label>
                    <label> :</label>
                    <input type="text" name='user_name' id='user_name' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={user_name || ""} />
                  </div>
                  {errors.username && <span style={{ color: 'red' }}>{errors.username}</span>}
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Password </label>
                    <label> :</label>
                    <div className='input'>
                      <input type={visible ? 'text' : 'password'} name='password' id='password' value={password || ""} autofocus="false" autoComplete='off'  className='input_field' onKeyUp={handleChange} onChange={handleChange} required />
                      <span className='input_icon-wrapper'>
                        {visible ? <Eye className='input_icon' onClick={() => { setVisible(false) }} /> : <EyeSlash className='input_icon' onClick={handleClick}/>}
                      </span>
                    </div>
                  </div>
                  {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Confirm Password </label>
                    <label> :</label>
                    <div className='input'>
                      <input type={visible ? 'text' : 'password'} name='confirm_password' id='confirm_password' value={confirm_password || ""} autofocus="false" autoComplete='off'className='input_field' onKeyUp={handleChange} onChange={handleChange} required />
                      <span className='input_icon-wrapper'>
                        {visible ? <Eye className='input_icon' onClick={() => { setVisible(false) }} /> : <EyeSlash className='input_icon' onClick={handleClick}/>}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tomasterfooter'><Footer /></div>
            </form>
          </div>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default BranchCreate
