import React, { useEffect, useRef, useState, forwardRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    numberSeriesCreate, numberSeriesList,
    numberSeriesSingleData, numberSeriesUpdate
} from '../../../../../redux/actions/action-creator';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import './define.css';
import Footer from '../../../../../components/home_page/Footer';
const methodOfNumberingArray =
    [
        { id: 1, name: 'Automatic' },
        { id: 2, name: 'Manual' },
        { id: 3, name: 'None' }
    ]
const yes_no_array =
    [
        { name: 'Yes' },
        { name: 'No' },
    ]
const number_beheviour =
    [
        { name: 'Retain Original Voucher No.' },
        { name: 'Renumber Vouchers' }
    ]
const DefineNumberSeries = forwardRef((props, ref_name) => {
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [numberModal, setNumberModal] = useState(false);
    const [all_data, setall_data] = useState([]);
    const [loading, setLoading] = useState(true)
    const [id, setId] = useState('')
    const [html_show, sethtml_show] = useState(false)
    const { userData = null } = useSelector(state => state.auth)
    // Fetch number series list ----------------------------
    useEffect(() => {
        const formData = new FormData();
        formData.append('voucher_type_name', props.name)
        formData.append('company_id', userData?.id)
        dispatch(numberSeriesList(formData)).then((response) => {
            if (response.status) {
                const newData = [...(response.data || [])];
                newData.unshift({ name: "Create Series" });
                setall_data(newData);
                setLoading(false)
            } else {
                console.log("response failed", response)
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [numberModal, props?.name])
    // Keyboard Functionality -----------------------------
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!numberModal) {
                if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    setSelectedIndex(prevState => Math.min(prevState + 1, all_data?.length - 1));
                } else if (event.key === 'ArrowUp') {
                    setSelectedIndex(prevState => Math.max(prevState - 1, 0));
                } else if (event.key === 'Enter') {
                    event.preventDefault();
                    const selectedLink = all_data[selectedIndex];
                    if (selectedLink) {
                        if (selectedLink?.name) {
                            setNumberModal(true)
                            sethtml_show(true)
                        }
                        if (selectedLink?.series_name) {
                            setId(selectedLink)
                            setNumberModal(true)
                        }
                    }
                }
                if (event.key === 'Escape') {
                    if (numberModal) { event.preventDefault(); }
                    else {
                        event.preventDefault();
                        props.setDefineNumberModal(false)
                        if (props.ref_name && props.ref_name.current) { props.ref_name?.current?.focus(); }
                        event.stopPropagation();
                    }
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [all_data, selectedIndex, numberModal]);
    const handleClickCloseDefineModal = (e) => {
        e.preventDefault();
        props.setDefineNumberModal(false)
        if (props.ref_name && props.ref_name.current) { props.ref_name?.current?.focus(); }
    }

    return (
        <div className='my-card'>
            <div className='cross_button' onClick={handleClickCloseDefineModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            {
                numberModal ?
                    <div style={{ position: 'absolute', width: '100%' }}>
                        <NumberSeriesDetails
                            setNumberModal={setNumberModal}
                            name={props.name}
                            numberModal={numberModal}
                            define={props.setDefineNumberModal}
                            id={id}
                            setId={setId}
                            html_show={html_show}
                            sethtml_show={sethtml_show}
                        />
                    </div>
                    : null
            }

            <form>
                <div className='row d-flex justify-content-center align-content-center'>
                    <div className='col-7 master_container_color' style={{ height: '90vh' }}>
                        <div className='row p-0 number_series_header'>
                            <p style={{ textAlign: 'left', }}>List of Number Series</p>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-6 name_series_left'>
                                        Series Name
                                    </div>
                                    <div className='col-6 method_voucher_num_right'>
                                        Method of voucher Numbering
                                    </div>
                                </div>
                            </div>
                            {
                                loading ? <h1></h1> :

                                    <div className='row m-0 p-0'>
                                        {all_data?.map((link, index) => (
                                            <div key={index} onClick={() => {
                                                return (
                                                    setSelectedIndex(index),
                                                    selectedIndex === index ? setNumberModal(true) : setNumberModal(false)
                                                )
                                            }} className={index === selectedIndex ? 'selected col-12' : ''}>
                                                <div className='row' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className='col-12'>
                                                        <Link className='number_series_created' style={link?.name === 'Create Series' ? { textAlign: 'right' } : null}>{link?.name}</Link>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Link className='number_series_created'>{link?.series_name}</Link>
                                                    </div>
                                                    <div className='col-6'>
                                                        <Link className='number_series_created'>{link?.method_of_voucher_numbering}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                            }
                            <div className='container-2 row' id='conditional_rendering'>
                                <div className='col d-block'>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    numberModal ? null :
                        <div className='tomasterfooter'> <Footer /></div>
                }
            </form>
        </div>
    )
});
export default DefineNumberSeries;

const NumberSeriesDetails = (props) => {


    const initialState = {
        series_name: '',
        method_of_voucher_numbering: 'Automatic',
        numbering_behaviour_on_insertion_deletion: 'Retain Original Voucher No.',
        set_alter_additional_numbering_details: 'No',
        prevent_creating_duplicate_voucher_nos: 'No',
        starting_number: '1',
        width_of_numerical_part: '0',
        prefill_with_zero: 'No',
        applicable_from_restart_numbering: '',
        starting_number_restarting_numbering: '',
        periodicity: '',
        applicable_from_prefix_details: '',
        particulars_prefix_details: '',
        applicable_from_suffix_details: '',
        particulars_suffix_details: '',
    }
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [numDetailsSeries, setNumDetailsSeries] = useState(false)
    const [methodShow, setMethodShow] = useState(false);
    const [numberBeheviour, setNumberBeheviour] = useState(false)
    const methodDropdownRef = useRef(null)
    const [seriesNameEntered, setSeriesNameEntered] = useState(false)
    const [selectedItem, setSelectedItem] = useState(0);
    const [backspaceCount, setBackspaceCount] = useState(0)

    const [setAlterAdditional, SetSetAlterAdditional] = useState(false)
    const [preventCreating, setPreventCreating] = useState(false)
    const [prefillWithZero, setPrefillWithZero] = useState(false)

    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataYesNo, setFilteredDataYesNo] = useState([])
    const [filteredDataMethodNumb, setFilteredDataMethodNumb] = useState([])
    const [filteredDataNumberBehav, setFilteredNumberBehav] = useState([])
    const [nameInput, setNameInput] = useState(null)


    const [Hash, sethashTag] = useState(false)
    const { userData = null } = useSelector(state => state.auth)
    const isMouseDownInside = useRef(false);
    const series_name_input_ref = useRef(null);
    const method_of_voucher_numbering_input_ref = useRef(null);
    const numbering_behaviour_on_insertion_deletion_input_ref = useRef(null);
    const set_alter_additional_numbering_details_input_ref = useRef(null);
    const prevent_creating_duplicate_voucher_nos_input_ref = useRef(null);
    const starting_number_input_ref = useRef(null);
    const width_of_numerical_part_input_ref = useRef(null);
    const prefill_with_zero_input_ref = useRef(null);
    const applicable_from_restart_numbering_input_ref = useRef(null);
    const starting_number_restarting_numbering_input_ref = useRef(null);
    const periodicity_input_ref = useRef(null);
    const applicable_from_prefix_details_input_ref = useRef(null);
    const particulars_prefix_details_input_ref = useRef(null);
    const applicable_from_suffix_details_input_ref = useRef(null);
    const particulars_suffix_details_input_ref = useRef(null);
    // Destructuring from state 
    const { series_name, method_of_voucher_numbering, numbering_behaviour_on_insertion_deletion,
        set_alter_additional_numbering_details, prevent_creating_duplicate_voucher_nos, starting_number,
        width_of_numerical_part, prefill_with_zero, applicable_from_restart_numbering, starting_number_restarting_numbering,
        periodicity, applicable_from_prefix_details, particulars_prefix_details, applicable_from_suffix_details, particulars_suffix_details
    } = state;

    const getNextInputRef = (name, value) => {
        switch (name) {
            case 'series_name':
                return method_of_voucher_numbering_input_ref;
            case 'method_of_voucher_numbering':
                return value === 'Automatic'
                    ? numbering_behaviour_on_insertion_deletion_input_ref
                    : prevent_creating_duplicate_voucher_nos_input_ref;
            case 'numbering_behaviour_on_insertion_deletion':
                return set_alter_additional_numbering_details_input_ref;
            case 'starting_number':
                return width_of_numerical_part_input_ref;
            case 'width_of_numerical_part':
                return prefill_with_zero_input_ref;
            case 'prefill_with_zero':
                return applicable_from_restart_numbering_input_ref;
            case 'applicable_from_restart_numbering':
                return starting_number_restarting_numbering_input_ref;
            case 'starting_number_restarting_numbering':
                return periodicity_input_ref;
            case 'periodicity':
                return applicable_from_prefix_details_input_ref;
            case 'applicable_from_prefix_details':
                return particulars_prefix_details_input_ref;
            case 'particulars_prefix_details':
                return applicable_from_suffix_details_input_ref;
            case 'applicable_from_suffix_details':
                return particulars_suffix_details_input_ref;
            default:
                return null;
        }
    }

    const getPrevInputRef = (name) => {
        switch (name) {
            case 'method_of_voucher_numbering':
                return series_name_input_ref;
            case 'numbering_behaviour_on_insertion_deletion':
                return method_of_voucher_numbering_input_ref;
            case 'prevent_creating_duplicate_voucher_nos':
                return method_of_voucher_numbering_input_ref;
            case 'set_alter_additional_numbering_details':
                return numbering_behaviour_on_insertion_deletion_input_ref;
            case 'width_of_numerical_part':
                return starting_number_input_ref;
            case 'prefill_with_zero':
                return width_of_numerical_part_input_ref;
            case 'applicable_from_restart_numbering':
                return prefill_with_zero_input_ref;
            case 'starting_number_restarting_numbering':
                return applicable_from_restart_numbering_input_ref;
            case 'periodicity':
                return starting_number_restarting_numbering_input_ref;
            case 'applicable_from_prefix_details':
                return periodicity_input_ref;
            case 'particulars_prefix_details':
                return applicable_from_prefix_details_input_ref;
            case 'applicable_from_suffix_details':
                return particulars_prefix_details_input_ref;
            case 'particulars_suffix_details':
                return applicable_from_suffix_details_input_ref;
            default:
                return starting_number_input_ref;
        }
    };

    // When Focus on method input while it trigger
    const handleFocus = (e) => {
        const { name, value } = e.target;
        if (name === 'method_of_voucher_numbering') {
            setMethodShow(true);
            const index = methodOfNumberingArray.findIndex(
                item => item.name?.toLowerCase() == state?.method_of_voucher_numbering?.toLowerCase());
            setSelectedItem(index)
        }
        if (name === 'numbering_behaviour_on_insertion_deletion') {
            setNumberBeheviour(true)
        }

    };
    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'method_of_voucher_numbering') {
            setMethodShow(false)
        }
        if (name === 'numbering_behaviour_on_insertion_deletion') {
            setNumberBeheviour(false)
        }
        if (name === 'prevent_creating_duplicate_voucher_nos') {
            setPreventCreating(false)
            const normalizedValue = value.toLowerCase().trim();
            const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
            const validPatternNo = /^(n|o|no)?$/;
            if (normalizedValue === '') {
                setState({ ...state, [name]: 'No' })
            } else if (validPatternNo.test(normalizedValue)) {
                setState({ ...state, [name]: 'No' })
            } else if (validPatternYes.test(normalizedValue)) {
                setState({ ...state, [name]: 'Yes' })
            }
        }
        if (name === 'set_alter_additional_numbering_details') {
            SetSetAlterAdditional(false)
            const normalizedValue = value.toLowerCase().trim();
            const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
            const validPatternNo = /^(n|o|no)?$/;
            if (normalizedValue === '') {
                setState({ ...state, [name]: 'No' })
            } else if (validPatternNo.test(normalizedValue)) {
                setState({ ...state, [name]: 'No' })
            } else if (validPatternYes.test(normalizedValue)) {
                setState({ ...state, [name]: 'Yes' })
            }
        }
        if (name === 'prefill_with_zero') {
            setPrefillWithZero(false)
            const normalizedValue = value.toLowerCase().trim();
            const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
            const validPatternNo = /^(n|o|no)?$/;
            if (normalizedValue === '') {
                setState({ ...state, [name]: 'No' })
            } else if (validPatternNo.test(normalizedValue)) {
                setState({ ...state, [name]: 'No' })
            } else if (validPatternYes.test(normalizedValue)) {
                setState({ ...state, [name]: 'Yes' })
            }
        }
        if (name === 'starting_number') {
            if (/^\d*$/.test(value) || value === '') {
                setState({ ...state, [name]: value });
            } else {
                starting_number_input_ref?.current?.focus();
            }
        }
        if (name === 'width_of_numerical_part') {
            if (/^\d*$/.test(value) || value === '') {
                setState({ ...state, [name]: value });
            } else {
                width_of_numerical_part_input_ref?.current?.focus();
            }
        }

    }
    // Get value from input field ------------------
    const handleChange = (e) => {
        const { name, value } = e.target;
        const capitalizeFirstLetter = (str) => {
            return str?.toLowerCase()?.replace(/(^|\s)\S/g,
                (firstLetter) => firstLetter?.toUpperCase());
        };
        if (name === 'series_name') {
            setSeriesNameEntered(!!value?.trim());
        }
        // space key functionality
        if (e.code == 'Space') {
            e.preventDefault();
            const cursorPosition_start = e?.target?.selectionStart;
            if (cursorPosition_start == 1) {
                setState({ ...state, [name]: '' });
                return;
            }
        }
        // Enter key functionality
        if (e.key === 'Enter' && state?.series_name !== '') {
            e.preventDefault();
            const nextInputRef = getNextInputRef(name, value)
            if (nextInputRef) {
                nextInputRef?.current?.focus();
                if (nextInputRef?.current) {
                    nextInputRef?.current?.setSelectionRange(0, 0);
                }
            }
            if (name === 'prevent_creating_duplicate_voucher_nos') {
                setPreventCreating(false)
                const normalizedValue = value.toLowerCase().trim();
                const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
                const validPatternNo = /^(n|o|no)?$/;
                if (normalizedValue === '') {
                    setState({ ...state, [name]: 'No' })
                } else if (validPatternNo.test(normalizedValue)) {
                    setState({ ...state, [name]: 'No' })
                } else if (validPatternYes.test(normalizedValue)) {
                    setState({ ...state, [name]: 'Yes' })
                }
            }
            if (name === 'set_alter_additional_numbering_details') {
                SetSetAlterAdditional(false)
                const normalizedValue = value.toLowerCase().trim();
                const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
                const validPatternNo = /^(n|o|no)?$/;
                if (normalizedValue === '') {
                    setState({ ...state, [name]: 'No' })
                    controlPlusASubmit();
                    props.setNumberModal(false)
                } else if (validPatternNo.test(normalizedValue)) {
                    setState({ ...state, [name]: 'No' })
                    controlPlusASubmit();
                    props.setNumberModal(false)
                } else if (validPatternYes.test(normalizedValue)) {
                    setState({ ...state, [name]: 'Yes' })
                    setNumDetailsSeries(true)
                }
            }
            if (name === 'prefill_with_zero') {
                setPrefillWithZero(false)
                const normalizedValue = value.toLowerCase().trim();
                const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
                const validPatternNo = /^(n|o|no)?$/;
                if (normalizedValue === '') {
                    setState({ ...state, [name]: 'No' })
                } else if (validPatternNo.test(normalizedValue)) {
                    setState({ ...state, [name]: 'No' })
                } else if (validPatternYes.test(normalizedValue)) {
                    setState({ ...state, [name]: 'Yes' })
                }
            }
            if (name === 'prevent_creating_duplicate_voucher_nos') {
                controlPlusASubmit();
                props.setNumberModal(false)
            }
            if (name === 'particulars_suffix_details') {
                controlPlusASubmit();
                setNumDetailsSeries(false)
                props.setNumberModal(false)

            }
            return;
        }
        // Back space key functionality
        if (e.key === 'Backspace') {
            e.preventDefault();
            const prevInputRef = getPrevInputRef(name)
            const cursorPosition = e.target.selectionStart;
            if (cursorPosition === 0) {
                if (backspaceCount === 1) {
                    setBackspaceCount(0);
                    if (prevInputRef && prevInputRef.current) {
                        prevInputRef.current.focus();
                        prevInputRef.current.setSelectionRange(0, 0);
                    }
                } else {
                    if (cursorPosition === 0 && value) {
                        if (prevInputRef && prevInputRef.current) {
                            prevInputRef.current.focus();
                            prevInputRef.current.setSelectionRange(0, 0);
                        }
                    } else {
                        setBackspaceCount(backspaceCount + 1);
                    }
                }
            }
            return;
        }

        // Set data in state accourding to name and validate the value -------
        const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        switch (name) {
            case 'method_of_voucher_numbering':
                const group = methodOfNumberingArray.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
                if (group) {
                    setState({ ...state, [name]: value })
                }
                break;
            case 'numbering_behaviour_on_insertion_deletion':
                const nat_group = number_beheviour.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
                if (nat_group) {
                    setState({ ...state, [name]: value })
                }
                break;
            case 'set_alter_additional_numbering_details':
                const group_behaves = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
                if (group_behaves) {
                    setState({ ...state, [name]: value })
                } else {
                    SetSetAlterAdditional(true)
                }
                break;
            case 'prevent_creating_duplicate_voucher_nos':
                const nett_debit = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
                if (nett_debit) {
                    setState({ ...state, [name]: value })
                } else {
                    setPreventCreating(true)
                }
                break;
            case 'prefill_with_zero':
                const used_for = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
                if (used_for) {
                    setState({ ...state, [name]: value })
                } else {
                    setPrefillWithZero(true)
                }
                break;
            default:
                const updatedValue = name === 'series_name' ?
                    capitalizeFirstLetter(value) : value;
                setState({ ...state, [name]: updatedValue })
                break;
        }
    }
    // If have id then fetch data according to id of number series
    useEffect(() => {
        if (props?.id?.id) {
            setTimeout(() => {
                setState({
                    ...state,
                    series_name: props?.id?.series_name,
                    method_of_voucher_numbering: props?.id?.method_of_voucher_numbering,
                    numbering_behaviour_on_insertion_deletion: props?.id?.numbering_behaviour ? props?.id?.numbering_behaviour : state?.numbering_behaviour_on_insertion_deletion,
                    set_alter_additional_numbering_details: props?.id?.set_additional_numbering ? props?.id?.set_additional_numbering : state?.set_alter_additional_numbering_details,
                    prevent_creating_duplicate_voucher_nos: props?.id?.prevent_creating_duplicate_voucher ? props?.id?.prevent_creating_duplicate_voucher : state?.prevent_creating_duplicate_voucher_nos,
                    starting_number: props?.id?.starting_number ? props?.id?.starting_number : state?.starting_number,
                    width_of_numerical_part: props?.id?.width_of_numerical_part ? props?.id?.starting_number : state?.width_of_numerical_part,
                    prefill_with_zero: props?.id?.prefill_with_zero ? props?.id?.prefill_with_zero : state?.prefill_with_zero,
                    applicable_from_restart_numbering: props?.id?.applicable_from ? props?.id?.applicable_from === '1970-01-01' ? '' : props?.id?.applicable_from : state?.applicable_from_restart_numbering,
                    starting_number_restarting_numbering: props?.id?.starting_number_2 ? props?.id?.starting_number_2 : state?.starting_number_restarting_numbering,
                    periodicity: props?.id?.periodicity ? props?.id?.periodicity : state?.periodicity,
                    applicable_from_prefix_details: props?.id?.applicable_from_prefix ? props?.id?.applicable_from_prefix === '1970-01-01' ? '' : props?.id?.applicable_from_prefix : state?.applicable_from_prefix_details,
                    particulars_prefix_details: props?.id?.prefix_particular ? props?.id?.prefix_particular : state?.particulars_prefix_details,
                    applicable_from_suffix_details: props?.id?.applicable_from_suffix ? props?.id?.applicable_from_suffix === '1970-01-01' ? '' : props?.id?.applicable_from_suffix : state?.applicable_from_suffix_details,
                    particulars_suffix_details: props?.id?.suffix_particular ? props?.id?.suffix_particular : state?.particulars_suffix_details,
                })
                props?.sethtml_show(true)
            }, 150);

            // const formdata = new FormData();
            // formdata.append('id', props?.id?.id)
            // dispatch(numberSeriesSingleData(formdata)).then((response) => {
            //     if (response.status) {
            //         setState({
            //             ...state,
            //             series_name: response?.data?.series_name,
            //             method_of_voucher_numbering: response?.data?.method_of_voucher_numbering,
            //             numbering_behaviour_on_insertion_deletion: response?.data?.numbering_behaviour ? response?.data?.numbering_behaviour : state?.numbering_behaviour_on_insertion_deletion,
            //             set_alter_additional_numbering_details: response?.data?.set_additional_numbering ? response?.data?.set_additional_numbering : state?.set_alter_additional_numbering_details,
            //             prevent_creating_duplicate_voucher_nos: response?.data?.prevent_creating_duplicate_voucher ? response?.data?.prevent_creating_duplicate_voucher : state?.prevent_creating_duplicate_voucher_nos,
            //             starting_number: response?.data?.starting_number ? response?.data?.starting_number : state?.starting_number,
            //             width_of_numerical_part: response?.data?.width_of_numerical_part ? response?.data?.starting_number : state?.width_of_numerical_part,
            //             prefill_with_zero: response?.data?.prefill_with_zero ? response?.data?.prefill_with_zero : state?.prefill_with_zero,
            //             applicable_from_restart_numbering: response?.data?.applicable_from ? response?.data?.applicable_from === '1970-01-01' ? '' : response?.data?.applicable_from : state?.applicable_from_restart_numbering,
            //             starting_number_restarting_numbering: response?.data?.starting_number_2 ? response?.data?.starting_number_2 : state?.starting_number_restarting_numbering,
            //             periodicity: response?.data?.periodicity ? response?.data?.periodicity : state?.periodicity,
            //             applicable_from_prefix_details: response?.data?.applicable_from_prefix ? response?.data?.applicable_from_prefix === '1970-01-01' ? '' : response?.data?.applicable_from_prefix : state?.applicable_from_prefix_details,
            //             particulars_prefix_details: response?.data?.prefix_particular ? response?.data?.prefix_particular : state?.particulars_prefix_details,
            //             applicable_from_suffix_details: response?.data?.applicable_from_suffix ? response?.data?.applicable_from_suffix === '1970-01-01' ? '' : response?.data?.applicable_from_suffix : state?.applicable_from_suffix_details,
            //             particulars_suffix_details: response?.data?.suffix_particular ? response?.data?.suffix_particular : state?.particulars_suffix_details,
            //         })
            //     } else {
            //         console.log("response failed", response)
            //     }
            // }).catch((error) => {
            //     console.log(error)
            // })
        }
    }, [props?.id?.id])
    //triger when the change value of search input and get filter data -------------
    useEffect(() => {
        const filteredSuggestions = methodOfNumberingArray?.filter((item, index) => {
            const lowercaseLedgerName = item?.name?.toLowerCase();
            const lowercaseSearchInput = state?.method_of_voucher_numbering?.toLowerCase();
            const matchesSearchInput = lowercaseLedgerName?.includes(state.method_of_voucher_numbering !== '' ? lowercaseSearchInput : '');
            return matchesSearchInput;
        })
        setFilteredData(state?.method_of_voucher_numbering !== '' ? method_of_voucher_numbering_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : methodOfNumberingArray : methodOfNumberingArray);
        if (filteredSuggestions?.length > 0 && method_of_voucher_numbering_input_ref?.current?.selectionStart > 0) {
            setSelectedItem(0);
        }
    }, [state?.method_of_voucher_numbering, methodOfNumberingArray, method_of_voucher_numbering_input_ref?.current?.selectionStart])

    useEffect(() => {
        const filteredSuggestions = yes_no_array?.filter((item, index) => {
            const lowercaseLedgerName = item?.name?.toLowerCase();
            const lowercaseSearchInput = (setAlterAdditional ? state?.set_alter_additional_numbering_details : state?.prefill_with_zero)?.toLowerCase();
            const matchesSearchInput = lowercaseLedgerName?.includes((setAlterAdditional ? state?.set_alter_additional_numbering_details : state?.prefill_with_zero) !== '' ? lowercaseSearchInput : '');
            return matchesSearchInput;
        })
        setFilteredDataYesNo((setAlterAdditional ? state?.set_alter_additional_numbering_details : state?.prefill_with_zero) !== '' ? (setAlterAdditional ? set_alter_additional_numbering_details_input_ref : prefill_with_zero_input_ref)?.current?.selectionStart > 0 ? filteredSuggestions : yes_no_array : yes_no_array);
        if (filteredSuggestions?.length > 0 && (setAlterAdditional ? set_alter_additional_numbering_details_input_ref : prefill_with_zero_input_ref)?.current?.selectionStart > 0) {
            setSelectedItem(0);
        }
    }, [state.set_alter_additional_numbering_details, prefill_with_zero, yes_no_array])

    useEffect(() => {
        const filteredSuggestions = number_beheviour?.filter((item, index) => {
            const lowercaseLedgerName = item?.name?.toLowerCase();
            const lowercaseSearchInput = state?.numbering_behaviour_on_insertion_deletion?.toLowerCase();
            const matchesSearchInput = lowercaseLedgerName?.includes(state?.numbering_behaviour_on_insertion_deletion !== '' ? lowercaseSearchInput : '');
            return matchesSearchInput;
        })
        setFilteredNumberBehav(state?.numbering_behaviour_on_insertion_deletion !== '' ? numbering_behaviour_on_insertion_deletion_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : number_beheviour : number_beheviour);
        if (filteredSuggestions?.length > 0 && numbering_behaviour_on_insertion_deletion_input_ref?.current?.selectionStart > 0) {
            setSelectedItem(0);
        }
    }, [state.numbering_behaviour_on_insertion_deletion, number_beheviour])
    // Keyboard Functionality


    const handleClickOutside = (event) => {
        let clickedElement = event.target;
        let isInputField = false;
        while (clickedElement) {
            if ((clickedElement.tagName &&
                (clickedElement.tagName.toLowerCase() === 'input' ||
                    clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
                isInputField = true;
                break;
            }
            clickedElement = clickedElement.parentNode;
        }

        if (!isInputField) {
            if (nameInput && nameInput.current) {
                event.preventDefault();
                console.log("this is name input :---", nameInput)
                nameInput.current.focus();
            }
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [nameInput])


    useEffect(() => {
        const handleClickOutside = (event) => {
            let clickedElement = event.target;
            let isInputField = false;
            while (clickedElement) {
                if ((clickedElement.tagName &&
                    (clickedElement.tagName.toLowerCase() === 'input' ||
                        clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
                    isInputField = true;
                    break;
                }
                clickedElement = clickedElement.parentNode;
            }
            if (!isInputField) {
                if (nameInput && nameInput.current) {
                    event.preventDefault();
                    nameInput.current.focus();
                }
            }
        };

        const handleMouseDown = () => {
            isMouseDownInside.current = true;
        };

        const handleMouseUp = () => {
            isMouseDownInside.current = false;
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [nameInput]);

    const handleKeyDown = useCallback((e) => {
        if (setAlterAdditional || preventCreating || prefillWithZero || methodShow || numberBeheviour) {
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedItem(prevState => Math.max(prevState - 1, 0));
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedItem(prevState => Math.min(prevState + 1, (prefillWithZero || preventCreating || setAlterAdditional ? filteredDataYesNo.length : numberBeheviour ? filteredDataNumberBehav.length : filteredData.length) - 1));
            }
            else if (e.key === 'Enter') {
                e.preventDefault();
                const selectedLink = prefillWithZero || preventCreating || setAlterAdditional ? filteredDataYesNo[selectedItem] : numberBeheviour ? filteredDataNumberBehav[selectedItem] : filteredData[selectedItem]
                if (methodShow) {
                    if (selectedLink) {
                        setState({ ...state, method_of_voucher_numbering: selectedLink.name })
                        setMethodShow(false)
                    }
                }
                if (numberBeheviour) {
                    if (selectedLink) {
                        setState({ ...state, numbering_behaviour_on_insertion_deletion: selectedLink.name })
                        setNumberBeheviour(false)
                    }
                }
                if (preventCreating) {
                    if (selectedLink) {
                        setState({ ...state, prevent_creating_duplicate_voucher_nos: selectedLink.name })
                        setPreventCreating(false)
                    }
                }
                if (setAlterAdditional) {
                    if (selectedLink) {
                        setState({ ...state, set_alter_additional_numbering_details: selectedLink.name })
                        setAlterAdditional(false)
                    }
                }
                if (prefillWithZero) {
                    if (selectedLink) {
                        setState({ ...state, prefill_with_zero: selectedLink.name })
                        setPrefillWithZero(false)
                    }
                }
            }
        }
        if (props.numberModal) {
            if (e.key === 'Escape') {
                e.preventDefault();
                if (numDetailsSeries) {
                    if (e.key === 'Escape') {
                        e.preventDefault();
                        setNumDetailsSeries(false)
                        e.stopPropagation();
                        set_alter_additional_numbering_details_input_ref?.current?.focus();
                        set_alter_additional_numbering_details_input_ref?.current?.setSelectionRange(0, 0);
                    }
                } else {
                    props.setNumberModal(false)
                    setState(initialState)
                    props.setId('')
                    props.sethtml_show(false)
                    e.stopPropagation();
                }

            }
        }

    }, [selectedItem, filteredData, filteredDataMethodNumb, filteredDataNumberBehav, filteredDataYesNo, methodShow, preventCreating, numberBeheviour, setAlterAdditional, prefillWithZero, numDetailsSeries])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    //---------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'a' && e.ctrlKey) {
                e.preventDefault();
                controlPlusASubmit();
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [state?.series_name])

    const handleCrossClick = (e) => {
        e.preventDefault();
        setNumDetailsSeries(false)
        set_alter_additional_numbering_details_input_ref?.current?.focus();
    }
    // submit form by ctr + a -------------------------------------------------
    const controlPlusASubmit = async () => {
        if (validateForm()) {
            try {
                const data = new FormData();
                data.append('company_id', userData?.id);
                data.append('voucher_type_name', props.name);
                data.append('series_name', state.series_name);
                data.append('method_of_voucher_numbering', state.method_of_voucher_numbering);
                data.append('numbering_behaviour', state.method_of_voucher_numbering === 'Manual' ? '' : state.numbering_behaviour_on_insertion_deletion);
                data.append('set_additional_numbering', state.method_of_voucher_numbering === 'Manual' ? '' : state.set_alter_additional_numbering_details);
                data.append('starting_number', state.method_of_voucher_numbering === 'Manual' ? '' : state.starting_number);
                data.append('width_of_numerical_part', state.method_of_voucher_numbering === 'Manual' ? '' : state.width_of_numerical_part);
                data.append('prefill_with_zero', state.method_of_voucher_numbering === 'Manual' ? '' : state.prefill_with_zero);
                data.append('applicable_from', state.method_of_voucher_numbering === 'Manual' ? '' : state.applicable_from_restart_numbering);
                data.append('starting_number_2', state.method_of_voucher_numbering === 'Manual' ? '' : state.starting_number_restarting_numbering);
                data.append('periodicity', state.method_of_voucher_numbering === 'Manual' ? '' : state.periodicity);
                data.append('applicable_from_prefix', state.method_of_voucher_numbering === 'Manual' ? '' : state.applicable_from_prefix_details);
                data.append('prefix_particular', state.method_of_voucher_numbering === 'Manual' ? '' : state.particulars_prefix_details);
                data.append('applicable_from_suffix', state.method_of_voucher_numbering === 'Manual' ? '' : state.applicable_from_suffix_details);
                data.append('suffix_particular', state.method_of_voucher_numbering === 'Manual' ? '' : state.particulars_suffix_details);
                data.append('prevent_creating_duplicate_voucher', state.method_of_voucher_numbering === 'Manual' ? state.prevent_creating_duplicate_voucher_nos : '');
                if (props?.id?.id) {
                    data.append('id', props?.id?.id)
                    const response = await dispatch(numberSeriesUpdate(data));
                    if (response.status) {
                        toast.success(response?.message || 'Group created!', {
                            position: toast.POSITION.TOP_CENTER
                        });
                        const formData = new FormData();
                        formData.append('voucher_type_name', props.name)
                        formData.append('company_id', userData?.id)
                        dispatch(numberSeriesList(formData))
                        resetForm();
                        props?.setId('')
                    } else {
                        toast.warn(response?.message || 'Fail!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } else {
                    const response = await dispatch(numberSeriesCreate(data));
                    if (response.status) {
                        toast.success(response?.message || 'Group created!', {
                            position: toast.POSITION.TOP_CENTER
                        });
                        const formData = new FormData();
                        formData.append('voucher_type_name', props.name)
                        formData.append('company_id', userData?.id)
                        dispatch(numberSeriesList(formData))
                        resetForm();
                    } else {
                        toast.warn(response?.message || 'Fail!', {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                }

            } catch (error) {
                console.log(error);
                toast.error(error?.message || 'Failed!', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            toast.error('Please fill all the fields.', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };
    // Submit by button 
    const numberSeriesSubmit = async (e) => {
        e.preventDefault();
        if (Hash) {
            if (validateForm()) {
                let data = new FormData();
                data.append('company_id', userData?.id);
                data.append('voucher_type_name', props.name);
                data.append('series_name', state.series_name);
                data.append('method_of_voucher_numbering', state.method_of_voucher_numbering);
                data.append('numbering_behaviour', state.method_of_voucher_numbering === 'Manual' ? '' : state.numbering_behaviour_on_insertion_deletion);
                data.append('set_additional_numbering', state.method_of_voucher_numbering === 'Manual' ? '' : state.set_alter_additional_numbering_details);
                data.append('starting_number', state.method_of_voucher_numbering === 'Manual' ? '' : state.starting_number);
                data.append('width_of_numerical_part', state.method_of_voucher_numbering === 'Manual' ? '' : state.width_of_numerical_part);
                data.append('prefill_with_zero', state.method_of_voucher_numbering === 'Manual' ? '' : state.prefill_with_zero);
                data.append('applicable_from', state.method_of_voucher_numbering === 'Manual' ? '' : state.applicable_from_restart_numbering);
                data.append('starting_number_2', state.method_of_voucher_numbering === 'Manual' ? '' : state.starting_number_restarting_numbering);
                data.append('periodicity', state.method_of_voucher_numbering === 'Manual' ? '' : state.periodicity);
                data.append('applicable_from_prefix', state.method_of_voucher_numbering === 'Manual' ? '' : state.applicable_from_prefix_details);
                data.append('prefix_particular', state.method_of_voucher_numbering === 'Manual' ? '' : state.particulars_prefix_details);
                data.append('applicable_from_suffix', state.method_of_voucher_numbering === 'Manual' ? '' : state.applicable_from_suffix_details);
                data.append('suffix_particular', state.method_of_voucher_numbering === 'Manual' ? '' : state.particulars_suffix_details);
                data.append('prevent_creating_duplicate_voucher', state.method_of_voucher_numbering === 'Manual' ? state.prevent_creating_duplicate_voucher_nos : '');
                await dispatch(numberSeriesCreate(data)).then((response) => {
                    if (response.status) {
                        toast.success(response?.message || 'group created!', {
                            position: toast.POSITION.TOP_CENTER
                        });
                        const formData = new FormData();
                        formData.append('voucher_type_name', props.name)
                        formData.append('company_id', userData?.id)
                        dispatch(numberSeriesList(formData))
                        resetForm();
                        props?.setId('')
                    }
                    else {
                        toast.warn(response?.message || 'Fail !', {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                }).catch(error => {
                    console.log(error)
                    toast.error(error?.message || ' Failed!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                })
            }
        }
    }

    // Function to validate form fields---------------------------------------
    const validateForm = () => {
        const condition1 = state?.method_of_voucher_numbering === 'Automatic'
        const condition3 =
            state?.numbering_behaviour_on_insertion_deletion !== '' &&
            state?.set_alter_additional_numbering_details !== '' &&
            state?.starting_number !== '' &&
            state?.width_of_numerical_part !== '' &&
            state?.prefill_with_zero !== ''
        const condition4 = state?.prevent_creating_duplicate_voucher_nos !== '';
        return (
            userData?.id !== '' &&
                state?.series_name !== '' &&
                state.method_of_voucher_numbering !== '' &&
                props.name !== '' &&
                condition1 ? condition3 : condition4

        );
    };
    // Function to reset form fields-----------------------------------------
    const resetForm = () => {
        setState(prevState => ({
            ...prevState,
            series_name: '',
            method_of_voucher_numbering: 'Automatic',
            numbering_behaviour_on_insertion_deletion: 'Retain Original Voucher No.',
            set_alter_additional_numbering_details: 'No',
            prevent_creating_duplicate_voucher_nos: '',
            starting_number: '1',
            width_of_numerical_part: '0',
            prefill_with_zero: 'No',
            applicable_from_restart_numbering: '',
            starting_number_restarting_numbering: '',
            periodicity: '',
            applicable_from_prefix_details: '',
            particulars_prefix_details: '',
            applicable_from_suffix_details: '',
            particulars_suffix_details: '',
        }));
    };



    return (
        props?.html_show ?
            <div className='my-card'>
                <div className='cross_button' onClick={() => { return (props.setNumberModal(false)) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                {
                    numDetailsSeries ?
                        <SetAlterNumberDetails
                            starting_number={starting_number}
                            prefillWithZero={prefillWithZero}
                            filteredDataYesNo={filteredDataYesNo}
                            handleCrossClick={handleCrossClick}
                            width_of_numerical_part={width_of_numerical_part}
                            prefill_with_zero={prefill_with_zero}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            setState={setState}
                            state={state}
                            applicable_from_restart_numbering={applicable_from_restart_numbering}
                            starting_number_restarting_numbering={starting_number_restarting_numbering}
                            periodicity={periodicity}
                            applicable_from_prefix_details={applicable_from_prefix_details}
                            particulars_prefix_details={particulars_prefix_details}
                            applicable_from_suffix_details={applicable_from_suffix_details}
                            particulars_suffix_details={particulars_suffix_details}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setNumDetailsSeries={setNumDetailsSeries}
                            starting_number_input_ref={starting_number_input_ref}
                            width_of_numerical_part_input_ref={width_of_numerical_part_input_ref}
                            prefill_with_zero_input_ref={prefill_with_zero_input_ref}
                            applicable_from_restart_numbering_input_ref={applicable_from_restart_numbering_input_ref}
                            starting_number_restarting_numbering_input_ref={starting_number_restarting_numbering_input_ref}
                            periodicity_input_ref={periodicity_input_ref}
                            applicable_from_prefix_details_input_ref={applicable_from_prefix_details_input_ref}
                            particulars_prefix_details_input_ref={particulars_prefix_details_input_ref}
                            applicable_from_suffix_details_input_ref={applicable_from_suffix_details_input_ref}
                            particulars_suffix_details_input_ref={particulars_suffix_details_input_ref}
                        /> : null
                }
                <form autoComplete='off' onSubmit={numberSeriesSubmit}>
                    <div className='row number_series_create_form'>
                        <div className='col-12 number_series_create_form_heading'>
                            Numbering Series Details
                        </div>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1 text-truncate'>Series Name </label>
                                <label> :</label>
                                <input
                                    type="text"
                                    autoFocus
                                    name='series_name'
                                    id='series_name'
                                    className={`lab-right`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={series_name_input_ref}
                                    value={series_name || ""} />
                            </div>
                            <div className='d-flex justify-content-between align-item-center position-relative'>
                                <label className='lab-1 text-truncate'>Method of Vocher Numbering </label>
                                <label> :</label>
                                <input
                                    type="text"
                                    name='method_of_voucher_numbering'
                                    id='method_of_voucher_numbering'
                                    className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={method_of_voucher_numbering_input_ref}
                                    value={method_of_voucher_numbering || ""} />
                                <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-method-numbering-component ${methodShow ? 'show-list' : ''}`} ref={methodDropdownRef}>
                                    <div className='col' style={{ background: '#162070', color: '#fff', position: 'sticky', top: 0 }}>
                                        Methods of Numbering
                                    </div>
                                    <div id='data'>
                                        {filteredData.length > 0 ? (
                                            filteredData.map((key, number) => {
                                                const name = key?.name;
                                                const index = name.toLowerCase().indexOf(method_of_voucher_numbering.toLowerCase());
                                                return (
                                                    <Link
                                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                        id='list'
                                                        key={number}
                                                        onClick={() => {
                                                            setSelectedItem(number)
                                                            if (number === selectedItem) {
                                                                setState({ ...state, under: name })
                                                                setMethodShow(false)
                                                            }
                                                        }}
                                                    >
                                                        {index !== -1 ? (
                                                            < >
                                                                {name.substring(0, index)}
                                                                <span style={{ color: 'red' }}>{name.substring(index, index + method_of_voucher_numbering.length)}</span>
                                                                {name.substring(index + method_of_voucher_numbering.length)}
                                                            </>
                                                        ) : (
                                                            name
                                                        )}
                                                    </Link>
                                                );
                                            })
                                        ) : (
                                            <p style={{ color: 'red' }}>No matching items found</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {
                                method_of_voucher_numbering === 'Automatic' ?
                                    <div>
                                        <div className='d-flex justify-content-between align-item-center position-relative'>
                                            <label className='lab-1 text-truncate'>Numbering behaviour on insertion/deletion </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='numbering_behaviour_on_insertion_deletion'
                                                id='numbering_behaviour_on_insertion_deletion'
                                                className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                ref={numbering_behaviour_on_insertion_deletion_input_ref}
                                                value={numbering_behaviour_on_insertion_deletion || ""} />
                                            <div id="myDropdown-list" className={`dropdown_number_behaviour ${numberBeheviour ? 'show-list' : ''}`}>
                                                <div className='col dropdown_header'>
                                                    Numberning Behaviours
                                                </div>
                                                <div id='data'>
                                                    {filteredDataNumberBehav?.length > 0 ? (
                                                        filteredDataNumberBehav?.map((key, number) => {
                                                            const name = key?.name;
                                                            const id = key?.id;
                                                            const index = name?.toLowerCase()?.indexOf(numbering_behaviour_on_insertion_deletion?.toLowerCase());
                                                            return (
                                                                <Link
                                                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                                    id='list'
                                                                    key={number}
                                                                    onClick={() => {
                                                                        setSelectedItem(number)
                                                                        if (number === selectedItem) {
                                                                            setState({ ...state, numbering_behaviour_on_insertion_deletion: name })
                                                                            // define_multiple_numbering_series_for_vouchers.current.focus();
                                                                            // define_multiple_numbering_series_for_vouchers.current.setSelectionRange(0, 0);
                                                                            // setActiveThis(false)
                                                                        }
                                                                    }}
                                                                >
                                                                    {index !== -1 ? (
                                                                        < >
                                                                            {name.substring(0, index)}
                                                                            <span style={{ color: 'red' }}>{name.substring(index, index + numbering_behaviour_on_insertion_deletion.length)}</span>
                                                                            {name.substring(index + numbering_behaviour_on_insertion_deletion.length)}
                                                                        </>
                                                                    ) : (
                                                                        name
                                                                    )}
                                                                </Link>
                                                            );
                                                        })
                                                    ) : (
                                                        <p style={{ color: 'red' }}>No matching items found</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center position-relative'>
                                            <label className='lab-1 text-truncate'>Set/Alter Additional numbering Details </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='set_alter_additional_numbering_details'
                                                id='set_alter_additional_numbering_details'
                                                className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                ref={set_alter_additional_numbering_details_input_ref}
                                                value={set_alter_additional_numbering_details || ""} />
                                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${setAlterAdditional ? 'show-list' : ''}`}>
                                                <div className='col dropdown_header'>
                                                    Yes / No
                                                </div>
                                                <div id='data'>
                                                    {filteredDataYesNo?.length > 0 ? (
                                                        filteredDataYesNo?.map((key, number) => {
                                                            const name = key?.name;
                                                            const id = key?.id;
                                                            const index = name?.toLowerCase()?.indexOf(set_alter_additional_numbering_details?.toLowerCase());
                                                            return (
                                                                <Link
                                                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                                    id='list'
                                                                    key={number}
                                                                    onClick={() => {
                                                                        setSelectedItem(number)
                                                                        if (number === selectedItem) {
                                                                            setState({ ...state, set_alter_additional_numbering_details: name })
                                                                            // define_multiple_numbering_series_for_vouchers.current.focus();
                                                                            // define_multiple_numbering_series_for_vouchers.current.setSelectionRange(0, 0);
                                                                            // setActiveThis(false)
                                                                        }
                                                                    }}
                                                                >
                                                                    {index !== -1 ? (
                                                                        < >
                                                                            {name.substring(0, index)}
                                                                            <span style={{ color: 'red' }}>{name.substring(index, index + set_alter_additional_numbering_details.length)}</span>
                                                                            {name.substring(index + set_alter_additional_numbering_details.length)}
                                                                        </>
                                                                    ) : (
                                                                        name
                                                                    )}
                                                                </Link>
                                                            );
                                                        })
                                                    ) : (
                                                        <p style={{ color: 'red' }}>No matching items found</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            {
                                method_of_voucher_numbering === 'Manual' ?
                                    <div className='d-flex justify-content-between align-item-center position-relative'>
                                        <label className='lab-1 text-truncate'>Prevent Creating duplicate voucher Nos. </label>
                                        <label> :</label>
                                        <input
                                            type="text"
                                            name='prevent_creating_duplicate_voucher_nos'
                                            id='prevent_creating_duplicate_voucher_nos'
                                            className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`}
                                            onKeyUp={handleChange}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            ref={prevent_creating_duplicate_voucher_nos_input_ref}
                                            value={prevent_creating_duplicate_voucher_nos || ""} />
                                        <div id="myDropdown-list" className={`dropdown_yes_no_list ${preventCreating ? 'show-list' : ''}`}>
                                            <div className='col dropdown_header'>
                                                Yes / No
                                            </div>
                                            <div id='data'>
                                                {filteredDataYesNo?.length > 0 ? (
                                                    filteredDataYesNo?.map((key, number) => {
                                                        const name = key?.name;
                                                        const id = key?.id;
                                                        const index = name?.toLowerCase()?.indexOf(prevent_creating_duplicate_voucher_nos?.toLowerCase());
                                                        return (
                                                            <Link
                                                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                                id='list'
                                                                key={number}
                                                                onClick={() => {
                                                                    setSelectedItem(number)
                                                                    if (number === selectedItem) {
                                                                        setState({ ...state, prevent_creating_duplicate_voucher_nos: name })
                                                                        // define_multiple_numbering_series_for_vouchers.current.focus();
                                                                        // define_multiple_numbering_series_for_vouchers.current.setSelectionRange(0, 0);
                                                                        // setActiveThis(false)
                                                                    }
                                                                }}
                                                            >
                                                                {index !== -1 ? (
                                                                    < >
                                                                        {name.substring(0, index)}
                                                                        <span style={{ color: 'red' }}>{name.substring(index, index + prevent_creating_duplicate_voucher_nos.length)}</span>
                                                                        {name.substring(index + prevent_creating_duplicate_voucher_nos.length)}
                                                                    </>
                                                                ) : (
                                                                    name
                                                                )}
                                                            </Link>
                                                        );
                                                    })
                                                ) : (
                                                    <p style={{ color: 'red' }}>No matching items found</p>
                                                )}
                                            </div>
                                        </div>
                                    </div> : null
                            }
                        </div>
                    </div>
                    <div className='tomasterfooter'><Footer submit={numberSeriesSubmit} /></div>
                </form>
            </div> : <div></div>
    );
}

const SetAlterNumberDetails = forwardRef((props, ref) => {


    return (
        <div className='container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className='cross_button' onClick={props.handleCrossClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='row' style={{ background: '#fff', zIndex: 10000, width: '75%', height: '90vh' }}>
                <div className='col'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1 text-truncate'>Starting Numbering </label>
                                <label> :</label>
                                <input
                                    type="text"
                                    name='starting_number'
                                    autoFocus
                                    id='starting_number'
                                    onKeyUp={props.handleChange}
                                    onChange={props.handleChange}
                                    onFocus={props.handleFocus}
                                    onBlur={props.handleBlur}
                                    className={`lab-right`}
                                    ref={props?.starting_number_input_ref}
                                    value={props.starting_number || ""} />
                            </div>   <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1 text-truncate'>Width of Numerical Part </label>
                                <label> :</label>
                                <input
                                    type="text"
                                    name='width_of_numerical_part'
                                    id='width_of_numerical_part'
                                    onKeyUp={props.handleChange}
                                    onChange={props.handleChange}
                                    onFocus={props.handleFocus}
                                    onBlur={props.handleBlur}
                                    className={`lab-right`}
                                    ref={props?.width_of_numerical_part_input_ref}
                                    value={props.width_of_numerical_part || ""} />
                            </div>
                            <div className='d-flex justify-content-between align-item-center position-relative'>
                                <label className='lab-1 text-truncate'>Prefill with zero </label>
                                <label> :</label>
                                <input
                                    type="text"
                                    name='prefill_with_zero'
                                    id='prefill_with_zero'
                                    onKeyUp={props.handleChange}
                                    onChange={props.handleChange}
                                    onFocus={props.handleFocus}
                                    onBlur={props.handleBlur}
                                    className={`lab-right`}
                                    ref={props?.prefill_with_zero_input_ref}
                                    value={props.prefill_with_zero || ""} />
                                <div id="myDropdown-list" className={`dropdown_yes_no_list ${props.prefillWithZero ? 'show-list' : ''}`}>
                                    <div className='col dropdown_header'>
                                        Yes / No
                                    </div>
                                    <div id='data'>
                                        {props.filteredDataYesNo?.length > 0 ? (
                                            props.filteredDataYesNo?.map((key, number) => {
                                                const name = key?.name;
                                                const id = key?.id;
                                                const index = name?.toLowerCase()?.indexOf(props.prefill_with_zero?.toLowerCase());
                                                return (
                                                    <Link
                                                        className={`list font-weight-bold ${number === props.selectedItem ? 'selected' : ''}`}
                                                        id='list'
                                                        key={number}
                                                        onClick={() => {
                                                            props.setSelectedItem(number)
                                                            if (number === props.selectedItem) {
                                                                props.setState({ ...props.state, prefill_with_zero: name })
                                                                // define_multiple_numbering_series_for_vouchers.current.focus();
                                                                // define_multiple_numbering_series_for_vouchers.current.setSelectionRange(0, 0);
                                                                // setActiveThis(false)
                                                            }
                                                        }}
                                                    >
                                                        {index !== -1 ? (
                                                            < >
                                                                {name.substring(0, index)}
                                                                <span style={{ color: 'red' }}>{name.substring(index, index + props.prefill_with_zero.length)}</span>
                                                                {name.substring(index + props.prefill_with_zero.length)}
                                                            </>
                                                        ) : (
                                                            name
                                                        )}
                                                    </Link>
                                                );
                                            })
                                        ) : (
                                            <p style={{ color: 'red' }}>No matching items found</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row p-0 m-0 mt-2' style={{ width: '100%' }}>
                            <div className='col-4'>
                                <div className='row border'>
                                    <p className='m-0'> <center><strong>  Restart Numbering</strong></center></p>
                                </div>
                                <div className='row border' style={{ height: '67.6vh' }}>
                                    <div className='col-12'>
                                        <div className='row border'>
                                            <div className='col-4 borf'>
                                                Applicable From
                                            </div>
                                            <div className='col-4'>
                                                Starting Number
                                            </div>
                                            <div className='col-4'>
                                                Periodicity
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row mt-1'>
                                                    <div className='col-4'>
                                                        <input
                                                            type="text"
                                                            className='input_table'
                                                            name='applicable_from_restart_numbering'
                                                            id='prefill_with_zero'
                                                            onKeyUp={props.handleChange}
                                                            onChange={props.handleChange}
                                                            onFocus={props.handleFocus}
                                                            onBlur={props.handleBlur}
                                                            ref={props?.applicable_from_restart_numbering_input_ref}
                                                            value={props.applicable_from_restart_numbering || ""} />
                                                    </div>
                                                    <div className='col-4'>
                                                        <input
                                                            type="text"
                                                            className='input_table'
                                                            name='starting_number_restarting_numbering'
                                                            id='prefill_with_zero'
                                                            onKeyUp={props.handleChange}
                                                            onChange={props.handleChange}
                                                            onFocus={props.handleFocus}
                                                            onBlur={props.handleBlur}
                                                            ref={props?.starting_number_restarting_numbering_input_ref}
                                                            value={props.starting_number_restarting_numbering || ""} />
                                                    </div>
                                                    <div className='col-4'>
                                                        <input
                                                            type="text"
                                                            className='input_table'
                                                            name='periodicity'
                                                            id='prefill_with_zero'
                                                            onKeyUp={props.handleChange}
                                                            onChange={props.handleChange}
                                                            onFocus={props.handleFocus}
                                                            onBlur={props.handleBlur}
                                                            ref={props?.periodicity_input_ref}
                                                            value={props.periodicity || ""} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='col-4'>
                                <div className='row border'>
                                    <p className='m-0'> <center><strong> Prefix Details</strong></center></p>
                                </div>
                                <div className='row border' style={{ height: '67.6vh' }}>
                                    <div className='col'>
                                        <div className='row border'>
                                            <div className='col-6'>
                                                Applicable From
                                            </div>
                                            <div className='col-6'>
                                                Particulars
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row mt-1'>
                                                    <div className='col-6'>
                                                        <input
                                                            type="text"
                                                            className='input_table'
                                                            name='applicable_from_prefix_details'
                                                            id='applicable_from_prefix_details'
                                                            onKeyUp={props.handleChange}
                                                            onChange={props.handleChange}
                                                            onFocus={props.handleFocus}
                                                            onBlur={props.handleBlur}
                                                            ref={props?.applicable_from_prefix_details_input_ref}
                                                            value={props.applicable_from_prefix_details || ""} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <input
                                                            type="text"
                                                            className='input_table'
                                                            name='particulars_prefix_details'
                                                            id='particulars_prefix_details'
                                                            onKeyUp={props.handleChange}
                                                            onChange={props.handleChange}
                                                            onFocus={props.handleFocus}
                                                            onBlur={props.handleBlur}
                                                            ref={props?.particulars_prefix_details_input_ref}
                                                            value={props.particulars_prefix_details || ""} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='row border'>
                                    <p className='m-0'> <center><strong>  Suffix Details</strong></center></p>
                                </div>
                                <div className='row border' style={{ height: '67.6vh' }}>
                                    <div className='col'>
                                        <div className='row border'>
                                            <div className='col-6'>
                                                Applicable From
                                            </div>
                                            <div className='col-6'>
                                                Particulars
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row mt-1'>
                                                    <div className='col-6'>
                                                        <input
                                                            type="text"
                                                            className='input_table'
                                                            name='applicable_from_suffix_details'
                                                            id='applicable_from_suffix_details'
                                                            onKeyUp={props.handleChange}
                                                            onChange={props.handleChange}
                                                            onFocus={props.handleFocus}
                                                            onBlur={props.handleBlur}
                                                            ref={props?.applicable_from_suffix_details_input_ref}
                                                            value={props.applicable_from_suffix_details || ""} />
                                                    </div>
                                                    <div className='col-6'>
                                                        <input
                                                            type="text"
                                                            className='input_table'
                                                            name='particulars_suffix_details'
                                                            id='particulars_suffix_details'
                                                            onKeyUp={props.handleChange}
                                                            onChange={props.handleChange}
                                                            onFocus={props.handleFocus}
                                                            onBlur={props.handleBlur}
                                                            ref={props?.particulars_suffix_details_input_ref}
                                                            value={props.particulars_suffix_details || ""} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
