import React, { useState, useCallback, useEffect, useRef } from 'react'
import StockItemSummaryHelp from '../../../../components/help/stock_summary/stockitemsummary/StockItemSummaryHelp'
import StockItemSummaryFooter from '../../../../components/Footer/StockItemSummaryFooter'
import TableLayout from '../../../../components/Table/TableLayout'
import NavLayout from '../../../../components/NavNameOfComponent/NavLayout'
import { currencyFormate, dateFormateShow } from '../../../../components/date_formate/Index'
import Period from '../../../../components/help/stock_summary/stock_summary_component/Period'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import StockItem from '../../../../components/help/stock_summary/stockitemsummary/StockItem'
import ChangeView from '../../../../components/help/stock_summary/stockitemsummary/ChangeView'
import GodownFilter from '../../../../components/help/stock_summary/stockitemsummary/GodownFilter'




function StockItemSummary() {
    // const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState(0)
    const [selectedItems, setSelectedItems] = useState([]);
    const [show, setShow] = useState(false)
    const [stockItem, setStockItem] = useState(false)
    const [changeView, setChangeView] = useState(false)
    const [godownWise, setGodownWise] = useState(false)
    const navigate = useNavigate();
    const { company_session } = useSelector(state => state.common);
    const [date, setDate] = useState({
        show_from_date: dateFormateShow(company_session?.start_financial_year),
        show_to_date: dateFormateShow(company_session?.current_date),
        send_from_date: company_session?.start_financial_year,
        send_to_date: company_session?.current_date,
        location_id: '',
        filter_name: 'Item-Wise',
        name_nav: `Primary`,
    })

    const data = [
        { id: 1, particular: 'Opening Balance', inward: { qty: '', alt_unit: '', value: '' }, outwards: { qty: '', alt_unit: '', value: '', gross_value: '', comsumption: '', gross_profit: '', per: '' }, closing: { qty: '', alt_unit: '', value: '' } },
        { id: 2, particular: 'April', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' } },
        { id: 3, particular: 'May', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' } },
        { id: 4, particular: 'June', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' } },
        { id: 5, particular: 'July', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
        { id: 6, particular: 'August', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
        { id: 7, particular: 'September', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
        { id: 8, particular: 'October', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '50 Nos', alt_unit: '5 Box', value: '50000' } },
        { id: 9, particular: 'November', inward: { qty: '150 Nos', alt_unit: '15 Box', value: '150000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
        { id: 10, particular: 'December', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
        { id: 11, particular: 'January', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
        { id: 12, particular: 'February', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
        { id: 13, particular: 'March', inward: { qty: '100 Nos', alt_unit: '10 Box', value: '100000' }, outwards: { qty: '50 Nos', alt_unit: '5 Box', value: '50000', gross_value: '50000', comsumption: '50000', gross_profit: '500', per: '' }, closing: { qty: '200 Nos', alt_unit: '20 Box', value: '200000' } },
    ];
    const handleKeyDown = useCallback((e) => {
        // if (show || godownWise || stockGroup || basic || exceptionReports || saveView || valuation) {

        // } else {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedItem(prevState => Math.min(prevState + 1, data?.length - 1));
            }
        if (e.key === 'Enter') {
            e.preventDefault();
            const selectedList = data[selectedItem]
            if (selectedList) {
                navigate(`../stock_item_voucher`)
            }
        }
        // }
    }, [selectedItem, data])
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown])
    return (
        <div className='section'>
            <div className='detail'>
                {
                    show ?
                        <Period
                            setShow={setShow}
                            setDate={setDate}
                            date={date}
                            setLoading={setLoading}
                        /> : null
                }
                {
                    stockItem ?
                        <StockItem
                            setStockItem={setStockItem}
                            setDate={setDate}
                            date={date}
                            setLoading={setLoading}
                        /> : null
                }
                {
                    changeView ?
                        <ChangeView
                            setChangeView={setChangeView}
                            setDate={setDate}
                            date={date}
                        /> : null
                }
                {
                    godownWise ?
                        <GodownFilter
                            godownWise={godownWise}
                            date={date}
                            setGodownWise={setGodownWise}
                            setDate={setDate}
                        /> : null
                }
                <div className='container-fluid'>
                    <NavLayout>
                        Stock Item Summary : Monthly <span>1-Apr-2024 To 31-March-2024</span>
                    </NavLayout>
                </div>
                <TableLayout>
                    {
                        // loading ? <div>Loading...</div> :
                        data?.map((item, index) => {
                            // // console.log('this is data :----', data)
                            // total_opening_value += parseFloat(item?.stock_item ? item?.grandOpeningData?.value : 0)
                            // total_inwards_value += parseFloat(item?.stock_item ? item?.grandInwardData?.value : 0)
                            // total_outwards_value += parseFloat(item?.stock_item ? item?.grandOutwardData?.value : 0)
                            // total_closing_value += parseFloat(item?.stock_item ? item?.grandClosingData?.value : 0)
                            // const parts = item?.grandOpeningData?.quantity?.split(' ');
                            // const parts_1 = item?.grandInwardData?.quantity?.split(' ');
                            // const parts_2 = item?.grandOutwardData?.quantity?.split(' ');
                            // const parts_3 = item?.grandClosingData?.quantity?.split(' ');
                            // // unit_name = parts[1]
                            // // unit_name = 'Nos'
                            // const unit_name = parts && parts.length > 1 ? parts[1] : '';
                            // total_opnening_quantity += parseFloat(item?.stock_item && parts ? parts[0] : 0)
                            // total_inwards_quantity += parseFloat(item?.stock_item && parts_1 ? parts_1[0] : 0)
                            // total_outwards_quantity += parseFloat(item?.stock_item && parts_2 ? parts_2[0] : 0)
                            // total_closing_quantity += parseFloat(item?.stock_item && parts_3 ? parts_3[0] : 0)
                            return (
                                <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                                    <div className={`row ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                                        <div className={`col-2 border-end border-start border-dark text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                                            {/* {item?.stock_item?.item_name} {item?.location_name} */}
                                            {item.particular}
                                        </div>
                                        <div className='col-10 m-0 p-0'>
                                            <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                                <div className='col-4 border-end border-dark m-0 p-0'>
                                                    <div className='row m-0 p-0' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_body_font' style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                            {/* {item?.stock_item ? item?.grandOpeningData?.quantity : item?.grandOpeningData?.quantity} */}
                                                            {item.inward.qty}
                                                        </div>
                                                        <div className='col-4 m-0 p-0 text-end table_body_font' style={{ overflow: 'hidden', }}>
                                                            {/* {item?.stock_item ? parseFloat(item?.grandOpeningData?.rate)?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : parseFloat(item?.grandOpeningData?.rate)?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")} */}
                                                            {item.inward.alt_unit}
                                                        </div>
                                                        <div className='col-4 text-end p-0 m-0 pe-1 table_body_font' style={{ overflow: 'hidden', whiteSpace: 'nowrap', wordSpacing: -7 }}>
                                                            {/* {item?.stock_item ? item?.grandOpeningData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandOpeningData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")} */}
                                                            {currencyFormate(item.inward.value)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-4 m-0 p-0'>
                                                    <div className='row m-0 p-0' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_body_font' >
                                                            {item.outwards.qty}
                                                        </div>
                                                        <div className='col-4 m-0 p-0 text-end table_body_font' >
                                                            {item.outwards.alt_unit}
                                                        </div>
                                                        <div className='col-4 text-end p-0 pe-1 m-0 table_body_font' >
                                                            {currencyFormate(item.outwards.value)}
                                                        </div>
                                                        {/* <div className='col-2 border border-dark text-end p-0 pe-1 m-0 table_body_font' >
                                                            {item.outwards.gross_value}
                                                        </div>
                                                        <div className='col-1 border border-dark text-end p-0 pe-1 m-0 table_body_font' >
                                                            {item.outwards.comsumption}
                                                        </div>
                                                        <div className='col-2 border border-dark text-end p-0 pe-1 m-0 table_body_font'>
                                                            {item.outwards.gross_profit}
                                                        </div>
                                                        <div className='col-1  border border-dark text-end p-0 pe-1 m-0 font_size_10_px' >
                                                            Per%
                                                        </div> */}
                                                    </div>
                                                </div>

                                                <div className='col-4 border-end border-dark m-0 p-0'>
                                                    <div className='row m-0 p-0' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_body_font'>
                                                            {/* {item?.stock_item ? item?.grandClosingData?.quantity : item?.grandClosingData?.quantity} */}
                                                            {item.closing.qty}
                                                        </div>
                                                        <div className='col-4 text-end m-0 p-0 table_body_font'>
                                                            {/* {item?.stock_item ? item?.grandClosingData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandClosingData?.rate?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")} */}
                                                            {item.closing.alt_unit}
                                                        </div>
                                                        <div className='col-4 text-end p-0 pe-1 m-0 table_body_font'>
                                                            {/* {item?.stock_item ? item?.grandClosingData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : item?.grandClosingData?.value?.toFixed(userData?.no_of_decimal_place)?.replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")} */}
                                                            {currencyFormate(item.closing.value)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </TableLayout>
                <StockItemSummaryFooter />
            </div>
            <div className='help' style={{ position: 'relative' }}>
                <StockItemSummaryHelp
                    setShow={setShow}
                    setStockItem={setStockItem}
                    setChangeView={setChangeView}
                    setGodownWise={setGodownWise} />
            </div>
        </div>
    )
}

export default StockItemSummary
