import React, { useState, useEffect } from 'react';
import './login.css';
import { EyeSlash, Eye } from 'react-bootstrap-icons';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormData from 'form-data';
import { ToastContainer, toast } from 'react-toastify';
const initialState = {
    branch_name:'',
    userName: '',
    password: ''
}
function Branch_Login(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userData = null } = useSelector(state => state.auth);
    const [state, setState] = useState(initialState)
    const [visible, setVisible] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    }
    const handleClick = () => {
        setVisible(!visible);
    };
    const {
        branch_name,
        userName,
        password
    } = state;

    const handleSubmit = (e1) => {
        e1.preventDefault();
        console.log('hello')
    }
    useEffect(() => {
        // Simulate the page loading for a very short time (nano time)
        const timer = setTimeout(() => {
            setLoading(false); // After the nano time, set loading to false to show the login page
        }, 10);

        return () => clearTimeout(timer); // Clean up the timer on unmount
    }, []);
    const registerPage = (e) => {
        navigate('/signUp');
    }
    console.log('======branch name',branch_name)

    return (
        <div className='container-fluid' id='login-container'>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            {
                loading ? (
                    <div className='container'>
                        <div className='row justify-content-center align-items-center' id='row'>
                            <div className="spinner-border text-success" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='row justify-content-center align-items-center' id='row'>
                        <div className='col-lg-6'>
                            <h1 className='text-primary text-left' style={{ textAlign: 'left' }} id='man-heading'>
                                <b> Tally Prime for Branch</b>
                            </h1>
                            <h4 id='subheading ' style={{ textAlign: 'left' }}>
                                <b> Tally Prime help you to connect and share your company details with all the Employees</b>
                            </h4>
                        </div>
                        <div className='col-lg-4 p-5'>
                            <div className='row shadow rounded' id='card'>
                                <div className='col mt-4'>
                                    <form method='post' autoComplete='off' onSubmit={handleSubmit}>
                                        <div className="input-group input-group-lg mb-3">
                                            <select name='branch_name' value={branch_name}  class="form-select" aria-label="Default select example" onChange={handleChange}>
                                                <option selected  hidden >Select Branch</option>
                                                <option value="googale pvt ltd">googale pvt ltd</option>
                                                <option value="apple pvt ltd">apple pvt ltd</option>
                                                <option value="facebook.com">facebook.com</option>
                                            </select>
                                        </div>
                                        <div className="input-group input-group-lg mb-3">
                                            <input type="text" className="form-control text-truncate" autoComplete="off" placeholder="Username or E-mail Address" name='userName' value={userName || ""} onChange={handleChange} onKeyUp={handleChange} />
                                        </div>
                                        <div className="input-group input-group-lg mb-3">
                                            <input type={visible ? 'text' : 'password'} className="form-control" autoComplete="off" id='password' placeholder="Password" name='password' value={password || ""} onChange={handleChange} onKeyUp={handleChange} />
                                            <span className='input_icon-wrapper'>
                                                {
                                                    visible ? <Eye className='input_icon' onClick={() => { setVisible(false) }} /> : <EyeSlash className='input_icon' onClick={handleClick} />
                                                }
                                            </span>
                                        </div>
                                        <ul className="nav">
                                            <li className="nav-item text-center">
                                                <Link className="nav-link active" >Forgot password?</Link>
                                            </li>
                                        </ul>
                                        <div className='input-group input-group-lg mb-3'>
                                            <button type="submit" style={{ width: '100%' }} className="btn btn-primary">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>)}
        </div>

    )
}

export default Branch_Login;
