import React from 'react';

function TableLayoutProfitBalance({ children, table_heading, table_heading1 }) {
    return (
        <div className='container-fluid' id='reletive' style={{ height: '90vh' }}>
            <div className='row border-bottom border-dark' style={{ height: 25 }}>
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8 text-start'>{table_heading}</div>
                        {/* <div className='col-4 text-end'></div> */}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-8 text-start'>{table_heading1}</div>
                        {/* <div className='col-4 text-end'></div> */}
                    </div>
                </div>
            </div>
            <div className={` row  border-bottom border-dark postion-relative`} style={{ height: '79vh' }} >
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8'></div>
                        <div className='col-4'></div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-8'></div>
                        <div className='col-4'></div>
                    </div>
                </div>
                <div className='row m-0 p-0' style={{ position: 'absolute', width: '100%' }}>
                    {children}
                </div>
            </div>
            <div className={`row  border-0`}>
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8 text-start'>Total</div>
                        <div className='col-4 text-end'></div>
                    </div>
                </div>
                <div className='col-md-6 border-end border-dark'>
                    <div className='row'>
                        <div className='col-8 text-start'>Total</div>
                        <div className='col-4 text-end'></div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TableLayoutProfitBalance
