import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LINK_PATH } from '../../../../constant';
import { ledgerBookList } from '../../../../redux/actions/action-creator';
function AccountBook() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleKeyDown = useCallback((event) => {
        if (location.pathname === '/ledgerbook') {

        } else {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (event.key === 'Enter') {
                event.preventDefault();
                // You can navigate to the selected link's path here
                const selectedLink = accounting[selectedIndex] || inventory[selectedIndex];
                if (selectedLink) {
                    event.preventDefault();
                    navigate(selectedLink.to)
                }
            }
            // else if (event.key === 'c' || event.key === 'C') {
            //     navigate('/chartofaccountmasters')
            // }
            else if (event.key === 'l' || event.key === 'L') {
                navigate('/ledgerbook')
            }
            // else if (event.key === 'g' || event.key === 'G') {
            //     navigate('/daybooktransaction')
            // }
            // else if (event.key === 'v' || event.key === 'V') {
            //     navigate('/voucherstransaction')
            // }
            // else if (event.key === 't' || event.key === 'T') {
            //     navigate('/accountbook')
            // }
            // else if (event.key === 'y' || event.key === 'Y') {
            //     navigate('/statementofaccount')
            // }
            // else if (event.key === 'r' || event.key === 'R') {
            //     navigate('/inventorybook')
            // }
            // else if (event.key === 's' || event.key === 'S') {
            //     navigate('/statementofinventory')
            // }
            // else if (event.key === 'o' || event.key === 'O') {
            //     navigate('/gstreport')
            // }
            // else if (event.key === 'p' || event.key === 'P') {
            //     navigate('/exceptionreports')
            // }
            // else if (event.key === 'j' || event.key === 'J') {
            //     navigate('/analysisverification')
            // }
            // else if (event.key === 'd' || event.key === 'D') {
            //     navigate('/analysisverification')
            // }
            // else if (event.key === 'e' || event.key === 'E') {
            //     navigate('/analysisverification')
            // }
            // else if (event.key === 'u' || event.key === 'U') {
            //     navigate('/analysisverification')
            // }
        }
    }, [selectedIndex, location])
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
    const accounting = [
        { to: '/createmasters', text: 'Cash/Bank Book (s)' },
        { to: '/ledgerbook', text: 'Ledger' },
        { to: '/chartofaccountmasters', text: 'Group Summary' },
        { to: '/chartofaccountmasters', text: 'Group Vouchers' },
    ];
    const inventory = [];
    inventory[4] = { to: '/voucherstransaction', text: 'ConTra Register' };
    inventory[5] = { to: '/daybooktransaction', text: 'PaYment Register' };
    inventory[6] = { to: '/daybooktransaction', text: 'Receipt Register' };
    inventory[7] = { to: '/daybooktransaction', text: 'Sales Register' };
    inventory[8] = { to: '/daybooktransaction', text: 'POS Register' };
    inventory[9] = { to: '/daybooktransaction', text: 'Purchase Register' };
    inventory[10] = { to: '/daybooktransaction', text: 'Journal Register' };
    inventory[11] = { to: '/daybooktransaction', text: 'Debit Note Register' };
    inventory[12] = { to: '/daybooktransaction', text: 'CrEdit Note Register' };
    inventory[13] = { to: '/daybooktransaction', text: 'VoUcher Clarification' };


    const totalLinks = (accounting.length + inventory.length) - 4;
    return (
        <div className='card-container' id='card-container'>
            <div className='card-title'>
                <p className='title-of'>Account Book</p>
            </div>
            <div className='gateway_tally_card'>
                <div className='list-1'>
                    <h6 className='list-heading'> SUMMARY</h6>
                    <ul className='list-item'>
                        {accounting.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 0 || index === 1 || index === 2 ? (
                                        <span style={{ color: 'red' }}>{link.text.substring(0, 1)}</span>
                                    ) : index === 3 ? (
                                        <>
                                            {link.text.substring(0, 6)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(6, 7)}</span>
                                            {link.text.substring(7)}
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                    {index === 3 ? null : link.text.substring(1)}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> REGISTER</h6>
                    <ul className='list-item'>
                        {inventory.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 6 || index === 7 || index === 9 || index === 10 || index === 11 ? (
                                        <span style={{ color: 'red' }}>{link.text.substring(0, 1)}</span>
                                    ) : index === 5 || index === 12 || index === 13 ? (
                                        <>
                                            {link.text.substring(0, 2)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(2, 3)}</span>
                                            {link.text.substring(3)}
                                        </>
                                    ) : index === 4 ? (
                                        <>
                                            {link.text.substring(0, 3)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(3, 4)}</span>
                                            {link.text.substring(4)}
                                        </>
                                    ) : index === 8 ? (
                                        <>
                                            {link.text.substring(0, 1)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(1, 2)}</span>
                                            {link.text.substring(2)}
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                    {index === 4 || index === 5 || index === 12 || index === 13 || index === 8 ? null : link.text.substring(1)}
                                </Link>
                            </li>
                        ))}
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default AccountBook;
