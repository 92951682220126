import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../../../../components/home_page/Footer';
import { Link,useParams } from 'react-router-dom';
import { stockGroupSingleData,stockGroupUpdate,stockGroupList } from '../../../../../redux/actions/action-creator';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css'

function StockGroup() {
  const {id} = useParams();
  const initialState = {
    stock_name: '',
    alise_name: '',
    description: '',
    under: '',
    is_gst_applicable: 'Applicable',
    hsn_sac_details: '',
    source_of_details: '',
    hsn_sac: '',
    description: '',
    gst_rate_details: '',
    taxability_type: '',
    igst_rate: '',
    cgst_rate: '',
    sgst_utst_rate: '',
    cess_valuation_type: '',
    cess_rate: '',
    applicable_for_reverse_charge: '',
    eligible_for_tax_input_credit: '',
    type_of_supply: '',
  }
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const dropdownCategory = useRef(null);
  const dropdownUnits = useRef(null);
  const inputRef = useRef(null);
  const inputCategoryRef = useRef(null);
  const inputUnitsRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const [showListCategory, setShowListCategory] = useState(false);
  const [showListUnits, setShowLIstUnits] = useState(false);
  const [state, setState] = useState(initialState);
  const data = useSelector(state => state.common.data);
  useEffect(()=>{
    const formData = new FormData();
    formData.append('id',id)
    dispatch(stockGroupSingleData(formData)).then((response) => {
      if (response.status) {
        setState({
          stock_name: response.data?.name,
          alise_name: response.data?.alias_name,
          description: response.data?.description,
          under: response.data?.under,
          is_gst_applicable:'Applicable',
          hsn_sac_details: response.data?.HSN_SAC_details,
          source_of_details: response.data?.source_details,
          hsn_sac: response.data?.HSN_SAC,
          gst_rate_details: response.data?.GST_rate_details,
          taxability_type: response.data?.taxability_type,
          igst_rate: response.data?.IGST_rate,
          cgst_rate:response.data?.CGST_rate,
          sgst_utst_rate: response.data?.SGST_UTGST_rate,
          cess_valuation_type:response.data?.cess_validation,
          cess_rate:response.data?.cess_rate,
          applicable_for_reverse_charge:response.data?.application_of_reverse,
          eligible_for_tax_input_credit:response.data?.eligible_tax_credit,
          type_of_supply:response.data?.name,
        })
        // toast.success(response?.message || 'data found!', {
        //   position: toast.POSITION.TOP_CENTER
        // });
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  },[id,dispatch])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
  }
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const handleClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
      setShowList(false);
    }
    if (dropdownCategory.current && !dropdownCategory.current.contains(event.target) && !inputCategoryRef.current.contains(event.target)) {
      setShowListCategory(false);
    }
    if (dropdownUnits.current && !dropdownUnits.current.contains(event.target) && !inputUnitsRef.current.contains(event.target)) {
      setShowLIstUnits(false);
    }
  };
  const handleFocus = () => {
    if (!showList) {
      setShowList(true);
    }
  };
  const categoryFocus = () => {
    if (!showListCategory) {
      setShowListCategory(true);
    }
  };
  const unitsFocus = () => {
    if (!showListUnits) {
      setShowLIstUnits(true);
    }
  };
  // const displayList = (value) => {
  //   setState({ ...state, under: value })
  //   document.getElementById('under').value = value;
  //   document.getElementById("myDropdown-list").classList.remove("show-list");
  //   return false
  // }
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', state.stock_name)
    formData.append('alias_name', state.alise_name)
    formData.append('under', state.under)
    formData.append('qty_items_added', '')
    formData.append('HSN_SAC_details', state.hsn_sac_details)
    formData.append('source_details', state.source_of_details)
    formData.append('HSN_SAC', state.hsn_sac)
    formData.append('description', state.description)
    formData.append('GST_rate_details', state.gst_rate_details)
    formData.append('taxability_type', state.taxability_type)
    formData.append('IGST_rate', state.igst_rate)
    formData.append('CGST_rate', state.cgst_rate)
    formData.append('SGST_UTGST_rate', state.sgst_utst_rate)
    formData.append('cess_validation', state.cess_valuation_type)
    formData.append('cess_rate', state.cess_rate)
    formData.append('application_of_reverse', state.applicable_for_reverse_charge)
    formData.append('eligible_tax_credit', state.eligible_for_tax_input_credit)
    formData.append('id', id)
    dispatch(stockGroupUpdate(formData)).then((response) => {
      if (response.status) {
        toast.success(response?.message || 'group created!', {
          position: toast.POSITION.TOP_CENTER
        });
        setState(
          {
            stock_name: '',
            alise_name: '',
            part_no: '',
            description: '',
            notes: '',
            set_modify_default_ledger_for_invoice: '',
            under: '',
            category: '',
            units: '',
            company_name_of_product: '',
            is_gst_applicable: 'Applicable',
            hsn_sac_details: '',
            source_of_details: '',
            hsn_sac: '',
            description: '',
            gst_rate_details: '',
            taxability_type: '',
            igst_rate: '',
            cgst_rate: '',
            sgst_utst_rate: '',
            cess_valuation_type: '',
            cess_rate: '',
            applicable_for_reverse_charge: '',
            eligible_for_tax_input_credit: '',
            type_of_supply: '',
            opening_balance: '',
          }
        )
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.message || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }
  const { stock_name, alise_name, part_no, description, notes,
    set_modify_default_ledger_for_invoice, under, category, units, company_name_of_product,
    is_gst_applicable,
    hsn_sac_details,
    source_of_details,
    hsn_sac,
    gst_rate_details,
    taxability_type,
    igst_rate,
    cgst_rate,
    sgst_utst_rate,
    cess_valuation_type,
    cess_rate,
    applicable_for_reverse_charge,
    eligible_for_tax_input_credit,
    type_of_supply, opening_balance } = state;
  return (
    <div className='section'>
      <div className='detail border'>
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <form autoComplete='off' onSubmit={handleSubmit} >
            <div className='row' style={{ width: '50%', background: '#fff', }}>
              <div className='col-12 pt-2 pb-2 ' >
                <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1'> Name</label>
                  <label> :</label>
                  <input type="text" name='stock_name' id='stock_name' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={stock_name || ""} />
                </div>
                <div className='d-flex justify-content-between align-item-center' id='alis'>
                  <label className='lab-1'> (alise) </label>
                  <label> :</label>
                  <input type="text" name='alise_name' id='alise_name' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={alise_name || ""} />
                </div>
              </div>
              <div className='col-12' >
                <div className='d-flex justify-content-between align-item-center'>
                  <label for='under' className='lab-1'> Technical Name of Product </label>
                  <label> :</label>
                  <input type='text' id='under' name="under" tabIndex={3} ref={inputRef} value={under || ""} onKeyUp={handleChange} onChange={handleChange} onFocus={handleFocus} className='lab-right ' />
                  <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                      List Of Group
                    </div>
                    <div className='col ' style={{ textAlign: 'right' }}>
                      <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                      <div className='border'></div>
                    </div>
                    <div id='data'>
                      Jayant
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <p><strong> <u>Statutory Details</u></strong></p>
                <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1'> Is GST applicable </label>
                  <label> :</label>
                  <input type="text" list='gstapplcble' name='is_gst_applicable' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={is_gst_applicable || ""} />
                  <datalist id='gstapplcble' >
                    <option value="Applicable">Applicable</option>
                    <option value="Not Applicable">Not Applicable</option>
                    <option value="Undefined">Undefined</option>
                  </datalist>
                </div>
                {
                  is_gst_applicable === 'Applicable' ?
                    <div>
                      <div>
                        <p><strong> <u> HSN/SAC & Related Details</u></strong></p>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> HSN/SAC Details </label>
                          <label> :</label>
                          <input type="text" list='hsd' name='hsn_sac_details' id='hsn_sac_details' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={hsn_sac_details || ""} />
                          <datalist id='hsd' >
                            <option value="As per Company/Group">As per Company/Group</option>
                            <option value="Specify Details Here">Specify Details Here</option>
                            <option value="Specify in Voucher">Specify in Voucher</option>
                          </datalist>
                        </div>

                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>HSN/SAC </label>
                          <label> :</label>
                          <input type="text" name='hsn_sac' id='hsn_sac' className='lab-right' value={hsn_sac || ""} onKeyUp={handleChange} onChange={handleChange} disabled={hsn_sac_details !== 'Specify Details Here'} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Description </label>
                          <label> :</label>
                          <input type="text" name='description' id='description' className='lab-right' value={description || ""} onKeyUp={handleChange} onChange={handleChange} disabled={hsn_sac_details !== 'Specify Details Here'} />
                        </div>
                        <p><strong> <u>GST Rate & Related Details</u></strong></p>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> GST Rate Details </label>
                          <label> :</label>
                          <input type="text" list='grd' name='gst_rate_details' className='lab-right' onKeyUp={handleChange} onChange={handleChange} value={gst_rate_details || ""} />
                          <datalist id='grd' >
                            <option value="As per Company/Group">As per Company/Group</option>
                            <option value="Specify Details Here">Specify Details Here</option>
                            <option value="Use GST classification">Use GST classification</option>
                            <option value="Specify Slab-Based Rates">Specify Slab-Based Rates</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Source of Details </label>
                          <label> :</label>
                          <input type="text" name='source_of_details' id='source_of_details' className='lab-right' value={source_of_details || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Taxability Type </label>
                          <label> :</label>
                          <input type="text" name='taxability_type' id='taxability_type' className='lab-right' value={taxability_type || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                        </div>

                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>IGST Rate </label>
                          <label> :</label>
                          <input type="number" name='igst_rate' id='igst_rate' className='lab-right' value={igst_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>CGST Rate </label>
                          <label> :</label>
                          <input type="text" name='cgst_rate' id='cgst_rate' className='lab-right' value={cgst_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>SGST/UTGST Rate </label>
                          <label> :</label>
                          <input type="text" name='sgst_utst_rate' id='sgst_utst_rate' className='lab-right' value={sgst_utst_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Cess Valuation Type </label>
                          <label> :</label>
                          <input type="text" name='cess_valuation_type' id='cess_valuation_type' className='lab-right' value={cess_valuation_type || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Cess Rate </label>
                          <label> :</label>
                          <input type="text" name='cess_rate' id='cess_rate' className='lab-right' value={cess_rate || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Applicable For Reverse Charge </label>
                          <label> :</label>
                          <input type="text" list='afrc' name='applicable_for_reverse_charge' id='applicable_for_reverse_charge' className='lab-right' value={applicable_for_reverse_charge || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                          <datalist id='afrc' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Eligible for tax input credit </label>
                          <label> :</label>
                          <input type="text" list='eftic' name='eligible_for_tax_input_credit' id='eligible_for_tax_input_credit' className='lab-right' value={eligible_for_tax_input_credit || ""} onKeyUp={handleChange} onChange={handleChange} disabled />
                          <datalist id='eftic' >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Type of Supply </label>
                          <label> :</label>
                          <input type="text" list='tos' name='type_of_supply' id='type_of_supply' className='lab-right' value={type_of_supply || ""} onKeyUp={handleChange} onChange={handleChange} />
                          <datalist id='tos' >
                            <option value="Capital Goods">Capital Goods</option>
                            <option value="Goods">Goods</option>
                            <option value="Services">Services</option>
                          </datalist>
                        </div>
                      </div>
                    </div> : null
                }

              </div>

            </div>
            <div className='tomasterfooter'><Footer /></div>
          </form>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default StockGroup
