import React, { useEffect, useState, useRef } from 'react';
import { useDispatch} from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import FormData from 'form-data';
import { signUp } from '../../redux/actions/action-creator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import '../../components/model/modal.css';
import './registration.css';
import { EyeSlash, Eye } from 'react-bootstrap-icons';
import { dateFormateShow, statesList } from '../date_formate/Index';
import { SuitDiamondFill } from 'react-bootstrap-icons';
import YesNoModal from '../model/YesNoModal';

const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();
const financialYearStartMonth = 3;
const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
const financialYearEndYear = financialYearStartYear + 1;
const date_1 = new Date(`${financialYearStartYear}-${financialYearStartMonth + 1}-01`)
const date_2 = new Date(`${financialYearEndYear}-${financialYearStartMonth}-31`)
const financialYear = `${date_1.getDate()}-${date_1.toLocaleString('default', { month: 'short' })}-${date_1.getFullYear()} To ${date_2.getDate()}-${date_2.toLocaleString('default', { month: 'short' })}-${date_2.getFullYear()}`;
const current_date_redux = `${currentDate.toLocaleString('en-US', { weekday: 'long' })},${currentDate.getDate()}-${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`

const initialState = {
    company_name: '',
    mailing_name: '',
    address: '',
    city: '',
    statename: 'Not Applicable',
    countryName: 'India',
    pincode: '',
    mobile: '',
    telephone: '',
    fax: '',
    email: '',
    website: '',
    fybfDate: dateFormateShow(date_1),
    bbfDate: dateFormateShow(date_1),
    username: '',
    password: "",
    enable_tallyAudit_features: '',
    maintain_multiple_branches_for_this_company: '',
    base_currency_symbol: 'Rs',
    formal_name: 'INR',
    suffix_symbol_to_amount: '',
    add_space_between_amount_and_symbol: 'Yes',
    number_of_decimal_places: '2',
    word_representing_amount_after_decimal: 'Paise',
    number_fo_decimal_places_in_words: '2'
}

const RegistrationPage = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [data, setData] = useState(initialState);
    const [backspaceCount, setBackspaceCount] = useState(0);
    const [hashtag, sethastag] = useState(false);
    const [nameInput, setNameInput] = useState(null)
    const [selectedItem, setSelectedItem] = useState(0);
    const [filteredStateData, setFilteredStateData] = useState([]);
    const [showStateList, setShowStateList] = useState(false);
    const [submitConfirm, setSubmitConfirm] = useState('');
    const [visible, setVisible] = useState(false);

    const isMouseDownInside = useRef(false);
    const company_name_input_ref = useRef(null);
    const mailing_name_input_ref = useRef(null);
    const address_input_ref = useRef(null);
    const city_input_ref = useRef(null);
    const statename_input_ref = useRef(null);
    const countryName_input_ref = useRef(null);
    const pincode_input_ref = useRef(null);
    const mobile_input_ref = useRef(null);
    const telephone_input_ref = useRef(null);
    const fax_input_ref = useRef(null);
    const email_input_ref = useRef(null);
    const website_input_ref = useRef(null);
    const fybfDate_input_ref = useRef(null);
    const bbfDate_input_ref = useRef(null);
    const username_input_ref = useRef(null);
    const password_input_ref = useRef(null);
    const enable_tallyAudit_features_input_ref = useRef(null);
    const maintain_multiple_branches_for_this_company_input_ref = useRef(null);
    const base_currency_symbol_input_ref = useRef(null);
    const formal_name_input_ref = useRef(null);
    const suffix_symbol_to_amount_input_ref = useRef(null);
    const add_space_between_amount_and_symbol_input_ref = useRef(null);
    const number_of_decimal_places_input_ref = useRef(null);
    const word_representing_amount_after_decimal_input_ref = useRef(null);
    const number_fo_decimal_places_in_words_input_ref = useRef(null);
    const dropdown_ref = useRef(null)
    const registration_input_ref = useRef(null)

    function getNextInputRef(name, value) {
        switch (name) {
            case 'company_name':
                setData({ ...data, ['mailing_name']: value })
                return mailing_name_input_ref;
            case 'mailing_name': return address_input_ref;
            case 'address': return city_input_ref;
            case 'city': return statename_input_ref;
            case 'statename': return countryName_input_ref;
            case 'countryName': return pincode_input_ref;
            case 'pincode': return mobile_input_ref;
            case 'mobile': return telephone_input_ref;
            case 'telephone': return fax_input_ref;
            case 'fax': return email_input_ref;
            case 'email': return website_input_ref;
            case 'website': return fybfDate_input_ref;
            case 'fybfDate': return bbfDate_input_ref;
            case 'bbfDate': return username_input_ref;
            case 'username': return password_input_ref;
            case 'password': return enable_tallyAudit_features_input_ref;
            case 'enable_tallyAudit_features': return base_currency_symbol_input_ref;
            case 'base_currency_symbol': return formal_name_input_ref;
            case 'formal_name': return suffix_symbol_to_amount_input_ref;
            case 'suffix_symbol_to_amount': return add_space_between_amount_and_symbol_input_ref;
            case 'add_space_between_amount_and_symbol': return number_of_decimal_places_input_ref;
            case 'number_of_decimal_places': return word_representing_amount_after_decimal_input_ref;
            case 'word_representing_amount_after_decimal': return number_fo_decimal_places_in_words_input_ref;
            case 'number_fo_decimal_places_in_words':
                return number_fo_decimal_places_in_words_input_ref;
            default:
                return null;
        }

    }

    function getPrevInputRef(name) {
        switch (name) {
            case 'mailing_name': return company_name_input_ref;
            case 'address': return mailing_name_input_ref;
            case 'city': return address_input_ref;
            case 'statename': return city_input_ref;
            case 'countryName': return statename_input_ref;
            case 'pincode': return countryName_input_ref;
            case 'mobile': return pincode_input_ref;
            case 'telephone': return mobile_input_ref;
            case 'fax': return telephone_input_ref;
            case 'email': return fax_input_ref;
            case 'website': return email_input_ref;
            case 'fybfDate': return website_input_ref;
            case 'bbfDate': return fybfDate_input_ref;
            case 'username': return bbfDate_input_ref;
            case 'password': return username_input_ref;
            case 'enable_tallyAudit_features': return password_input_ref;
            case 'base_currency_symbol': return enable_tallyAudit_features_input_ref;
            case 'formal_name': return base_currency_symbol_input_ref;
            case 'suffix_symbol_to_amount': return formal_name_input_ref;
            case 'add_space_between_amount_and_symbol': return suffix_symbol_to_amount_input_ref;
            case 'number_of_decimal_places': return add_space_between_amount_and_symbol_input_ref;
            case 'word_representing_amount_after_decimal': return number_of_decimal_places_input_ref;
            case 'number_fo_decimal_places_in_words': return word_representing_amount_after_decimal_input_ref;
            case 'company_name':
            default: return null;
        }
    }

    function getCurrentInputRef(name) {
        switch (name) {
            case 'company_name': return company_name_input_ref;
            case 'mailing_name': return mailing_name_input_ref;
            case 'address': return address_input_ref;
            case 'city': return city_input_ref;
            case 'statename': return statename_input_ref;
            case 'countryName': return countryName_input_ref;
            case 'pincode': return pincode_input_ref;
            case 'mobile': return mobile_input_ref;
            case 'telephone': return telephone_input_ref;
            case 'fax': return fax_input_ref;
            case 'email': return email_input_ref;
            case 'website': return website_input_ref;
            case 'fybfDate': return fybfDate_input_ref;
            case 'bbfDate': return bbfDate_input_ref;
            case 'username': return username_input_ref;
            case 'password': return password_input_ref;
            case 'enable_tallyAudit_features': return enable_tallyAudit_features_input_ref;
            case 'base_currency_symbol': return base_currency_symbol_input_ref;
            case 'formal_name': return formal_name_input_ref;
            case 'suffix_symbol_to_amount': return suffix_symbol_to_amount_input_ref;
            case 'add_space_between_amount_and_symbol': return add_space_between_amount_and_symbol_input_ref;
            case 'number_of_decimal_places': return number_of_decimal_places_input_ref;
            case 'word_representing_amount_after_decimal': return word_representing_amount_after_decimal_input_ref;
            case 'number_fo_decimal_places_in_words': return number_fo_decimal_places_in_words_input_ref;
            default: return null;
                break;
        }
    }

    const {
        company_name, mailing_name, address, fybfDate, bbfDate, username, password,
        enable_tallyAudit_features, city, statename, countryName, pincode, mobile,
        telephone, fax, email, website, base_currency_symbol, formal_name,
        suffix_symbol_to_amount, add_space_between_amount_and_symbol, number_of_decimal_places,
        word_representing_amount_after_decimal, number_fo_decimal_places_in_words
    } = data;


    const handleChange = (e) => {
        const { name, value } = e.target;

        //Capitalize First Letter Function------------
        const capitalizeFirstLetter = (str) => {
            return str.toLowerCase().replace(/(^|\s)\S/g,
                (firstLetter) => firstLetter.toUpperCase());
        };

        // space key functionality----------------------
        if (e.code == 'Space') {
            e.preventDefault();
            const cursorPosition_start = e.target.selectionStart;
            if (cursorPosition_start == 1) {
                setData({ ...data, [name]: '' });
                return;
            }
        }

        // Enter key functionality -------------------------
        if (e.key === 'Enter' && data?.company_name !== '') {
            e.preventDefault();
            if (name === 'statename') {
                const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
                const matchFound = statesList.find(item => cleanString(item.name) === cleanString(value));
                if (matchFound) {
                    countryName_input_ref?.current?.focus();
                    countryName_input_ref.current.setSelectionRange(0, 0);
                } else {
                    statename_input_ref?.current?.focus();
                }

            } else
                if (name === 'number_fo_decimal_places_in_words') {
                    number_fo_decimal_places_in_words_input_ref.current.blur()
                    setSubmitConfirm('Accept')
                } else {
                    const nextInputRef = getNextInputRef(name, value)
                    if (nextInputRef) {
                        nextInputRef.current.focus();
                        if (nextInputRef.current) {
                            nextInputRef.current.setSelectionRange(0, 0);
                        }
                    }
                }
            return;
        }

        // Back Space key functionality --------------------------
        if (e.key === 'Backspace') {
            e.preventDefault();
            const nextInputRef = getPrevInputRef(name);
            const cursorPosition = e.target.selectionStart;
            if (cursorPosition === 0) {
                if (backspaceCount === 1) {
                    setBackspaceCount(0);
                    if (nextInputRef && nextInputRef.current) {
                        nextInputRef.current.focus();
                        nextInputRef.current.setSelectionRange(0, 0);
                    }
                } else {
                    if (cursorPosition === 0 && value) {
                        if (nextInputRef && nextInputRef.current) {
                            nextInputRef.current.focus();
                            nextInputRef.current.setSelectionRange(0, 0);
                        }
                    } else {
                        setBackspaceCount(backspaceCount + 1);
                    }
                }
            }
            return;
        }

        // Set data in state accourding to name and validate the value -------
        const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        switch (name) {
            case 'statename':
                const state_name = statesList.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
                if (state_name) {
                    setData({ ...data, [name]: value })
                }
                break;
            case 'pincode':
                if ((/^\d{1,6}$/).test(value) || value === '') {
                    setData({ ...data, [name]: value });
                }
                break;
            case 'mobile':
                if ((/^\d{1,10}$/).test(value) || value === '') {
                    setData({ ...data, [name]: value });
                }
                break;
            case 'telephone':
                if ((/^(\d+)[-]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
                    setData({ ...data, [name]: value });
                }
                break;
            case 'fax':
                if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
                    setData({ ...data, [name]: value });
                }
                break;
            case 'email':
                setData({ ...data, [name]: value });
                break;
            case 'website':
                setData({ ...data, [name]: value });
                break;
            case 'fybfDate':
                setData({ ...data, [name]: value });
                break;
            case 'bbfDate':
                setData({ ...data, [name]: value });
                break;
            case 'enable_tallyAudit_features':
                setData({ ...data, [name]: value });
                break;
            case 'base_currency_symbol':
                setData({ ...data, [name]: value });
                break;
            case 'formal_name':
                setData({ ...data, [name]: value });
                break;
            case 'add_space_between_amount_and_symbol':
                setData({ ...data, [name]: value });
                break;
            case 'number_of_decimal_places':
                setData({ ...data, [name]: value });
                break;
            case 'word_representing_amount_after_decimal':
                setData({ ...data, [name]: value });
                break;
            case 'number_fo_decimal_places_in_words':
                setData({ ...data, [name]: value });
            default:
                const updatedValue = name === 'company_name' || name === 'mailing_name' || name === 'address' || name === 'city' || name === 'statename' || name === 'countryName' ?
                    capitalizeFirstLetter(value) : value;
                setData({ ...data, [name]: updatedValue })
                break;
        }
    }

    const handleFocus = (e) => {
        const { name, value } = e.target;
        const ref = getCurrentInputRef(name);
        if (ref) {
            setNameInput(ref);
        }
        if (name === 'statename') {
            setShowStateList(true)
        }
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'company_name' && value === '') {
            company_name_input_ref.current.focus();
        }
        if (name === 'statename') {
            setShowStateList(false)
        }
        if (name === 'mobile') {
            if (!/^\d{10}$/.test(value)) {
                mobile_input_ref.current.focus();
            }
        }
        if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmailFormat = emailRegex.test(value);
            if (isValidEmailFormat) {
                setData({ ...data, [name]: value });
            } else {
                email_input_ref?.current?.focus();
            }
        }
        if (name === 'website' && value !== '') {
            const emailRegex = /^(ftp|http[s]?):\/\/[^ "]+$/;
            const isValidEmailFormat = emailRegex.test(value);
            if (isValidEmailFormat) {
                setData({ ...data, [name]: value });
            } else {
                website_input_ref?.current?.focus();
            }
        }
        if (name === 'fybfDate') {
            const fybfDateRegex = /^(\d+)[.\-\/](\d+)[.-\/]?(\d*)$/;
            const extendedDateRegex = /^(\d{2})-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d{4})$/;
            const isValidDateFormat = fybfDateRegex.test(value);
            const isValidDateFormat_1 = extendedDateRegex.test(value);
            if (isValidDateFormat || isValidDateFormat_1) {
                const get_in_date = new Date(value)
                console.log("this is date value :-----", get_in_date)
                const in_date = dateFormateShow(get_in_date)
                setData({ ...data, [name]: in_date });
            } else {
                fybfDate_input_ref?.current?.focus();
            }
        }
        if (name === 'bbfDate') {
            const fybfDateRegex = /^(\d+)[.\-\/](\d+)[.-\/]?(\d*)$/;
            const extendedDateRegex = /^(\d{2})-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d{4})$/;
            const isValidDateFormat = fybfDateRegex.test(value);
            const isValidDateFormat_1 = extendedDateRegex.test(value);
            const date_1 = new Date(data.fybfDate)
            const date_2 = new Date(value)
            if ((isValidDateFormat || isValidDateFormat_1) && date_2 >= date_1) {
                const in_date = dateFormateShow(new Date(value))
                setData({ ...data, [name]: in_date });
            } else {
                bbfDate_input_ref?.current?.focus();
            }
        }
        if (name === 'username') {
            const usernameRegex = /^[a-zA-Z][a-zA-Z0-9!@#$%^&*()_+{}\[\]:;"'<>,.?/~`|-]*$/

            const isValidUsername = usernameRegex.test(value);

            if (isValidUsername) {
                setData({ ...data, [name]: value });
            } else {
                toast.error('Username must start with char!', {
                    position: toast.POSITION.TOP_CENTER,
                });
                username_input_ref?.current?.focus();
            }
        }
        if (name === 'password') {
            const passwordRegex = /^.{5,}$/;
            const isValidPassword = passwordRegex.test(value);

            if (isValidPassword) {
                setData({ ...data, [name]: value });
            } else {
                password_input_ref?.current?.focus();
            }
        }
    }

    //triger when the change value of search input and get filter data -------------
    useEffect(() => {
        if (statesList[0]?.name === 'Not Applicable') {

        } else {
            statesList?.unshift({ id: '1', name: 'Not Applicable' });
        }
        const filteredSuggestions = statesList?.filter((item, index) => {
            const lowercaseLedgerName = item?.name?.toLowerCase();
            const lowercaseSearchInput = statename?.toLowerCase();
            const matchesSearchInput = lowercaseLedgerName?.includes(statename !== '' ? lowercaseSearchInput : '');
            return matchesSearchInput;
        })
        setFilteredStateData(statename !== '' ? statename_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : statesList : statesList);
        setSelectedItem(0)
    }, [data.statename, statesList, statename_input_ref?.current?.selectionStart])


    const handleClickOutside = (event) => {
        let clickedElement = event.target;
        let isInputField = false;
        while (clickedElement) {
            if ((clickedElement.tagName &&
                (clickedElement.tagName.toLowerCase() === 'input' ||
                    clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
                isInputField = true;
                break;
            }
            clickedElement = clickedElement.parentNode;
        }

        if (!isInputField) {
            if (nameInput && nameInput.current) {
                event.preventDefault();
                console.log("this is name input :---", nameInput)
                nameInput.current.focus();
            }
        }
    };
    
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [nameInput])


    useEffect(() => {
        const handleClickOutside = (event) => {
            let clickedElement = event.target;
            let isInputField = false;
            while (clickedElement) {
                if ((clickedElement.tagName &&
                    (clickedElement.tagName.toLowerCase() === 'input' ||
                        clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
                    isInputField = true;
                    break;
                }
                clickedElement = clickedElement.parentNode;
            }
            if (!isInputField) {
                if (nameInput && nameInput.current) {
                    event.preventDefault();
                    nameInput.current.focus();
                }
            }
        };

        const handleMouseDown = () => {
            isMouseDownInside.current = true;
        };

        const handleMouseUp = () => {
            isMouseDownInside.current = false;
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [nameInput]);



    // Keyboad functionality in dropdown select and enter---------------------------
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (showStateList) {
                if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    setSelectedItem(prevState => Math.max(prevState - 1, 0));
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setSelectedItem(prevState => Math.min(prevState + 1, filteredStateData.length - 1));
                } else if (e.key === 'Enter') {
                    e.preventDefault();
                    const selectedLink = filteredStateData[selectedItem]
                    if (selectedLink) {
                        setData({ ...data, statename: selectedLink.name })
                        setShowStateList(false)
                    }
                }
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedItem, statename, data, showStateList]);
    //---------------------------------------------------------------------------
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'a' && e.ctrlKey) {
                e.preventDefault();
                controlPlusASubmit();
            }
            if (submitConfirm === 'Accept') {
                if (e.key === 'y' || e.key === 'Y') {
                    e.preventDefault();
                    controlPlusASubmit();
                }
            }
            if (submitConfirm === 'Accept') {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    controlPlusASubmit();
                }
            }
            if (submitConfirm === 'Accept') {
                if (e.key === 'n' || e.key === 'N') {
                    e.preventDefault();
                    setSubmitConfirm('')
                    company_name_input_ref.current.focus();
                }
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [company_name, submitConfirm])


    const inputPasword = document.querySelector('.input_field');
    if (inputPasword) {
        const inputicon = document.querySelector('.input_icon');
        inputicon.addEventListener('click', () => {
            setVisible(!visible)
        })
    }
    // submit form by ctr + a -------------------------------------------------
    const controlPlusASubmit = async () => {
        if (validateForm()) {
            try {
                let data = new FormData();
                data.append('user_name', username);
                data.append('password', password);
                data.append('company_name', company_name);
                data.append('mailing_name', mailing_name);
                data.append('address', address);
                data.append('city', city);
                data.append('state', statename);
                data.append('country', countryName);
                data.append('pincode', pincode);
                data.append('mobile', mobile);
                data.append('telephone', telephone);
                data.append('fax', fax);
                data.append('email', email);
                data.append('website', website);
                data.append('formal_name', formal_name);
                data.append('suffex_symbol_amount', suffix_symbol_to_amount);
                data.append('base_currancy_symbol', base_currency_symbol);
                data.append('add_space_between_amount_symbol', add_space_between_amount_and_symbol);
                data.append('financial_year_begning_form', fybfDate);
                data.append('books_being_date', bbfDate);
                data.append('enable_tally_audit_feature', enable_tallyAudit_features);
                data.append('no_of_decimal_place', number_of_decimal_places);
                data.append('word_reprenting_after_amount', number_fo_decimal_places_in_words);
                data.append('no_of_decimal_place_for_amount', '');
                const response = await dispatch(signUp(data));
                if (response.status) {
                    toast.success(response?.message || 'Register created!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setTimeout(() => {
                        navigate('/');
                    }, 5500)
                    resetForm();
                } else {
                    toast.warn(response?.message || 'Fail!', {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setSubmitConfirm('')
                    company_name_input_ref.current.focus();
                }
            } catch (error) {
                console.log(error);
                toast.error(error?.message || 'Failed!', {
                    position: toast.POSITION.TOP_CENTER,
                });
                setSubmitConfirm('')
                company_name_input_ref.current.focus();
            }
        } else {
            toast.error('Please fill all the fields.', {
                position: toast.POSITION.TOP_CENTER
            });
            setSubmitConfirm('')
            company_name_input_ref.current.focus();
        }
    };
    const handleSubmit = async (e1) => {
        e1.preventDefault();
        if (hashtag) {
            try {
                if (validateForm()) {
                    let data = new FormData();
                    data.append('user_name', username);
                    data.append('password', password);
                    data.append('company_name', company_name);
                    data.append('mailing_name', mailing_name);
                    data.append('address', address);
                    data.append('city', city);
                    data.append('state', statename);
                    data.append('country', countryName);
                    data.append('pincode', pincode);
                    data.append('mobile', mobile);
                    data.append('telephone', telephone);
                    data.append('fax', fax);
                    data.append('email', email);
                    data.append('website', website);
                    data.append('formal_name', formal_name);
                    data.append('suffex_symbol_amount', suffix_symbol_to_amount);
                    data.append('base_currancy_symbol', base_currency_symbol);
                    data.append('add_space_between_amount_symbol', add_space_between_amount_and_symbol);
                    data.append('financial_year_begning_form', fybfDate);
                    data.append('books_being_date', bbfDate);
                    data.append('enable_tally_audit_feature', enable_tallyAudit_features);
                    data.append('no_of_decimal_place', number_of_decimal_places);
                    data.append('word_reprenting_after_amount', number_fo_decimal_places_in_words);
                    data.append('no_of_decimal_place_for_amount', '');
                    dispatch(signUp(data)).then((response) => {

                        if (response.status) {
                            toast.success(response?.message || 'Register Success!', {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setTimeout(() => {
                                navigate('/');
                            }, 5500)
                            resetForm();

                        }
                        else {
                            toast.warn(response?.message || 'Register Fail !', {
                                position: toast.POSITION.TOP_CENTER
                            });
                            sethastag(false)

                        }
                    }).catch(error => {
                        toast.error(error?.message || 'Register Failed!', {
                            position: toast.POSITION.TOP_CENTER
                        });
                        sethastag(false)
                        setSubmitConfirm('')
                    })
                } else {
                    toast.warn('Fill the input all !', {
                        position: toast.POSITION.TOP_CENTER
                    });
                    setSubmitConfirm('')
                    sethastag(false)
                    company_name_input_ref.current.focus();
                }

            } catch (error) {
                console.log(`error${error}`)
            }
        }

    }
    // Function to validate form fields---------------------------------------
    const validateForm = () => {
        return (
            data?.company_name !== '' &&
            data?.fybfDate !== '' &&
            data?.bbfDate !== '' &&
            data?.username !== '' &&
            data?.password !== '' &&
            data?.mobile !== '' &&
            data?.email !== ''
        );
    };
    // Function to reset form fields-----------------------------------------
    const resetForm = () => {
        setData(initialState)
        setSubmitConfirm('')
        sethastag(false)
    };
    return (
        <div className='container-fluid'>
            {
                submitConfirm === 'Accept' ?
                    <YesNoModal>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='modal_message'>
                                        {submitConfirm}
                                    </p>
                                </div>
                                <div className='col-5'>
                                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                                </div>
                                <div className='col-2'>or</div>
                                <div className='col-5'>
                                    <Link className='modal_link' onClick={() => { return (setSubmitConfirm(''), company_name_input_ref.current.focus()) }}>No</Link>
                                </div>
                            </div>
                        </div>
                    </YesNoModal> : null
            }
            <ToastContainer />
            <div className='row' style={{ pointerEvents: submitConfirm === 'Accept' ? 'none' : 'auto' }}>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col m-0 p-0'>
                            <h5 className='shadow-sm' style={{ background: '#d3d4d5', textAlign: 'left', paddingLeft: 5 }}><b>Registration Form </b></h5>
                        </div>
                    </div>
                    <form className='m-0 p-0' method='POST' autoComplete='off' target="_self" onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col'>
                                <div className='row'>
                                    <div className='col-sm-6 ps-1'>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Company Name<span className='star_color'>*</span></label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                autoFocus
                                                name='company_name'
                                                id='company_name'
                                                className='lab-right text-capitalize'
                                                value={company_name || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={company_name_input_ref}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Mailing Name </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='mailing_name'
                                                id='mailing_name'
                                                className='lab-right '
                                                value={mailing_name || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={mailing_name_input_ref}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Address </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='address'
                                                className='lab-right text-capitalize'
                                                value={address || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={address_input_ref}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-sm-6 ps-1'>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Financial year beginning from<span className='star_color'>*</span>  </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='fybfDate'
                                                id='fybfDate'
                                                className='lab-right text-uppercase'
                                                value={fybfDate || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={fybfDate_input_ref}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Books beginning from <span className='star_color'>*</span></label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='bbfDate'
                                                id='bbfDate'
                                                className='lab-right text-uppercase'
                                                value={bbfDate || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={bbfDate_input_ref}
                                            />

                                        </div>
                                        <p>Security</p>
                                        <div className='d-flex justify-content-between'>
                                            <label className='lab-1'> Username (Administrator)<span className='star_color'>*</span></label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='username'
                                                id='username'
                                                className='lab-right'
                                                value={username || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={username_input_ref}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center '>
                                            <label className='lab-1'> Password<span className='star_color'>*</span> </label>
                                            <label> :</label>
                                            <div className='input'>
                                                <input
                                                    type={visible ? 'text' : 'password'}
                                                    name='password'
                                                    id='password'
                                                    value={password || ""}
                                                    autoComplete='off'
                                                    className='input_field'
                                                    onKeyUp={handleChange}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onFocus={handleFocus}
                                                    ref={password_input_ref}
                                                />
                                                <span className='input_icon-wrapper'>
                                                    {visible ? <Eye className='input_icon' /> : <EyeSlash className='input_icon' />}
                                                </span>
                                            </div>
                                        </div>

                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Enable TallyAudit Features </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                list='etf'
                                                name='enable_tallyAudit_features'
                                                id='enable_tallyAudit_features'
                                                value={enable_tallyAudit_features || ""}
                                                className='lab-right'
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={enable_tallyAudit_features_input_ref} />
                                            <datalist id='etf'>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </datalist>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 ps-1'>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> City </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='city'
                                                id='city'
                                                value={city || ""}
                                                className='lab-right text-capitalize'
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={city_input_ref}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center' id='state'>
                                            <label className='lab-1'> State </label>
                                            <label> :</label>
                                            <input
                                                type='text'
                                                list="state_list"
                                                name='statename'
                                                id='stateName'
                                                className='lab-right'
                                                value={statename || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={statename_input_ref}
                                            />
                                            <div id="myDropdown-list" className={`dropdown-content-list-state ${showStateList ? 'show-list' : ''}`} ref={dropdown_ref}>
                                                <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                                    List Of State
                                                </div>
                                                <div className='col ' style={{ textAlign: 'right' }}>
                                                    <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                                    <div className='border'></div>
                                                </div>
                                                <div id='data'>
                                                    {filteredStateData?.length > 0 ? (
                                                        filteredStateData?.map((key, number) => {
                                                            const name = key?.name;
                                                            const index = name?.toLowerCase()?.indexOf(statename?.toLowerCase());
                                                            return (
                                                                <Link
                                                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                                    id='list'
                                                                    key={number}
                                                                    onClick={() => {
                                                                        setSelectedItem(number)
                                                                        if (number === selectedItem) {
                                                                            setData({ ...data, statename: name })
                                                                            setShowStateList(false)
                                                                            countryName_input_ref?.current?.focus();
                                                                            countryName_input_ref?.current?.setSelectionRange(0, 0)
                                                                        }
                                                                    }}
                                                                >
                                                                    {name === 'Not Applicable' ? (
                                                                        <>
                                                                            <SuitDiamondFill /> &nbsp;
                                                                            {name}
                                                                        </>
                                                                    ) : index !== -1 ? (
                                                                        < >
                                                                            {name?.substring(0, index)}
                                                                            <span style={{ color: 'red' }}>{name?.substring(index, index + statename?.length)}</span>
                                                                            {name?.substring(index + statename?.length)}
                                                                        </>
                                                                    ) : (
                                                                        name
                                                                    )}
                                                                </Link>
                                                            );
                                                        })
                                                    ) : (
                                                        <p style={{ color: 'red' }}>No matching items found</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center' id='country'>
                                            <label className='lab-1'> Country </label>
                                            <label> :</label>
                                            <input
                                                type='text'
                                                name='countryName'
                                                list='country_list'
                                                id='countryName'
                                                value={countryName || ""}
                                                className='lab-right'
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={countryName_input_ref}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <label className='lab-1'> Pincode </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='pincode'
                                                id='pincode'
                                                value={pincode || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className='lab-right'
                                                ref={pincode_input_ref}
                                            />
                                        </div>

                                        <div className='d-flex justify-content-between'>
                                            <label className='lab-1'> Mobile<span className='star_color'>*</span> </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='mobile'
                                                id='mobile'
                                                value={mobile || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className='lab-right'
                                                ref={mobile_input_ref}
                                            />
                                        </div>


                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6 ps-1 pb-1'>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'>  Telephone </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='telephone'
                                                id='telephone'
                                                value={telephone || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className='lab-right'
                                                ref={telephone_input_ref} />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Fax </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='fax'
                                                id='fax'
                                                value={fax || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className='lab-right'
                                                ref={fax_input_ref} />
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <label className='lab-1'> E-Mail<span className='star_color'>*</span> </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='email'
                                                id='email'
                                                value={email || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className='lab-right'
                                                ref={email_input_ref}
                                            />
                                        </div>

                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Website </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='website'
                                                id='website'
                                                value={website || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className='lab-right'
                                                ref={website_input_ref} />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='border mt-2'></div> */}

                                <div className='row border-top'>
                                    <div className='col-sm-6 ps-1 pt-1'>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1 text-truncate'> Base Currency symbol </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='base_currency_symbol'
                                                id='base_currency_symbol'
                                                value={base_currency_symbol || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className="lab-right"
                                                ref={base_currency_symbol_input_ref} />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Formal name </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='formal_name'
                                                id='formal_name'
                                                value={formal_name || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className="lab-right"
                                                ref={formal_name_input_ref}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Suffix symbol to amount </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                list='ssta'
                                                name='suffix_symbol_to_amount'
                                                id='suffix_symbol_to_amount'
                                                value={suffix_symbol_to_amount || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className='lab-right'
                                                ref={suffix_symbol_to_amount_input_ref} />
                                            <datalist id='ssta'>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </datalist>
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1 text-truncate'> Add space between amount and symbol </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                list='asbaas'
                                                name='add_space_between_amount_and_symbol'
                                                id='add_space_between_amount_and_symbol'
                                                value={add_space_between_amount_and_symbol || ""}
                                                className='lab-right'
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={add_space_between_amount_and_symbol_input_ref} />
                                            <datalist id='asbaas'>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </datalist>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 ps-1 pt-1'>

                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1'> Number of decimal places </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='number_of_decimal_places'
                                                id='number_of_decimal_places'
                                                value={number_of_decimal_places || ""}
                                                className="lab-right"
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                ref={number_of_decimal_places_input_ref} />
                                        </div>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <label className='lab-1 text-nowrap text-truncate'> Word representing amount after decimal  </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='word_representing_amount_after_decimal'
                                                id='word_representing_amount_after_decimal'
                                                value={word_representing_amount_after_decimal || ""}
                                                onChange={handleChange}
                                                onKeyUp={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className="lab-right"
                                                ref={word_representing_amount_after_decimal_input_ref} />

                                        </div>
                                        <div className='d-flex justify-content-between align-item-center mb-1'>
                                            <label className='lab-1 text-nowrap text-truncate'> Number of decimal places for amount in words </label>
                                            <label> :</label>
                                            <input
                                                type="text"
                                                name='number_fo_decimal_places_in_words'
                                                id='number_fo_decimal_places_in_words'
                                                value={number_fo_decimal_places_in_words || ""}
                                                onKeyUp={handleChange}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                onFocus={handleFocus}
                                                className="lab-right"
                                                ref={number_fo_decimal_places_in_words_input_ref} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div >
                        <div className='tomasterfooter'>
                            <div className='footer'>
                                <div className='boxfooter'>
                                    <button className='button'>
                                        <strong> Q:Quite</strong>
                                    </button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'>
                                        <strong></strong>
                                    </button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'>
                                        <strong></strong>
                                    </button>
                                </div>
                                <div className='boxfooter'>
                                    <button
                                        type='submit'
                                        className='button'
                                        onClick={() => { return (sethastag(true)) }}
                                        ref={registration_input_ref}
                                    >
                                        <strong><u> <u>A</u></u>:Accept</strong>
                                    </button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'>
                                        <strong><u></u></strong>
                                    </button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'></button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'>
                                        <strong> <u>D</u>:Delete</strong>
                                    </button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'>
                                        <strong> </strong>
                                    </button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'></button>
                                </div>
                                <div className='boxfooter'>
                                    <button disabled className='button'></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}
export default RegistrationPage
