import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { stockCategoryUpdate, stockCategoryPrimaryList, stockCategorySingleData } from '../../../../../redux/actions/action-creator';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css';
import YesNoModal from '../../../../../components/model/YesNoModal';

function StockCatagory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const initialState = {
    stock_category_name: '',
    alise_name: '',
    under: 'Primary',
    under_id: 'Primary',
  }
  const { stock_category_under_data = null } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [stock_submit, setstock_submit] = useState('')
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const stock_category_name_input_ref = useRef(null);
  const alias_name_input_ref = useRef(null);
  const inputRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const [isStockCategoryNameEntered, setIsStockCategoryNameEntered] = useState(false);
  const [hashtag, setHashtag] = useState(false)
  const [state, setState] = useState(initialState);
  useEffect(() => {
    const formData = new FormData();
    formData.append('id', id)
    dispatch(stockCategorySingleData(formData)).then((response) => {
      if (response.status) {
        setState({
          stock_category_name: response.data?.name,
          alise_name: response.data?.alias_name,
          under_id: response.data?.under,
          under: response.data?.under_name,
        })
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.Responsemessage || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [id])
  const handleInputFocus = () => {
    setShowList(true)
    const index = stock_category_under_data.findIndex(item => item.name?.toLowerCase() == state?.under?.toLowerCase());
    setSelectedItem(index)
  }
  const handleBlur = () => { setShowList(false) }
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Function to capitalize the first letter of each word
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    // To check the value is not empty of group name if empty then other field disabled
    if (name === 'stock_category_name') {
      setIsStockCategoryNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter' && name !== 'under' && state?.stock_category_name !== '') {
      e.preventDefault();
      const nextInputRef = name === 'stock_category_name' ? alias_name_input_ref :
        name === 'alise_name' ? inputRef : null
      if (nextInputRef) {
        nextInputRef.current.focus();
        if (nextInputRef.current) {
          nextInputRef.current.setSelectionRange(0, 0);
        }
      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const prevInputRef = name === 'alise_name' ? stock_category_name_input_ref :
        name === 'under' ? alias_name_input_ref : null;
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        prevInputRef.current.focus();
        if (prevInputRef.current) {
          prevInputRef.current.setSelectionRange(0, 0);
        }
      }
      return;
    }
    // Capitalize the first letter of each word for certain input fields
    const updatedValue = name === 'stock_category_name' || name === 'alise_name' ?
      capitalizeFirstLetter(value) : value;
    setState({ ...state, [name]: updatedValue })
    if (name === 'under') {
      if (state.stock_category_name !== '' && state.under !== '' && e.key === 'Enter') {
        e.preventDefault();
        inputRef.current.blur();
        setstock_submit('Accept');
      }
    }
  }
  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(stockCategoryPrimaryList(formData))
  }, [])
  //triger when the change value of search input and get filter data ---------------------------------------------------
  useEffect(() => {
    if (stock_category_under_data[0]?.name === 'Primary') {
      // Do Not Add Any Data 
    } else {
      stock_category_under_data?.unshift({ id: 'Primary', name: 'Primary' });
    }
    const filteredSuggestions = stock_category_under_data?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.under?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.under !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredData(state.under !== '' ? inputRef.current.selectionStart > 0 ? filteredSuggestions : stock_category_under_data : stock_category_under_data);
  }, [state.under, stock_category_under_data, inputRef?.current?.selectionStart])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hashtag) {
      const formData = new FormData();
      formData.append('name', state.stock_category_name)
      formData.append('alias_name', state.alise_name)
      formData.append('under', state.under_id)
      formData.append('company_id', userData?.id)
      formData.append('id', id)
      dispatch(stockCategoryUpdate(formData)).then((response) => {
        if (response.status) {
          navigate('/chart-of-account-invmas-stockcategory')
        }
        else {
          toast.warn(response?.message || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
          setHashtag(false)
          setIsStockCategoryNameEntered(false)
          stock_category_name_input_ref.current.focus();
          setstock_submit('');
        }
      }).catch(error => {
        console.log(error)
        toast.error(error?.message || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
        setHashtag(false)
        setIsStockCategoryNameEntered(false)
        stock_category_name_input_ref.current.focus();
        setstock_submit('');
      })
    }
  }
  const { stock_category_name, alise_name, under } = state;
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showList === true) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink = filteredData[selectedItem]
          if (selectedLink) {
            setState({
              ...state, under: selectedLink.name, under_id: selectedLink.id
            })
            setShowList(false)
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, under, state, showList]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'a' && e.ctrlKey) {
        e.preventDefault();
        controlPlusASubmit();
      }
      if (stock_submit === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (stock_submit === 'Accept') {
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setstock_submit('')
          stock_category_name_input_ref.current.focus();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state?.stock_category_name, stock_submit])
  // submit form by ctr + a =============
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('company_id', userData?.id);
        formData.append('name', state.stock_category_name);
        formData.append('alias_name', state.alise_name);
        formData.append('under', state.under_id);
        formData.append('id', id)
        const response = await dispatch(stockCategoryUpdate(formData));
        if (response.status) {
          navigate('/chart-of-account-invmas-stockcategory')
        } else {
          toast.warn(response?.message || 'Fail!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setstock_submit('')
          stock_category_name_input_ref.current.focus();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'Failed!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setstock_submit('')
        stock_category_name_input_ref.current.focus();
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setstock_submit('')
      stock_category_name_input_ref.current.focus();
    }
  };
  // Function to validate form fields
  const validateForm = () => {
    return (
      userData?.id !== '' &&
      state?.godown_name !== '' &&
      state?.under !== ''
    );
  };
  // Function to reset form fields
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      stock_category_name: '',
      alise_name: '',
      under_id: prevState?.under_id,
      under: prevState?.under,
    }));
    setstock_submit('');
    setTimeout(() => {
      stock_category_name_input_ref.current.focus();
    }, 50);
    setIsStockCategoryNameEntered(false);
  };



  return (
    <div className='section'>
      <div className='detail border'>
        {
          stock_submit === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {stock_submit}
                    </p>
                  </div>
                  <div className='col-5'>
                    <Link className="modal_link" onClick={controlPlusASubmit}>Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-5'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form autoComplete='off' onSubmit={handleSubmit}>
              <div className='col-6 p-3' style={stock_submit == 'Accept' ? { background: '#fff', pointerEvents: 'none' } : { background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Name</label>
                    <label> :</label>
                    <input
                      type="text"
                      name='stock_category_name'
                      id='stock_category_name'
                      className='lab-right'
                      onChange={handleChange}
                      onKeyUp={handleChange}
                      value={stock_category_name || ""}
                      ref={stock_category_name_input_ref}
                      autoFocus />
                  </div>
                  <div className='d-flex justify-content-between align-item-center' id='alis'>
                    <label className='lab-1'> (alise) </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='alise_name'
                      id='alise_name'
                      className={`lab-right ${isStockCategoryNameEntered || !stock_submit ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      ref={alias_name_input_ref}
                      value={alise_name || ""}
                      disabled={state.stock_category_name === ''} />
                  </div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Under </label>
                    <label> :</label>
                    <input
                      type='text'
                      id='under'
                      name="under"
                      ref={inputRef}
                      value={under || ""}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                      onBlur={handleBlur}
                      className={`lab-right ${isStockCategoryNameEntered || !stock_submit ? '' : 'disable-field'}`}
                      disabled={state.stock_category_name === ''} />
                    <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                        List Of Group
                      </div>
                      <div className='col ' style={{ textAlign: 'right' }}>
                        <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                        <div className='border'></div>
                      </div>
                      <div id='data'>
                        {filteredData.length > 0 ? (
                          filteredData.map((key, number) => {
                            const name = key?.name;
                            const index = name.toLowerCase().indexOf(under.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    setState({ ...state, under: name })
                                    setShowList(false)
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                    {name.substring(index + under.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tomasterfooter'><div className='footer'>
                <div className='boxfooter'>
                  <button className='button'>
                    <strong> Q:Quite</strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong></strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong></strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button type='submit' className='button' onClick={() => { return (setHashtag(true)) }}>
                    <strong><u> <u>A</u></u>:Accept</strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong><u></u></strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'></button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong> <u>D</u>:Delete</strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button'>
                    <strong> </strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'></button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'></button>
                </div>
              </div></div>
            </form>
          </div>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default StockCatagory

