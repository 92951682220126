import React from 'react'

function Budgets() {
  return (
    <div>
        this is budgets of chart of account 
      
    </div>
  )
}

export default Budgets
