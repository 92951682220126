import React from 'react'

function NavLayout({children}) {
    return (
        <div className='row border border-dark table_date_header' style={{height:19.5}} >
            <div className='col-12  d-flex justify-content-between table_header_font'style={{height:19.5}}>
                {/* Stock Summary : {date?.filter_name} {date?.name_nav ? '(Godown: ' : null} {date?.name_nav}{date?.name_nav ? ')' : null}
                <span>  {`${date?.show_from_date} To ${date?.show_to_date} `}</span> */}
                {children}
                
            </div>
        </div>
    )
}

export default NavLayout
