import React from 'react'

function Currency() {
  return (
    <div>
        this is currency
      
    </div>
  )
}

export default Currency;
