import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../home_page/Footer';
const ExceptionReports = (props) => {
    return (
        <div id='company'>
            <button type="button" class="btn-close" id='company-close' aria-label="Close" onClick={props.handleCloseExceptionReports()}></button>
            <div className='row justify-content-center'>
                <div className='col-3 bg-light border pb-3'>
                    <p style={{ textAlign: 'center' }}>Exception Reports</p>
                    <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />
                </div>
            </div>
            <div className='row justify-content-center' >
                <div className='col-6 shadow bg-light'>
                    <div className='title'>
                        <p>List of Exception Reports</p>
                    </div>
                    <div className='container-1'>
                        <ul className='master-list'>
                            <li>
                                <Link to="/create">
                                    Show More
                                </Link>
                            </li>

                        </ul>
                        <hr />
                        <div className='container-2 row'>
                            <div className='col'>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            
            <div className='tomasterfooter'><Footer /></div>

        </div>
    )
}

export default ExceptionReports

