import React from 'react'

function TableLayoutTrialBalance({ children }) {

    return (
        <div className='container-fluid' id='reletive' style={{ height: '90vh' }}>
            <div className='row border-bottom border-dark' style={{ height: 40 }}>
                <div className='col-5 border-end border-start border-dark  table_header_font'>
                    <b style={{ wordSpacing: '30px' }}> Particular </b>
                </div>
                <div className='col-7 m-0 p-0'>
                    <div className='row m-0 p-0' style={{ width: '100%', height: 39.33 }}>
                        <div className='col-3  border-end border-dark m-0 p-0 table_header_font'>
                            <b>Opening Balance</b>
                        </div>
                        <div className='col-6 border-end border-dark m-0 p-0 table_header_font'>
                            <b>Transaction</b>
                            <div className='row m-0 p-0 border-top border-dark' style={{ width: '100%' }}>
                                <div className='col-4 text-end p-0 m-0 table_header_font' >
                                    Debit
                                </div>
                                <div className='col-2 text-end p-0 m-0 border-end border-dark table_header_font' >
                                    
                                </div>

                                <div className='col-4 text-end p-0 pe-1 m-0 table_header_font' >
                                    Credit
                                </div>
                                <div className='col-2 text-end p-0 pe-1 m-0 table_header_font' >
                                    
                                </div>
                            </div>
                        </div>
                        <div className='col-3 border-end border-dark m-0 p-0 table_header_font'>
                            <b>Closing Balance</b>
                        </div>
                    </div>
                </div>
            </div>

            <div className={` row  border-bottom border-dark postion-relative`} style={{ height: '76.5vh' }} >
                <div className='col-5 border-end border-start border-dark text-start table_body_font' style={{ height: '76.5vh' }} >
                </div>
                <div className='col-7 m-0 p-0' style={{ height: '76.5vh' }}>
                    <div className='row m-0 p-0 ' style={{ width: '100%', height: '76.5vh' }}>
                        <div className='col-3 border-end border-dark m-0 p-0'>
                        </div>
                        <div className='col-6 border-end border-dark m-0 p-0'>
                        </div>
                        <div className='col-3 border-end border-dark m-0 p-0'>
                        </div>
                    </div>
                </div>
                <div className='row m-0 p-0' style={{ position: 'absolute', width: '100%' }}>
                    <div className='col-12'>
                        {children}
                    </div>
                </div>
            </div>


            <div className={`row  border-0`}>
                <div className='col-5 border-end border-dark text-start table_footer_font'>
                    Grand Total
                </div>
                <div className='col-7 m-0 p-0'>
                    <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                        <div className='col-3 border-end border-dark m-0 p-0'>

                        </div>
                        <div className='col-6 border-end border-dark m-0 p-0'>

                        </div>
                        <div className='col-3 border-end border-dark m-0 p-0'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableLayoutTrialBalance




