import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../../../../components/home_page/Footer'
import { Link } from 'react-router-dom'
const Period = (props) => {
    const [date, setdate] = useState({ from: props?.data?.date, to: '' })
    const from_input_ref = useRef(null)
    const { company_session } = useSelector(state => state.common);
    const handlechange = (e) => {
        const { name, value } = e.target;
        setdate({ ...date, [name]: value })
        if (name === 'from') {
            if (e?.key === 'Enter' && date?.from !== '') {
                e?.preventDefault();
                const match = date?.from?.match(/^(\d+)[.\-\,\'\;\`\/](\d+)[.\-\,\'\;\`\/]?(\d*)$/);
                if (match) {
                    const [, day, month, year_from] = match;
                    const year = new Date(company_session?.start_financial_year).getFullYear();
                    const financialYearStart = year;
                    const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
                    if (year_from !== '') {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            let parsedYear;
                            if (year_from.length === 2) {
                                const currentYear = new Date().getFullYear();
                                const currentCentury = Math.floor(currentYear / 100) * 100;
                                parsedYear = parseInt(year_from, 10) + currentCentury;
                                if (parsedYear === financialYearStart) {
                                    if (parsedMonth > 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const day_name = new Date(formattedDateString);
                                            const option = { weekday: 'long' }
                                            const day_show = day_name.toLocaleString('en-US', option)
                                            setdate({ ...date, from: formattedDateString })
                                            props.setData({ ...props.data, date: formattedDateString, day_name: day_show })
                                            props.setshow_period(false)
                                        } else {
                                            alert('Invalid Day')
                                        }
                                    }
                                } else if (parsedYear === financialYearEnd) {
                                    if (parsedMonth <= 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const day_name = new Date(formattedDateString);
                                            const option = { weekday: 'long' }
                                            const day_show = day_name.toLocaleString('en-US', option)

                                            setdate({ ...date, from: formattedDateString })
                                            props.setData({ ...props.data, date: formattedDateString, day_name: day_show })
                                            props.setshow_period(false)


                                        } else {
                                            alert('Invalid Day')
                                        }
                                    }
                                } else {
                                    alert('Select in Range')
                                }
                            } else if (year_from.length === 4) {
                                parsedYear = parseInt(year_from, 10);
                                if (parsedYear === financialYearStart) {
                                    if (parsedMonth > 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const day_name = new Date(formattedDateString);
                                            const option = { weekday: 'long' }
                                            const day_show = day_name.toLocaleString('en-US', option)
                                            setdate({ ...date, from: formattedDateString })
                                            props.setData({ ...props.data, date: formattedDateString, day_name: day_show })
                                            props.setshow_period(false)
                                        } else {
                                            alert('Invalid Day')
                                        }
                                    }
                                } else if (parsedYear === financialYearEnd) {
                                    if (parsedMonth <= 3) {
                                        const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                                        const parsedDay = parseInt(day, 10);
                                        if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                            const monthNames = [
                                                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                            ];
                                            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                            const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                                            const day_name = new Date(formattedDateString);
                                            const option = { weekday: 'long' }
                                            const day_show = day_name.toLocaleString('en-US', option)
                                            setdate({ ...date, from: formattedDateString })
                                            props.setData({ ...props.data, date: formattedDateString, day_name: day_show })
                                            props.setshow_period(false)
                                        } else {
                                            alert('Invalid Day')
                                        }
                                    }
                                } else {
                                    alert('Select in Range')
                                }
                            }
                        } else {
                            alert('Invalid MOnth')
                        }

                    } else {
                        const parsedMonth = parseInt(month, 10);
                        if (parsedMonth >= 1 && parsedMonth <= 12) {
                            const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                            const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                            const parsedDay = parseInt(day, 10);
                            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                                const monthNames = [
                                    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                                ];
                                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                                const day_name = new Date(formattedDateString);
                                const option = { weekday: 'long' }
                                const day_show = day_name.toLocaleString('en-US', option)
                                setdate({ ...date, from: formattedDateString })
                                props.setData({ ...props.data, date: formattedDateString, day_name: day_show })
                                props.setshow_period(false)
                            } else {
                                alert('Invalid Day')
                            }
                        } else {
                            alert('Invalid Month')
                        }
                    }
                }
                else {
                    alert("Invalid date")
                }
            }
        }
    }
    useEffect(() => {
        const handlekeydown = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                props.setshow_period(false)
                e.stopPropagation();
            }
        }
        document.addEventListener('keydown', handlekeydown)
        return () => {
            document.removeEventListener('keydown', handlekeydown);
        };
    }, [props.data, date])
    const { from } = date;
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setshow_period(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container pb-3 border border-dark' id='card-period'>
                <div className='row'>
                    <div className='col'>
                        <b className='border-bottom'>Voucher Date</b>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Date of Voucher</label>
                            <label>:</label>
                            <input
                                type='text'
                                autoFocus
                                name='from'
                                id='from'
                                ref={from_input_ref}
                                onChange={handlechange}
                                onKeyUp={handlechange}
                                value={from || ''}
                                className='lab-right' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}
export default Period;