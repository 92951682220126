import React from 'react';
import './valuation.css';
import Footer from '../../../home_page/Footer';

const Valuation = (props) => {
    return (
        <div id='company'>
            <button type="button" class="btn-close" id='company-close' aria-label="Close" onClick={props.handleCloseValuation()}></button>
            <div className='row justify-content-center'>
                <div className='col-3 bg-light border pb-3'>
                    <p style={{ textAlign: 'center' }}>Change Valuation Method</p>
                    <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-2 shadow bg-light'>
                    <div className='title'>
                        <p>List of Valuation Methods</p>
                    </div>
                    <div className='container-1'>

                        <hr />
                        <div className='container-2 row'>
                            <div className='col'>
                                <ul className='list-item'>

                                    <li style={{ textAlign: 'left' }}>At Zero Cost</li>
                                    <li style={{ textAlign: 'left' }}>At Zero Price</li>
                                    <li style={{ textAlign: 'left' }}>Avg. Cost</li>
                                    <li style={{ textAlign: 'left' }}>Avg. Price</li>
                                    <li style={{ textAlign: 'left' }}>Default</li>
                                    <li style={{ textAlign: 'left' }}>FIFO</li>
                                    <li style={{ textAlign: 'left' }}>FIFO Perpetual</li>
                                    <li style={{ textAlign: 'left' }}>Last Purchase Cost</li>
                                    <li style={{ textAlign: 'left' }}>Last Sale Price</li>
                                    <li style={{ textAlign: 'left' }}>LIFO Annual</li>
                                    <li style={{ textAlign: 'left' }}>LIFO Perpetual</li>
                                    <li style={{ textAlign: 'left' }}>Monthly Avg. Cost</li>
                                    <li style={{ textAlign: 'left' }}>Std. Cost</li>
                                    <li style={{ textAlign: 'left' }}>Std. Price</li>


                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter'><Footer /></div>
        </div>
    )
}

export default Valuation
