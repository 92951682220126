import React, { useState, useCallback, useEffect } from 'react'
import TableLayoutTrialBalance from '../../../../../components/Table/TableLayoutTrialBalance'
import NavLayout from '../../../../../components/NavNameOfComponent/NavLayout'
import TrialBalanceHelp from '../../../../../components/help/TrialBalance/TrialBalanceHelp'
import TrialBalanceFooter from '../../../../../components/Footer/TrialBalanceFooter'


function GroupSummary() {
    const [selectedItem, setSelectedItem] = useState(0)
    const [selectedItems, setSelectedItems] = useState([]);
    const [show, setShow] = useState(false)
    const [showGroup, setShowGroup] = useState(false)
    const [showLedgerWise, setShowLedgerWise] = useState(false)
    const [showMonthly, setShowMonthly] = useState(false)
    const [showBasis, setShowBasis] = useState(false)
    const [showChange, setShowChange] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [showSave, setShowSave] = useState(false)
    const [showApply, setShowApply] = useState(false)
    const data = [
        { groupname: 'Current Liabilities', opening: '', debit: '', credit: '9,99,99,99,999.00', closing: '9,99,99,99,999.00 Cr' },
        // { groupname: 'Purchase Account', opening: '', debit: '9,99,99,99,999.00', credit: '', closing: '9,99,99,99,999.00 Dr' }
    ]
    const handleKeyDown = useCallback((e) => {
        // if (show || godownWise || stockGroup || basic || exceptionReports || saveView || valuation) {

        // } else {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedItem(prevState => Math.min(prevState + 1, data?.length - 1));
            }
        if (e.key === 'Enter') {
            e.preventDefault();
            const selectedList = data[selectedItem]
            if (selectedList) {
                // navigate(`../stock_item_voucher`)
            }
        }
        // }
    }, [selectedItem, data])
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown])
    return (
        <div className='section'>
            <div className='detail'>
                <div className='container-fluid'>
                    <NavLayout>
                        Group Summary <span>1-Apr-2024 To 31-March-2024</span>
                    </NavLayout>
                </div>
                <TableLayoutTrialBalance>
                    {
                        // loading ? <div>Loading...</div> :
                        data?.map((item, index) => {
                            // // console.log('this is data :----', data)
                            // total_opening_value += parseFloat(item?.stock_item ? item?.grandOpeningData?.value : 0)
                            // total_inwards_value += parseFloat(item?.stock_item ? item?.grandInwardData?.value : 0)
                            // total_outwards_value += parseFloat(item?.stock_item ? item?.grandOutwardData?.value : 0)
                            // total_closing_value += parseFloat(item?.stock_item ? item?.grandClosingData?.value : 0)
                            // const parts = item?.grandOpeningData?.quantity?.split(' ');
                            // const parts_1 = item?.grandInwardData?.quantity?.split(' ');
                            // const parts_2 = item?.grandOutwardData?.quantity?.split(' ');
                            // const parts_3 = item?.grandClosingData?.quantity?.split(' ');
                            // // unit_name = parts[1]
                            // // unit_name = 'Nos'
                            // const unit_name = parts && parts.length > 1 ? parts[1] : '';
                            // total_opnening_quantity += parseFloat(item?.stock_item && parts ? parts[0] : 0)
                            // total_inwards_quantity += parseFloat(item?.stock_item && parts_1 ? parts_1[0] : 0)
                            // total_outwards_quantity += parseFloat(item?.stock_item && parts_2 ? parts_2[0] : 0)
                            // total_closing_quantity += parseFloat(item?.stock_item && parts_3 ? parts_3[0] : 0)
                            return (
                                <div key={index} className={selectedItems.includes(index) ? 'selected_multi' : ''}>
                                    <div className={`row ${index === selectedItem ? selectedItems.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`} >
                                        <div className={`col-5 border-end border-start border-dark text-start table_body_font ${item?.stock_item ? 'ps-1' : ''}`}>
                                            {/* {item?.stock_item?.item_name} {item?.location_name} */}
                                            {item.groupname}
                                        </div>
                                        <div className='col-7 m-0 p-0'>
                                            <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                                <div className='col-3 border-end border-dark m-0 p-0 table_header_font'>
                                                    {item.opening}
                                                </div>
                                                <div className='col-6 m-0 p-0'>
                                                    <div className='row m-0 p-0 ' style={{ width: '100%' }}>
                                                        <div className='col-4 text-end p-0 m-0 table_header_font' >
                                                            {item.debit}
                                                        </div>
                                                        <div className='col-2 text-end p-0 m-0  table_header_font' >
                                                            100%
                                                        </div>

                                                        <div className='col-4 text-end p-0 pe-1 m-0 table_header_font' >
                                                            {item.credit}
                                                        </div>
                                                        <div className='col-2 text-end p-0 pe-1 m-0 table_header_font' >
                                                            100%
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-3 border-end border-dark m-0 p-0 table_header_font'>
                                                    {item.closing}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </TableLayoutTrialBalance>
                <TrialBalanceFooter />
            </div>
            <div className='help' style={{ position: 'relative' }}>
                <TrialBalanceHelp
                    setShow={setShow}
                    setShowGroup={setShowGroup}
                    setShowLedgerWise={setShowLedgerWise}
                    setShowMonthly={setShowMonthly}
                    setShowBasis={setShowBasis}
                    setShowChange={setShowChange}
                    setShowReport={setShowReport}
                    setShowSave={setShowSave}
                    setShowApply={setShowApply}
                />
            </div>
        </div>
    )
}

export default GroupSummary
