import React from 'react'

function Currencies() {
  return (
    <div>
        this is currencies of char of account
      
    </div>
  )
}

export default Currencies
