import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { stockGroupCreate, stockGroupList } from '../../../../../redux/actions/action-creator';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css'
import YesNoModal from '../../../../../components/model/YesNoModal';
const is_gst_applicable_array =
  [
    { name: 'Applicable' },
    { name: 'Not Applicable' },
  ]
const yes_no_array =
  [
    { name: 'Yes' },
    { name: 'No' },
  ]
const taxability_array =
  [
    { name: 'Exempt' },
    { name: 'Nill Rated' },
    { name: 'None-Gst' },
    { name: 'Taxable' },
  ]
const hsn_detail_gst_rate_array =
  [
    { name: 'As per Company/Stock Group' },
    { name: 'Specify Details Here' },
    { name: 'Specify in Voucher' },
  ]
function StockGroup() {
  const dispatch = useDispatch();

  const { stock_group_data = null } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);

  const isMouseDownInside = useRef(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const stock_name_input_ref = useRef(null);
  const alise_name_input_ref = useRef(null);
  const description_input_ref = useRef(null);
  const is_gst_applicable_input_ref = useRef(null);
  const hsn_sac_details_input_ref = useRef(null);
  const source_of_details_input_ref = useRef(null);
  const hsn_sac_input_ref = useRef(null);
  // const description_input_ref = useRef(null);
  const gst_rate_details_input_ref = useRef(null);
  const taxability_type_input_ref = useRef(null);
  const igst_rate_input_ref = useRef(null);
  const cgst_rate_input_ref = useRef(null);
  const sgst_utst_rate_input_ref = useRef(null);
  const cess_valuation_type_input_ref = useRef(null);
  const cess_rate_input_ref = useRef(null);
  const applicable_for_reverse_charge_input_ref = useRef(null);
  const eligible_for_tax_input_credit_input_ref = useRef(null);
  const hiddenRef = useRef(null);
  const type_of_supply_input_ref = useRef(null);

  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataIsGstApplicable, setFilteredDataIsGstApplicable] = useState([])
  const [filteredDataYesNo, setFilteredDataYesNo] = useState([])
  const [filteredDataHsnDetailGstRate, setFilteredDataHsnDetailGstRate] = useState([])
  const [filteredDataTaxability, setFilteredDataTaxability] = useState([])

  const [selectedItem, setSelectedItem] = useState(0);
  const [showList, setShowList] = useState(false);
  const [isGstAppShow, setIsGstAppShow] = useState(false)
  const [hsnSacDetailShow, setHsnSacDetailShow] = useState(false)
  const [gstRateDetailsShow, setGstRateDetailsShow] = useState(false)
  const [taxabilityShow, setTaxabilityShow] = useState(false)
  const [cessValueShow, setCessValueShow] = useState(false)
  const [applicableForResShow, setApplicableForResShow] = useState(false)
  const [eligibleForTaxshow, setEligibleForTaxShow] = useState(false)


  const [state, setState] = useState({
    stock_name: '',
    alise_name: '',
    description: '',
    under: 'Primary',
    under_id: 'Primary',
    is_gst_applicable: 'Applicable',
    hsn_sac_details: 'As per Company/Stock Group',
    source_of_details: 'Not Applicable',
    hsn_sac: '',
    description: '',
    gst_rate_details: 'As per Company/Stock Group',
    taxability_type: '',
    igst_rate: '0',
    cgst_rate: '0',
    sgst_utst_rate: '0',
    cess_valuation_type: 'Not Applicable',
    cess_rate: '0',
    applicable_for_reverse_charge: 'No',
    eligible_for_tax_input_credit: 'No',
    type_of_supply: '',
    hidden: '',
  });
  const [isStockGroupNameEntered, setIsStockGroupNameEntered] = useState(false);
  const [hashtag, setHashtag] = useState(false);
  const [submitConfirm, setSubmitConfirm] = useState('');
  const [backspaceCount, setBackspaceCount] = useState(0)
  const [nameInput, setNameInput] = useState(null)

  const { stock_name, alise_name, description, under, is_gst_applicable, hsn_sac_details, source_of_details,
    hsn_sac, gst_rate_details, taxability_type, igst_rate, cgst_rate, sgst_utst_rate, cess_valuation_type,
    cess_rate, applicable_for_reverse_charge, eligible_for_tax_input_credit, type_of_supply, hidden } = state;


  function getNextInputRef(name, value) {
    switch (name) {
      case 'stock_name': return alise_name_input_ref;
      case 'alise_name': return inputRef;
      case 'under': return is_gst_applicable_input_ref;
      case 'is_gst_applicable': return state?.is_gst_applicable === 'Not Applicable' ? null : hsn_sac_details_input_ref;
      case 'hsn_sac_details': return state?.hsn_sac_details === 'As per Company/Stock Group'
        ? gst_rate_details_input_ref
        : hsn_sac_input_ref;
      case 'hsn_sac': return description_input_ref;
      case 'description': return gst_rate_details_input_ref;
      case 'gst_rate_details': return (value === 'As per Company/Stock Group') ? null : taxability_type_input_ref;
      case 'taxability_type': return (value === 'Taxable') ? igst_rate_input_ref : null;
      case 'igst_rate': return cgst_rate_input_ref;
      case 'cgst_rate': return sgst_utst_rate_input_ref;
      case 'sgst_utst_rate': return cess_valuation_type_input_ref;
      case 'cess_valuation_type': return (value === 'Based on Value' || value === 'Based on Quantity ')
        ? cess_rate_input_ref
        : applicable_for_reverse_charge_input_ref;
      case 'applicable_for_reverse_charge': return eligible_for_tax_input_credit_input_ref;
      case 'eligible_for_tax_input_credit': return null;
      default: return null;
    }
  }
  function getPrevInputRef(name, value) {
    switch (name) {
      case 'eligible_for_tax_input_credit': return applicable_for_reverse_charge_input_ref;
      case 'applicable_for_reverse_charge': return cess_valuation_type_input_ref;
      case 'cess_valuation_type': return sgst_utst_rate_input_ref;
      case 'sgst_utst_rate': return cgst_rate_input_ref;
      case 'cgst_rate': return igst_rate_input_ref;
      case 'igst_rate': return taxability_type_input_ref;
      case 'taxability_type': return gst_rate_details_input_ref;
      case 'gst_rate_details': return (state.hsn_sac_details === 'As per Company/Stock Group')
        ? hsn_sac_details_input_ref
        : description_input_ref;
      case 'description': return hsn_sac_input_ref;
      case 'hsn_sac': return (value === 'As per Company/Stock Group' || value === 'Specify in Voucher')
        ? gst_rate_details_input_ref
        : hsn_sac_details_input_ref;
      case 'hsn_sac_details': return is_gst_applicable_input_ref;
      case 'is_gst_applicable': return inputRef;
      case 'under': return alise_name_input_ref;
      default: return stock_name_input_ref;
    }
  }

  function getCurrentInputRef(name) {
    switch (name) {
      case 'stock_name': return stock_name_input_ref;
      case 'alise_name': return alise_name_input_ref;
      case 'under': return inputRef;
      case 'is_gst_applicable': return is_gst_applicable_input_ref;
      case 'hsn_sac_details': return hsn_sac_details_input_ref
      case 'hsn_sac': return hsn_sac_input_ref;
      case 'description': return description_input_ref;
      case 'gst_rate_details': return gst_rate_details_input_ref;
      case 'taxability_type': return taxability_type_input_ref
      case 'igst_rate': return igst_rate_input_ref;
      case 'cgst_rate': return cgst_rate_input_ref;
      case 'sgst_utst_rate': return sgst_utst_rate_input_ref;
      case 'cess_valuation_type': return cess_valuation_type_input_ref
      case 'applicable_for_reverse_charge': return applicable_for_reverse_charge_input_ref;
      case 'eligible_for_tax_input_credit': return eligible_for_tax_input_credit_input_ref;
      default: return null;
    }
  }

  // Trigger focus on input ------------------------------------------------
  const handleFocus = (e) => {
    const { name, value } = e.target;
    const ref = getCurrentInputRef(name);
    if (ref) {
      setNameInput(ref);
    }
    if (name === 'under') {
      setShowList(true)
      const index = stock_group_data.findIndex(item => item.name?.toLowerCase() == state?.under?.toLowerCase());
      setSelectedItem(index)
    }
    if (name === 'is_gst_applicable') {
      setIsGstAppShow(true)
    }
    if (name === 'hsn_sac_details') {
      setHsnSacDetailShow(true)
    }
    if (name === 'gst_rate_details') {
      setGstRateDetailsShow(true)
    }
    if (name === 'taxability_type') {
      setTaxabilityShow(true)
    }
  }


  // Trigger blur on input ------------------------------------------------
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'under') {
      setShowList(false)
    }
    if (name === 'is_gst_applicable') {
      setIsGstAppShow(false)
    }
    if (name === 'hsn_sac_details') {
      setHsnSacDetailShow(false)
    }
    if (name === 'gst_rate_details') {
      setGstRateDetailsShow(false)
    }
    if (name === 'taxability_type') {
      setTaxabilityShow(false)
    }
    if (name === 'applicable_for_reverse_charge') {
      setApplicableForResShow(false)
      const normalizedValue = value.toLowerCase().trim();
      const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
      const validPatternNo = /^(n|o|no)?$/;
      if (normalizedValue === '') {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternNo.test(normalizedValue)) {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternYes.test(normalizedValue)) {
        setState({ ...state, [name]: 'Yes' })
      }
    }
    if (name === 'eligible_for_tax_input_credit') {
      setEligibleForTaxShow(false)
      const normalizedValue = value.toLowerCase().trim();
      const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
      const validPatternNo = /^(n|o|no)?$/;
      if (normalizedValue === '') {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternNo.test(normalizedValue)) {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternYes.test(normalizedValue)) {
        setState({ ...state, [name]: 'Yes' })
      }
    }

  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    if (name === 'stock_name') {
      setIsStockGroupNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter' && state?.stock_name !== '') {
      e.preventDefault();
      const nextInputRef = getNextInputRef(name, value)
      if (nextInputRef) {
        nextInputRef.current.focus();
        if (nextInputRef.current) {
          nextInputRef.current.setSelectionRange(0, 0);
        }
      }
      if (name === 'taxability_type') {
        if (value === 'Exempt' || value === 'Nil Rated' || value === 'Non-GST') {
          setSubmitConfirm('Accept')
          taxability_type_input_ref.current.blur();
        }
      }
      if (name === 'is_gst_applicable') {
        if (value === 'Not Applicable') {
          setSubmitConfirm('Accept')
          is_gst_applicable_input_ref.current.blur();
        }
      }
      if (name === 'eligible_for_tax_input_credit') {
        setSubmitConfirm('Accept')
        eligible_for_tax_input_credit_input_ref.current.blur();
      }
      if (name === 'gst_rate_details') {
        if (value === 'As per Company/Stock Group') {
          setSubmitConfirm('Accept')
          gst_rate_details_input_ref.current.blur();
        }
      }
      if (name === 'igst_rate') {
        const cleanedValue = value.replace(/^0+/, '');
        const igstRate = parseInt(cleanedValue, 10) || 0;
        setState((prevState) => ({
          ...prevState,
          igst_rate: cleanedValue.toString(),
          cgst_rate: igstRate / 2,
          sgst_utst_rate: igstRate / 2,
        }));
      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = getPrevInputRef(name);
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }

    // Set data in state accourding to name and validate the value -------
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'under':
        const group = stock_group_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'is_gst_applicable':
        const nat_group = is_gst_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nat_group) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'hsn_sac_details':
        const group_behaves = hsn_detail_gst_rate_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group_behaves) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'gst_rate_details':
        const nett_debit = hsn_detail_gst_rate_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nett_debit) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'taxability_type':
        const used_for = taxability_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (used_for) {
          setState({ ...state, [name]: value })
        }
        break;
      // case 'cess_valuation_type':
      //   const method_to = applicable_not_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
      //   if (method_to) {
      //     setState({ ...state, [name]: value })
      //   } else {
      //     setShowMethodForList(true)
      //   }
      //   break;
      case 'applicable_for_reverse_charge':
        const appli = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (appli) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'eligible_for_tax_input_credit':
        const elig = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (elig) {
          setState({ ...state, [name]: value })
        }
        break;
      default:
        const updatedValue = name === 'stock_name' || name === 'alise_name' || name === 'hsn_sac' || name === 'description' ?
          capitalizeFirstLetter(value) : value;
        setState({ ...state, [name]: updatedValue })
        break;
    }
  }

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(stockGroupList(formData))
  }, [])

  //triger when the change value of search input and get filter data ---------------------------------------------------
  useEffect(() => {
    if (stock_group_data[0]?.name === 'Primary') { } else {
      stock_group_data?.unshift({ id: 'Primary', name: 'Primary' });
    }
    const filteredSuggestions = stock_group_data?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.under?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.under !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredData(state.under !== '' ? inputRef.current.selectionStart > 0 ? filteredSuggestions : stock_group_data : stock_group_data);

  }, [state.under, stock_group_data, inputRef?.current?.selectionStart])



  useEffect(() => {
    const filteredSuggestions = yes_no_array?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = (applicableForResShow ? state?.applicable_for_reverse_charge : eligible_for_tax_input_credit)?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes((applicableForResShow ? state?.applicable_for_reverse_charge : eligible_for_tax_input_credit) !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataYesNo(((applicableForResShow ? state?.applicable_for_reverse_charge : eligible_for_tax_input_credit)) !== '' ? ((applicableForResShow ? applicable_for_reverse_charge_input_ref : eligible_for_tax_input_credit_input_ref))?.current?.selectionStart > 0 ? filteredSuggestions : yes_no_array : yes_no_array);
    if (filteredSuggestions?.length > 0 && ((applicableForResShow ? applicable_for_reverse_charge_input_ref : eligible_for_tax_input_credit_input_ref))?.current?.selectionStart > 0) {
      setSelectedItem(0);
    }
  }, [state.applicable_for_reverse_charge, eligible_for_tax_input_credit, yes_no_array, ((applicableForResShow ? applicable_for_reverse_charge_input_ref : eligible_for_tax_input_credit_input_ref))?.current?.selectionStart])


  useEffect(() => {
    const filteredSuggestions = hsn_detail_gst_rate_array?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = (hsnSacDetailShow ? state?.hsn_sac_details : gst_rate_details)?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes((hsnSacDetailShow ? state?.hsn_sac_details : gst_rate_details) !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataHsnDetailGstRate(((hsnSacDetailShow ? state?.hsn_sac_details : gst_rate_details)) !== '' ? ((hsnSacDetailShow ? hsn_sac_details_input_ref : gst_rate_details_input_ref))?.current?.selectionStart > 0 ? filteredSuggestions : hsn_detail_gst_rate_array : hsn_detail_gst_rate_array);
    if (filteredSuggestions?.length > 0 && ((hsnSacDetailShow ? hsn_sac_details_input_ref : gst_rate_details_input_ref))?.current?.selectionStart > 0) {
      setSelectedItem(0);
    }
  }, [state.hsn_sac_details, gst_rate_details, hsn_detail_gst_rate_array, ((hsnSacDetailShow ? hsn_sac_details_input_ref : gst_rate_details_input_ref))?.current?.selectionStart])



  useEffect(() => {
    const filteredSuggestions = is_gst_applicable_array?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.is_gst_applicable?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.is_gst_applicable !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataIsGstApplicable(state.is_gst_applicable !== '' ? is_gst_applicable_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : is_gst_applicable_array : is_gst_applicable_array);
    if (filteredSuggestions?.length > 0 && is_gst_applicable_input_ref?.current?.selectionStart > 0) {
      setSelectedItem(0);
    }
  }, [state.is_gst_applicable, is_gst_applicable_array, is_gst_applicable_input_ref?.current?.selectionStart])

  useEffect(() => {
    const filteredSuggestions = taxability_array?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.taxability_type?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.taxability_type !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataTaxability(state.taxability_type !== '' ? taxability_type_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : taxability_array : taxability_array);
    if (filteredSuggestions?.length > 0 && taxability_type_input_ref?.current?.selectionStart > 0) {
      setSelectedItem(0);
    }
  }, [state.taxability_type, is_gst_applicable_array, taxability_type_input_ref?.current?.selectionStart])

  const handleClickOutside = (event) => {
    let clickedElement = event.target;
    let isInputField = false;
    while (clickedElement) {
      if ((clickedElement.tagName &&
        (clickedElement.tagName.toLowerCase() === 'input' ||
          clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
        isInputField = true;
        break;
      }
      clickedElement = clickedElement.parentNode;
    }

    if (!isInputField) {
      if (nameInput && nameInput.current) {
        event.preventDefault();
        console.log("this is name input :---", nameInput)
        nameInput.current.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [nameInput])


  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event.target;
      let isInputField = false;
      while (clickedElement) {
        if ((clickedElement.tagName &&
          (clickedElement.tagName.toLowerCase() === 'input' ||
            clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement.parentNode;
      }
      if (!isInputField) {
        if (nameInput && nameInput.current) {
          event.preventDefault();
          nameInput.current.focus();
        }
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [nameInput]);

  const handleKeyDown = useCallback((e) => {
    if (showList || isGstAppShow || hsnSacDetailShow || gstRateDetailsShow || taxabilityShow || applicableForResShow || eligibleForTaxshow) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedItem(prevState => Math.min(prevState + 1, (isGstAppShow ? filteredDataIsGstApplicable.length : hsnSacDetailShow || gstRateDetailsShow ? filteredDataHsnDetailGstRate.length : taxabilityShow ? filteredDataTaxability.length : applicableForResShow || eligibleForTaxshow ? filteredDataYesNo.length : filteredData.length) - 1));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        const selectedLink = isGstAppShow ? filteredDataIsGstApplicable[selectedItem] : hsnSacDetailShow || gstRateDetailsShow ? filteredDataHsnDetailGstRate[selectedItem] : taxabilityShow ? filteredDataTaxability[selectedItem] : applicableForResShow || eligibleForTaxshow ? filteredDataYesNo[selectedItem] : filteredData[selectedItem]
        if (selectedLink) {
          if (showList) {
            setState({
              ...state,
              under: selectedLink.name,
              under_id: selectedLink.id,
              source_of_details: selectedLink.name,
              hsn_sac: selectedLink.name === 'Primary' ? '' : selectedLink.HSN_SAC,
              description: selectedLink.name === 'Primary' ? '' : selectedLink.description,
              taxability_type: selectedLink.name === 'Primary' ? '' : selectedLink.taxability_type,
              igst_rate: selectedLink.name === 'Primary' ? '0' : selectedLink.IGST_rate,
              cgst_rate: selectedLink.name === 'Primary' ? '0' : selectedLink.CGST_rate,
              sgst_utst_rate: selectedLink.name === 'Primary' ? '0' : selectedLink.SGST_UTGST_rate
            })
            setShowList(false)
          }
          if (isGstAppShow) {
            console.log("this is selected :----", selectedItem)
            setState(prevState => ({
              ...prevState,
              is_gst_applicable: selectedLink.name
            }));
            setIsGstAppShow(false)
          }
          if (hsnSacDetailShow) {
            setState(prevState => ({
              ...prevState,
              hsn_sac_details: selectedLink.name
            }));
            setHsnSacDetailShow(false)
          }
          if (gstRateDetailsShow) {
            setState(prevState => ({
              ...prevState,
              gst_rate_details: selectedLink.name
            }));
            setGstRateDetailsShow(false)
          }
          if (taxabilityShow) {
            setState(prevState => ({
              ...prevState,
              taxability_type: selectedLink.name
            }));
            setTaxabilityShow(false)
          }
          if (applicableForResShow) {
            setState(prevState => ({
              ...prevState,
              applicable_for_reverse_charge: selectedLink.name
            }));
            setApplicableForResShow(false)
          }
          if (eligibleForTaxshow) {
            setState(prevState => ({
              ...prevState,
              eligible_for_tax_input_credit: selectedLink.name
            }));
            setEligibleForTaxShow(false)
          }

        }
      }
    }
  }, [selectedItem, filteredData, filteredDataHsnDetailGstRate, filteredDataIsGstApplicable, filteredDataTaxability, filteredDataYesNo, showList, isGstAppShow, hsnSacDetailShow, gstRateDetailsShow, taxabilityShow, eligibleForTaxshow, applicableForResShow])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  //---------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'a' && e.ctrlKey) {
        e.preventDefault();
        controlPlusASubmit();
      }
      if (submitConfirm === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (submitConfirm === 'Accept') {
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setSubmitConfirm('')
          stock_name_input_ref.current.focus();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [submitConfirm])
  // submit form by ctr + a -------------------------------------------------
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('company_id', userData?.id)
        formData.append('name', state.stock_name)
        formData.append('alias_name', state.alise_name)
        formData.append('under', state.under_id)
        formData.append('qty_items_added', '')
        formData.append('HSN_SAC_details', state.hsn_sac_details)
        formData.append('source_details', state.source_of_details)
        formData.append('HSN_SAC', state.hsn_sac)
        formData.append('description', state.description)
        formData.append('GST_rate_details', state.gst_rate_details)
        formData.append('taxability_type', state.taxability_type)
        formData.append('IGST_rate', parseFloat(state.igst_rate))
        formData.append('CGST_rate', parseFloat(state.cgst_rate))
        formData.append('SGST_UTGST_rate', parseFloat(state.sgst_utst_rate))
        formData.append('cess_validation', state.cess_valuation_type)
        formData.append('cess_rate', state.cess_rate)
        formData.append('application_of_reverse', state.applicable_for_reverse_charge)
        formData.append('eligible_tax_credit', state.eligible_for_tax_input_credit)
        const response = await dispatch(stockGroupCreate(formData));
        if (response.status) {
          toast.success(response?.message || 'Group created!', {
            position: toast.POSITION.TOP_CENTER
          });
          const formData = new FormData();
          formData.append('company_id', userData?.id)
          dispatch(stockGroupList(formData))
          resetForm();
        } else {
          toast.warn(response?.message || 'Fail!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setSubmitConfirm('')
          stock_name_input_ref.current.focus();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'catch error ....!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setSubmitConfirm('')
        stock_name_input_ref.current.focus();
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setSubmitConfirm('')
      stock_name_input_ref.current.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (hashtag) {
      if (validateForm()) {
        try {
          const formData = new FormData();
          formData.append('company_id', userData?.id)
          formData.append('name', state.stock_name)
          formData.append('alias_name', state.alise_name)
          formData.append('under', state.under_id)
          formData.append('qty_items_added', '')
          formData.append('HSN_SAC_details', state.hsn_sac_details)
          formData.append('source_details', state.source_of_details)
          formData.append('HSN_SAC', state.hsn_sac)
          formData.append('description', state.description)
          formData.append('GST_rate_details', state.gst_rate_details)
          formData.append('taxability_type', state.taxability_type)
          formData.append('IGST_rate', parseFloat(state.igst_rate))
          formData.append('CGST_rate', parseFloat(state.cgst_rate))
          formData.append('SGST_UTGST_rate', parseFloat(state.sgst_utst_rate))
          formData.append('cess_validation', state.cess_valuation_type)
          formData.append('cess_rate', state.cess_rate)
          formData.append('application_of_reverse', state.applicable_for_reverse_charge)
          formData.append('eligible_tax_credit', state.eligible_for_tax_input_credit)
          const response = dispatch(stockGroupCreate(formData));
          if (response.status) {
            toast.success(response?.message || 'Group created!', {
              position: toast.POSITION.TOP_CENTER
            });
            const formData = new FormData();
            formData.append('company_id', userData?.id)
            dispatch(stockGroupList(formData))
            resetForm();
          } else {
            toast.warn(response?.message || 'Fail!', {
              position: toast.POSITION.TOP_CENTER,
            });
            setSubmitConfirm('')
            stock_name_input_ref.current.focus();
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.message || 'catch error ....!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setSubmitConfirm('')
          stock_name_input_ref.current.focus();
        }
      } else {
        toast.error('Please fill all the fields.', {
          position: toast.POSITION.TOP_CENTER
        });
        setSubmitConfirm('')
        stock_name_input_ref.current.focus();
      }
    }
  }

  // Function to validate form fields---------------------------------------
  const validateForm = () => {
    return (
      userData?.id !== '' &&
      state?.stock_name !== '' &&
      state?.under !== '' &&
      state.is_gst_applicable !== '' &&
      state.hsn_sac_details !== '' &&
      state.gst_rate_details !== '' &&
      state.igst_rate !== '' &&
      state.cgst_rate !== '' &&
      state.sgst_utst_rate !== '' &&
      state.cess_valuation_type !== '' &&
      state.cess_rate !== '' &&
      state.applicable_for_reverse_charge !== '' &&
      state.eligible_for_tax_input_credit !== ''
    );
  };
  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      stock_name: '',
      alise_name: '',
      description: '',
      under: 'Primary',
      under_id: 'Primary',
      is_gst_applicable: 'Applicable',
      hsn_sac_details: 'As per Company/Stock Group',
      source_of_details: 'Not Applicable',
      hsn_sac: '',
      description: '',
      gst_rate_details: 'As per Company/Stock Group',
      taxability_type: '',
      igst_rate: '0',
      cgst_rate: '0',
      sgst_utst_rate: '0',
      cess_valuation_type: 'Not Applicable',
      cess_rate: '0',
      applicable_for_reverse_charge: 'No',
      eligible_for_tax_input_credit: 'No',
    }));
    submitConfirm('');
    setTimeout(() => {
      stock_name_input_ref.current.focus();
    }, 50);
    setIsStockGroupNameEntered(false);
  };
  return (
    <div className='section'>
      <div className='detail'>
        {
          submitConfirm === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {submitConfirm}
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        <div className='container-fluid' style={{ background: 'rgba(0,0,0,0.1)', height: '94.3vh' }}>
          <form autoComplete='off' onSubmit={handleSubmit} >
            <div className='row' style={{ width: '50%', background: '#fff', height: '90vh' }}>
              <div className='col-12 p-0 ps-1 pe-1 pt-1' >
                <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1'> Name</label>
                  <label> :</label>
                  <input
                    type="text"
                    name='stock_name'
                    id='stock_name'
                    className={`lab-right`}
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    ref={stock_name_input_ref}
                    value={stock_name || ""}
                    autoFocus />
                </div>
                <div className='d-flex justify-content-between align-item-center' id='alis'>
                  <label className='lab-1'> (alise) </label>
                  <label> :</label>
                  <input
                    type="text"
                    name='alise_name'
                    id='alise_name'
                    className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    ref={alise_name_input_ref}
                    value={alise_name || ""}
                    disabled={isStockGroupNameEntered === false} />
                </div>
              </div>
              <div className='col-12 p-0 ps-1 pe-1' >
                <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1'> Technical Name of Product </label>
                  <label> :</label>
                  <input
                    type='text'
                    id='under'
                    name="under"
                    ref={inputRef}
                    value={under || ""}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                    disabled={isStockGroupNameEntered === false} />
                  <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                      List Of Group
                    </div>
                    <div className='col ' style={{ textAlign: 'right' }}>
                      <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                      <div className='border'></div>
                    </div>
                    <div id='data'>
                      {filteredData.length > 0 ? (
                        filteredData.map((key, number) => {
                          const name = key?.name;
                          const index = name.toLowerCase().indexOf(under.toLowerCase());
                          return (
                            <Link
                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                              id='list'
                              key={number}
                              // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedItem(number)
                                if (number === selectedItem) {
                                  setState({ ...state, under: name })
                                  setShowList(false)
                                  is_gst_applicable_input_ref.current.focus();
                                }
                              }}
                            >
                              {index !== -1 ? (
                                < >
                                  {name.substring(0, index)}
                                  <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                  {name.substring(index + under.length)}
                                </>
                              ) : (
                                name
                              )}
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 p-0 ps-1 pe-1'>
                <p className='m-0 p-0 text-start'><strong> <u>Statutory Details</u></strong></p>
                <div className='d-flex justify-content-between align-item-center position-relative'>
                  <label className='lab-1'> Is GST applicable </label>
                  <label> :</label>
                  <input
                    type="text"
                    name='is_gst_applicable'
                    className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    ref={is_gst_applicable_input_ref}
                    value={is_gst_applicable || ""}
                    disabled={isStockGroupNameEntered === false} />
                  <div id="myDropdown-list" className={`dropdown_yes_no_list ${isGstAppShow ? 'show-list' : ''}`} ref={dropdownRef}>
                    <div className='col dropdown_header'>
                      Select List
                    </div>
                    <div id='data'>
                      {filteredDataIsGstApplicable?.length > 0 ? (
                        filteredDataIsGstApplicable?.map((key, number) => {
                          const name = key?.name;
                          const id = key?.id;
                          const index = name?.toLowerCase()?.indexOf(is_gst_applicable?.toLowerCase());
                          return (
                            <Link
                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                              id='list'
                              key={number}
                              onClick={() => {
                                setSelectedItem(number)
                                if (number === selectedItem) {
                                  setState({ ...state, is_gst_applicable: name })
                                  // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                  // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                  setIsGstAppShow(false)
                                }
                              }}
                            >
                              {index !== -1 ? (
                                < >
                                  {name.substring(0, index)}
                                  <span style={{ color: 'red' }}>{name.substring(index, index + is_gst_applicable.length)}</span>
                                  {name.substring(index + is_gst_applicable.length)}
                                </>
                              ) : (
                                name
                              )}
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}
                    </div>
                  </div>
                </div>
                {
                  is_gst_applicable === 'Applicable' ?
                    <div>
                      <div>
                        <p className='m-0 p-0 text-start'><strong> <u> HSN/SAC & Related Details</u></strong></p>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> HSN/SAC Details </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='hsn_sac_details'
                            id='hsn_sac_details'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={hsn_sac_details_input_ref}
                            value={hsn_sac_details || ""}
                            disabled={isStockGroupNameEntered === false} />
                          <div id="myDropdown-list" className={`dropdown_hsn_gst_list ${hsnSacDetailShow ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              List of Action
                            </div>
                            <div id='data'>
                              {filteredDataHsnDetailGstRate?.length > 0 ? (
                                filteredDataHsnDetailGstRate?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(hsn_sac_details?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, hsn_sac_details: name })
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                          setHsnSacDetailShow(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + hsn_sac_details.length)}</span>
                                          {name.substring(index + hsn_sac_details.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>HSN/SAC </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='hsn_sac'
                            id='hsn_sac'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            value={hsn_sac || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={hsn_sac_input_ref}
                            disabled={hsn_sac_details !== 'Specify Details Here'} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Description </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='description'
                            id='description'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            value={description || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={description_input_ref}
                            disabled={hsn_sac_details !== 'Specify Details Here'} />
                        </div>
                        <p className='m-0 p-0 text-start'><strong> <u>GST Rate & Related Details</u></strong></p>
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1'> GST Rate Details </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='gst_rate_details'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={gst_rate_details_input_ref}
                            value={gst_rate_details || ""}
                            disabled={isStockGroupNameEntered === false} />
                          <div id="myDropdown-list" className={`dropdown_hsn_gst_list ${gstRateDetailsShow ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              List of Action
                            </div>
                            <div id='data'>
                              {filteredDataHsnDetailGstRate?.length > 0 ? (
                                filteredDataHsnDetailGstRate?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(gst_rate_details?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, gst_rate_details: name })
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                          setGstRateDetailsShow(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + gst_rate_details.length)}</span>
                                          {name.substring(index + gst_rate_details.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <input type='hidden' name='hidden' value={hidden || ''} onChange={handleChange} ref={hiddenRef} />
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Source of Details </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='source_of_details'
                            id='source_of_details'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            value={source_of_details || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={source_of_details_input_ref}
                            disabled />
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative' >
                          <label className='lab-1'>Taxability Type </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='taxability_type'
                            id='taxability_type'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            value={taxability_type || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={taxability_type_input_ref}
                            disabled={gst_rate_details !== 'Specify Details Here'} />
                          <div id="myDropdown-list" className={`dropdown_taxability_list ${taxabilityShow ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Taxability Type
                            </div>
                            <div id='data'>
                              {filteredDataTaxability?.length > 0 ? (
                                filteredDataTaxability?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(taxability_type?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, taxability_type: name })
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                          setTaxabilityShow(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + taxability_type.length)}</span>
                                          {name.substring(index + taxability_type.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>IGST Rate </label>
                          <label> :</label>
                          <div className='d-flex justify-content-start' style={{ width: '40%' }} >
                            <input
                              type="text"
                              name='igst_rate'
                              id='igst_rate'
                              className={`lab_tax ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                              value={igst_rate || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              onKeyPress={(e) => {

                                if (!/\d/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              ref={igst_rate_input_ref}
                              disabled={gst_rate_details !== 'Specify Details Here' || taxability_type !== 'Taxable'} />
                            <p className='m-0'> %</p>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>CGST Rate </label>
                          <label> :</label>
                          <div className='d-flex justify-content-start' style={{ width: '40%' }} >
                            <input
                              type="text"
                              name='cgst_rate'
                              id='cgst_rate'
                              className={`lab_tax ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                              value={cgst_rate || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={cgst_rate_input_ref}
                              disabled={gst_rate_details !== 'Specify Details Here' || taxability_type !== 'Taxable'} />
                            <p className='m-0'> %</p>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>SGST/UTGST Rate </label>
                          <label> :</label>
                          <div className='d-flex justify-content-start' style={{ width: '40%' }} >
                            <input
                              type="text"
                              name='sgst_utst_rate'
                              id='sgst_utst_rate'
                              className={`lab_tax ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                              value={sgst_utst_rate || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={sgst_utst_rate_input_ref}
                              disabled={gst_rate_details !== 'Specify Details Here' || taxability_type !== 'Taxable'} />
                            <p className='m-0'> %</p>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Cess Valuation Type </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='cess_valuation_type'
                            id='cess_valuation_type'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            value={cess_valuation_type || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={cess_valuation_type_input_ref}
                            disabled={gst_rate_details !== 'Specify Details Here' || taxability_type !== 'Taxable'} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' >
                          <label className='lab-1'>Cess Rate </label>
                          <label> :</label>
                          <div className='d-flex justify-content-start' style={{ width: '40%' }} >
                            <input
                              type="text"
                              name='cess_rate'
                              id='cess_rate'
                              className={`lab_tax ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                              value={cess_rate || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={cess_rate_input_ref}
                              disabled={cess_valuation_type === 'Not Applicable'} />
                            <p className='m-0'>{state.cess_valuation_type === 'Based on Value' ? '%' : state.cess_valuation_type === 'Based on Quantity' ? 'Unit' : '%'}</p>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative' >
                          <label className='lab-1'>Applicable For Reverse Charge </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='applicable_for_reverse_charge'
                            id='applicable_for_reverse_charge'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            value={applicable_for_reverse_charge || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={applicable_for_reverse_charge_input_ref}
                            disabled={gst_rate_details !== 'Specify Details Here' || taxability_type !== 'Taxable'} />
                          <div id="myDropdown-list" className={`dropdown_yes_no_list ${applicableForResShow ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Yes / No
                            </div>
                            <div id='data'>
                              {filteredDataYesNo?.length > 0 ? (
                                filteredDataYesNo?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(applicable_for_reverse_charge?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, applicable_for_reverse_charge: name })
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                          setApplicableForResShow(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + applicable_for_reverse_charge.length)}</span>
                                          {name.substring(index + applicable_for_reverse_charge.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center position-relative' >
                          <label className='lab-1'>Eligible for tax input credit </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='eligible_for_tax_input_credit'
                            id='eligible_for_tax_input_credit'
                            className={`lab-right ${isStockGroupNameEntered ? '' : 'disable-field'}`}
                            value={eligible_for_tax_input_credit || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={eligible_for_tax_input_credit_input_ref}
                            disabled={gst_rate_details !== 'Specify Details Here' || taxability_type !== 'Taxable'} />
                          <div id="myDropdown-list" className={`dropdown_yes_no_list ${eligibleForTaxshow ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Yes / No
                            </div>
                            <div id='data'>
                              {filteredDataYesNo?.length > 0 ? (
                                filteredDataYesNo?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(eligible_for_tax_input_credit?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, eligible_for_tax_input_credit: name })
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                          // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                          setEligibleForTaxShow(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + eligible_for_tax_input_credit.length)}</span>
                                          {name.substring(index + eligible_for_tax_input_credit.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : null
                }
              </div>
            </div>
            <div className='tomasterfooter'>
              <div className='footer'>
                <div className='boxfooter'>
                  <button className='button'>
                    <strong> Q:Quite</strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong></strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong></strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button type='submit' className='button' onClick={() => { return (setHashtag(true)) }}>
                    <strong><u> <u>A</u></u>:Accept</strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong><u></u></strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'></button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'>
                    <strong> <u>D</u>:Delete</strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button'>
                    <strong> </strong>
                  </button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'></button>
                </div>
                <div className='boxfooter'>
                  <button disabled className='button'></button>
                </div>
              </div>
            </div>
          </form>
        </div >
      </div >
      <div className='help' style={{ position: 'relative' }}>
        <div className='boxfooter' style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <button className={`button`} style={{ width: '100%' }} >
            <strong>
              <span>F12:</span>
              Configure
            </strong>
          </button>
        </div>
      </div>
    </div >
  )
}

export default StockGroup
