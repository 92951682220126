import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LINK_PATH } from '../../../constant';
function DisplayMoreReports() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (event.key === 'Enter') {
                event.preventDefault();
                // You can navigate to the selected link's path here
                const selectedLink = accounting[selectedIndex] || inventory[selectedIndex] || statutory[selectedIndex] || exception[selectedIndex];
                if (selectedLink) {
                    event.preventDefault();
                    navigate(selectedLink.to)
                }
            }
            else if (event.key === 't' || event.key === 'T') {
                navigate('/trial_balance')
            }
            else if (event.key === 'd' || event.key === 'D') {
                navigate('/daybooktransaction')
            }
            else if (event.key === 'c' || event.key === 'C') {
                navigate('/chartofaccountmasters')
            }
            else if (event.key === 'f' || event.key === 'F') {
                navigate('/voucherstransaction')
            }
            else if (event.key === 'a' || event.key === 'A') {
                navigate('/accountbook')
            }
            else if (event.key === 's' || event.key === 'S') {
                navigate('/statementofaccount')
            }
            else if (event.key === 'i' || event.key === 'I') {
                navigate('/inventorybook')
            }
            else if (event.key === 'e' || event.key === 'E') {
                navigate('/statementofinventory')
            }
            else if (event.key === 'o' || event.key === 'O') {
                navigate('/gstreport')
            }
            else if (event.key === 'x' || event.key === 'X') {
                navigate('/exceptionreports')
            }
            else if (event.key === 'v' || event.key === 'V') {
                navigate('/analysisverification')
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);
    const accounting = [
        { to: '/trial_balance', text: 'Trial Balance' },
        { to: '/daybooktransaction', text: 'Day Book' },
        { to: '/chartofaccountmasters', text: 'Cash Flow' },
        { to: '/chartofaccountmasters', text: 'Funds Flow' },
        { to: '/accountbook', text: 'Account Books' },
        { to: '/statementofaccount', text: 'Statements of Accounts' },
    ];
    const inventory = [];
    inventory[6] = { to: '/inventorybook', text: 'Inventory Books' };
    inventory[7] = { to: '/statementofinventory', text: 'StatEments of Inventory' };
    const statutory = [];
    statutory[8] = { to: '/gstreport', text: 'GST Reports' };
    const exception = [];
    exception[9] = { to: `${LINK_PATH}/exceptionreports`, text: 'Exception Reports' };
    exception[10] = { to: '/analysisverification', text: 'Analysis & Verification' };

    const totalLinks = (accounting.length + inventory.length + statutory.length + exception.length) - 23;
    return (
        <div className='card-container' id='card-container'>
            <div className='card-title'>
                <p className='title-of'> Display More Reports</p>
            </div>
            <div className='gateway_tally_card'>
                <div className='list-1'>
                    <h6 className='list-heading'> ACCOUNTING</h6>
                    <ul className='list-item'>
                        {accounting.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 0 || index === 1 || index === 2 || index === 3 || index === 4 || index === 5 ? (
                                        <span style={{ color: 'red' }}>{link.text.substring(0, 1)}</span>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                    {link.text.substring(1)}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> INVENTORY</h6>
                    <ul className='list-item'>
                        {inventory.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 6 ? (
                                        <span style={{ color: 'red' }}>{link.text.substring(0, 1)}</span>
                                    ) : index === 7 ? (
                                        <>
                                            {link.text.substring(0, 4)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(4, 5)}</span>
                                            {link.text.substring(5)}
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                    {index === 7 ? null : link.text.substring(1)}
                                </Link>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> STATUTORY</h6>
                    <ul className='list-item'>
                        {statutory.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 8 ? (
                                        <>
                                            {link.text.substring(0, 7)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(7, 8)}</span>
                                            {link.text.substring(8)}
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                </Link>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> EXCEPTION</h6>
                    <ul className='list-item'>
                        {exception.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 9 ? (
                                        <>
                                            {link.text.substring(0, 1)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(1, 2)}</span>
                                            {link.text.substring(2)}
                                        </>
                                    ) : index === 10 ? (
                                        <>
                                            {link.text.substring(0, 11)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(11, 12)}</span>
                                            {link.text.substring(12)}
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                </Link>
                            </li>
                        ))}

                    </ul>
                </div>
            </div>

        </div>
    )
}

export default DisplayMoreReports;
