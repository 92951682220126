import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import Footer from '../../../../components/home_page/Footer'
import { Link } from 'react-router-dom'
const OtherVoucher = (props) => {
    const [state, setState] = useState({
        search_input: '',
    })
    const { voucher_type_data = null } = useSelector(state => state.common);
    // console.log("this is voucher type :----",voucher_type_data)
    const [filteredData, setFilteredData] = useState([])
    const [selectedItem, setSelectedItem] = useState(0);
    const search_input_ref = useRef(null)
    const { search_input } = state;
    const handlechange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    }

    useEffect(() => {
        const filteredSuggestions = voucher_type_data?.filter((item, index) => {
            const lowercaseLedgerName = item?.voucher_type_name?.toLowerCase();
            const lowercaseSearchInput = state?.search_input?.toLowerCase();
            const matchesSearchInput = lowercaseLedgerName?.includes(state.search_input !== '' ? lowercaseSearchInput : '');
            return matchesSearchInput;
        })
        setFilteredData(state.search_input !== '' ? search_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : voucher_type_data : voucher_type_data);
        if (filteredSuggestions?.length > 0 && search_input_ref?.current?.selectionStart > 0) {
            setSelectedItem(0);
        }
    }, [state.search_input, search_input_ref?.current?.selectionStart])


    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        } else if (e.key === 'Enter') {
            e.preventDefault();
            const selectedLink = voucher_type_data[selectedItem]
            if (selectedLink) {
                props.setshow_other_voucher(false)
                props.displayNameOfVoucherType(selectedLink?.voucher_type_name, voucher_type_data)
            }
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            props.setshow_other_voucher(false);
            e.stopPropagation();
        }

    }, [selectedItem, filteredData])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
    return (
        <div className='my-card'>
            <div className='cross_button' onClick={() => { return (props.setshow_other_voucher(false)) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div className='container'>
                <div class="row justify-content-center">
                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div class="col-3 border bg-light border-dark">
                                <p className='p-0 text-center m-0'>Change Voucher Type</p>
                                <input
                                    autoFocus
                                    className='voucher_entry_account_input'
                                    type="text"
                                    id='search_input'
                                    name='search_input'
                                    placeholder='Enter Here......'
                                    onChange={handlechange}
                                    ref={search_input_ref}
                                    value={search_input || ""} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row justify-content-center'>
                            <div class="col-4 border bg-light border-dark" style={{ maxHeight: '75vh', overflowY: 'scroll' }}>
                                <div className='row background' style={{ height: 19.5 }}>
                                    <div className='col-12 text-left'>
                                        <p className='text-left'> List of voucher type</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    {filteredData?.length > 0 ? (
                                        filteredData?.map((key, number) => {
                                            const name = key?.voucher_type_name;
                                            const index = name?.toLowerCase()?.indexOf(search_input?.toLowerCase());
                                            return (
                                                <div
                                                    className={` col-12 dropdown_link ${number === selectedItem ? 'selected' : ''}`}
                                                    key={number}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedItem(number)
                                                        if (number === selectedItem) {
                                                            setState({ ...state, voucher_name: name })
                                                        }
                                                    }}
                                                >
                                                    <Link>
                                                        <div className='row p-0'>
                                                            <div className='col-9'>
                                                                {index !== -1 ? (
                                                                    < >
                                                                        {name?.substring(0, index)}
                                                                        <span style={{ color: 'red' }}>{name?.substring(index, index + search_input.length)}</span>
                                                                        {name?.substring(index + search_input.length)}
                                                                    </>
                                                                ) : (
                                                                    name
                                                                )}
                                                            </div>
                                                            <div className='col-3 ps-0'>
                                                                F4
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
        </div>
    )
}
export default OtherVoucher;