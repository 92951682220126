import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
function StatementofInventory() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (event.key === 'Enter') {
                event.preventDefault();
                // You can navigate to the selected link's path here
                const selectedLink = accounting[selectedIndex] || inventory[selectedIndex];
                if (selectedLink) {
                    event.preventDefault();
                    navigate(selectedLink.to)
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);
    const accounting = [
        { to: '/createmasters', text: 'Stock Query' },
        { to: '/daybooktransaction', text: 'Movement Analysis' },
        { to: '/chartofaccountmasters', text: 'Ageing Analysis' },
        { to: '/chartofaccountmasters', text: 'Reorder Status' },
        { to: '/chartofaccountmasters', text: 'COst Estimation' },
    ];
    const inventory = [];
    inventory[5] = { to: '/voucherstransaction', text: 'SaLes Order Outstandings' };
    inventory[6] = { to: '/daybooktransaction', text: 'Purchase Order Outstandings ' };
    inventory[7] = { to: '/daybooktransaction', text: 'Sale Bills Pending' };
    inventory[8] = { to: '/daybooktransaction', text: 'PUrchase Bills Pending' };
    inventory[9] = { to: '/daybooktransaction', text: 'Quit' };



    const totalLinks = (accounting.length + inventory.length) - 5;
    return (
        <div className='card-container' id='card-container'>
            <div className='card-title'>
                <p className='title-of'>Statements of Invetory</p>
            </div>
            <div className='gateway_tally_card'>
                <div className='list-1'>
                    <h6 className='list-heading'> STOCK</h6>
                    <ul className='list-item'>
                        {accounting.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>{link?.text}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> STOCK OUTSTANDINGS</h6>
                    <ul className='list-item'>
                        {inventory.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>{link?.text}</Link>
                            </li>
                        ))}
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default StatementofInventory;
