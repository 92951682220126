import React, { useState } from 'react'
import HelpCard from '../../HelpCard'

function StockItemVoucherHelp(props) {

    const fields = [
        { id: "1", name: "Period", shortcutKey: "F2", state: props.setShow },
        { id: "2", name: "Stock Item", shortcutKey: "F4", state: props.setStockItem },
        { id: "3", name: "Show Profit", shortcutKey: "F5" },
        { id: "4", name: "Godown Type", shortcutKey: "F8", state: props.setGodownWise },
        { id: "5", name: "Change View", shortcutKey: "H", state: props.setChangeView },
        { id: "6", name: "Exception Reorts", shortcutKey: "J" },
        { id: "7", name: "Apply Filter", shortcutKey: "F" },
        { id: "8", name: "Configure", shortcutKey: "F12" },
    ]
    return (
        <div>
            {
                fields.map((item, index) => {
                    return (
                        <HelpCard key={item.id} fields={item} />
                    )
                })
            }

        </div>
    )
}

export default StockItemVoucherHelp
