import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../../../../components/home_page/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { voucherTypeDefaultList, numberSeriesList, numberSeriesCreate, voucherTypeUpdate, voucherTypeSingleData }
  from '../../../../../redux/actions/action-creator';
import FormData from 'form-data';
import { toast } from 'react-toastify';
const initialState = {
  voucher_type_name: '',
  alise_name: '',
  select_type_of_voucher: '',
  select_type_of_voucher_id: '',
  abbreviation: '',
  active_this_voucher_type: 'Yes',
  define_multiple_numbering_series_for_vouchers: 'No',
  show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour: 'Yes',
  method_of_voucher_number: '',
  use_effective_dates_for_vouchers: 'No',
  allow_zero_valued_transactions: 'No',
  make_this_voucher_type_as_optional_by_default: 'No',
  allow_narration_in_voucher: 'Yes',
  provide_narration_for_each_ledger_in_voucher: 'No',
  print_voucher_after_saving: 'No',
  enable_default_accounting_allocations: '',
  set_alter_default_accounting_entries: '',
  track_additional_costs_for_purchases: '',
  default_jurisdiction: '',
  default_title_to_print: '',
  print_formal_receipt_after_saving: '',
  use_for_pos_invoicing: '',
  message_to_print_1: '',
  message_to_print_2: '',
  default_bank: '',
  set_alter_declaration: '',
  use_a_manufacturing_journal: '',
}
function VouchersType() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [isVoucherTypeNameEntered, setIsVoucherTypeNameEntered] = useState(false);
  const [isVoucherTypeNameExists, setIsVoucherTypeNameExists] = useState('');
  const [state, setState] = useState(initialState);
  const voucher_type_default_data = useSelector(state => state.common.voucher_type_default_data);
  const { userData = null } = useSelector(state => state.auth);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const voucherTypeNameInputRef = useRef(null)
  const [showList, setShowList] = useState(false);
  const [contra, setcontra] = useState(false);
  const [creditNote, setcreditNote] = useState(false);
  const [debitNote, setdebitNote] = useState(false);
  const [journal, setjournal] = useState(false);
  const [payment, setpayment] = useState(false);
  const [purchase, setpurchase] = useState(false);
  const [receipt, setreceipt] = useState(false);
  const [sales, setsales] = useState(false);
  const [deliveryNote, setdeliveryNote] = useState(false);
  const [receiptNote, setreceiptNote] = useState(false);
  const [physicalStock, setphysicalStock] = useState(false);
  const [stockJournal, setstockJournal] = useState(false);
  const [purchaseOrder, setpurchaseOrder] = useState(false);
  const [salesOrder, setsalesOrder] = useState(false);
  const [defineNumberModal, setDefineNumberModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0);
  useEffect(() => {
    const data = new FormData();
    data.append('company_id',userData?.id)
    dispatch(voucherTypeDefaultList(data))
    const formData = new FormData();
    formData.append('voucher_type_name', state.voucher_type_name)
    formData.append('company_id', userData?.id)
    dispatch(numberSeriesList(formData))

  }, [])
  useEffect(() => {
    let data = new FormData();
    data.append('id', id);
    dispatch(voucherTypeSingleData(data)).then((response) => {
      if (response.status) {
        setState({
          voucher_type_name: response?.data?.voucher_type_name,
          alise_name: response?.data?.alias_name,
          select_type_of_voucher: response?.data?.select_type_of_voucher,
          select_type_of_voucher_id: response?.data?.voucher_type_id,
          abbreviation: response?.data?.abbreviation,
          active_this_voucher_type: response?.data?.activate_this_voucher_type === 1 ? 'Yes' : 'No',
          define_multiple_numbering_series_for_vouchers: response?.data?.define_multiple_numbering_series_for_vouchers,
          show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour: response?.data?.show_unsed_vch_nos_in_transaction_for_retian_original_vch_no_beh,
          // method_of_voucher_number: response?.data?.voucher_type_name,
          use_effective_dates_for_vouchers: response?.data?.use_effective_date_for_vouchers,
          allow_zero_valued_transactions: response?.data?.allow_zero_valued_transactions,
          make_this_voucher_type_as_optional_by_default: response?.data?.make_this_voucher_type_as_optional_by_default,
          allow_narration_in_voucher: response?.data?.allow_narration_in_voucher,
          provide_narration_for_each_ledger_in_voucher: response?.data?.provide_narrations_for_each_ledger_in_voucher,
          print_voucher_after_saving: response?.data?.printing_voucher_after_saving,
          enable_default_accounting_allocations: response?.data?.enable_default_accounting_allocations,
          set_alter_default_accounting_entries: response?.data?.set_alter_default_accounting_entries,
          track_additional_costs_for_purchases: response?.data?.track_additional_costs_for_purchases,
          default_jurisdiction: response?.data?.default_jurisdiction,
          default_title_to_print: response?.data?.default_title_to_print,
          print_formal_receipt_after_saving: response?.data?.voucher_type_name,
          use_for_pos_invoicing: response?.data?.use_for_pos_invoicing,
          message_to_print_1: response?.data?.message_to_print_1,
          message_to_print_2: response?.data?.message_to_print_2,
          default_bank: response?.data?.default_bank,
          set_alter_declaration: response?.data?.set_alter_declaration,
          use_a_manufacturing_journal: response?.data?.voucher_type_name,
        })
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.message || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }, [id])
  useEffect(() => {
    if (state.select_type_of_voucher == 'Contra') {
      setcontra(true); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false); setpurchase(false);
      setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false); setphysicalStock(false);
      setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false);
    } else if (state.select_type_of_voucher == 'Credit Note') {
      setcontra(false); setcreditNote(true); setdebitNote(false); setjournal(false); setpayment(false); setpurchase(false)
      setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false); setphysicalStock(false)
      setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Debit Note') {
      setcontra(false); setcreditNote(false); setdebitNote(true); setjournal(false); setpayment(false); setpurchase(false)
      setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false); setphysicalStock(false)
      setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Journal') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(true); setpayment(false); setpurchase(false)
      setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false); setphysicalStock(false)
      setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Payment') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(true); setpurchase(false)
      setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false); setphysicalStock(false)
      setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Purchase') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false)
      setpurchase(true); setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false)
      setphysicalStock(false); setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Receipt') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false)
      setpurchase(false); setreceipt(true); setsales(false); setdeliveryNote(false); setreceiptNote(false)
      setphysicalStock(false); setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Sales') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false)
      setpurchase(false); setreceipt(false); setsales(true); setdeliveryNote(false); setreceiptNote(false)
      setphysicalStock(false); setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Delivery Note') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false)
      setpayment(false); setpurchase(false); setreceipt(false); setsales(false); setdeliveryNote(true)
      setreceiptNote(false); setphysicalStock(false); setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Receipt Note') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false)
      setpurchase(false); setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(true)
      setphysicalStock(false); setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Physical Stock') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false); setpurchase(false)
      setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false)
      setphysicalStock(true); setstockJournal(false); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Stock Journal') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false)
      setpurchase(false); setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false)
      setphysicalStock(false); setstockJournal(true); setpurchaseOrder(false); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Purchase Order') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false)
      setpurchase(false); setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false)
      setphysicalStock(false); setstockJournal(false); setpurchaseOrder(true); setsalesOrder(false)
    } else if (state.select_type_of_voucher == 'Sales Order') {
      setcontra(false); setcreditNote(false); setdebitNote(false); setjournal(false); setpayment(false)
      setpurchase(false); setreceipt(false); setsales(false); setdeliveryNote(false); setreceiptNote(false)
      setphysicalStock(false); setstockJournal(false); setpurchaseOrder(false); setsalesOrder(true)
    }
  }, [state])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    if (name === 'voucher_type_name') {
      // Set flag to enable other fields if Group Name has a value
      setIsVoucherTypeNameEntered(!!value.trim());
      setState({
        ...state, voucher_type_name: value.toLowerCase()
          .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase())
      });
      setIsVoucherTypeNameExists('')
    }
    if (name === 'select_type_of_voucher') {
      const filteredSuggestions = Object.keys(voucher_type_default_data).filter(key =>
        voucher_type_default_data[key]?.voucher_type_name.toLowerCase().includes(state.select_type_of_voucher.toLowerCase()));
      setFilteredData(filteredSuggestions);
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const handleClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
      setShowList(false);
    }
  };
  const handleFocus = () => {
    if (!showList) {
      setShowList(true);
    }
  };
  const handleOnBlur = (event) => {
    if (event.type === 'blur' || event.type === 'keydown') {
      // Check your condition for showing the modal
      const conditionMatched = state.define_multiple_numbering_series_for_vouchers === 'Yes';
      if (conditionMatched) {
        setDefineNumberModal(true);
      }
    }
  };
  const handleClickCloseDefineModal = () => {
    setDefineNumberModal(false);
  }

  const groupSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append('company_id', userData?.id);
    data.append('voucher_type_name', state.voucher_type_name);
    data.append('alias_name', state.alise_name);
    data.append('voucher_type_id', state.select_type_of_voucher_id);
    data.append('select_type_of_voucher', state.select_type_of_voucher);
    data.append('abbreviation', state.abbreviation);
    data.append('activate_this_voucher_type', state.active_this_voucher_type === 'Yes' ? 1 : 0);
    data.append('define_multiple_numbering_series_for_vouchers', state.define_multiple_numbering_series_for_vouchers);
    data.append('show_unsed_vch_nos_in_transaction_for_retian_original_vch_no_beh', state.show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour);
    data.append('use_effective_date_for_vouchers', state.use_effective_dates_for_vouchers);
    data.append('allow_zero_valued_transactions', state.allow_zero_valued_transactions);
    data.append('make_this_voucher_type_as_optional_by_default', state.make_this_voucher_type_as_optional_by_default);
    data.append('allow_narration_in_voucher', state.allow_narration_in_voucher);
    data.append('provide_narrations_for_each_ledger_in_voucher', state.provide_narration_for_each_ledger_in_voucher);
    data.append('enable_default_accounting_allocations', state.enable_default_accounting_allocations);
    data.append('set_alter_default_accounting_entries', state.set_alter_default_accounting_entries);
    data.append('printing_voucher_after_saving', state.print_voucher_after_saving);
    data.append('default_jurisdiction', state.default_jurisdiction);
    data.append('default_title_to_print', state.default_title_to_print);
    data.append('track_additional_costs_for_purchases', state.track_additional_costs_for_purchases);
    data.append('allow_access_for_user', '');
    data.append('use_for_pos_invoicing', state.use_for_pos_invoicing);
    data.append('message_to_print_1', state.message_to_print_1);
    data.append('message_to_print_2', state.message_to_print_2);
    data.append('default_bank', state.default_bank);
    data.append('set_alter_declaration', state.set_alter_declaration);
    data.append('id', id);
    dispatch(voucherTypeUpdate(data)).then((response) => {
      if (response.status) {
        toast.success(response?.message || 'group created!', {
          position: toast.POSITION.TOP_CENTER
        });
        setState({
          voucher_type_name: '',
          alise_name: '',
          select_type_of_voucher_id: '',
          select_type_of_voucher: '',
        })
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.message || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })

  }
  const {
    voucher_type_name,
    alise_name,
    select_type_of_voucher,
    abbreviation,
    active_this_voucher_type,
    define_multiple_numbering_series_for_vouchers,
    show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour,
    method_of_voucher_number,
    use_effective_dates_for_vouchers,
    allow_zero_valued_transactions,
    make_this_voucher_type_as_optional_by_default,
    allow_narration_in_voucher,
    provide_narration_for_each_ledger_in_voucher, print_voucher_after_saving,
    enable_default_accounting_allocations,
    set_alter_default_accounting_entries,
    track_additional_costs_for_purchases,
    default_jurisdiction, default_title_to_print,
    print_formal_receipt_after_saving,
    use_for_pos_invoicing, message_to_print_1, message_to_print_2, default_bank, set_alter_declaration,
    use_a_manufacturing_journal,
  } = state;
  // useEffect(() => {
  //   if (state.voucher_type_name !== '') {
  //     let formdata = new FormData();
  //     formdata.append('voucher_type_name', state.voucher_type_name)
  //     dispatch(voucherTypeNameCheck(formdata)).then((response) => {
  //       if (response.status) {
  //         setIsVoucherTypeNameExists('');
  //         setPlayErrorSound(false);
  //       }
  //       else {
  //         toast.warn(response?.message || 'Fail !', {
  //           position: toast.POSITION.TOP_CENTER
  //         });
  //       }
  //     }).catch(error => {
  //       setIsVoucherTypeNameExists(error.message)
  //       setIsVoucherTypeNameEntered(false);
  //       setPlayErrorSound(true);
  //     })
  //   }
  // }, [state])
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showList == true) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        }
        else if (e.key === 'Enter') {
          e.preventDefault();
          // You can navigate to the selected link's path here
          const selectedLink = voucher_type_default_data[selectedItem]
          if (selectedLink) {
            setState({ ...state, select_type_of_voucher: selectedLink.voucher_type_name, select_type_of_voucher_id: selectedLink.id })
            inputRef.current.blur();
            setShowList(false)
          }
        }
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (state.voucher_type_name !== '') {

        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, select_type_of_voucher, state, isVoucherTypeNameExists]);


  if (typeof voucher_type_default_data === 'undefined' || voucher_type_default_data === null) {
    return null; // or return a loading indicator, error message, or fallback component
  }


  return (
    <div className='section'>
      <div className='detail border position-relative'>
        {
          isVoucherTypeNameExists === 'Name Already Exists! Please Enter Different Name' ?
            <div style={{ position: 'absolute', bottom: 0, right: 0, width: '20%', height: '200px', borderWidth: 1, borderColor: 'black', borderStyle: 'solid', background: 'skyblue' }}>
              <div className='error_box_heading'>
                <p style={{ color: 'red', borderBottomWidth: 1, borderBottomStyle: 'solid' }}><center><strong>Error</strong></center></p>
              </div>
              {isVoucherTypeNameExists}
            </div> : null
        }
        {
          defineNumberModal ?
            <div style={{ position: 'absolute', width: '100%' }}>
              <DefineNumberSeries defineNumberModal={defineNumberModal} setDefineNumberModal={setDefineNumberModal} handleClickCloseDefineModal={handleClickCloseDefineModal} name={state.voucher_type_name} />
            </div> : null
        }
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form autoComplete='off' onSubmit={groupSubmit}>
              <div className='col-11' style={{ background: '#fff', overflow: 'hidden', width: '95%', borderRight: '1px solid black', borderBottom: '1px solid black' }}>
                <div className='row mt-2'>
                  <div className='col-6'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Name</label>
                      <label>:</label>
                      <input
                        type="text"
                        name='voucher_type_name'
                        autoFocus
                        id='voucher_type_name'
                        className={`lab-right`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        ref={voucherTypeNameInputRef}
                        value={voucher_type_name || ""} />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> (alise) </label>
                      <label> :</label>
                      <input type="text" name='alise_name' id='alise_name' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={alise_name || ""} />
                    </div>
                  </div>
                </div>
                <div className='row height'>
                  <div className='col-6 border'>
                    <div className='row'>
                      <div className='col text-decoration-underline'>
                        <b>General</b>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Select type of voucher  </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='select_type_of_voucher'
                        id='select_type_of_voucher'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        ref={inputRef}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        value={select_type_of_voucher || ""} />
                      <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                          List Of Voucher Type
                        </div>
                        <div className='col ' style={{ textAlign: 'right' }}>
                          <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                          <div className='border'></div>
                        </div>
                        <div id='data'>
                          {filteredData.length > 0 ? (
                            filteredData.map((key, number) => {
                              const name = voucher_type_default_data[key]?.voucher_type_name;
                              const id = voucher_type_default_data[key]?.id;
                              const index = name.toLowerCase().indexOf(select_type_of_voucher.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, select_type_of_voucher: name, select_type_of_voucher_id: id })
                                      inputRef.current.blur();
                                      setShowList(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + select_type_of_voucher.length)}</span>
                                      {name.substring(index + select_type_of_voucher.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Abbreviation </label>
                      <label> :</label>
                      <input type="text" name='abbreviation' id='abbreviation' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={abbreviation || ""} />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Activate this voucher Type </label>
                      <label> :</label>
                      <input type="text" list='atvt' name='active_this_voucher_type' id='active_this_voucher_type' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={active_this_voucher_type || ""} />
                      <datalist id='atvt'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Define multiple numbering series for vouchers </label>
                      <label> :</label>
                      <input
                        type="text"
                        list='dmnsfv'
                        name='define_multiple_numbering_series_for_vouchers'
                        id='define_multiple_numbering_series_for_vouchers'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        // onKeyUp={handleChange}
                        onChange={handleChange}
                        onBlur={handleOnBlur}
                        onKeyDown={handleOnBlur}
                        value={define_multiple_numbering_series_for_vouchers || ""} />
                      <datalist id='dmnsfv'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Show unsed vch nos. in transaction for retain original voucher  no. behaviour </label>
                      <label> :</label>
                      <input type="text" list='suvnitfrovnb' name='show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour' id='show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour || ""} />
                      <datalist id='suvnitfrovnb'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Method of voucher numbering  </label>
                      <label> :</label>
                      <input type="text" list='movn' name='method_of_voucher_number' id='method_of_voucher_number' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={method_of_voucher_number || ""} />
                      <datalist id='movn'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Use effective dates for Vouchers  </label>
                      <label> :</label>
                      <input type="text" list='uedfv' name='use_effective_dates_for_vouchers' id='use_effective_dates_for_vouchers' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={use_effective_dates_for_vouchers || ""} />
                      <datalist id='uedfv'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Allow zero-valued transactions  </label>
                      <label> :</label>
                      <input type="text" list='azvt' name='allow_zero_valued_transactions' id='allow_zero_valued_transactions' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={allow_zero_valued_transactions || ""} />
                      <datalist id='azvt'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    {
                      contra || creditNote || debitNote || payment || purchase || receipt || sales || deliveryNote || receiptNote || purchaseOrder || salesOrder || stockJournal ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Make this voucher type as 'Optional' by default  </label>
                          <label> :</label>
                          <input type="text" list='mtvtaobd' name='make_this_voucher_type_as_optional_by_default' id='make_this_voucher_type_as_optional_by_default' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={make_this_voucher_type_as_optional_by_default || ""} />
                          <datalist id='mtvtaobd'>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div> : null
                    }
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Allow narration in voucher </label>
                      <label> :</label>
                      <input type="text" list='aniv' name='allow_narration_in_voucher' id='allow_narration_in_voucher' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={allow_narration_in_voucher || ""} />
                      <datalist id='aniv'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    {
                      contra || creditNote || debitNote || journal || payment || purchase || receipt || sales ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Provide narration for each ledger in voucher  </label>
                          <label> :</label>
                          <input type="text" list='pnfeliv' name='provide_narration_for_each_ledger_in_voucher' id='provide_narration_for_each_ledger_in_voucher' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={provide_narration_for_each_ledger_in_voucher || ""} />
                          <datalist id='pnfeliv'>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div> : null
                    }
                    {
                      stockJournal ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Use a Manufacturing Journal  </label>
                          <label> :</label>
                          <input type="text" list='uamj' name='use_a_manufacturing_journal' id='use_a_manufacturing_journal' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={use_a_manufacturing_journal || ""} />
                          <datalist id='uamj'>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div> : null
                    }


                    {
                      debitNote || journal || payment || purchase || physicalStock || stockJournal ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Track Additional Costs for Purchases  </label>
                          <label> :</label>
                          <input type="text" list='tacfp' name='track_additional_costs_for_purchases' id='track_additional_costs_for_purchases' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={track_additional_costs_for_purchases || ""} />
                          <datalist id='tacfp'>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        : null
                    }
                    {
                      contra || creditNote || debitNote || payment || purchase || receipt || sales || deliveryNote || receiptNote || purchaseOrder || salesOrder ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Enable defaul accounting allocation  </label>
                          <label> :</label>
                          <input type="text" list='edaa' name='enable_default_accounting_allocations' id='enable_default_accounting_allocations' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={enable_default_accounting_allocations || ""} />
                          <datalist id='edaa'>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div>
                        : null
                    }
                    {
                      enable_default_accounting_allocations == 'Yes' ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Set/Alter default Accounting Entries </label>
                          <label> :</label>
                          <input type="text" list='sadae' name='set_alter_default_accounting_entries' id='set_alter_default_accounting_entries' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_default_accounting_entries || ""} />
                          <datalist id='sadae'>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div> : null
                    }
                  </div>
                  <div className='col-4 border'>
                    <div className='row'>
                      <div className='col text-decoration-underline'>
                        <b>Printing</b>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Print voucher after saving </label>
                      <label> :</label>
                      <input type="text" list='pvas' name='print_voucher_after_saving' id='print_voucher_after_saving' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={print_voucher_after_saving || ""} />
                      <datalist id='pvas'>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </datalist>
                    </div>
                    {
                      sales ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1 text-truncate'>Use for POS invoicing </label>
                            <label> :</label>
                            <input type="text" list='ufpi' name='use_for_pos_invoicing' id='use_for_pos_invoicing' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={use_for_pos_invoicing || ""} />
                            <datalist id='ufpi'>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </datalist>
                          </div>
                          {
                            use_for_pos_invoicing == 'Yes' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1 text-truncate'>Message to print (1) </label>
                                  <label> :</label>
                                  <input type="text" name='message_to_print_1' id='message_to_print_1' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={message_to_print_1 || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1 text-truncate'>Message to print (2) </label>
                                  <label> :</label>
                                  <input type="text" name='message_to_print_2' id='message_to_print_2' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={message_to_print_2 || ""} />
                                </div>
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      sales ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Default Bank </label>
                          <label> :</label>
                          <input type="text" name='default_bank' id='default_bank' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_bank || ""} />
                        </div> : null
                    }
                    {
                      purchaseOrder ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Default Jurisdiction </label>
                          <label> :</label>
                          <input type="text" name='default_jurisdiction' id='default_jurisdiction' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_jurisdiction || ""} />
                        </div> : null
                    }
                    {
                      creditNote || sales || deliveryNote ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1 text-truncate'>Default Jurisdiction </label>
                            <label> :</label>
                            <input type="text" name='default_jurisdiction' id='default_jurisdiction' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_jurisdiction || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1 text-truncate'>Default Title to print </label>
                            <label> :</label>
                            <input type="text" name='default_title_to_print' id='default_title_to_print' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_title_to_print || ""} />
                          </div>
                        </div> : null
                    }
                    {
                      debitNote ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Default Title to print </label>
                          <label> :</label>
                          <input type="text" name='default_title_to_print' id='default_title_to_print' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={default_title_to_print || ""} />
                        </div> : null
                    }
                    {
                      receipt ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Print formal receipt after saving </label>
                          <label> :</label>
                          <input type="text" name='print_formal_receipt_after_saving' id='print_formal_receipt_after_saving' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={print_formal_receipt_after_saving || ""} />
                        </div> : null
                    }
                    {
                      sales || purchaseOrder || salesOrder ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Set/alter declaration </label>
                          <label> :</label>
                          <input type="text" list='sad' name='set_alter_declaration' id='set_alter_declaration' className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={set_alter_declaration || ""} />
                          <datalist id='sad'>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </datalist>
                        </div> : null
                    }

                  </div>
                  <div className='col-2 border'>
                    <div className='row'>
                      <div className='col text-decoration-underline'>
                        <b>Name of class</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                defineNumberModal ? null :
                  <div className='tomasterfooter' style={{ zIndex: 100 }}><Footer /></div>
              }
            </form>
          </div>
        </div>
      </div>
      <div className='help'>
      </div>
    </div>
  )
}

export default VouchersType;

const DefineNumberSeries = (props) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [numberModal, setNumberModal] = useState(false);
  const [numData, setNumData] = useState([]);
  const [isMount, setIsMount] = useState(true)
  const number_series_data = useSelector(state => state.common.number_series_data)
  const {userData=null} = useSelector(state => state.auth)
  const handleNumberSerieModal = () => { setNumberModal(true) }
  const closeNumberSerieModal = () => { setNumberModal(false); };
  console.log("number series--", number_series_data)
  useEffect(() => {
    const formData = new FormData();
    formData.append('voucher_type_name', props.name)
    formData.append('company_id',userData?.id)
    dispatch(numberSeriesList(formData))
  }, [])
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % totalLinks);
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + totalLinks) % totalLinks);
      } else if (event.key === 'Enter') {
        event.preventDefault();
        // You can navigate to the selected link's path here
        const selectedLink = links[selectedIndex];
        if (selectedLink) {
          handleNumberSerieModal();
        }
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        props.setDefineNumberModal(false)
        event.stopPropagation();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex]);
  const links = [
    { id: 1, text: "Create Series" },
  ];
  const data_number_series = [];
  data_number_series[1] = { id: 1, name: 'Number system 1', method: 'Automatic (Retain Original Voucher No.)' };
  data_number_series[2] = { id: 2, name: 'Number system 2', method: 'Automatic (Retain Original Voucher No.)' };
  data_number_series[3] = { id: 3, name: 'Number system 3', method: 'Automatic (Retain Original Voucher No.)' };
  const totalLinks = links.length;
  if (typeof number_series_data === 'undefined' || number_series_data === null) {
    return null; // or return a loading indicator, error message, or fallback component
  }
  return (
    <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
      <div className='cross_button' onClick={props.handleClickCloseDefineModal}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      {
        numberModal ?
          <div style={{ position: 'absolute', width: '100%' }}>
            <NumberSeriesDetails closeNumberSerieModal={closeNumberSerieModal} name={props.name} numberModal={numberModal} />
          </div>
          : null
      }
      <div className='company-list-data'>
        <div className='select-company-search-box'>
          <p className='m-0'> Define Number Series</p>
          <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />
        </div>
        <div className='number_series_container'>
          <div className='number_series_header'>
            <p style={{ textAlign: 'left', }}>List of Number Series</p>
          </div>
          <div className='row m-0  number_series_list'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-6 name_series_left'>
                  Series Name
                </div>
                <div className='col-6 method_voucher_num_right'>
                  Method of voucher Numbering
                </div>
              </div>
            </div>
            <div className='row m-0 p-0'>
              {links.map((link, index) => (
                <div key={index} onClick={handleNumberSerieModal} className={index === selectedIndex ? 'selected col-12' : ''}>
                  <Link className='number_series_create_link'>{link?.text}</Link>
                </div>
              ))}
              {Object.keys(number_series_data).map((link, index) => (
                <div key={index} className={index === selectedIndex ? 'selected col-12' : ''}>
                  <div className='row' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div className='col-6'>
                      <Link className='number_series_created'>{number_series_data[link]?.series_name}</Link>
                    </div>
                    <div className='col-6'>
                      <Link className='number_series_created'>{link?.method_of_voucher_numbering}</Link>
                    </div>
                  </div>
                </div>
              ))
              }
            </div>
            <div className='container-2 row' id='conditional_rendering'>
              <div className='col d-block'>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* {
                      Object.keys(data).map((item, index) => {
                        return (
                          <Link style={{ textDecoration: 'none', textAlign: 'left', color: '#000', paddingLeft: 10 }} to={`../alter_accounting_groupupdate/${data[item]?.id}`} key={index} className={index === selectedIndex ? 'selected' : ''}>{data[item]?.name}</Link>
                        )
                      })
                    } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const NumberSeriesDetails = (props) => {
  const initialState = {
    series_name: '',
    method_of_voucher_numbering: 'Automatic',
    numbering_behaviour_on_insertion_deletion: 'Retain Original Voucher No.',
    set_alter_additional_numbering_details: 'No',
    prevent_creating_duplicate_voucher_nos: '',
    starting_number: '1',
    width_of_numerical_part: '0',
    prefill_with_zero: 'No',
    applicable_from_restart_numbering: '',
    starting_number_restarting_numbering: '',
    periodicity: '',
    applicable_from_prefix_details: '',
    particulars_prefix_details: '',
    applicable_from_suffix_details: '',
    particulars_suffix_details: '',
  }
  const dispatch = useDispatch();
  const { userData = null } = useSelector(state => state.auth)
  const [state, setState] = useState(initialState);
  const [numDetailsSeries, setNumDetailsSeries] = useState(false)
  const [methodShow, setMethodShow] = useState(false);
  const methodInputRef = useRef(null)
  const methodDropdownRef = useRef(null)
  const [seriesNameEntered, setSeriesNameEntered] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    if (name === 'series_name') {
      // Set flag to enable other fields if Group Name has a value
      //   setIsVoucherTypeNameEntered(!!value.trim()); 
      setSeriesNameEntered(!!value.trim());
      setState({
        ...state, series_name: value.toLowerCase()
          .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase())
      });
    }
    if (name === 'method_of_voucher_numbering') {
      const filteredSuggestions = methodOfNumberingArray.filter(key =>
        key?.name.toLowerCase().includes(state.method_of_voucher_numbering.toLowerCase()));
      setFilteredData(filteredSuggestions);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const handleClick = (event) => {
    if (methodDropdownRef.current && !methodDropdownRef.current.contains(event.target) && !methodInputRef.current.contains(event.target)) {
      setMethodShow(false);
    }
  };
  const handleMethodFocus = () => {
    if (!methodShow && Object.keys(methodOfNumberingArray).length > 0) {
      setMethodShow(true);
    }
  };
  const handleNumberSerieModal = (event) => {
    if (event.type === 'blur' || (event.type === 'keydown' && event.key === 'Escape')) {
      // Check your condition for showing the modal
      const conditionMatched = state.set_alter_additional_numbering_details === 'Yes';
      if (conditionMatched) {
        setNumDetailsSeries(true);
      }
      if (event.key === 'Escape') {
        event.preventDefault();
        setNumDetailsSeries(false);
      }
    }
  }
  const closeNumberSerieModal = () => {
    setNumDetailsSeries(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append('company_id', userData?.id);
    data.append('voucher_type_name', props.name);
    data.append('series_name', state.series_name);
    data.append('method_of_voucher_numbering', state.method_of_voucher_numbering);
    data.append('numbering_behaviour', state.numbering_behaviour_on_insertion_deletion);
    data.append('set_additional_numbering', state.set_alter_additional_numbering_details);
    data.append('starting_number', state.starting_number);
    data.append('width_of_numerical_part', state.width_of_numerical_part);
    data.append('prefill_with_zero', state.prefill_with_zero);
    data.append('applicable_from', state.applicable_from_restart_numbering);
    data.append('starting_number_2', state.starting_number_restarting_numbering);
    data.append('periodicity', state.periodicity);
    data.append('applicable_from_prefix', state.applicable_from_prefix_details);
    data.append('prefix_particular', state.particulars_prefix_details);
    data.append('applicable_from_suffix', state.applicable_from_suffix_details);
    data.append('suffix_particular', state.particulars_suffix_details);
    dispatch(numberSeriesCreate(data)).then((response) => {
      if (response.status) {
        toast.success(response?.message || 'group created!', {
          position: toast.POSITION.TOP_CENTER
        });
      }
      else {
        toast.warn(response?.message || 'Fail !', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }).catch(error => {
      console.log(error)
      toast.error(error?.message || ' Failed!', {
        position: toast.POSITION.TOP_CENTER
      });
    })
  }
  const { series_name, method_of_voucher_numbering,
    numbering_behaviour_on_insertion_deletion,
    set_alter_additional_numbering_details,
    prevent_creating_duplicate_voucher_nos,
    starting_number,
    width_of_numerical_part,
    prefill_with_zero,
    applicable_from_restart_numbering,
    starting_number_restarting_numbering,
    periodicity,
    applicable_from_prefix_details,
    particulars_prefix_details,
    applicable_from_suffix_details,
    particulars_suffix_details, } = state;
  const methodOfNumberingArray = [
    { id: 1, name: 'Automatic' },
    { id: 2, name: 'Manual' },
    { id: 3, name: 'None' },
  ]
  useEffect(() => {

    const handleKeyDown = (e) => {

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        console.log("selected data link -----", methodOfNumberingArray[selectedItem])
      }
      else if (e.key === 'Enter') {
        e.preventDefault();
        // You can navigate to the selected link's path here
        const selectedLink = methodOfNumberingArray[selectedItem]
        if (selectedLink) {
          setState({ ...state, method_of_voucher_numbering: selectedLink.name })
          methodInputRef.current.blur();
          setMethodShow(false)
        }
      }

    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, method_of_voucher_numbering, state]);
  return (
    <div className='container-fluid p-0 filter' style={{ background: 'rgba(0,0,0,0.6)', height: '89.6vh', position: 'relative' }}>
      <div className='cross_button' onClick={props.closeNumberSerieModal}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <form autoComplete='off' onSubmit={handleSubmit}>
        {
          numDetailsSeries ?
            <SetAlterNumberDetails closeNumberSerieModal={closeNumberSerieModal}
              starting_number={starting_number}
              width_of_numerical_part={width_of_numerical_part}
              prefill_with_zero={prefill_with_zero}
              applicable_from_restart_numbering={applicable_from_restart_numbering}
              starting_number_restarting_numbering={starting_number_restarting_numbering}
              periodicity={periodicity}
              applicable_from_prefix_details={applicable_from_prefix_details}
              particulars_prefix_details={particulars_prefix_details}
              applicable_from_suffix_details={applicable_from_suffix_details}
              particulars_suffix_details={particulars_suffix_details}
              handleChange={handleChange} /> : null
        }
        <div className='row number_series_create_form'>
          <div className='col-12 number_series_create_form_heading'>
            Numbering Series Details
          </div>

          <div className='col-12'>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1 text-truncate'>Series Name </label>
              <label> :</label>
              <input type="text" autoFocus name='series_name' id='series_name' className={`lab-right`} onKeyUp={handleChange} onChange={handleChange} value={series_name || ""} />
            </div>
            <div className='d-flex justify-content-between align-item-center position-relative'>
              <label className='lab-1 text-truncate'>Method of Vocher Numbering </label>
              <label> :</label>
              <input
                type="text"
                name='method_of_voucher_numbering'
                id='method_of_voucher_numbering'
                className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`}
                onKeyUp={handleChange}
                onChange={handleChange}
                onFocus={handleMethodFocus}
                ref={methodInputRef}
                value={method_of_voucher_numbering || ""} />
              <div id="myDropdown-list-maintain" className={`dropdown-content-list-for-method-numbering-component ${methodShow ? 'show-list' : ''}`} ref={methodDropdownRef}>
                <div className='col' style={{ background: '#162070', color: '#fff', position: 'sticky', top: 0 }}>
                  Methods of Numbering
                </div>
                <div id='data'>
                  {filteredData.length > 0 ? (
                    filteredData.map((key, number) => {
                      const name = key?.name;
                      const index = name.toLowerCase().indexOf(method_of_voucher_numbering.toLowerCase());
                      return (
                        <Link
                          className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                          id='list'
                          key={number}
                          // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                          onClick={() => {
                            setSelectedItem(number)
                            if (number === selectedItem) {
                              setState({ ...state, under: name })
                              methodInputRef.current.blur();
                              setMethodShow(false)
                            }
                          }}
                        >
                          {index !== -1 ? (
                            < >
                              {name.substring(0, index)}
                              <span style={{ color: 'red' }}>{name.substring(index, index + method_of_voucher_numbering.length)}</span>
                              {name.substring(index + method_of_voucher_numbering.length)}
                            </>
                          ) : (
                            name
                          )}
                        </Link>
                      );
                    })
                  ) : (
                    <p style={{ color: 'red' }}>No matching items found</p>
                  )}
                </div>
              </div>
            </div>
            {
              method_of_voucher_numbering === 'Automatic' ?
                <div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Numbering behaviour on insertion/deletion </label>
                    <label> :</label>
                    <input type="text" name='numbering_behaviour_on_insertion_deletion' id='numbering_behaviour_on_insertion_deletion' className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`} onKeyUp={handleChange} onChange={handleChange} value={numbering_behaviour_on_insertion_deletion || ""} />
                  </div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1 text-truncate'>Set/Alter Additional numbering Details </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='set_alter_additional_numbering_details'
                      id='set_alter_additional_numbering_details'
                      className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onBlur={handleNumberSerieModal}
                      onKeyDown={handleNumberSerieModal}
                      value={set_alter_additional_numbering_details || ""} />
                  </div>
                </div> : null
            }
            {
              method_of_voucher_numbering === 'Manual' ?
                <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1 text-truncate'>Prevent Creating duplicate voucher Nos. </label>
                  <label> :</label>
                  <input
                    type="text"
                    name='prevent_creating_duplicate_voucher_nos'
                    id='prevent_creating_duplicate_voucher_nos'
                    className={`lab-right ${seriesNameEntered ? '' : 'disable-field'}`}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onBlur={handleNumberSerieModal}
                    onKeyDown={handleNumberSerieModal}
                    value={prevent_creating_duplicate_voucher_nos || ""} />
                </div> : null
            }
          </div>
        </div>
        <div className='tomasterfooter'><Footer /></div>
      </form>
    </div>
  );
}

const SetAlterNumberDetails = (props) => {
  return (
    <div className='container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className='cross_button' onClick={props.closeNumberSerieModal}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <div className='row' style={{ background: '#fff', zIndex: 10000, width: '75%', height: '85.5vh' }}>
        <div className='col'>
          <form autoComplete='off'>
            <div className='row'>
              <div className='col-6'>
                <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1 text-truncate'>Starting Numbering </label>
                  <label> :</label>
                  <input type="text" name='starting_number' id='starting_number' onKeyUp={props.handleChange} onChange={props.handleChange} className={`lab-right`} value={props.starting_number || ""} />
                </div>   <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1 text-truncate'>Width of Numerical Part </label>
                  <label> :</label>
                  <input type="text" name='width_of_numerical_part' id='width_of_numerical_part' onKeyUp={props.handleChange} onChange={props.handleChange} className={`lab-right`} value={props.width_of_numerical_part || ""} />
                </div>
                <div className='d-flex justify-content-between align-item-center'>
                  <label className='lab-1 text-truncate'>Prefill with zero </label>
                  <label> :</label>
                  <input type="text" name='prefill_with_zero' id='prefill_with_zero' onKeyUp={props.handleChange} onChange={props.handleChange} className={`lab-right`} value={props.prefill_with_zero || ""} />
                </div>
              </div>
              <div className='row p-0 m-0 mt-2' style={{ width: '100%' }}>
                <div className='col-4'>
                  <div className='row border'>
                    <p className='m-0'> <center><strong>  Restart Numbering</strong></center></p>
                  </div>
                  <div className='row border' style={{ height: '67.6vh' }}>
                    <div className='col-12'>
                      <div className='row border'>
                        <div className='col-4 borf'>
                          Applicable From
                        </div>
                        <div className='col-4'>
                          Starting Number
                        </div>
                        <div className='col-4'>
                          Periodicity
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='row mt-1'>
                            <div className='col-4'>
                              <input type="text" className='input_table' name='applicable_from_restart_numbering' id='prefill_with_zero' onKeyUp={props.handleChange} onChange={props.handleChange} value={props.applicable_from_restart_numbering || ""} />
                            </div>
                            <div className='col-4'>
                              <input type="text" className='input_table' name='starting_number_restarting_numbering' id='prefill_with_zero' onKeyUp={props.handleChange} onChange={props.handleChange} value={props.starting_number_restarting_numbering || ""} />
                            </div>
                            <div className='col-4'>
                              <input type="text" className='input_table' name='periodicity' id='prefill_with_zero' onKeyUp={props.handleChange} onChange={props.handleChange} value={props.periodicity || ""} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div className='col-4'>
                  <div className='row border'>
                    <p className='m-0'> <center><strong> Prefix Details</strong></center></p>
                  </div>
                  <div className='row border' style={{ height: '67.6vh' }}>
                    <div className='col'>
                      <div className='row border'>
                        <div className='col-6'>
                          Applicable From
                        </div>
                        <div className='col-6'>
                          Particulars
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='row mt-1'>
                            <div className='col-6'>
                              <input type="text" className='input_table' name='applicable_from_prefix_details' id='applicable_from_prefix_details' onKeyUp={props.handleChange} onChange={props.handleChange} value={props.applicable_from_prefix_details || ""} />
                            </div>
                            <div className='col-6'>
                              <input type="text" className='input_table' name='particulars_prefix_details' id='particulars_prefix_details' onKeyUp={props.handleChange} onChange={props.handleChange} value={props.particulars_prefix_details || ""} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='row border'>
                    <p className='m-0'> <center><strong>  Suffix Details</strong></center></p>
                  </div>
                  <div className='row border' style={{ height: '67.6vh' }}>
                    <div className='col'>
                      <div className='row border'>
                        <div className='col-6'>
                          Applicable From
                        </div>
                        <div className='col-6'>
                          Particulars
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='row mt-1'>
                            <div className='col-6'>
                              <input type="text" className='input_table' name='applicable_from_suffix_details' id='applicable_from_suffix_details' onKeyUp={props.handleChange} onChange={props.handleChange} value={props.applicable_from_suffix_details || ""} />
                            </div>
                            <div className='col-6'>
                              <input type="text" className='input_table' name='particulars_suffix_details' id='particulars_suffix_details' onKeyUp={props.handleChange} onChange={props.handleChange} value={props.particulars_suffix_details || ""} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
