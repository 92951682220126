import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LINK_PATH } from '../../../../constant';
function ExceptionReports() {
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
            } else if (event.key === 'ArrowUp') {
                setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            } else if (event.key === 'Enter') {
                event.preventDefault();
                // You can navigate to the selected link's path here
                const selectedLink = accounting[selectedIndex] || inventory[selectedIndex]|| exception[selectedIndex];
                if (selectedLink) {
                    event.preventDefault();
                    navigate(selectedLink.to)
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex]);
    const accounting = [
        { to: '/createmasters', text: 'Ledgers Without E-mAil IDs' },
    ];
    const inventory = [];
    inventory[1] = { to: '/inventorybook', text: 'Optional Vouchers' };
    inventory[2] = { to: '/inventorybook', text: 'Cancelled vouchers Vouchers' };
    inventory[3] = { to: '/inventorybook', text: 'PoSt-Dated Vouchers' };
    const exception = [];
    exception[4] = { to: `${LINK_PATH}/balancesheetreports`, text: 'Negative Ledgers' };
    exception[5] = { to: '/profitandlossac', text: 'Negative Stock' };
    exception[6] = { to: '/profitandlossac', text: 'OveRdue Receivables' };
    exception[7] = { to: '/profitandlossac', text: 'Overdue Payables' };
    exception[8] = { to: '/profitandlossac', text: 'Quit' };

    const totalLinks = (accounting.length + inventory.length + exception.length) - 5;
    return (
        <div className='card-container' id='card-container'>
            <div className='card-title'>
                <p className='title-of'> Exception Reports</p>
            </div>
            <div className='gateway_tally_card'>
                <div className='list-1'>
                    <h6 className='list-heading'>MASTERS</h6>
                    <ul className='list-item'>
                        {accounting.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>{link?.text}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='list-1'>
                    <h6 className='list-heading'>REGISTER</h6>
                    <ul className='list-item'>
                        {inventory.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>{link?.text}</Link>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className='list-1'>
                    <h6 className='list-heading'>REPORTS</h6>
                    <ul className='list-item'>
                        {exception.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>{link?.text}</Link>
                            </li>
                        ))}

                    </ul>
                </div>
            </div>

        </div>
    )
}

export default ExceptionReports;
