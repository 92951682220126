import React, { useEffect, useState, useRef } from 'react';
import Footer from '../../../../../../components/home_page/Footer';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ledgerPostingList } from '../../../../../../redux/actions/action-creator';
import LedgerBook from '../LedgerBook';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { voucherEntryDelete } from '../../../../../../redux/actions/action-creator';
import { currencyFormate } from '../../../../../../components/date_formate/Index';
// Date formate function to send
function dateFormateSend(datestring) {
  const date_send = new Date(datestring);
  const day = date_send.getDate();
  const month = date_send.getMonth() + 1;
  const year = date_send.getFullYear();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}
// Date formate function to show 
function dateFormateShow(datestring) {
  const date_show = new Date(datestring);
  const day = date_show.getDate();
  const month = date_show.toLocaleString('default', { month: 'short' });
  const year = date_show.getFullYear();
  return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
function LedgerVoucher() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [record, setrecord] = useState(false)
  const [loading, setLoading] = useState(true);
  const [show_configure, setshow_configure] = useState(false);
  const [show_period, setshow_period] = useState(false);
  const [show_period_double, setshow_period_double] = useState(false);
  const [show_other_voucher, setshow_other_voucher] = useState(false);
  const [removedItems, setRemovedItems] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const boxRef = useRef(null)
  const [all_data, setall_data] = useState({
    main_ledger_record: {},
    voucherEntryList: []
  })
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const { company_session } = useSelector(state => state.common);
  const { userData } = useSelector(state => state.auth);
  const date_formated_send = dateFormateSend(company_session?.current_date)
  const date_formated_show = dateFormateShow(company_session?.current_date)
  const [state, setState] = useState({
    opening_balance: '',
    total_credit_amount: '',
    total_debit_amount: ''
  })
  const [state_configure, setState_configure] = useState(
    {
      show_narrations: 'No',
      formate_of_report: 'Condensed',
      show_bill_wise_details: 'No',
      show_inventory_details: 'No',
      show_mode_of_payment: 'No',
      show_additional_bank_details: 'No',
      display_name_for_ledgers: 'Name Only',
      display_name_for_stock_items: 'Name Only',
      sorting_method: 'Default',
      show_running_balance: 'Yes',


    })
  // Set dates in state to send userInterface and server
  const [date, setDate] = useState({
    from_date: dateFormateShow(company_session?.start_financial_year),
    to: date_formated_show
  });
  const [send_date, setsend_date] = useState({ from_date: dateFormateSend(company_session?.start_financial_year), to_date: date_formated_send })
  // Fetch data of ledger 
  useEffect(() => {
    let formdata = new FormData();
    formdata.append('fromDate', send_date?.from_date)
    formdata.append('toDate', send_date?.to_date)
    formdata.append('ledger_id', id)
    formdata.append('company_id', userData?.id)
    dispatch(ledgerPostingList(formdata)).then((response) => {
      if (response.status) {
        setall_data({
          main_ledger_record: response.data.main_ledger_record,
          voucherEntryList: response.data.voucherEntryList
        })
        let current_debit_total_amount = 0
        let current_credit_total_amount = 0
        response.data.voucherEntryList?.map((item, ind) => {
          return (
            current_debit_total_amount += parseFloat(item?.total_debit_amount),
            current_credit_total_amount += parseFloat(item?.total_credit_amount)
          )
          if (selectedItem > 0) {

          }
        })
        setState({ opening_balance: response.data.opening_balance, total_credit_amount: current_credit_total_amount, total_debit_amount: current_debit_total_amount })
        setrecord(false)
        setLoading(false)
      } else {
        <h1>No Any Record Found!....</h1>
        console.log("-----", response)
        setrecord(true)
      }
    })
      .catch((error) => {
        setrecord(true)
        console.error('Error group data:', error);
      })
  }, [send_date, date, show_period_double, isDeleteModalOpen])
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (show_period || show_period_double) {
      } else {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedItem(prevState => Math.min(prevState + 1, all_data?.voucherEntryList?.length - 1));
          }
        if (e.key === 'Enter') {
          e.preventDefault();
          const selectedList = all_data[selectedItem]
          if (selectedList) {
            navigate(`../voucherstransaction/${selectedList?.id}`)
          }
        } else if (e.ctrlKey && e.key === 'd') {
          e.preventDefault();
          setIsDeleteModalOpen(true);
        } else if (e.key === ' ') { // Handling space key press for selecting items
          e.preventDefault();
          toggleSelection(selectedItem);
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, all_data])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (show_other_voucher || show_period || show_period_double) {

      } else {
        if (e.key === 'F2') {
          e.preventDefault();
          setshow_period_double(true)
        }
        if (e.key === 'F4') {
          e.preventDefault();
          setshow_other_voucher(true)
        }
        if (e.key === 'F12') {
          e.preventDefault();
          setshow_configure(true)
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [show_other_voucher, show_period_double]);

  // scroll functionality ---------------------------
  useEffect(() => {
    if (boxRef.current) {
      // setClick(parseInt((boxRef.current.scrollHeight - boxRef.current.offsetHeight - boxRef.current.scrollTop) / 19))
      const selectedElement = boxRef.current.querySelector('.selected');
      if (selectedElement) {
        const boxHeight = boxRef.current.offsetHeight;
        const elementBottom = selectedElement.offsetTop + 70;
        const elementBottom1 = selectedElement.offsetTop + selectedElement.offsetHeight + 70;
        if (elementBottom > boxHeight) {
          // setClick(parseInt((boxRef.current.scrollHeight - boxRef.current.offsetHeight - boxRef.current.scrollTop) / 20))
          boxRef.current.scrollTop = elementBottom - boxHeight;
        } else {
          if (elementBottom1 > boxHeight) {
            boxRef.current.scrollTop = elementBottom - boxHeight;
          }
        }
      }
    }
  }, [selectedItem]);
  // Function to toggle selection of an item
  const toggleSelection = (index) => {
    const selectedIndex = selectedItems.indexOf(index);
    let newSelected = [];

    if (selectedIndex === -1) {
      // Add the item to the selection if not already selected
      newSelected = newSelected.concat(selectedItems, index);
    } else if (selectedIndex === 0) {
      // Remove the item from the selection if already selected
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }

    setSelectedItems(newSelected);
  };


  // Function to remove selected items
  const removeSelectedItems = () => {

    const updatedData = all_data?.voucherEntryList?.filter((_, index) => {
      return (
        all_data?.voucherEntryList[index] !== all_data?.voucherEntryList[selectedItem])
    });

    const removedItemsList = all_data?.voucherEntryList?.filter((_, index) => {
      return (
        all_data?.voucherEntryList[index] === all_data?.voucherEntryList[selectedItem])
    });
    let current_debit_total_amount = 0
    let current_credit_total_amount = 0
    updatedData.map((item, ind) => {
      return (
        current_debit_total_amount += parseFloat(item?.total_debit_amount),
        current_credit_total_amount += parseFloat(item?.total_credit_amount)
      )
      if (selectedItem > 0) {

      }
    })
    setState({ ...state, total_credit_amount: current_credit_total_amount, total_debit_amount: current_debit_total_amount })
    setall_data({ ...all_data, voucherEntryList: updatedData });
    setRemovedItems([...removedItems, { index: selectedItem, item: removedItemsList }]);
  };

  // Function to restore selected items
  const restoreSelectedItems = () => {
    let current_debit_total_amount = 0
    let current_credit_total_amount = 0
    const { index, item } = removedItems[removedItems.length - 1];
    // const updatedVoucherEntryList = all_data?.voucherEntryList?.concat(removedItems);
    const updatedData = {
      ...all_data,
      voucherEntryList: [
        ...all_data?.voucherEntryList.slice(0, index),
        ...item,
        ...all_data?.voucherEntryList.slice(index),
      ],
    };
    updatedData?.voucherEntryList?.map((item, ind) => {
      return (
        current_debit_total_amount += parseFloat(item?.total_debit_amount),
        current_credit_total_amount += parseFloat(item?.total_credit_amount)
      )
    })
    setState({ ...state, total_credit_amount: current_credit_total_amount, total_debit_amount: current_debit_total_amount })
    setall_data(updatedData)

    setRemovedItems([])

    // const restoredItems = selectedItem.map(index => all_data[index]);
    // setall_data([...all_data, ...restoredItems]);
  };

  const formatCurrency = (number) => {
    const [wholePart, decimalPart] = Number(number).toFixed(2).split('.');
    const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${formattedWholePart}.${decimalPart}`;
  };
  //---------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isDeleteModalOpen) {
        if (e.key === 'a' && e.ctrlKey) {
          e.preventDefault();
          yPressToDelete();
        }
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          yPressToDelete();
        }
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setIsDeleteModalOpen(false)
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isDeleteModalOpen])
  //Triggered when a press ok in modal to delete uqc code -----------------------------------------------------------------------------------------
  const yPressToDelete = () => {
    setLoading(true);
    const promises = [];
    const deleteMessages = { success: [], failure: [] };
    if (selectedItems.length > 0) {
      const uidsArray = selectedItems.map((selectedIndex) => all_data?.voucherEntryList[selectedIndex]?.uid);
      const uidsString = uidsArray.join(',');
      const formData = new FormData();
      formData.append('uid', uidsString);
      formData.append('company_id', userData?.id);
      const deletePromise = dispatch(voucherEntryDelete(formData));
      promises.push(deletePromise);
    } else {
      const formData = new FormData();
      formData.append('uid', all_data?.voucherEntryList[selectedItem]?.uid);
      formData.append('company_id', userData?.id);
      const deletePromise = dispatch(voucherEntryDelete(formData));
      promises.push(deletePromise);
    }
    Promise.all(promises)
      .then((responses) => {
        responses.forEach((response, index) => {
          if (response.status) {
            deleteMessages.success.push(response?.message || 'Group delete successfully!');
          } else {
            deleteMessages.failure.push(response?.message || 'Fail!');
          }
        });
        if (deleteMessages.success.length > 0) {
          setSelectedItems([]);
          toast.success(deleteMessages.success.join('\n'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
        if (deleteMessages.failure.length > 0) {
          toast.warn(deleteMessages.failure.join('\n'), {
            position: toast.POSITION.TOP_CENTER
          });
        }
        setIsDeleteModalOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        toast.error(error?.message || 'Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
        setIsDeleteModalOpen(false);
        setLoading(false);
      });
  };


  if (loading) {
    return <div>Loading...</div>
  }
  let cumulativeBalance = parseFloat(state?.opening_balance) || 0;
  return (
    <div className='section'>
      <div className='detail border'>
        <Modal
          isOpen={isDeleteModalOpen}
          style={customStyles}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <p className='modal_message'>
                  Accept ?
                </p>
              </div>
              <div className='col-5'>
                <Link className="modal_link" onClick={yPressToDelete} >Yes</Link>
              </div>
              <div className='col-2'>or</div>
              <div className='col-5'>
                <Link className='modal_link' onClick={() => { return (setIsDeleteModalOpen(false)) }}>No</Link>
              </div>
            </div>
          </div>
        </Modal>
        {
          show_period_double ? <PeriodDouble date={date} setall_data={setall_data} setrecord={setrecord} setDate={setDate} setsend_date={setsend_date} setshow_period_double={setshow_period_double} show_period_double={show_period_double} setLoading={setLoading} /> : null
        }
        {
          show_other_voucher ? <LedgerBook setshow_other_voucher={setshow_other_voucher} show_other_voucher={show_other_voucher} /> : null
        }
        {
          show_configure ? <Configure setshow_configure={setshow_configure} show_configure={show_configure} setState_configure={setState_configure} /> : null
        }
        <div className='container-fluid'>
          <div className='row border border-dark table_date_header'>
            <div className='col-12 m-0 p-0 ps-1 pe-1 d-flex justify-content-between  table_header_font'>
              <b> {location.pathname == `/ledgervoucher/${id}` ? 'Ledger Voucher' : null} </b>
              <span>{`${date?.from_date} To ${date?.to}`}</span>
            </div>
          </div>
          <div className="row">
            <div className='col-4'>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher'> Ledger</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.ledger_name}</strong></div>
              </div>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher'> Address</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.address}</strong></div>
              </div>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher'> Mobile</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.mobile_no}</strong></div>
              </div>
            </div>
            <div className='col-4  p-0'>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher' > GSTIN</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.ledger_name}</strong></div>
              </div>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher'> Addhar Number</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.aadhaar}</strong></div>
              </div>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher'> Email</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.email}</strong></div>
              </div>
            </div>
            <div className='col-4 p-0'>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher'> Ac No</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.account_no}</strong></div>
              </div>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab_ledger_voucher' > Transport Details</label>
                <label> :</label>
                <div className='lab_right_ledger_voucher'><strong>{all_data?.main_ledger_record?.transport_detail}</strong></div>
              </div>
            </div>
          </div>
          <div className='row border-top border-bottom border-dark heading_dyn_creation_data ' style={{ height: 19.5 }}>
            <div className='col-1 font_size' style={{ width: '9%', textAlign: 'right', height: 19.5 }}>Date</div>
            <div className='col-1  p-0 font_size text-right ' style={{ width: '7%', height: 19.5 }}>Vch No.</div>
            <div className='col-4 font_size' style={{ width: '26%', textAlign: 'left', height: 19.5 }}>Particular</div>
            <div className='col-1 font_size' style={{ width: '9%', textAlign: 'left', height: 19.5 }}>Intrest</div>
            <div className='col-1 font_size' style={{ width: '9%', textAlign: 'left', height: 19.5 }}>Int Rec</div>
            <div className='col-1 font_size p-0 text-start' style={{ width: '8%', height: 19.5 }}>Vch Type</div>
            <div className='col-1 font_size m-0 p-0 pe-1' style={{ width: '9.5%', textAlign: 'right', height: 19.5 }}>Debit</div>
            <div className='col-1 font_size m-0 p-0 pe-1' style={{ width: '9.5%', textAlign: 'right', height: 19.5 }}>Credit</div>
            <div className='col-1 m-0 p-0 pe-1 font_size ' style={{ width: '13%', textAlign: 'right', height: 19.5 }}>Balance</div>
          </div>
          <div className='row position-relative' style={{ height: '66vh', overflowY: 'scroll', overflowX: 'hidden' }} ref={boxRef}>

            {
              record ?
                <div className='col-12 d-flex align-items-center justify-content-center'>
                  <p className='text-muted'>No Record Found !...</p>
                </div> :

                <div className='col-12 m-0 p-0'>
                  <div className='row'>
                    <div className='col-9 font_size' style={{ width: '64%' }}>Opening Balance :</div>
                    <div className='col-1 font_size' style={{ width: '12%', textAlign: 'right' }}>{state?.opening_balance > 0 ? formatCurrency(state?.opening_balance) : null}</div>
                    <div className='col-1 font_size' style={{ width: '12%', textAlign: 'right' }}>{state?.opening_balance < 0 ? formatCurrency(state?.opening_balance) : null}</div>
                  </div>
                  {
                    all_data?.voucherEntryList?.map((item, index) => {

                      cumulativeBalance += parseFloat(item?.total_debit_amount) - parseFloat(item?.total_credit_amount);
                      return (
                        <div key={index} className={selectedItems?.includes(index) ? 'selected_multi' : ''}>
                          <div className={`row px-1  mt-0  ${index === selectedItem ? selectedItems?.includes(index) ? 'selected_multi_selected' : 'selected' : ''}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              toggleSelection(index);
                              setSelectedItem(index);
                            }}>
                            <div className='col-1 font_size text-end' style={{ width: '9%' }}>{dateFormateShow(item?.create_date)}</div>
                            <div className='col-1 font_size text-end' style={{ width: '7%' }}>{item?.serial_no}</div>
                            <div className='col-4 font_size' style={{ width: '26%', textAlign: 'left' }}>
                              {state_configure?.formate_of_report === 'Condensed' ? item?.particular_ledger_pos[0]?.particular_ledger_name : null}
                            </div>
                            <div className='col-1 font_size' style={{ width: '9%', textAlign: 'left', }}>{ }</div>
                            <div className='col-1 font_size' style={{ width: '9%', textAlign: 'left', }}>{ }</div>
                            <div className='col-1 font_size p-0 text-start ' style={{ width: '8%' }}>{item?.voucher_type_name}</div>
                            <div className='col-1 font_size  m-0 p-0 pe-1' style={{ width: '9.5%', textAlign: 'right', }}>{item?.total_debit_amount > 0 ? currencyFormate(parseFloat(item?.total_debit_amount)) : null}</div>
                            <div className='col-1 font_size  m-0 p-0 pe-1' style={{ width: '9.5%', textAlign: 'right', }}>{item?.total_credit_amount > 0 ? currencyFormate(parseFloat(item?.total_credit_amount)) : null}</div>
                            <div className='col-1 font_size' style={{
                              textAlign: 'right',
                              flex: '0 1 auto',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden', width: '13%',
                            }}>
                              {
                                cumulativeBalance > 0 ? `${currencyFormate(cumulativeBalance)} Dr` : `${currencyFormate(cumulativeBalance)} Cr`
                              }
                            </div>
                            {state_configure?.formate_of_report === 'Detailed' ? item?.particular_ledger_pos?.map((ledger, ledgerIndex) => (
                              <>
                                <div className='col-12' key={ledgerIndex}>
                                  <div className='row'>
                                    <div className='col-3 font_size offset-2' style={{ textAlign: 'left' }}>
                                      {ledger?.particular_ledger_name}
                                    </div>
                                    <div className='col-2 font_size' style={{ textAlign: 'left' }}>
                                      {`${formatCurrency(ledger?.particular_ledger_pay_amount)} ${ledger?.transaction_type}`}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )) : null}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
            }
          </div>
          <div className='row border-top border-dark'>
            <div className='col-12'>
              <div className='row' style={{ height: 19.5 }}>
                <div className='col-8 font_size' style={{ width: '68.2%', textAlign: 'right' }}>Current Total :</div>
                <div className='col-1  m-0 p-0 pe-1 font_size' style={{ width: '9.5%', textAlign: 'right' }}><strong>{currencyFormate(state?.total_debit_amount)}</strong></div>
                <div className='col-1 m-0 p-0 pe-1 font_size' style={{ width: '9.5%', textAlign: 'right' }}><strong>{currencyFormate(state?.total_credit_amount)}</strong></div>
              </div>
              <div className='row border-top border-dark' style={{ height: 19.5 }}>
                <div className='col-4 pe-0 font_size' style={{ width: '68.2%', textAlign: 'right', marginLeft: -13 }}>Closing Balance :</div>
                <div className='col-4 m-0 p-0 pe-1 font_size' style={{ width: '10.6%', textAlign: 'right', marginRight: -5 }}><strong>{currencyFormate((parseFloat(state?.total_debit_amount) || 0) - (parseFloat(state?.total_credit_amount) || 0) + (parseFloat(state?.opening_balance) || 0))}</strong></div>
              </div>
            </div>
          </div>
        </div>

        <div className='tomasterfooter'>
          {
            show_other_voucher || show_period_double || show_configure ? null :

              <div className='footer'>
                <div className='boxfooter'>
                  <button className='button'>
                    Q: Quite
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button'>
                    Enter: Alter
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button'>
                    Space: Select
                  </button>
                </div>
                <div className='boxfooter'>
                  <button type='submit' className='button'>
                    <u>A</u>: Add Vch
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button'>
                    <u>2</u>: Duplicate Vch
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button'>
                    <u>I</u>: Insert Vch
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button' onClick={(e) => {
                    return (
                      e.preventDefault(),
                      setIsDeleteModalOpen(true))
                  }}>
                    <u>D</u>: Delete
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button'>
                    <u>X</u>: Cancel
                  </button>
                </div>
                <div className='boxfooter'>
                  <button className='button' onClick={removeSelectedItems}>
                    <u><u>R</u></u>: Remove Line
                  </button>
                </div>
                <div className='boxfooter' onClick={restoreSelectedItems}>
                  <button disabled={removedItems.length <= 0} className='button'>
                    <u><u>U</u></u>: Restore Line
                  </button>
                </div>
              </div>
          }
        </div>
      </div>
      <div className='help' style={{ position: 'relative' }}>
        {
          show_other_voucher || show_period_double || show_configure ? null :
            <>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={() => { return (setshow_period_double(true)) }}>
                  <p className='card-header'>
                    <span> <u>F2</u></span>:
                    Period
                  </p>
                </button>
              </div>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={() => { return (setshow_other_voucher(true)) }}>
                  <p className='card-header'>
                    <span>F4</span>:
                    Ledger
                  </p>
                </button>
              </div>
              <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <div className='help_button_card ms-1' >
                  <button className={`button_padding`} onClick={() => {
                    setshow_configure(true);
                  }}>
                    <p className='card-header'>
                      <span className='border-bottom border-dark'>E</span>:
                      Export
                    </p>
                  </button>
                </div>
                <div className='help_button_card mb-2 ms-1' >
                  <button className={`button_padding`} onClick={() => {
                    setshow_configure(true);
                  }}>
                    <p className='card-header'>
                      <span className='double_underline'>P</span>:
                      Print
                    </p>
                  </button>
                </div>
                <div className='help_button_card mb-2 ms-1' >
                  <button className={`button_padding`} onClick={() => {
                    setshow_configure(true);
                  }}>
                    <p className='card-header'>
                      <span>F12</span>:
                      Configure
                    </p>
                  </button>
                </div>
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default LedgerVoucher

function PeriodDouble(props) {
  const dispatch = useDispatch();
  const [date, setdate] = useState({ from: '', to: '', show_date: '' })
  const from_input_ref = useRef(null)
  const to_input_ref = useRef(null)
  const { company_session } = useSelector(state => state.common);
  const handlechange = (e) => {
    const { name, value } = e?.target;
    setdate((prevDate) => ({ ...prevDate, [name]: value }));
    if (name === 'from') {
      if (e?.key === 'Enter' && date?.from !== '') {
        e?.preventDefault();
        const match = date?.from?.match(/^(\d+)[.-\/](\d+)[.-\/]?(\d*)$/);
        if (match) {
          const [, day, month, year_from] = match;
          const year = new Date(company_session?.start_financial_year).getFullYear();
          const financialYearStart = year;
          const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
          if (year_from !== '') {
            const parsedMonth = parseInt(month, 10);
            if (parsedMonth >= 1 && parsedMonth <= 12) {
              let parsedYear;
              if (year_from.length === 2) {
                const currentYear = new Date().getFullYear();
                const currentCentury = Math.floor(currentYear / 100) * 100;
                parsedYear = parseInt(year_from, 10) + currentCentury;
                if (parsedYear === financialYearStart) {
                  if (parsedMonth > 3) {
                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                    const parsedDay = parseInt(day, 10);
                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                      const monthNames = [
                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                      ];
                      const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                      const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                      const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                      setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                      to_input_ref.current.focus();
                    } else {
                      alert('invalid day')
                    }
                  } else {
                    alert('Invalid month')
                  }

                } else if (parsedYear === financialYearEnd) {
                  if (parsedMonth <= 3) {
                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                    const parsedDay = parseInt(day, 10);
                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                      const monthNames = [
                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                      ];
                      const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                      const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                      const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                      setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                      to_input_ref.current.focus();
                    } else {
                      alert('invalid day')
                    }
                  } else {
                    alert('Invalid month')
                  }
                } else if (parsedYear !== financialYearStart && parsedYear !== financialYearEnd) {
                  const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                  const parsedDay = parseInt(day, 10);
                  if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                    const monthNames = [
                      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    ];
                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                    const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                    const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                    setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                    to_input_ref.current.focus();
                  } else {
                    alert('invalid day')
                  }
                }

              } else if (year_from.length === 4) {
                parsedYear = parseInt(year_from, 10);
                const monthNames = [
                  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                ];
                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
                const formattedDateString_send = `${parsedYear}-${month}-${day || '01'}`;
                setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                to_input_ref.current.focus();
              } else {
                alert("Invalid Year");
                return;
              }
            } else {
              alert("Invalid Month")
            }
          } else {
            const parsedMonth = parseInt(month, 10);
            if (parsedMonth >= 1 && parsedMonth <= 12) {
              const startYear = month <= 3 ? financialYearEnd : financialYearStart;
              const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
              const parsedDay = parseInt(day, 10);
              if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                const monthNames = [
                  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                ];
                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                const formattedDateString_send = `${startYear}-${month}-${day || '01'}`;
                setdate({ ...date, from: formattedDateString, show_date: formattedDateString_send })
                to_input_ref.current.focus();
              } else {
                alert('invalid day')
              }

            } else {
              alert('Invalid month')
            }
          }
        } else {
          alert("Invalid date")
        }
      }
    }
    if (name === 'to') {
      if (e?.key === 'Enter' && date?.from !== '' && date?.to !== '') {
        e?.preventDefault();
        // props?.setsend_date({ from_date: '', to_date: '' })
        const match = date?.to?.match(/^(\d+)[.-\/](\d+)[.-\/]?(\d*)$/);
        if (match) {
          const [, day, month, year_from] = match;
          const from_date_check = new Date(date?.show_date)
          const from_date_month = from_date_check?.getMonth() + 1
          const from_date_day = from_date_check.getDate()
          const from_date_year = from_date_check.getFullYear()
          if (year_from !== '') {
            const parsedMonth = parseInt(month, 10);
            let parsedYear;
            if (parsedMonth >= 1 && parsedMonth <= 12) {
              if (year_from.length === 2) {
                const currentYear = new Date().getFullYear();
                const currentCentury = Math.floor(currentYear / 100) * 100;
                parsedYear = parseInt(year_from, 10) + currentCentury;
                if (parsedYear == from_date_year) {
                  if (parsedMonth >= from_date_month) {
                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                    const parsedDay = parseInt(day, 10);
                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                      if (parsedDay >= from_date_day) {
                        const monthNames = [
                          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                        ];
                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                        const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                        const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                        props?.setDate({
                          from_date: date?.from,
                          to: formattedDateString
                        });
                        props?.setsend_date({
                          from_date: date?.show_date,
                          to_date: formattedDateString_send
                        });
                        props.setLoading(true)
                        props?.setshow_period_double(false);

                      } else {
                        alert('invalid day')
                      }
                    } else {
                      alert('invalid day')
                    }
                  } else {
                    alert('invalid month')

                  }

                } else if (parsedYear > from_date_year) {
                  const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                  const parsedDay = parseInt(day, 10);
                  if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                    const monthNames = [
                      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    ];
                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                    const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                    const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                    props?.setDate({
                      from_date: date?.from,
                      to: formattedDateString
                    });
                    props?.setsend_date({
                      from_date: date?.show_date,
                      to_date: formattedDateString_send
                    });
                    props.setLoading(true)
                    props?.setshow_period_double(false);


                  } else {
                    alert('invalid day')
                  }

                } else {
                  alert('Invalid Year')
                }
              }
              else if (year_from.length === 4) {
                parsedYear = parseInt(year_from, 10);
                if (parsedYear >= from_date_year) {
                  if (parsedMonth >= from_date_month) {
                    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                    const parsedDay = parseInt(day, 10);
                    if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                      if (parsedDay >= from_date_day) {
                        const monthNames = [
                          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                        ];
                        const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                        const formattedDateString = `${parsedDay || '01'}-${monthAbbreviation}-${parsedYear}`;
                        const formattedDateString_send = `${parsedYear}-${month}-${parsedDay || '01'}`;
                        props?.setDate({
                          from_date: date?.from,
                          to: formattedDateString
                        });
                        props?.setsend_date({
                          from_date: date?.show_date,
                          to_date: formattedDateString_send
                        });
                        props.setLoading(true)
                        props?.setshow_period_double(false);

                      } else {
                        alert('invalid day')
                      }
                    } else {
                      alert('invalid day')
                    }
                  } else {
                    alert('invalid month')
                  }

                } else {
                  alert('Invalid Year')
                }
              } else {
                alert('invalid year')
              }
            } else {
              alert("Invalid Month")
            }
          } else {
            const parsedMonth = parseInt(month, 10);
            if (parsedMonth >= 1 && parsedMonth <= 12) {
              if (parsedMonth == from_date_month) {
                const daysInMonth = new Date(from_date_year, parsedMonth, 0).getDate(); // Get the number of days in the month
                const parsedDay = parseInt(day, 10);
                if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                  if (day > from_date_day || day == from_date_day) {
                    const monthNames = [
                      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    ];
                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                    const formattedDateString = `${day || '01'}-${monthAbbreviation}-${from_date_year}`;
                    const formattedDateString_send = `${from_date_year}-${month}-${day || '01'}`;
                    props?.setDate({
                      from_date: date?.from,
                      to: formattedDateString
                    });
                    props?.setsend_date({
                      from_date: date?.show_date,
                      to_date: formattedDateString_send
                    });
                    props.setLoading(true)
                    props?.setshow_period_double(false);
                  } else {
                    alert('invalid day')
                  }
                } else {
                  alert('invalid day')
                }
              } else if (from_date_month <= 3 && parsedMonth <= 3) {
                if (from_date_month === 2 && (parsedMonth === 1 || parsedMonth === 3)) {
                  alert('Invalid Month')
                } else if (from_date_month === 3 && (parsedMonth === 1 || parsedMonth === 2)) {
                  alert("invalid month")
                } else {
                  const year = new Date(company_session?.start_financial_year).getFullYear();
                  const financialYearStart = year;
                  const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
                  const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                  const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
                  const parsedDay = parseInt(day, 10);
                  if (parsedDay >= 1 && parsedDay <= daysInMonth) {
                    const monthNames = [
                      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    ];
                    const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                    const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                    const formattedDateString_send = `${startYear}-${month}-${day || '01'}`;
                    props?.setDate({
                      from_date: date?.from,
                      to: formattedDateString
                    });
                    props?.setsend_date({
                      from_date: date?.show_date,
                      to_date: formattedDateString_send
                    });
                    props.setLoading(true)
                    props?.setshow_period_double(false);
                  } else {
                    alert('invalid day')
                  }
                }
              } else if (from_date_month >= 4) {
                const year = new Date(company_session?.start_financial_year).getFullYear();
                const financialYearStart = year;
                const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
                const startYear = month <= 3 ? financialYearEnd : financialYearStart;
                const monthNames = [
                  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                ];
                const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
                const formattedDateString = `${day || '01'}-${monthAbbreviation}-${startYear}`;
                const formattedDateString_send = `${startYear}-${month}-${day || '01'}`;
                props?.setDate({
                  from_date: date?.from,
                  to: formattedDateString
                });
                props?.setsend_date({
                  from_date: date?.show_date,
                  to_date: formattedDateString_send
                });
                props.setLoading(true)
                props?.setshow_period_double(false);
              } else {
                alert('Invalid date you enter')
              }

            } else {
              alert('Invalid month')
            }
          }
        } else {
          alert("Invalid date")
        }
      }
    }
  }

  useEffect(() => {
    const handlekeydown = (e) => {
      if (e?.key === 'Escape') {
        e?.preventDefault();
        props?.setshow_period_double(false)
        e?.stopPropagation();
      }
    }
    document.addEventListener('keydown', handlekeydown)
    return () => {
      document.removeEventListener('keydown', handlekeydown);
    };
  }, [props?.data, date])
  const { from, to } = date;
  return (
    <div className='my-card'>
      <div className='cross_button' onClick={() => { return (props.setshow_period_double(false)) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <div className='container pb-3' id='card-period'>
        <div className='row'>
          <div className='col'>
            <b className='border-bottom'>Voucher Date</b>
          </div>
        </div>
        <div className='row'>
          <form autoComplete='off' onSubmit={(e) => e.preventDefault()}>
            <div className='col'>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab-1'>From Date</label>
                <label>:</label>
                <input type='text' autoFocus name='from' id='from' ref={from_input_ref} onChange={handlechange} onKeyUp={handlechange} value={from || ''} className='lab-right' />
              </div>
              <div className='d-flex justify-content-between align-item-center'>
                <label className='lab-1'>To</label>
                <label>:</label>
                <input type='text' name='to' id='to' ref={to_input_ref} onChange={handlechange} onKeyUp={handlechange} value={to || ''} className='lab-right' />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
    </div>
  )
}

function Configure(props) {
  const [state, setState] = useState(
    {
      show_narrations: 'No',
      formate_of_report: 'Condensed',
      show_bill_wise_details: 'No',
      show_inventory_details: 'No',
      show_mode_of_payment: 'No',
      show_additional_bank_details: 'No',
      display_name_for_ledgers: 'Name Only',
      display_name_for_stock_items: 'Name Only',
      sorting_method: 'Default',
      show_running_balance: 'Yes',


    })
  const show_narrations_input_ref = useRef(null);
  const formate_of_report_input_ref = useRef(null);
  const show_bill_wise_details_input_ref = useRef(null);
  const show_inventory_details_input_ref = useRef(null);
  const show_mode_of_payment_input_ref = useRef(null);
  const show_additional_bank_details_input_ref = useRef(null);
  const display_name_for_ledgers_input_ref = useRef(null);
  const display_name_for_stock_items_input_ref = useRef(null);
  const sorting_method_input_ref = useRef(null);
  const show_running_balance_input_ref = useRef(null);

  const handlechange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter') {
      e.preventDefault();
      const nextInputRef = name === 'show_narrations' ? formate_of_report_input_ref :
        name === 'formate_of_report' ? show_bill_wise_details_input_ref :
          name === 'show_bill_wise_details' ? show_inventory_details_input_ref :
            name === 'show_inventory_details' ? show_mode_of_payment_input_ref :
              name === 'show_mode_of_payment' ? show_additional_bank_details_input_ref :
                name === 'show_additional_bank_details' ? display_name_for_ledgers_input_ref :
                  name === 'display_name_for_ledgers' ? display_name_for_stock_items_input_ref :
                    name === 'display_name_for_stock_items' ? sorting_method_input_ref :
                      name === 'sorting_method' ? show_running_balance_input_ref : null;
      if (name === 'show_running_balance') {
        if (e.key === 'Enter') {
          e.preventDefault();
          props.setState_configure(
            {
              show_narrations: state?.show_narrations,
              formate_of_report: state?.formate_of_report,
              show_bill_wise_details: state?.show_bill_wise_details,
              show_inventory_details: state?.show_inventory_details,
              show_mode_of_payment: state?.show_mode_of_payment,
              show_additional_bank_details: state?.show_additional_bank_details,
              display_name_for_ledgers: state?.display_name_for_ledgers,
              display_name_for_stock_items: state?.display_name_for_stock_items,
              sorting_method: state?.sorting_method,
              show_running_balance: state?.show_running_balance,
            }
          )
          props.setshow_configure(false)
        }
      }
      if (nextInputRef) {
        nextInputRef.current.focus();
        if (nextInputRef.current) {
          nextInputRef.current.setSelectionRange(0, nextInputRef?.current?.value?.length);
        }
      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const prevInputRef = name === 'formate_of_report' ? show_narrations_input_ref :
        name === 'show_bill_wise_details' ? formate_of_report_input_ref :
          name === 'show_inventory_details' ? show_bill_wise_details_input_ref :
            name === 'show_mode_of_payment' ? show_inventory_details_input_ref :
              name === 'show_additional_bank_details' ? show_mode_of_payment_input_ref :
                name === 'display_name_for_ledgers' ? show_additional_bank_details_input_ref :
                  name === 'display_name_for_stock_items' ? display_name_for_ledgers_input_ref :
                    name === 'sorting_method' ? display_name_for_stock_items_input_ref :
                      name === 'show_running_balance' ? sorting_method_input_ref : null;
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        prevInputRef.current.focus();
        if (prevInputRef.current) {
          prevInputRef.current.setSelectionRange(0, 0);
        }
      }
      return;
    }
  }
  const {
    show_narrations,
    formate_of_report,
    show_bill_wise_details,
    show_inventory_details,
    show_mode_of_payment,
    show_additional_bank_details,
    display_name_for_ledgers,
    display_name_for_stock_items,
    sorting_method,
    show_running_balance,
  } = state;
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        props.setshow_configure(false);
        e.stopPropagation();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.show_configure]);
  return (
    <div className='my-card'>
      <div className='cross_button' onClick={() => { return (props.setshow_configure(false)) }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
      </div>
      <div className='container pb-3 border border-dark' id='card-period' style={{ width: '60%', background: '#fff' }}>
        <div className='row'>
          <div className='col border-bottom'>
            <b>Configure</b>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <p className='m-0 p-0 mb-1 border-bottom border-gray'>General Details</p>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Show Narration</label>
              <label>:</label>
              <input
                type='text'
                autoFocus
                name='show_narrations'
                id='show_narrations'
                ref={show_narrations_input_ref}
                onChange={handlechange}
                onKeyUp={handlechange}
                value={show_narrations || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Formate of Report</label>
              <label>:</label>
              <input
                type='text'
                name='formate_of_report'
                ref={formate_of_report_input_ref}
                id='formate_of_report'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={formate_of_report || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Show Bill Wise Details</label>
              <label>:</label>
              <input
                type='text'
                name='show_bill_wise_details'
                id='show_bill_wise_details'
                ref={show_bill_wise_details_input_ref}
                onChange={handlechange}
                onKeyUp={handlechange}
                value={show_bill_wise_details || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Show Inventory Details</label>
              <label>:</label>
              <input
                type='text'
                name='show_inventory_details'
                ref={show_inventory_details_input_ref}
                id='show_inventory_details'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={show_inventory_details || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Show Mode of Payment</label>
              <label>:</label>
              <input
                type='text'
                name='show_mode_of_payment'
                ref={show_mode_of_payment_input_ref}
                id='show_mode_of_payment'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={show_mode_of_payment || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Show Additional Bank Details</label>
              <label>:</label>
              <input
                type='text'
                name='show_additional_bank_details'
                ref={show_additional_bank_details_input_ref}
                id='show_additional_bank_details'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={show_additional_bank_details || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Display Name For Ledgers</label>
              <label>:</label>
              <input
                type='text'
                name='display_name_for_ledgers'
                ref={display_name_for_ledgers_input_ref}
                id='display_name_for_ledgers'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={display_name_for_ledgers || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Display name for stock items</label>
              <label>:</label>
              <input
                type='text'
                name='display_name_for_stock_items'
                ref={display_name_for_stock_items_input_ref}
                id='display_name_for_stock_items'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={display_name_for_stock_items || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Sorting Method</label>
              <label>:</label>
              <input
                type='text'
                name='sorting_method'
                ref={sorting_method_input_ref}
                id='sorting_method'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={sorting_method || ''}
                className='lab-right' />
            </div>
            <div className='d-flex justify-content-between align-item-center'>
              <label className='lab-1'>Show Running Balance</label>
              <label>:</label>
              <input
                type='text'
                name='show_running_balance'
                ref={show_running_balance_input_ref}
                id='show_running_balance'
                onChange={handlechange}
                onKeyUp={handlechange}
                value={show_running_balance || ''}
                className='lab-right' />
            </div>
          </div>
        </div>
      </div>
      <div className='tomasterfooter' style={{ background: 'red' }}><Footer /></div>
    </div>
  )
}

