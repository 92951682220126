import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import './voucher.css'
import {
  ChangeMode,
  Configure,
  DispatchDetails,
  OrderDetails,
  OtherVoucher,
  Period,
  VoucherTypeNameSelect
} from './voucher_modal/Index'
import {
  voucherTypeList,
  contraLedgerListing,
  serialNumberList,
  contraVoucherCreate,
  storeVoucherEntryData,
  numberSeriesList,
  godownList,
  stockItemList,
  filterVoucherTypeData
} from '../../../redux/actions/action-creator';

import {
  dateFormateSend,
  dateFormateShow,
  dateDayShow,
  currencyFormate,
  removeCurrencyComma
} from '../../../components/date_formate/Index'

import YesNoModal from '../../../components/model/YesNoModal';
import { SuitDiamondFill } from 'react-bootstrap-icons';

let filter_voucher = [];
const array_avai_not_avai = [
  { id: 1, name: 'Not Available' },
  { id: 2, name: 'Available' }]

function Vouchers({ setProgress }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // get state data from redux 
  const { userData } = useSelector(state => state.auth);
  const { voucher_type_data = null } = useSelector(state => state.common);
  const { company_session } = useSelector(state => state.common);
  // states For conditionally Functionality
  const [backspaceCount, setBackspaceCount] = useState(0)
  const [nameInput, setNameInput] = useState(null)
  const [showBorder, setShowBorder] = useState(false)
  const [loading, setLoading] = useState(true);
  const [account_default_balance, setaccount_default_balance] = useState('')
  const [particular_default_balance, setparticular_default_balance] = useState()
  const [currentBalance, setCurrentBalance] = useState();
  const [filteredData, setFilteredData] = useState([])
  const [filteredDataPartyBillTo, setFilteredDataPartyBillTo] = useState([])
  const [filteredDataPartyShipTo, setFilteredDataPartyShipTo] = useState([])
  const [filteredDataParticular, setFilteredDataParticular] = useState([])
  const [filteredDataItem, setFilteredDataItem] = useState([])
  const [filteredDataItemLedger, setFilteredDataItemLedger] = useState([])
  const [filteredDataItemLedgerPurchase, setFilteredDataItemLedgerPurchase] = useState([])
  const [filteredDataBankAccountOdLedger, setFilteredDataBankAccountOdLedger] = useState([])
  const [filteredDataCashInHandLedger, setFilteredDataCashInHandLedger] = useState([])
  const [filteredDataSundryDebCredLedger, setFilteredDataSundryDebCredLedger] = useState([])
  const [filteredDataPartyAccountName, setFilteredDataPartyAccountName] = useState([])
  const [filteredDatSalesPurchase, setFilteredDataSalesPurchase] = useState([])
  const [filteredDataDispatchDetails, setFiltereddDataDispatchDetails] = useState([])
  const [filteredDataOrderDetails, setFiltereddDataOrderDetails] = useState([])
  const [filteredDataLocation, setFiltereddDataLocation] = useState([])
  const [filteredDataLocationItem, setFiltereddDataLocationItem] = useState([])
  const [selectedItem, setSelectedItem] = useState(0);
  const [mode, setmode] = useState('Single Mode');

  const date_formated_send = dateFormateSend(company_session?.current_date)
  const date_formated_show = dateFormateShow(company_session?.current_date)
  const date_day_show = dateDayShow(date_formated_show)
  const [data, setData] = useState({
    entry_name: 'Contra',
    entry_name_id: '1',
    voucher_number_series_id: '1',
    serial_no: '',
    date: date_formated_show,
    day_name: date_day_show,
    account: '',
    account_id: '',
    account_current_balance: '',
    party_bill_to: '',
    party_bill_to_id: '',
    partry_bill_to_current_balance: '',
    mailing_name_bill_to: '',
    address_bill_to: '',
    contact_bill_to: '',
    party_ship_to: '',
    party_ship_to_id: '',
    party_ship_to_current_balance: '',
    mailing_name_ship_to: '',
    addressh_ship_to: '',
    contact_ship_to: '',
    supplier_inv_ref: '',
    date_payment: '',
    sale_purchase_ledger_account: '',
    sale_purchase_ledger_account_id: '',
    sale_purchase_ledger_current_balance: '',
    dispatch_details: 'Not Available',
    order_details: 'Not Available',
    location: '',
    location_id: '',
    party_account_name: '',
    party_account_name_id: '',
    party_account_name_payment: '',
    bank_account_od_ledger: '',
    bank_account_od_payment: '',
    bank_account_od_ledger_id: '',
    cash_in_hand_ledger: '',
    cash_in_hand_payment: '',
    cash_in_hand_ledger_id: '',
    sundry_creditor_debtors_ledger: '',
    sundry_creditor_debtors_payment: '',
    sundry_creditor_debtors_ledger_id: '',
    utr_chq_no: '',
    cash_tendered: '',
    txn_id_ref_no: '',
    balance: '',
    narration: '',
    check_num_series_manual_automatic: '',
    is_unit_available: '',
    where_unit_num: '',
    where_alternate_unit_num: '',
    alternate_unit_name: '',
    main_unit_name: '',
    combination_of_units: ''
  })
  const [state_dispatch_details, setstate_dispatch_details] = useState({
    delivery_note_no: '',
    dispatch_doc_no: '',
    dispatched_through: '',
    destination: '',
    carrier_name_agent: '',
    bill_of_lading_lr_rr_no: '',
    date: ''
  })
  const [state_order_details, setstate_order_details] = useState({
    order_no: '',
    mode_terms_of_payment: '',
    other_references: '',
    terms_of_delivey: '',
  })
  const [valueLoop, setValueLoop] = useState([
    { id: '1', first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '', }])

  const [item_entry, setItem_entry] = useState([
    {
      id: '1',
      item_name: '',
      item_id: '',
      godown_location: '',
      godown_location_id: '',
      item_qty: '',
      item_rate: '',
      per_unit: '',
      item_amt: '',
      item_alternate_unit: '',
      is_unit_available: '',
      where_unit_num: '',
      where_alternate_unit_num: '',
      main_unit_name: '',
      combination_of_units: '',
      check_open: false,
      total_nos_box: '',
      total_no_box_unit: '',
    }])

  const [item_entry_ledger_account, setItem_entry_ledger_account] = useState([
    { id: '1', ledger_account: '', ledger_account_id: '', rate_percentage: '', ledger_amount: '', check: false }])

  const [double_mode_input, setdouble_mode_input] = useState([
    { id: '1', credit_debit: 'Cr', particular_ledger_name: '', particular_ledger_balance: '', particular_ledger_id: '', debit_amount: '', credit_amount: '' }])

  // state for open modal and close 
  const [show_period, setshow_period] = useState(false)
  const [show_other_voucher, setshow_other_voucher] = useState(false)
  const [show_voucher_type, setshow_voucher_type] = useState(false)
  const [show_configure, setshow_configure] = useState(false);
  const [show_dispatch_details_modal, setshow_dispatch_details_modal] = useState(false);
  const [show_order_details_modal, setshow_order_details_modal] = useState(false);
  const [change_mode, setchange_mode] = useState(false);
  //state for open dropdown 
  const [show_account_list, setshow_account_list] = useState(false);
  const [show_party_bill_to_list, setshow_party_bill_to_list] = useState(false);
  const [show_party_ship_to_list, setshow_party_ship_to_list] = useState(false);
  const [show_sales_purchase_list, setshow_sales_purchase_list] = useState(false);
  const [show_particular_list, setshow_particular_list] = useState(false);
  const [show_item_list, setshow_item_list] = useState(false);
  const [show_item_ledger_list, setshow_item_ledger_list] = useState(false);
  const [show_godown_list, setshow_godown_list] = useState(false);
  const [show_particular_double_list, setshow_particular_double_list] = useState(false);
  const [show_dispatch_order, setshow_dispatch_order] = useState(false);
  const [show_order_details, setshow_order_details] = useState(false);
  const [show_location, setshow_location] = useState(false);
  const [show_sundry_deb_cred_list, setshow_sundry_deb_cred_list] = useState(false);
  const [show_cash_in_hand_list, setshow_cash_in_hand_list] = useState(false);
  const [show_bank_od_list, setshow_bank_od_list] = useState(false);
  const [show_party_account_name, setshow_party_account_name] = useState(false);
  const [total_amount, settotal_amount] = useState(0);
  const [total_amount_item, settotal_amount_item] = useState(0);
  const [total_amount_credit, settotal_amount_credit] = useState(0);
  const [total_amount_debit, settotal_amount_debit] = useState(0);
  const [total_trigger, settotal_trigger] = useState(false)
  const [total_trigger_credit, settotal_trigger_credit] = useState(false)
  const [total_trigger_debit, settotal_trigger_debit] = useState(false)
  const [store_index, setstore_index] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0)
  const [contra_account_ledger_list, setcontra_account_ledger_list] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [godownLocation, setgodownLocation] = useState([]);
  const [godownLocationItem, setgodownLocationItem] = useState([]);
  const [itemLedgerData, setItemLedgerData] = useState([]);
  const [itemLedgerPurchaseData, setItemLedgerPurchaseData] = useState([]);
  const [bankAccountOdLedger, setbankAccountOdLedger] = useState([]);
  const [cashInHandLedger, setcashInHandLedger] = useState([]);
  const [sundryDebCredLedger, setsundryDebCredLedger] = useState([]);
  const [partyAccountNameLedger, setpartyAccountNameLedger] = useState([]);
  const [receipt_particular_ledger_list, setreceipt_particular_ledger_list] = useState([]);
  const [party_bill_and_ship_to_ledger_list, setparty_bill_and_ship_to_ledger_list] = useState([]);
  const [sales_ledger_list, setsales_ledger_list] = useState([]);
  const [purchase_ledger_list, setpurchase_ledger_list] = useState([]);
  const [hashtag, sethashtag] = useState(false);
  const [voucherEntrySubmit, setvoucherEntrySubmit] = useState('');

  // Create mutable object of all input to focus 
  const serial_no_input_ref = useRef(null)
  const date_input_ref = useRef(null)
  const party_bill_to_input_ref = useRef(null)
  const mailing_name_bill_to_input_ref = useRef(null)
  const address_bill_to_input_ref = useRef(null)
  const contact_bill_to_input_ref = useRef(null)
  const party_ship_to_input_ref = useRef(null)
  const mailing_name_ship_to_input_ref = useRef(null)
  const addressh_ship_to_input_ref = useRef(null)
  const contact_ship_to_input_ref = useRef(null)
  const supplier_inv_ref_input_ref = useRef(null)
  const date_payment_input_ref = useRef(null)
  const sale_purchase_ledger_account_input_ref = useRef(null)
  const dispatch_details_input_ref = useRef(null)
  const order_details_input_ref = useRef(null)
  const location_input_ref = useRef(null)
  const bank_account_od_ledger_input_ref = useRef(null)
  const bank_account_od_payment_input_ref = useRef(null)
  const cash_in_hand_ledger_input_ref = useRef(null)
  const cash_in_hand_payment_input_ref = useRef(null)
  const sundry_creditor_debtors_ledger_input_ref = useRef(null)
  const sundry_creditor_debtors_payment_input_ref = useRef(null)
  const utr_chq_no_input_ref = useRef(null)
  const party_account_name_input_ref = useRef(null)
  const party_account_name_payment_input_ref = useRef(null)
  const cash_tendered_input_ref = useRef(null)
  const txn_id_ref_no_input_ref = useRef(null)
  const balance_input_ref = useRef(null)
  const narration_input_ref = useRef(null);
  const first_input_ref = useRef([])
  const particular_ledger_pay_amount_input_ref = useRef([])
  const item_name_input_ref = useRef([])
  const item_ledger_account_input_ref = useRef([])
  const item_ledger_account_amount_input_ref = useRef([])
  const godown_input_ref = useRef([])
  const item_quantity_input_ref = useRef([])
  const item_rate_input_ref = useRef([])
  const item_rate_per_unit_input_ref = useRef([])
  const item_amount_input_ref = useRef([])
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const credit_debit_input_ref = useRef([])
  const particular_ledger_double_name_input_ref = useRef([])
  const debit_input_ref = useRef([])
  const credit_input_ref = useRef([])


  // Destructuring object from state
  const {
    entry_name, serial_no, date, day_name, account, account_current_balance,
    party_bill_to, partry_bill_to_current_balance, mailing_name_bill_to, address_bill_to,
    contact_bill_to, party_ship_to, party_ship_to_current_balance, mailing_name_ship_to,
    addressh_ship_to, contact_ship_to, sale_purchase_ledger_account, dispatch_details,
    order_details, location, bank_account_od_ledger, cash_in_hand_ledger,
    sundry_creditor_debtors_ledger, utr_chq_no, cash_tendered, txn_id_ref_no, balance,
    narration, supplier_inv_ref, date_payment, bank_account_od_payment, cash_in_hand_payment,
    sundry_creditor_debtors_payment, party_account_name, party_account_name_payment
  } = data;


  function getNextInputRef(name, value) {
    switch (name) {
      case 'serial_no': return date_input_ref;
      case 'date':
        return entry_name === 'Sales' ||
          entry_name === 'Purchase' ||
          entry_name === 'Receipt' ||
          entry_name === 'Payment' ?
          supplier_inv_ref_input_ref : inputRef
      case 'supplier_inv_ref': return date_payment_input_ref;
      case 'date_payment':
        return entry_name === 'Sales' ||
          entry_name === 'Purchase' ?
          party_bill_to_input_ref : inputRef;
      case 'party_bill_to': return mailing_name_bill_to_input_ref;
      case 'mailing_name_bill_to': return address_bill_to_input_ref;
      case 'address_bill_to': return contact_bill_to_input_ref;
      case 'contact_bill_to': return party_ship_to_input_ref;
      case 'party_ship_to': return mailing_name_ship_to_input_ref;
      case 'mailing_name_ship_to': return addressh_ship_to_input_ref;
      case 'addressh_ship_to': return contact_ship_to_input_ref;
      case 'contact_ship_to': return location_input_ref;
      case 'location': return sale_purchase_ledger_account_input_ref;
      case 'sale_purchase_ledger_account': return dispatch_details_input_ref;
      case 'dispatch_details': return dispatch_details === 'Not Available' ? order_details_input_ref : null;
      case 'party_account_name': return party_account_name_payment_input_ref;
      default: return null;
    }
  }
  function getCurrentINputRef(name, value) {
    switch (name) {
      case 'serial_no': return serial_no_input_ref;
      case 'account': return inputRef;
      case 'date': return date_input_ref
      case 'supplier_inv_ref': return supplier_inv_ref_input_ref;
      case 'date_payment': return date_payment_input_ref
      case 'party_bill_to': return party_bill_to_input_ref;
      case 'mailing_name_bill_to': return mailing_name_bill_to_input_ref;
      case 'address_bill_to': return address_bill_to_input_ref;
      case 'contact_bill_to': return contact_bill_to_input_ref;
      case 'party_ship_to': return party_ship_to_input_ref;
      case 'mailing_name_ship_to': return mailing_name_ship_to_input_ref;
      case 'addressh_ship_to': return addressh_ship_to_input_ref;
      case 'contact_ship_to': return contact_ship_to_input_ref;
      case 'location': return location_input_ref;
      case 'sale_purchase_ledger_account': return sale_purchase_ledger_account_input_ref;
      case 'dispatch_details': return dispatch_details_input_ref
      case 'order_details': return order_details_input_ref
      case 'bank_account_od_ledger': return bank_account_od_ledger_input_ref;
      case 'bank_account_od_payment': return bank_account_od_payment_input_ref;
      case 'cash_in_hand_ledger': return cash_in_hand_ledger_input_ref;
      case 'cash_in_hand_payment': return cash_in_hand_payment_input_ref;
      case 'sundry_creditor_debtors_ledger': return sundry_creditor_debtors_ledger_input_ref;
      case 'sundry_creditor_debtors_payment': return sundry_creditor_debtors_payment_input_ref;
      case 'party_account_name': return party_account_name_input_ref;
      case 'party_account_name_payment': return party_account_name_payment_input_ref;
      case 'utr_chq_no': return utr_chq_no_input_ref;
      case 'cash_tendered': return cash_tendered_input_ref;
      case 'balance': return balance_input_ref;
      case 'narration': return narration_input_ref;
      default: return null;
    }
  }
  function getPrevInputRef(name, value) {
    switch (name) {
      case 'narration':
        return entry_name === 'Sales' ||
          entry_name === 'Purchase' ||
          entry_name === 'Credit Note' ||
          entry_name === 'Debit Note' ? party_account_name_payment_input_ref :
          'Hello Jayant i am Pending '
      //-----------------------------------------------------
      case 'party_account_name_payment': return party_account_name_input_ref
      case 'party_account_name':
        return sundry_creditor_debtors_ledger === 'Not Applicable' ?
          sundry_creditor_debtors_ledger_input_ref :
          sundry_creditor_debtors_payment_input_ref
      case 'sundry_creditor_debtors_payment':
        return sundry_creditor_debtors_ledger_input_ref;
      case 'sundry_creditor_debtors_ledger':
        return cash_in_hand_ledger === 'Not Applicable' ?
          cash_in_hand_ledger_input_ref :
          cash_tendered_input_ref
      case 'cash_tendered': return cash_in_hand_payment_input_ref;
      case 'cash_in_hand_payment': return cash_in_hand_ledger_input_ref;
      case 'cash_in_hand_ledger':
        return bank_account_od_ledger === 'Not Applicable' ?
          bank_account_od_ledger_input_ref :
          utr_chq_no_input_ref
      case 'utr_chq_no': return bank_account_od_payment_input_ref;
      case 'bank_account_od_payment': return bank_account_od_ledger_input_ref;
      case 'bank_account_od_ledger':

      //-------------------------------------------------------------
      case 'contact_ship_to': return location_input_ref;
      case 'location': return sale_purchase_ledger_account_input_ref;
      case 'sale_purchase_ledger_account': return dispatch_details_input_ref;
      case 'dispatch_details': return dispatch_details === 'Not Available' ? order_details_input_ref : null;
      case 'party_account_name': return party_account_name_payment_input_ref;
      default: return null;
    }
  }
  // Fetching data ledger list voucher type list and serial number
  useEffect(() => {
    setProgress(10)
    const formdata = new FormData();
    formdata.append('company_id', userData?.id)
    dispatch(voucherTypeList(formdata))
    dispatch(godownList(formdata))
    const serial = new FormData();
    serial.append('voucher_type_name', data?.entry_name)
    serial.append('voucher_number_series_id', data?.voucher_number_series_id)
    serial.append('company_id', userData?.id)
    dispatch(serialNumberList(serial)).then((response) => {
      if (response.status) {
        setData({ ...data, serial_no: response.new_serial_no, check_num_series_manual_automatic: response?.data })
        setLoading(false)
        setProgress(50)
      }
    })
  }, [])


  // Fetching data ledger list voucher type list and serial number
  useEffect(() => {
    const formdata = new FormData();
    formdata.append('company_id', userData?.id)
    dispatch(stockItemList(formdata))
      .then((response) => {
        if (response?.status) {
          if (response?.data == null) {
            setItemData([])
          } else {
            setItemData(response?.data)
          }
        }
      })
    const fetchDataByGroupIds = async (apiFunction) => {
      const formData = new FormData();
      formData.append('company_id', userData?.id);
      try {
        const response = await dispatch(apiFunction(formData));
        if (response.status) {
          return response.data;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchAllData = async () => {
      try {
        const [
          group1Data,
          groupEmptyData,
        ] = await Promise.all([
          fetchDataByGroupIds(stockItemList),
          fetchDataByGroupIds(godownList),
        ]);
        // Update state with fetched data
        if (group1Data) setItemData(group1Data);
        if (groupEmptyData) {
          setgodownLocationItem(groupEmptyData[0]?.name === 'Any' ? [...groupEmptyData].shift() : [...groupEmptyData])
          setgodownLocation(groupEmptyData)
        }
        setProgress(100)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAllData()
  }, [hashtag, voucherEntrySubmit])

  useEffect(() => {
    const fetchDataByGroupIds = async (groupIds) => {
      const formData = new FormData();
      formData.append('group_ids', groupIds);
      formData.append('company_id', userData?.id);
      try {
        const response = await dispatch(contraLedgerListing(formData));
        if (response.status) {
          return response.data;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchAllData = async () => {
      try {
        const [
          group1Data,
          groupEmptyData,
          group4_31_32Data,
          group28Data,
          group25Data,
          group7_8_10_11_12_18_19_28Data,
          group7_8_10_11_12_18_19_25Data,
          group1_3Data,
          group6Data,
          group31_32Data
        ] = await Promise.all([
          fetchDataByGroupIds('1,3,6'),
          fetchDataByGroupIds(''),
          fetchDataByGroupIds('4,31,32'),
          fetchDataByGroupIds('28'),
          fetchDataByGroupIds('25'),
          fetchDataByGroupIds('7,8,10,11,12,18,19,28'),
          fetchDataByGroupIds('7,8,10,11,12,18,19,25'),
          fetchDataByGroupIds('1,3'),
          fetchDataByGroupIds('6'),
          fetchDataByGroupIds('31,32')
        ]);

        // Update state with fetched data
        if (group1Data) setcontra_account_ledger_list(group1Data);
        if (groupEmptyData) setreceipt_particular_ledger_list(groupEmptyData);
        if (group4_31_32Data) setparty_bill_and_ship_to_ledger_list(group4_31_32Data);
        if (group28Data) setsales_ledger_list(group28Data);
        if (group25Data) setpurchase_ledger_list(group25Data);
        if (group7_8_10_11_12_18_19_28Data) setItemLedgerData(group7_8_10_11_12_18_19_28Data);
        if (group7_8_10_11_12_18_19_25Data) setItemLedgerPurchaseData(group7_8_10_11_12_18_19_25Data);
        if (group1_3Data) setbankAccountOdLedger(group1_3Data);
        if (group6Data) setcashInHandLedger(group6Data);
        if (group31_32Data) setsundryDebCredLedger(group31_32Data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAllData()
  }, [hashtag, voucherEntrySubmit])

  //---------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'a' && e.ctrlKey) {
        e.preventDefault();
        controlPlusASubmit();
      }
      if (voucherEntrySubmit === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (voucherEntrySubmit === 'Accept') {
        if (e.key === 'n' || e.key === 'N' || e.key === 'Escape') {
          e.preventDefault();
          setvoucherEntrySubmit('')
          inputRef.current.focus();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [voucherEntrySubmit])


  const getValueFromDropdownOfItem = (selectedLink, currentIndex) => {
    if (selectedLink?.name === 'End Of List') {
      setItem_entry((prevValueLoop) => {
        const updatedValueLoop = [...prevValueLoop];
        updatedValueLoop[currentIndex]['item_name'] = ''
        updatedValueLoop[currentIndex]['item_id'] = ''
        updatedValueLoop[currentIndex]['check_open'] = true
        return updatedValueLoop;
      });
    } else {
      if (selectedLink?.per !== '' && selectedLink?.total_no_box_unit !== '') {
        const quotient = Math.floor(parseInt(selectedLink?.where_unit) / parseInt(selectedLink?.where_alternate_unit));
        const remainder = parseFloat(selectedLink?.where_unit) % parseFloat(selectedLink?.where_alternate_unit);
        setItem_entry((prevValueLoop) => {
          alert()
          const updatedValueLoop = [...prevValueLoop];
          updatedValueLoop[currentIndex]['check_open'] = false
          updatedValueLoop[currentIndex]['item_name'] = selectedLink.name;
          updatedValueLoop[currentIndex]['item_id'] = selectedLink.id;
          // updatedValueLoop[currentIndex]['godown_location_id'] = data.location==='Any'? data.location_id:selectedLink.id;
          updatedValueLoop[currentIndex]['item_qty'] = updatedValueLoop[currentIndex]?.item_qty !== '' ? updatedValueLoop[currentIndex]?.item_qty : `1 ${selectedLink?.default_unit_id !== '' ? selectedLink?.default_unit_name : selectedLink?.unit_name}`
          // updatedValueLoop[currentIndex]['item_rate'] = updatedValueLoop[currentIndex]?.item_rate !== '' ? currencyFormate(parseFloat(updatedValueLoop[currentIndex]?.item_rate)) : currencyFormate(parseFloat(selectedLink?.default_unit_rate))
          updatedValueLoop[currentIndex]['per_unit'] = updatedValueLoop[currentIndex]?.per_unit !== '' ? updatedValueLoop[currentIndex]?.per_unit : selectedLink.unit_name
          // updatedValueLoop[currentIndex]['item_amt'] = updatedValueLoop[currentIndex]?.item_amt !== '' ? currencyFormate(parseFloat(updatedValueLoop[currentIndex]?.item_amt)) : currencyFormate(parseFloat(selectedLink?.default_unit_rate))
          updatedValueLoop[currentIndex]['item_rate'] = ''
          updatedValueLoop[currentIndex]['item_amt'] = ''
          updatedValueLoop[currentIndex]['item_alternate_unit'] = selectedLink?.alt_unit_name
          updatedValueLoop[currentIndex]['main_unit_name'] = selectedLink?.unit_name
          updatedValueLoop[currentIndex]['where_unit_num'] = selectedLink?.where_unit
          updatedValueLoop[currentIndex]['where_alternate_unit_num'] = selectedLink?.where_alternate_unit
          updatedValueLoop[currentIndex]['is_unit_available'] = selectedLink?.unit_name
          updatedValueLoop[currentIndex]['combination_of_units'] = `${quotient} ${selectedLink?.alt_unit_name}+${remainder} ${selectedLink?.unit_name}`
          updatedValueLoop[currentIndex]['total_nos_box'] = `${quotient} ${selectedLink?.alt_unit_name}+${remainder} ${selectedLink?.unit_name}`
          updatedValueLoop[currentIndex]['total_no_box_unit'] = selectedLink?.alt_unit_name
          return updatedValueLoop;
        });
      } else if (selectedLink?.unit_name !== 'Not Applicable' && selectedLink?.unit_name !== '' && selectedLink?.alt_unit_name === 'Not Applicable') {
        setItem_entry((prevValueLoop) => {
          const updatedValueLoop = [...prevValueLoop];
          updatedValueLoop[currentIndex]['check_open'] = false
          updatedValueLoop[currentIndex]['item_name'] = selectedLink.name;
          updatedValueLoop[currentIndex]['item_id'] = selectedLink.id;
          updatedValueLoop[currentIndex]['item_qty'] = `1 ${selectedLink?.default_unit_id !== '' ? selectedLink?.default_unit_name : selectedLink?.unit_name}`
          // updatedValueLoop[currentIndex]['item_rate'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
          updatedValueLoop[currentIndex]['per_unit'] = selectedLink.unit_name
          // updatedValueLoop[currentIndex]['item_amt'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
          updatedValueLoop[currentIndex]['item_rate'] = ''
          updatedValueLoop[currentIndex]['item_amt'] = ''
          updatedValueLoop[currentIndex]['is_unit_available'] = selectedLink?.unit_name
          updatedValueLoop[currentIndex]['combination_of_units'] = ''
          updatedValueLoop[currentIndex]['total_nos_box'] = ''
          updatedValueLoop[currentIndex]['total_no_box_unit'] = ''
          return updatedValueLoop;
        });
      } else {
        setItem_entry((prevValueLoop) => {
          const updatedValueLoop = [...prevValueLoop];
          updatedValueLoop[currentIndex]['check_open'] = false
          updatedValueLoop[currentIndex]['item_name'] = selectedLink.name;
          updatedValueLoop[currentIndex]['item_id'] = selectedLink.id;
          updatedValueLoop[currentIndex]['item_qty'] = `1 ${selectedLink?.default_unit_id !== '' ? selectedLink?.default_unit_name : selectedLink?.unit_name}`
          // updatedValueLoop[currentIndex]['item_rate'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
          updatedValueLoop[currentIndex]['per_unit'] = selectedLink.unit_name
          // updatedValueLoop[currentIndex]['item_amt'] = currencyFormate(parseFloat(selectedLink?.default_unit_rate))
          updatedValueLoop[currentIndex]['item_rate'] = ''
          updatedValueLoop[currentIndex]['item_amt'] = ''
          updatedValueLoop[currentIndex]['is_unit_available'] = selectedLink?.unit_name
          updatedValueLoop[currentIndex]['combination_of_units'] = ''
          updatedValueLoop[currentIndex]['total_nos_box'] = ''
          updatedValueLoop[currentIndex]['total_no_box_unit'] = ''

          return updatedValueLoop;
        });
      }
    }
  }
  // Input Focus and Blur For Account Input field
  const handleFocus = (e) => {
    const { name, value } = e.target;
    const ref = getCurrentINputRef(name);
    if (ref) {
      setNameInput(ref);
    }
    if (name == 'account') {
      if (contra_account_ledger_list[0]?.ledger_name === 'End Of List') {
        contra_account_ledger_list?.shift();
      }
      setshow_account_list(true)
      const index = contra_account_ledger_list.findIndex(
        item => item.ledger_name?.toLowerCase() == data?.account?.toLowerCase());
      setSelectedItem(index || 0)
    }

    if (name == 'party_bill_to') {
      setshow_party_bill_to_list(true)
      const index = party_bill_and_ship_to_ledger_list.findIndex(
        item => item.ledger_name?.toLowerCase() == data?.party_bill_to?.toLowerCase());
      setSelectedItem(index || 0)
    }
    if (name == 'party_ship_to') {
      setshow_party_ship_to_list(true)
      const index = party_bill_and_ship_to_ledger_list.findIndex(
        item => item.ledger_name?.toLowerCase() == data?.party_ship_to?.toLowerCase());
      setSelectedItem(index || 0)
    }
    if (name == 'sale_purchase_ledger_account') {
      setshow_sales_purchase_list(true)
      const index = (data?.entry_name === 'Sales' ? sales_ledger_list : purchase_ledger_list).findIndex(
        item => item.ledger_name?.toLowerCase() == data?.sale_purchase_ledger_account?.toLowerCase());
      setSelectedItem(index || 0)
    }
    if (name === 'dispatch_details') {
      setshow_dispatch_order(true)
    }
    if (name === 'order_details') {
      setshow_order_details(true)
    }
    if (name === 'location') {
      const index = godownLocation.findIndex(
        item => item.name?.toLowerCase() == data?.location?.toLowerCase());
      setSelectedItem(index || 0)
      setshow_location(true)

    }
    if (name === 'sundry_creditor_debtors_ledger') {
      setshow_sundry_deb_cred_list(true)
    }
    if (name === 'cash_in_hand_ledger') {
      setshow_cash_in_hand_list(true)
    }
    if (name === 'bank_account_od_ledger') {
      setshow_bank_od_list(true)
    }
    if (name === 'party_account_name') {
      if (party_bill_and_ship_to_ledger_list[0]?.ledger_name === 'Not Applicable') {
        party_bill_and_ship_to_ledger_list?.shift({ id: 'Primary', ledger_name: 'Not Applicable' });
      }
      setshow_party_account_name(true)
    }

  }
  // handle Blur fun ------------------------------------
  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateSomeInputAccourdingToEnterAndblur(name, value)
    if (name === 'date_payment') {
      if (date_payment !== '') {
        setData((prevFormData) => {
          let updatedFormData = { ...prevFormData }
          updatedFormData.date_payment = datePayment_func(value)
          return updatedFormData;
        });
      }
    }
    if (name === 'account') {
      setshow_account_list(false)
    }
    if (name === 'party_bill_to') {
      setshow_party_bill_to_list(false)
    }
    if (name === 'party_ship_to') {
      setshow_party_ship_to_list(false)
    }
    if (name === 'sale_purchase_ledger_account') {
      setshow_sales_purchase_list(false)
    }
    if (name === 'dispatch_details') {
      setshow_dispatch_order(false)
    }
    if (name === 'order_details') {
      setshow_order_details(false)
    }
    if (name === 'location') {
      setshow_location(false)
    }
    if (name === 'sundry_creditor_debtors_ledger') {
      setshow_sundry_deb_cred_list(false)
    }
    if (name === 'cash_in_hand_ledger') {
      setshow_cash_in_hand_list(false)
    }
    if (name === 'bank_account_od_ledger') {
      setshow_bank_od_list(false)
    }
    if (name === 'party_account_name') {
      setshow_party_account_name(false)
    }
  }

  //Input Focus and Blur For particular  Input field
  const handleParticularFocus = () => {
    setShowBorder(true)
    if ((data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ?
      receipt_particular_ledger_list[0] : contra_account_ledger_list[0])?.ledger_name === 'End Of List') {
    } else {
      (data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ?
        receipt_particular_ledger_list : contra_account_ledger_list)?.unshift({ id: '', ledger_name: 'End Of List' });
    }
    setshow_particular_list(true)
    const index = (data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ?
      receipt_particular_ledger_list : contra_account_ledger_list)?.findIndex(
        item => item.ledger_name?.toLowerCase() == data?.sale_purchase_ledger_account?.toLowerCase());
    setSelectedItem(index)
  }

  const handleParticularBlur = () => {
    setshow_particular_list(false)
    if (valueLoop.length == 1) {
      if (valueLoop[0]?.first === '') {
        setShowBorder(false)
      }
    }
  }

  //Input Focus and Blur For particular  Input field
  const handleGodownFocus = () => {
    setshow_location(false)
    setshow_godown_list(true)
  }

  const handleGodownBlur = () => {
    setshow_godown_list(false)
  }

  //Input Focus and Blur For particular  Input field
  const handleItemFocus = () => {
    if (itemData[0]?.name === 'End Of List') { }
    else {
      itemData?.unshift({ id: '', name: 'End Of List' });
    }
    setshow_item_list(true)
    const index = itemData?.findIndex(
      item => item.name?.toLowerCase() == item_entry[currentIndex]?.item_name?.toLowerCase());
    setSelectedItem(index)
  }

  //Input Focus and Blur For particular  Input field
  const handleItemLedgerFocus = () => {
    if (itemData[0]?.name === 'End Of List') { }
    else {
      itemData?.unshift({ id: '', name: 'End Of List' });
    }
    setshow_item_ledger_list(true)
  }

  const handleItemBlur = () => {
    setshow_item_list(false)
  }

  const handleItemLedgerBlur = () => {
    setshow_item_ledger_list(false)
  }

  const datePayment_func = (date_get) => {
    let date_payment_to;
    const match = date_get?.match(/^(\d+)[.\-\/](\d+)[.-\/]?(\d*)$/);
    if (match) {
      const [, day, month, year_from] = match;
      const year = new Date(company_session?.start_financial_year).getFullYear();
      const financialYearStart = year;
      const financialYearEnd = new Date(company_session?.end_financial_year).getFullYear();
      if (year_from !== '') {
        const parsedMonth = parseInt(month, 10);
        if (parsedMonth >= 1 && parsedMonth <= 12) {
          let parsedYear;
          if (year_from.length === 2) {
            const currentYear = new Date().getFullYear();
            const currentCentury = Math.floor(currentYear / 100) * 100;
            parsedYear = parseInt(year_from, 10) + currentCentury;
            const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
            const parsedDay = parseInt(day, 10);
            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
              const monthNames = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
              ];
              const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
              date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
              const new_date_check = new Date(date_payment_to)
              const current_date_to_validate = new Date(data?.date);
              if (current_date_to_validate < new_date_check) {
                date_payment_input_ref.current.focus();
              } else {
                return date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
              }
            } else {
              alert('Invalid Day')
            }
          } else if (year_from.length === 4) {
            parsedYear = parseInt(year_from, 10);
            const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate(); // Get the number of days in the month
            const parsedDay = parseInt(day, 10);
            if (parsedDay >= 1 && parsedDay <= daysInMonth) {
              const monthNames = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
              ];
              const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
              date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
              const new_date_check = new Date(date_payment_to)
              const current_date_to_validate = new Date(data?.date);
              if (current_date_to_validate < new_date_check) {
                date_payment_input_ref.current.focus();
              } else {
                return date_payment_to = `${day || '01'}-${monthAbbreviation}-${parsedYear}`;
              }
            } else {
              alert('Invalid Day')
            }
          }
        } else {
          alert('Invalid Month')
        }
      } else {
        const parsedMonth = parseInt(month, 10);
        if (parsedMonth >= 1 && parsedMonth <= 12) {
          const startYear = month <= 3 ? financialYearEnd : financialYearStart;
          const daysInMonth = new Date(startYear, parsedMonth, 0).getDate(); // Get the number of days in the month
          const parsedDay = parseInt(day, 10);
          if (parsedDay >= 1 && parsedDay <= daysInMonth) {
            const monthNames = [
              'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
              'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];
            const monthAbbreviation = monthNames[parseInt(month, 10) - 1];
            date_payment_to = `${day || '01'}-${monthAbbreviation}-${startYear}`;
            const new_date_check = new Date(date_payment_to)
            const current_date_to_validate = new Date(data?.date);
            if (current_date_to_validate < new_date_check) {
              date_payment_input_ref.current.focus();
            } else {
              return date_payment_to = `${day || '01'}-${monthAbbreviation}-${startYear}`;
            }
          } else {
            alert('Invalid Day')
          }
        } else {
          alert('Invalid Month')
        }
      }
    } else {
      alert("Invalid date")
    }
  }

  // Triger function whenever the change input value 
  const handleChange = (e) => {
    const { name, value } = e.target;
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e?.target?.selectionStart;
      if (cursorPosition_start == 1) {
        setData({ ...data, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if ((e.key === 'Enter' || (show_account_list || show_particular_list || show_party_bill_to_list || show_party_ship_to_list || show_sales_purchase_list || show_dispatch_order || show_order_details || show_location || show_item_list || show_godown_list || show_item_ledger_list || show_bank_od_list || show_cash_in_hand_list || show_sundry_deb_cred_list || show_party_account_name ? e.key === 'Enter' : e.key === 'ArrowDown')) && data?.serial_no !== '') {
      e.preventDefault();
      const nextInputRef = getNextInputRef(name)
      validateSomeInputAccourdingToEnterAndblur(name, value)
      if (nextInputRef) {
        nextInputRef?.current?.focus();
        if (nextInputRef?.current) {
          nextInputRef?.current?.setSelectionRange(0, nextInputRef?.current?.value?.length);
        }
      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = getPrevInputRef(name);
      console.log("this is input reference :---", nextInputRef)
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }

    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'account':
        const account_value = contra_account_ledger_list.some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (account_value) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'party_bill_to':
        const value_1 = party_bill_and_ship_to_ledger_list.some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_1) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'party_ship_to':
        const value_2 = party_bill_and_ship_to_ledger_list.some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_2) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'location':
        const value_3 = godownLocation.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_3) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'sale_purchase_ledger_account':
        const value_4 = (data?.entry_name === 'Sales' ? sales_ledger_list : purchase_ledger_list).some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_4) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'dispatch_details':
        const value_5 = array_avai_not_avai.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_5) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'order_details':
        const value_6 = array_avai_not_avai.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_6) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'bank_account_od_ledger':
        const value_7 = bankAccountOdLedger.some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_7) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'cash_in_hand_ledger':
        const value_8 = cashInHandLedger.some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_8) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'sundry_creditor_debtors_ledger':
        const value_9 = sundryDebCredLedger.some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_9) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'party_account_name':
        const value_10 = party_bill_and_ship_to_ledger_list.some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_10) {
          setData({ ...data, [name]: value });
        }
        break;
      case 'party_account_name_payment':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
          setData({ ...data, [name]: value });
        }
        break;
      case 'cash_tendered':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
          setData({ ...data, [name]: value });
        }
        break;
      case 'cash_in_hand_payment':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
          setData({ ...data, [name]: value });
        }
        break;
      case 'bank_account_od_payment':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value.replace(/[,.]/g, '')) || value === '') {
          setData({ ...data, [name]: value });
        }
        break;
      case 'sundry_creditor_debtors_payment':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
          setData({ ...data, [name]: value });
        }
        break;
      case 'contact_bill_to':
        if ((/^\d{1,10}$/).test(value) || value === '') {
          setData({ ...data, [name]: value });
        }
        break;
      case 'contact_ship_to':
        if ((/^\d{1,10}$/).test(value) || value === '') {
          setData({ ...data, [name]: value });
        }
        break;

      default:
        // Capitalize the first letter of each word for certain input fields
        const updatedValue = name === 'mailing_name_bill_to' || name === 'address_bill_to' || name === 'mailing_name_ship_to' || name === 'addressh_ship_to' ?
          capitalizeFirstLetter(value) : value;
        setData({ ...data, [name]: updatedValue })
        break;
    }

  }

  const validateSomeInputAccourdingToEnterAndblur = (name, value) => {
    if (name === 'account' && value) {
      if (first_input_ref) {
        first_input_ref.current[0].focus();
      }
    }
    if (name === 'dispatch_details') {
      if (value === 'Available') {
        setshow_dispatch_details_modal(true)
      }
    }
    if (name === 'order_details') {
      if (value === 'Available') {
        setshow_order_details_modal(true)
      } else {
        if (item_name_input_ref) {
          item_name_input_ref?.current[0]?.focus();
        }
      }
    }
    if (name === 'bank_account_od_ledger') {
      if (data?.bank_account_od_ledger === 'Not Applicable') {
        bank_account_od_payment_input_ref.current.disabled = true;
        utr_chq_no_input_ref.current.disabled = true;
        cash_in_hand_ledger_input_ref?.current?.focus();
      } else {
        utr_chq_no_input_ref.current.disabled = false;
        bank_account_od_payment_input_ref.current.disabled = false
        bank_account_od_payment_input_ref?.current?.focus();
      }
    }

    if (name === 'bank_account_od_payment') {
      if (total_amount - data?.bank_account_od_payment == 0) {
        setData({ ...data, party_account_name: '', party_account_name_id: '' })
        cash_in_hand_ledger_input_ref.current.disabled = true;
        cash_in_hand_payment_input_ref.current.disabled = true;
        sundry_creditor_debtors_ledger_input_ref.current.disabled = true;
        sundry_creditor_debtors_payment_input_ref.current.disabled = true;
        party_account_name_input_ref.current.disabled = true;
        party_account_name_payment_input_ref.current.disabled = true;
        cash_tendered_input_ref.current.disabled = true;
        balance_input_ref.current.disabled = true;
        utr_chq_no_input_ref?.current?.focus();

      } else if (total_amount - data?.bank_account_od_payment > 0) {
        cash_in_hand_ledger_input_ref.current.disabled = false;
        cash_in_hand_payment_input_ref.current.disabled = false;
        sundry_creditor_debtors_ledger_input_ref.current.disabled = false;
        sundry_creditor_debtors_payment_input_ref.current.disabled = false;
        party_account_name_input_ref.current.disabled = false;
        party_account_name_payment_input_ref.current.disabled = false;
        cash_tendered_input_ref.current.disabled = false;
        balance_input_ref.current.disabled = false;
        utr_chq_no_input_ref?.current?.focus();
      }
    }
    if (name === 'utr_chq_no') {
      if (cash_in_hand_ledger_input_ref?.current?.disabled) {
        narration_input_ref?.current?.focus();
      } else {
        cash_in_hand_ledger_input_ref?.current?.focus();
      }
    }
    if (name === 'cash_in_hand_ledger') {
      if (data?.cash_in_hand_ledger === 'Not Applicable') {
        cash_in_hand_payment_input_ref.current.disabled = true;
        cash_tendered_input_ref.current.disabled = true;
        balance_input_ref.current.disabled = true;
        sundry_creditor_debtors_ledger_input_ref?.current?.focus()
      } else {
        balance_input_ref.current.disabled = false;
        cash_in_hand_payment_input_ref.current.disabled = false;
        cash_tendered_input_ref.current.disabled = false;
        setData({ ...data, cash_in_hand_payment: total_amount - bank_account_od_payment })
        cash_in_hand_payment_input_ref?.current?.focus()
      }
    }
    if (name === 'cash_in_hand_payment') {
      if (total_amount - data?.bank_account_od_payment - data?.cash_in_hand_payment == 0) {
        setData({ ...data, party_account_name: '', party_account_name_id: '' })
        sundry_creditor_debtors_ledger_input_ref.current.disabled = true;
        sundry_creditor_debtors_payment_input_ref.current.disabled = true;
        party_account_name_input_ref.current.disabled = true;
        party_account_name_payment_input_ref.current.disabled = true;
        balance_input_ref.current.disabled = true;
        cash_tendered_input_ref?.current?.focus();
      } else if (total_amount - data?.bank_account_od_payment - data?.cash_in_hand_payment > 0) {
        sundry_creditor_debtors_ledger_input_ref.current.disabled = false;
        sundry_creditor_debtors_payment_input_ref.current.disabled = false;
        party_account_name_input_ref.current.disabled = false;
        party_account_name_payment_input_ref.current.disabled = false;
        cash_tendered_input_ref.current.disabled = false;
        balance_input_ref.current.disabled = false;
        cash_tendered_input_ref?.current?.focus();
      }
    }
    if (name === 'cash_tendered') {
      if (cash_in_hand_payment !== '') {
        if ((value && parseFloat(data?.cash_in_hand_payment) <= parseFloat(data?.cash_tendered))) {
          if (sundry_creditor_debtors_ledger_input_ref.current.disabled) {
            narration_input_ref?.current?.focus();
          } else {
            sundry_creditor_debtors_ledger_input_ref?.current?.focus();
          }
          setData({ ...data, balance: cash_tendered - cash_in_hand_payment })
        } else {
          cash_tendered_input_ref?.current?.focus();
        }
      } else {
        if (sundry_creditor_debtors_ledger_input_ref.current.disabled) {
          narration_input_ref?.current?.focus();
        } else {
          sundry_creditor_debtors_ledger_input_ref?.current?.focus();
        }
      }
    }
    if (name === 'sundry_creditor_debtors_ledger') {
      if (data?.sundry_creditor_debtors_ledger === 'Not Applicable') {
        sundry_creditor_debtors_payment_input_ref.current.disabled = true;
        setData({ ...data, party_account_name_payment: total_amount - bank_account_od_payment - cash_in_hand_payment - sundry_creditor_debtors_payment })
        party_account_name_input_ref?.current?.focus()
      } else {
        sundry_creditor_debtors_payment_input_ref.current.disabled = false;
        setData({
          ...data,
          sundry_creditor_debtors_payment: total_amount - bank_account_od_payment - cash_in_hand_payment,
        })
        sundry_creditor_debtors_payment_input_ref?.current?.focus()
      }
    }
    if (name === 'sundry_creditor_debtors_payment') {
      if (total_amount - data?.bank_account_od_payment - data?.cash_in_hand_payment - data?.sundry_creditor_debtors_payment == 0) {
        setData({ ...data, party_account_name: '', party_account_name_id: '' })
        party_account_name_input_ref.current.disabled = true;
        party_account_name_payment_input_ref.current.disabled = true;
        narration_input_ref?.current?.focus();
      } else if (total_amount - data?.bank_account_od_payment - data?.cash_in_hand_payment - data?.sundry_creditor_debtors_payment > 0) {
        party_account_name_input_ref.current.disabled = false;
        party_account_name_payment_input_ref.current.disabled = false;
        party_account_name_input_ref?.current?.focus();
      }
    }
    if (name === 'party_account_name') {
      setData({ ...data, party_account_name_payment: total_amount - bank_account_od_payment - cash_in_hand_payment - sundry_creditor_debtors_payment })
    }
    if (name === 'party_account_name_payment') {
      if (total_amount - data?.bank_account_od_payment - data?.cash_in_hand_payment - data?.sundry_creditor_debtors_payment - data?.party_account_name_payment == 0) {
        narration_input_ref?.current?.focus();
      } else if (total_amount - data?.bank_account_od_payment - data?.cash_in_hand_payment - data?.sundry_creditor_debtors_payment > 0) {
        party_account_name_payment_input_ref?.current?.focus();
      }
    }


    if (name === 'narration') {
      if (narration_input_ref?.current) {
        narration_input_ref.current.blur();
        setvoucherEntrySubmit('Accept')
      }
    }
  }

  // Triger function whenever the change input value to infenite input rendering 
  const handleChangeInputLoop = (index, key, value) => {
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (key) {
      case 'first':
        const value_1 = (data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ? receipt_particular_ledger_list : contra_account_ledger_list).some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_1) {
          setValueLoop((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
      case 'particular_ledger_pay_amount':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
          setValueLoop((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
      default:
        setValueLoop((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index][key] = value;
          return updatedFormData;
        });
        break;
    }
  }
  // Triger function whenever the change input value to infenite input rendering 
  const handleChangeItemEntry = (index, key, value) => {
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

    switch (key) {
      case 'item_name':
        const value_1 = itemData.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_1) {
          setItem_entry((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
      case 'godown_location':
        const value_2 = godownLocationItem.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_2) {
          setItem_entry((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
      case 'item_rate':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value) || value === '') {
          setItem_entry((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
      case 'item_amt':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value.replace(/[,.]/g, '')) || value === '') {
          setItem_entry((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
      default:
        setItem_entry((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index][key] = value;
          return updatedFormData;
        });
        break;
    }

  }
  // Triger function whenever the change input value to infenite input rendering 
  const handleChangeItemLedgerAccountEntry = (index, key, value) => {
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (key) {
      case 'ledger_account':
        const value_1 = (data?.entry_name === 'Sales' ? itemLedgerData : itemLedgerPurchaseData).some(item => cleanString(item.ledger_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (value_1) {
          setItem_entry_ledger_account((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
      case 'ledger_amount':
        if ((/^(\d+)[,]?(\d{3})?[.]?(\d*)$/).test(value.replace(/[,.]/g, '')) || value === '') {
          setItem_entry_ledger_account((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index][key] = value;
            return updatedFormData;
          });
        }
        break;
    }
  }

  // Triger function whenever user press enter key on input field ----------------------------------------------------------------------
  const handleKeyUp = (index, key, value, e) => {
    if (e.key === 'Enter' && value !== '') {
      e.preventDefault();
      if (valueLoop[index].first !== 'End Of List') {
        if (key === 'particular_ledger_pay_amount' && valueLoop[index].first !== '') {
          const intValue = parseFloat(value, 10);
          if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
            settotal_trigger(true)
            if (data?.entry_name === 'Payment') {
              const newParticularLedgerBalance = parseFloat(currentBalance) + parseFloat(value);
              setCurrentBalance(newParticularLedgerBalance);
              setValueLoop((prevFormData) => {
                const updatedFormData = prevFormData.map((item, currentIndex) => {
                  if (item.particular_ledger_id === prevFormData[index].particular_ledger_id) {
                    return {
                      ...item,
                      particular_ledger_balance: newParticularLedgerBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&'),
                    };
                  }
                  return item; // Keep other elements unchanged
                });
                return updatedFormData;
              });
            } else {
              const newParticularLedgerBalance = parseFloat(currentBalance) - parseFloat(value);
              setCurrentBalance(newParticularLedgerBalance);
              setValueLoop((prevFormData) => {
                const updatedFormData = prevFormData.map((item, currentIndex) => {
                  if (item.particular_ledger_id === prevFormData[index].particular_ledger_id) {
                    return {
                      ...item,
                      particular_ledger_balance: newParticularLedgerBalance.toFixed(2).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,"),
                    };
                  }
                  return item; // Keep other elements unchanged
                });
                return updatedFormData;
              });
            }

            const isLastInputField = index === valueLoop.length - 1;
            if (isLastInputField) {
              setValueLoop((prevFormData) => [
                ...prevFormData,
                { id: `${prevFormData.length + 1}`, first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '' },
              ]);
              const newIndex = index + 1;
              setcurrentIndex(newIndex)
              setstore_index(newIndex)
              setTimeout(() => {
                first_input_ref.current[newIndex].focus();
                first_input_ref.current[newIndex].setSelectionRange(0,first_input_ref?.current[newIndex]?.value?.length);
                setValueLoop((prevFormData) => {
                  const updatedFormData = [...prevFormData];
                  updatedFormData[index]['particular_ledger_pay_amount'] = parseFloat(value).toFixed(2).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
                  return updatedFormData;
                });
              }, 50);

              settotal_trigger(true)
            } else {
              const nextInput = particular_ledger_pay_amount_input_ref.current[index + 1];
              if (nextInput) {
                nextInput.focus();
              }
            }
          }
        } else {
          setCurrentBalance(particular_default_balance)
          const nextInput = particular_ledger_pay_amount_input_ref.current[index];
          if (nextInput) {
            nextInput.focus();
          }
        }
      } else {
        setValueLoop((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[index] = {
            ...updatedFormData[index],
            particular_ledger_balance: "",
          };
          return updatedFormData;
        });
        narration_input_ref.current.focus();
      }
    }
  }
  // Triger function whenever user press enter key on input field ----------------------------------------------------------------------
  const handleKeyUpItemEntry = (index, key, value, e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (item_entry[index].check_open) {
        // setItem_entry([...item_entry.slice(0, -1)]);
        setcurrentIndex(0)
        item_ledger_account_input_ref?.current[0]?.focus();
      } else {
        if (key === 'item_name') {
          if (data?.location === 'Any') {
            godown_input_ref?.current[index]?.focus();
          } else {
            if (item_entry[index]?.is_unit_available) {
              item_quantity_input_ref.current[index].focus();
            } else {
              item_amount_input_ref?.current[index]?.focus();
            }
          }
        }
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      if (key === 'godown_location') {
        if (item_quantity_input_ref) {
          if (item_entry[index]?.is_unit_available) {
            item_quantity_input_ref.current[index].focus();
          } else {
            item_amount_input_ref?.current[index]?.focus();
          }
        }
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      if (key === 'item_qty') {
        const regex = /(\d+)(\D+)/g;
        const splitText = [];
        let match;
        while ((match = regex.exec(value)) !== null) {
          splitText.push({ number: match[1], text: match[2] });
        }
        if (item_amount_input_ref && value !== '') {
          if (splitText[0]?.text && splitText[0]?.number) { } else {
            const quotient = Math.floor(parseInt(value) / parseInt(item_entry[index]?.where_alternate_unit_num));
            const remainder = parseFloat(value) % parseFloat(item_entry[index]?.where_alternate_unit_num);
            let result_1 = `${quotient} ${item_entry[index]?.item_alternate_unit}`
            let result_2 = remainder > 0 ? `+ ${remainder}${item_entry[index]?.main_unit_name}` : ''
            setItem_entry((prevValueLoop) => {
              const updatedValueLoop = [...prevValueLoop];
              updatedValueLoop[index]['item_qty'] = `${value} ${item_entry[index]?.per_unit}`
              updatedValueLoop[index]['combination_of_units'] = `(${result_1} ${result_2})`
              return updatedValueLoop;
            });
          }
          item_rate_input_ref.current[index].focus();
        } else {
          if (item_amount_input_ref) {
            item_amount_input_ref.current[index].focus();
          }
        }
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      if (key === 'item_rate') {
        const regex = /(\d+)(\D+)/g;
        const splitText = [];
        let match;
        while ((match = regex.exec(item_entry[index]?.item_qty)) !== null) {
          splitText.push({ number: match[1], text: match[2] });
        }
        setItem_entry((prevValueLoop) => {
          const updatedValueLoop = [...prevValueLoop];
          updatedValueLoop[index]['item_amt'] = `${currencyFormate(parseFloat(value) * parseFloat(splitText[0]?.number))}`
          updatedValueLoop[index]['item_rate'] = `${currencyFormate(parseFloat(value))}`
          return updatedValueLoop;
        });
        if (item_rate_per_unit_input_ref) {
          item_rate_per_unit_input_ref.current[index].focus();
        }
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      if (key === 'per_unit') {

        if (item_amount_input_ref) {
          item_amount_input_ref.current[index].focus();
        }
      }
    }
    if (e.key === 'Enter' && value !== '') {
      e.preventDefault();
      if (key === 'item_amt') {
        const intValue = parseFloat(removeCurrencyComma(value), 10);
        if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
          settotal_trigger(true)
          const isLastInputField = index === item_entry.length - 1;
          if (isLastInputField) {
            setItem_entry((prevFormData) => [
              ...prevFormData,
              {
                id: `${prevFormData.length + 1}`,
                item_name: '',
                item_id: '',
                godown_location: '',
                godown_location_id: '',
                item_qty: '',
                item_rate: '',
                per_unit: '',
                item_amt: '',
                item_alternate_unit: '',
                is_unit_available: '',
                where_unit_num: '',
                where_alternate_unit_num: '',
                main_unit_name: '',
                combination_of_units: '',
                check_open: false,
                total_nos_box: '',
                total_no_box_unit: ''
              },
            ]);
            const newIndex = index + 1;
            setcurrentIndex(newIndex)
            setTimeout(() => {
              item_name_input_ref.current[newIndex].focus();
              setItem_entry((prevFormData) => {
                const updatedFormData = [...prevFormData];
                updatedFormData[index]['item_amt'] = currencyFormate(parseFloat(removeCurrencyComma(value)))
                return updatedFormData;
              });
            }, 50);
            settotal_trigger(true)
          } else {
            const newIndex = index + 1;
            setcurrentIndex(newIndex)
            setTimeout(() => {
              setItem_entry((prevFormData) => {
                const updatedFormData = [...prevFormData];
                updatedFormData[index]['item_amt'] = currencyFormate(parseFloat(removeCurrencyComma(value)))
                return updatedFormData;
              });
            }, 50);
            settotal_trigger(true)
            const nextInput = item_name_input_ref.current[index + 1];
            if (nextInput) {
              nextInput.focus();
            }
          }
        }
      }
    }
  }
  // Triger function whenever user press enter key on input field ----------------------------------------------------------------------
  const handleKeyUpItemLedgerAccountEntry = (index, key, value, e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (item_entry_ledger_account[index]?.check) {
        bank_account_od_ledger_input_ref?.current?.focus();
      } else {
        if (key === 'ledger_account') {
          item_ledger_account_amount_input_ref?.current[index].focus();
        }
      }
    }
    if (e.key === 'Enter' && value !== '') {
      e.preventDefault();
      if (key === 'ledger_amount') {
        const intValue = parseInt(value, 10);
        if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
          settotal_trigger(true)
          const isLastInputField = index === item_entry_ledger_account.length - 1;
          if (isLastInputField) {
            setItem_entry_ledger_account((prevFormData) => [
              ...prevFormData,
              { id: `${prevFormData.length + 1}`, ledger_account: '', ledger_account_id: '', rate_percentage: '', ledger_amount: '', check: false },
            ]);
            const newIndex = index + 1;
            setcurrentIndex(newIndex)
            setTimeout(() => {
              item_ledger_account_input_ref?.current[newIndex]?.focus();
              setItem_entry_ledger_account((prevFormData) => {
                const updatedFormData = [...prevFormData];
                updatedFormData[index]['ledger_amount'] = parseFloat(value).toFixed(2).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,");
                return updatedFormData;
              });
            }, 50);
            settotal_trigger(true)
          }
        }
      }
    }
  }

  // Triger function whenever the change input value to infenite input rendering 
  const handleDoubleModeInputChange = (index, key, value) => {
    setdouble_mode_input((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index][key] = value;
      return updatedFormData;
    });
    if (key === 'particular_ledger_name') {
      setstore_index(index)
      const filteredSuggestions = Object.keys(contra_account_ledger_list).filter(key =>
        contra_account_ledger_list[key]?.ledger_name?.toLowerCase()?.includes(double_mode_input[index]?.particular_ledger_name?.toLowerCase()));
      setFilteredData(filteredSuggestions);
      setshow_particular_double_list(true)
    }
    if (key === 'credit_debit') {
      setdouble_mode_input((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          credit_debit: value?.toLowerCase()?.replace(/(^|\s)\S/g, (firstLetter) => firstLetter?.toUpperCase()),
        };
        return updatedFormData;
      });
    }
  }
  // Triger function whenever user press enter key on input field 
  const handleDoubleKeyUp = (index, key, value, e) => {
    if (key === 'credit_debit') {
      if (e.key === 'Enter' && double_mode_input[index]?.credit_debit !== '') {
        particular_ledger_double_name_input_ref.current[index].focus();
      }
    }
    if (key === 'debit_amount') {
      if (e.key === 'Enter' && double_mode_input[index].particular_ledger_name !== '' && double_mode_input[index].credit_debit !== '') {
        const intValue = parseInt(value, 10);
        if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
          settotal_trigger_debit(true)
          const newParticularLedgerBalance = parseFloat(currentBalance) + parseFloat(value);
          setCurrentBalance(newParticularLedgerBalance);
          setdouble_mode_input((prevFormData) => {
            const updatedFormData = prevFormData.map((item, currentIndex) => {
              if (item.particular_ledger_id === prevFormData[index].particular_ledger_id) {
                return {
                  ...item,
                  particular_ledger_balance: newParticularLedgerBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&'),
                };
              }
              return item;
            });
            return updatedFormData;
          });
          const isLastInputField = index === double_mode_input.length - 1;
          if (isLastInputField) {
            setdouble_mode_input((prevFormData) => [
              ...prevFormData,
              { id: `${prevFormData.length + 1}`, credit_debit: '', particular_ledger_name: '', particular_ledger_balance: '', particular_ledger_id: '', debit_amount: '', credit_amount: '' },
            ]);
            const newIndex = index + 1;
            setTimeout(() => {
              credit_debit_input_ref.current[newIndex].focus();
              setdouble_mode_input((prevFormData) => {
                const updatedFormData = [...prevFormData];
                updatedFormData[index]['debit_amount'] = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                return updatedFormData;
              });
            }, 50);
            console.log("this is double ---", double_mode_input)
            settotal_trigger_debit(true)
          }
        }
      }
    }
    if (key === 'credit_amount') {
      if (e.key === 'Enter' && double_mode_input[index].particular_ledger_name !== '' && double_mode_input[index].credit_debit !== '') {
        const intValue = parseInt(value, 10);
        if (Number.isInteger(intValue) && Math.sign(intValue) === 1) {
          settotal_trigger_credit(true)
          const newParticularLedgerBalance = parseFloat(currentBalance) - parseFloat(value);
          setCurrentBalance(newParticularLedgerBalance);
          setdouble_mode_input((prevFormData) => {
            const updatedFormData = prevFormData.map((item, currentIndex) => {
              if (item.particular_ledger_id === prevFormData[index].particular_ledger_id) {
                return {
                  ...item,
                  particular_ledger_balance: newParticularLedgerBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&'),
                };
              }
              return item; // Keep other elements unchanged
            });
            return updatedFormData;
          });
          const isLastInputField = index === double_mode_input.length - 1;
          if (isLastInputField) {
            setdouble_mode_input((prevFormData) => [
              ...prevFormData,
              { id: `${prevFormData.length + 1}`, credit_debit: '', particular_ledger_name: '', particular_ledger_balance: '', particular_ledger_id: '', debit_amount: '', credit_amount: '' },
            ]);
            const newIndex = index + 1;
            setTimeout(() => {
              credit_debit_input_ref.current[newIndex].focus();
              setdouble_mode_input((prevFormData) => {
                const updatedFormData = [...prevFormData];
                updatedFormData[index]['credit_amount'] = parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                return updatedFormData;
              });
            }, 50);
            console.log("this is double ---", double_mode_input)
            settotal_trigger_credit(true)
          }
        }
      }
    }

  }
  // Ensure that the selectedItem state is updated after the data is loaded
  useEffect(() => {
    if (show_account_list || show_particular_list || show_party_bill_to_list || show_party_ship_to_list || show_sales_purchase_list || show_dispatch_order || show_order_details || show_location || show_item_list || show_godown_list || show_item_ledger_list) {
      setSelectedItem(0);
    }
  }, [show_account_list, show_particular_list, show_party_bill_to_list, show_party_ship_to_list, show_sales_purchase_list, show_dispatch_order, show_order_details, show_location, show_item_list, show_godown_list, show_item_ledger_list]);
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = contra_account_ledger_list?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.account?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data.account !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredData(data?.account !== '' ? inputRef?.current?.selectionStart > 0 ? filteredSuggestions : contra_account_ledger_list : contra_account_ledger_list);
  }, [account, contra_account_ledger_list])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    if (sundryDebCredLedger[0]?.ledger_name === 'Not Applicable') {
      // Do Not Add Any Data 
    } else {
      sundryDebCredLedger?.unshift({ id: '', ledger_name: 'Not Applicable' });
    }
    const filteredSuggestions = sundryDebCredLedger?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.sundry_creditor_debtors_ledger?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data.sundry_creditor_debtors_ledger !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataSundryDebCredLedger(data?.sundry_creditor_debtors_ledger !== '' ? sundry_creditor_debtors_ledger_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : sundryDebCredLedger : sundryDebCredLedger);
  }, [sundry_creditor_debtors_ledger, sundryDebCredLedger])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    if (cashInHandLedger[0]?.ledger_name === 'Not Applicable') {
      // Do Not Add Any Data 
    } else {
      cashInHandLedger?.unshift({ id: '', ledger_name: 'Not Applicable' });
    }
    const filteredSuggestions = cashInHandLedger?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.cash_in_hand_ledger?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data.cash_in_hand_ledger !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataCashInHandLedger(data?.cash_in_hand_ledger !== '' ? cash_in_hand_ledger_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : cashInHandLedger : cashInHandLedger);
  }, [cash_in_hand_ledger, cashInHandLedger])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    if (bankAccountOdLedger[0]?.ledger_name === 'Not Applicable') {
      // Do Not Add Any Data 
    } else {
      bankAccountOdLedger?.unshift({ id: '', ledger_name: 'Not Applicable' });
    }
    const filteredSuggestions = bankAccountOdLedger?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.bank_account_od_ledger?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data.bank_account_od_ledger !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataBankAccountOdLedger(data?.bank_account_od_ledger !== '' ? bank_account_od_ledger_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : bankAccountOdLedger : bankAccountOdLedger);
  }, [bank_account_od_ledger, bankAccountOdLedger])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = array_avai_not_avai?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.dispatch_details?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data.dispatch_details !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFiltereddDataDispatchDetails(data?.dispatch_details !== '' ? dispatch_details_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : array_avai_not_avai : array_avai_not_avai);
  }, [dispatch_details])
  //triger when the change value of search input and get filter data 

  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    if ((data?.entry_name === 'Sales' ? itemLedgerData[0] : itemLedgerPurchaseData[0])?.ledger_name === 'End Of List') {
      // Do Not Add Any Data 
    } else {
      (data?.entry_name === 'Sales' ? itemLedgerData : itemLedgerPurchaseData)?.unshift({ id: '', ledger_name: 'End Of List' });
    }
    const filteredSuggestions = (data?.entry_name === 'Sales' ? itemLedgerData : itemLedgerPurchaseData)?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(item_entry_ledger_account[currentIndex]?.ledger_account?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(item_entry_ledger_account[currentIndex]?.ledger_account !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataItemLedger(item_entry_ledger_account[currentIndex]?.ledger_account !== '' ? item_ledger_account_input_ref?.current[currentIndex]?.selectionStart > 0 ? filteredSuggestions : (data?.entry_name === 'Sales' ? itemLedgerData : itemLedgerPurchaseData) : (data?.entry_name === 'Sales' ? itemLedgerData : itemLedgerPurchaseData));
  }, [item_entry_ledger_account[currentIndex]?.ledger_account, itemLedgerData, itemLedgerPurchaseData, entry_name])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    if (godownLocation[0]?.name === 'Any') {
      // Do Not Add Any Data 
    } else {
      godownLocation?.unshift({ id: '', name: 'Any' });
    }
    const filteredSuggestions = godownLocation?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.location?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data.location !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFiltereddDataLocation(data?.location !== '' ? location_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : godownLocation : godownLocation);
  }, [location, godownLocation])
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = godownLocationItem?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.name?.toLowerCase())
      const lowercaseSearchInput = cleanString(item_entry[currentIndex]?.godown_location?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(item_entry[currentIndex]?.godown_location !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFiltereddDataLocationItem(item_entry[currentIndex]?.godown_location !== '' ? godown_input_ref?.current[currentIndex]?.selectionStart > 0 ? filteredSuggestions : godownLocationItem : godownLocationItem);
  }, [item_entry[currentIndex]?.godown_location, godownLocationItem])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = array_avai_not_avai?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.order_details?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data.order_details !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFiltereddDataOrderDetails(data?.order_details !== '' ? order_details_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : array_avai_not_avai : array_avai_not_avai);
  }, [order_details])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    if (party_bill_and_ship_to_ledger_list[0]?.ledger_name === 'Not Applicable') {
      // Do Not Add Any Data 
    } else {
      party_bill_and_ship_to_ledger_list?.unshift({ id: 'Primary', ledger_name: 'Not Applicable' });
    }
    const filteredSuggestions = party_bill_and_ship_to_ledger_list?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.party_bill_to?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data?.party_bill_to !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataPartyBillTo(data?.party_bill_to !== '' ? party_bill_to_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : party_bill_and_ship_to_ledger_list : party_bill_and_ship_to_ledger_list);
  }, [party_bill_to, party_bill_and_ship_to_ledger_list])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    if (party_bill_and_ship_to_ledger_list[0]?.ledger_name === 'Not Applicable') {
      // Do Not Add Any Data 
    } else {
      party_bill_and_ship_to_ledger_list?.unshift({ id: 'Primary', ledger_name: 'Not Applicable' });
    }
    const filteredSuggestions = party_bill_and_ship_to_ledger_list?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.party_ship_to?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data?.party_ship_to !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataPartyShipTo(data?.party_ship_to !== '' ? party_ship_to_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : party_bill_and_ship_to_ledger_list : party_bill_and_ship_to_ledger_list);
  },
    [party_ship_to,
      party_bill_and_ship_to_ledger_list
    ])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = party_bill_and_ship_to_ledger_list?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.party_account_name?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data?.party_account_name !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataPartyAccountName(data?.party_account_name !== '' ? party_account_name_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : party_bill_and_ship_to_ledger_list : party_bill_and_ship_to_ledger_list);
  },
    [party_account_name,
      party_bill_and_ship_to_ledger_list
    ])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = (data?.entry_name === 'Sales' ? sales_ledger_list : purchase_ledger_list)?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(data?.sale_purchase_ledger_account?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(data?.sale_purchase_ledger_account !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataSalesPurchase(data?.sale_purchase_ledger_account !== '' ? sale_purchase_ledger_account_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : (data?.entry_name === 'Sales' ? sales_ledger_list : purchase_ledger_list) : (data?.entry_name === 'Sales' ? sales_ledger_list : purchase_ledger_list));
  },
    [sale_purchase_ledger_account,
      sales_ledger_list,
      purchase_ledger_list,
      data?.entry_name
    ])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = (data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ? receipt_particular_ledger_list : contra_account_ledger_list)?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.ledger_name?.toLowerCase())
      const lowercaseSearchInput = cleanString(valueLoop[store_index]?.first?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(valueLoop[store_index]?.first !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataParticular(valueLoop[store_index]?.first !== '' ? first_input_ref?.current[store_index]?.selectionStart > 0 ? filteredSuggestions : (data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ? receipt_particular_ledger_list : contra_account_ledger_list) : (data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ? receipt_particular_ledger_list : contra_account_ledger_list));
  },
    [valueLoop[store_index]?.first,
      contra_account_ledger_list,
      receipt_particular_ledger_list,
    data?.entry_name
    ])
  //triger when the change value of search input and get filter data 
  useEffect(() => {
    const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    const filteredSuggestions = (data?.entry_name === 'Sales' ? itemData : itemData)?.filter((item, index) => {
      const lowercaseLedgerName = cleanString(item?.name?.toLowerCase())
      const lowercaseSearchInput = cleanString(item_entry[currentIndex]?.item_name?.toLowerCase())
      const matchesSearchInput = lowercaseLedgerName?.includes(item_entry[currentIndex]?.item_name !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataItem(item_entry[currentIndex]?.item_name !== '' ? item_name_input_ref?.current[currentIndex]?.selectionStart > 0 ? filteredSuggestions : itemData : itemData)
  },
    [item_entry[currentIndex]?.item_name, itemData])

  // getting the total of amount ---------------------------------------------------------------------------
  useEffect(() => {
    if (total_trigger) {
      let new_total_amount = (data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? item_entry : valueLoop).reduce((acc, item) => {
        let particular_ledger_pay_amount_value = parseFloat((data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? item?.item_amt : item?.particular_ledger_pay_amount)?.replace(/\,/g, '')) || 0;
        return acc + particular_ledger_pay_amount_value;
      }, 0)
      let new_total_amount_ledger = 0;
      if (item_entry.some(item => item?.item_name === '')) {
        new_total_amount_ledger = (data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? item_entry_ledger_account : []).reduce((acc, item) => {
          let particular_ledger_pay_amount_value = parseFloat((item?.ledger_amount)?.replace(/\,/g, '')) || 0;
          return acc + particular_ledger_pay_amount_value;
        }, 0)
      }
      settotal_amount_item(new_total_amount)
      settotal_amount(new_total_amount + new_total_amount_ledger);
      if (data?.entry_name === 'Payment') {
        setData(prevData => ({
          ...prevData,
          account_current_balance: parseFloat(account_default_balance) - new_total_amount
        }));
      } else if (data?.entry_name === 'Sales' || data?.entry_name === 'Purchase') {

      } else {
        setData(prevData => ({
          ...prevData,
          account_current_balance: parseFloat(account_default_balance) + new_total_amount
        }));
      }
      settotal_trigger(false)
    }
  }, [handleChangeInputLoop, handleChangeItemEntry, handleChangeItemLedgerAccountEntry])

  // getting the total of amount ---------------------------------------------------------------------------
  useEffect(() => {
    if (total_trigger_credit) {
      let new_total_amount = double_mode_input.reduce((acc, item) => {
        let creditamount = parseFloat(item?.credit_amount?.replace(/\,/g, '')) || 0;
        return acc + creditamount;
      }, 0)
      settotal_amount_credit(new_total_amount);
      settotal_trigger(false)
    }
    if (total_trigger_debit) {
      let new_total_amount = double_mode_input.reduce((acc, item) => {
        let debitamount = parseFloat(item?.debit_amount?.replace(/\,/g, '')) || 0;
        return acc + debitamount;
      }, 0)
      settotal_amount_debit(new_total_amount);
      settotal_trigger(false)
    }
  }, [handleDoubleModeInputChange])
  // display name voucher type in the userInterface------------------------------------------------------------------------
  const displayNameOfVoucherType = (name, voucherData,) => {
    filter_voucher = voucherData?.filter((item) => {
      return (item?.select_type_of_voucher?.replace(/\s/g, '') === name?.replace(/\s/g, ''))
    })
    if (filter_voucher?.length > 1) {
      console.log("this is voucher type data in voucher transaction :---", filter_voucher)
      dispatch(filterVoucherTypeData(filter_voucher))
      setshow_voucher_type(true)
    } else {
      if (filter_voucher[0]?.vaucher_number_series_data?.length > 1) {
        dispatch(filterVoucherTypeData(filter_voucher))
        numberlistfun(filter_voucher[0]?.voucher_type_name)
        setshow_voucher_type(true)
      } else {
        const serial = new FormData();
        serial.append('voucher_type_name', filter_voucher[0]?.voucher_type_name)
        serial.append('voucher_number_series_id', filter_voucher[0]?.vaucher_number_series_data[0]?.id)
        serial.append('company_id', userData?.id)
        dispatch(serialNumberList(serial)).then((response) => {
          if (response.status) {
            setData({ ...data, entry_name: name, entry_name_id: filter_voucher[0]?.id, serial_no: response.new_serial_no, voucher_number_series_id: filter_voucher[0]?.vaucher_number_series_data[0]?.id })
          }
        }).catch((e) => {
          console.log(e)
        })
      }
      localStorage.setItem('voucher_type_name', name);
    }
  }
  const numberlistfun = (name) => {
    const formdata = new FormData();
    formdata.append('voucher_type_name', name)
    formdata.append('company_id', userData?.id)
    dispatch(numberSeriesList(formdata)).then((response) => { console.log("this i s= response :------", response) })
  }

  // keyboard Functionality
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (show_account_list || show_particular_list || show_party_bill_to_list || show_party_ship_to_list || show_sales_purchase_list || show_dispatch_order || show_order_details || show_location || show_item_list || show_godown_list || show_item_ledger_list || show_bank_od_list || show_cash_in_hand_list || show_sundry_deb_cred_list || show_party_account_name) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedItem(prevState => Math.min(prevState + 1, (show_account_list ? filteredData.length : show_party_bill_to_list ? filteredDataPartyBillTo.length : show_party_ship_to_list ? filteredDataPartyShipTo.length : show_sales_purchase_list ? filteredDatSalesPurchase.length : show_location ? filteredDataLocation?.length : show_order_details ? filteredDataOrderDetails?.length : show_dispatch_order ? filteredDataDispatchDetails?.length : show_item_list ? filteredDataItem?.length : show_item_ledger_list ? filteredDataItemLedger?.length : show_bank_od_list ? filteredDataBankAccountOdLedger?.length : show_cash_in_hand_list ? filteredDataCashInHandLedger?.length : show_sundry_deb_cred_list ? filteredDataSundryDebCredLedger?.length : show_party_account_name ? filteredDataPartyAccountName?.length : show_godown_list ? filteredDataLocationItem?.length : filteredDataParticular?.length) - 1));
          }
        if (e.key === 'Enter') {
          e.preventDefault();
          const selectedLink =
            show_account_list ? filteredData[selectedItem] : show_party_bill_to_list ? filteredDataPartyBillTo[selectedItem] : show_party_ship_to_list ? filteredDataPartyShipTo[selectedItem] : show_sales_purchase_list ? filteredDatSalesPurchase[selectedItem] : show_dispatch_order ? filteredDataDispatchDetails[selectedItem] : show_order_details ? filteredDataOrderDetails[selectedItem] : show_location ? filteredDataLocation[selectedItem] : show_item_list ? filteredDataItem[selectedItem] : show_item_ledger_list ? filteredDataItemLedger[selectedItem] : show_bank_od_list ? filteredDataBankAccountOdLedger[selectedItem] : show_cash_in_hand_list ? filteredDataCashInHandLedger[selectedItem] : show_sundry_deb_cred_list ? filteredDataSundryDebCredLedger[selectedItem] : show_party_account_name ? filteredDataPartyAccountName[selectedItem] : show_godown_list ? filteredDataLocationItem[selectedItem] : filteredDataParticular[selectedItem]
          if (!selectedLink) {
            setSelectedItem(0);
            return;
          }
          if (selectedLink) {
            if (show_account_list) {
              setData({
                ...data, account: selectedLink?.ledger_name, account_id: selectedLink?.id, account_current_balance: selectedLink?.opening_balance
              })
              setaccount_default_balance(selectedLink?.opening_balance)
            }
            if (show_bank_od_list) {
              setData({
                ...data, bank_account_od_ledger: selectedLink?.ledger_name, bank_account_od_ledger_id: selectedLink?.id,
              })
            }
            if (show_cash_in_hand_list) {
              setData({
                ...data, cash_in_hand_ledger: selectedLink?.ledger_name, cash_in_hand_ledger_id: selectedLink?.id,
              })
            }
            if (show_sundry_deb_cred_list) {
              setData({
                ...data, sundry_creditor_debtors_ledger: selectedLink?.ledger_name, sundry_creditor_debtors_ledger_id: selectedLink?.id,
              })
            }
            if (show_party_account_name) {
              setData({
                ...data, party_account_name: selectedLink?.ledger_name, party_account_name_id: selectedLink?.id,
              })
            }
            if (show_dispatch_order) {
              setData({
                ...data, dispatch_details: selectedLink?.name
              })
            }
            if (show_location) {
              setData({
                ...data, location: selectedLink?.name, location_id: selectedLink?.id
              })
            }
            if (show_item_ledger_list) {
              if (selectedLink?.ledger_name === 'End Of List') {
                setItem_entry_ledger_account((prevValueLoop) => {
                  const updatedValueLoop = [...prevValueLoop];
                  updatedValueLoop[currentIndex]['ledger_account'] = '';
                  updatedValueLoop[currentIndex]['ledger_account_id'] = '';
                  updatedValueLoop[currentIndex]['check'] = true
                  return updatedValueLoop;
                });
              } else {
                setItem_entry_ledger_account((prevValueLoop) => {
                  const updatedValueLoop = [...prevValueLoop];
                  updatedValueLoop[currentIndex]['ledger_account'] = selectedLink?.ledger_name;
                  updatedValueLoop[currentIndex]['ledger_account_id'] = selectedLink?.id;
                  return updatedValueLoop;
                });
              }
              setshow_item_ledger_list(false)
            }
            if (show_order_details) {
              setData({
                ...data, order_details: selectedLink?.name
              })
            }
            if (show_party_bill_to_list) {
              setData({
                ...data,
                party_bill_to: selectedLink?.ledger_name,
                party_bill_to_id: selectedLink?.id,
                mailing_name_bill_to: selectedLink?.mailing_name,
                contact_bill_to: selectedLink?.mobile_no,
                address_bill_to: selectedLink?.address,
                partry_bill_to_current_balance: selectedLink?.opening_balance,
                party_account_name: selectedLink?.ledger_name,
                party_account_name_id: selectedLink?.id,

                party_ship_to: selectedLink?.ledger_name,
                party_ship_to_id: selectedLink?.id,
                mailing_name_ship_to: selectedLink?.mailing_name,
                contact_ship_to: selectedLink?.mobile_no,
                addressh_ship_to: selectedLink?.address,
                party_ship_to_current_balance: selectedLink?.opening_balance,
              })
            }
            if (show_party_ship_to_list) {
              setData({
                ...data,
                party_ship_to: selectedLink?.ledger_name,
                party_ship_to_id: selectedLink?.id,
                party_ship_to_current_balance: selectedLink?.opening_balance,
                mailing_name_ship_to: selectedLink?.mailing_name, party_bill_to_id: selectedLink?.id,
                contact_ship_to: selectedLink?.mobile_no,
                addressh_ship_to: selectedLink?.address,
              })
            }
            if (show_sales_purchase_list) {
              setData({
                ...data, sale_purchase_ledger_account: selectedLink?.ledger_name, sale_purchase_ledger_account_id: selectedLink?.id, sale_purchase_ledger_current_balance: selectedLink?.opening_balance
              })
              setaccount_default_balance(selectedLink?.opening_balance)
            }
            if (show_particular_list) {
              const new_data = valueLoop.filter((item) => { return (item.particular_ledger_id === selectedLink.id) })
              if (new_data.length > 0) {
                const get_amount = new_data.slice(-1).map((item, index) => { return (item.particular_ledger_balance) })
                setValueLoop((prevValueLoop) => {
                  const updatedValueLoop = [...prevValueLoop];
                  updatedValueLoop[store_index]['first'] = selectedLink.ledger_name;
                  updatedValueLoop[store_index]['particular_ledger_balance'] = selectedLink.ledger_name === 'End of List' ? '' : get_amount[0];
                  updatedValueLoop[store_index]['particular_ledger_id'] = selectedLink.ledger_name === 'End of List' ? '' : selectedLink.id;
                  return updatedValueLoop;
                });
                setparticular_default_balance(get_amount)
              } else {
                setValueLoop((prevValueLoop) => {
                  const updatedValueLoop = [...prevValueLoop];
                  updatedValueLoop[store_index]['first'] = selectedLink.ledger_name;
                  updatedValueLoop[store_index]['particular_ledger_balance'] = selectedLink?.opening_balance;
                  updatedValueLoop[store_index]['particular_ledger_id'] = selectedLink.ledger_name === 'End of List' ? '' : selectedLink.id;
                  return updatedValueLoop;
                });
                setparticular_default_balance(selectedLink.opening_balance === undefined || selectedLink.opening_balance === '' || selectedLink.opening_balance === null ? 0 : selectedLink.opening_balance)
              }
              setshow_particular_list(false)
            }
            if (show_item_list) {
              getValueFromDropdownOfItem(selectedLink, currentIndex)
              setshow_item_list(false)
            }
            if (show_godown_list) {
              setItem_entry((prevValueLoop) => {
                const updatedValueLoop = [...prevValueLoop];
                updatedValueLoop[currentIndex]['godown_location'] = selectedLink.name;
                updatedValueLoop[currentIndex]['godown_location_id'] = selectedLink.id;
                // updatedValueLoop[currentIndex]['item_id'] = selectedLink.name === 'End of List' ? '' : selectedLink.id;
                return updatedValueLoop;
              });
              setshow_godown_list(false)
            }
            if (show_particular_double_list) {
              const new_data = double_mode_input.filter((item) => { return (item.particular_ledger_id === selectedLink.id) })
              if (new_data.length > 0) {
                const get_amount = new_data.slice(-1).map((item, index) => { return (item.particular_ledger_balance) })
                setdouble_mode_input((prevValueLoop) => {
                  const updatedValueLoop = [...prevValueLoop];
                  updatedValueLoop[store_index]['particular_ledger_name'] = selectedLink.ledger_name;
                  updatedValueLoop[store_index]['particular_ledger_balance'] = get_amount[0];
                  updatedValueLoop[store_index]['particular_ledger_id'] = selectedLink.id;
                  return updatedValueLoop;
                });
                if (double_mode_input[store_index].credit_debit === 'Cr') {
                  credit_input_ref.current[store_index].focus();
                }
                else {
                  debit_input_ref.current[store_index].focus();
                }
                setparticular_default_balance(get_amount)
              } else {
                setdouble_mode_input((prevValueLoop) => {
                  const updatedValueLoop = [...prevValueLoop];
                  updatedValueLoop[store_index]['particular_ledger_name'] = selectedLink.ledger_name;
                  updatedValueLoop[store_index]['particular_ledger_balance'] = selectedLink?.opening_balance;
                  updatedValueLoop[store_index]['particular_ledger_id'] = selectedLink.id;
                  return updatedValueLoop;
                });
                if (double_mode_input[store_index].credit_debit === 'Cr') {
                  credit_input_ref.current[store_index].focus();
                }
                else {
                  debit_input_ref.current[store_index].focus();
                }
                setparticular_default_balance(selectedLink.opening_balance === undefined || selectedLink.opening_balance === '' || selectedLink.opening_balance === null ? 0 : selectedLink.opening_balance)
              }
              setshow_particular_double_list(false)
            }
          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [entry_name, selectedItem, account, data, store_index, handleChangeInputLoop, handleDoubleModeInputChange, handleChange, handleDoubleKeyUp, voucher_type_data, show_account_list, particular_default_balance, currentBalance, dispatch_details, order_details, location, show_item_list, show_godown_list, show_bank_od_list, show_cash_in_hand_list, show_sundry_deb_cred_list, show_party_account_name, displayNameOfVoucherType]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === "h" || e.key === "H")) {
        e.preventDefault();
        setchange_mode(true)
      }
      if (e.ctrlKey && (e.key === "l" || e.key === "L")) {
        e.preventDefault();
        location_input_ref?.current?.focus();
      }
      if (e.ctrlKey && (e.key === "s" || e.key === "S")) {
        e.preventDefault();
        sale_purchase_ledger_account_input_ref?.current?.focus();
      }
      if (e.ctrlKey && (e.key === "d" || e.key === "D")) {
        e.preventDefault();
        dispatch_details_input_ref?.current?.focus();
      }
      if (e.ctrlKey && (e.key === "o" || e.key === "O")) {
        e.preventDefault();
        order_details_input_ref?.current?.focus();
      }
      if (e.key === 'F2') {
        e.preventDefault();
        setshow_account_list(false)
        setshow_period(true)
      } else if (e.key === 'F4') {
        e.preventDefault();
        displayNameOfVoucherType('Contra', voucher_type_data)
      } else if (e.key === 'F5') {
        e.preventDefault();
        displayNameOfVoucherType('Payment', voucher_type_data)
      } else if (e.key === 'F6') {
        e.preventDefault();
        displayNameOfVoucherType('Receipt', voucher_type_data)
      } else if (e.key === 'F7') {
        e.preventDefault();
        displayNameOfVoucherType('Journal', voucher_type_data)
      } else if (e.key === 'F8') {
        e.preventDefault();
        setshow_account_list(false)
        displayNameOfVoucherType('Sales', voucher_type_data)
      } else if (e.key === 'F9') {
        e.preventDefault();
        displayNameOfVoucherType('Purchase', voucher_type_data)
      } else if (e.key === 'F10') {
        e.preventDefault();
        setshow_other_voucher(true)
      }
      else if (e.key === 'F12') {
        e.preventDefault();
        setshow_configure(true)
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [displayNameOfVoucherType]);
  // submit form by ctr + a -------------------------------------------------
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      setLoading(true)
      let formData = new FormData();
      formData.append('voucher_type_id', data?.entry_name_id);
      formData.append('voucher_type_name', data?.entry_name);
      formData.append('voucher_number_series_id', data?.voucher_number_series_id);
      formData.append('serial_no', data?.serial_no);
      formData.append('total_amount', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? total_amount_item : total_amount);
      formData.append('company_id', userData?.id);
      formData.append('create_date', dateFormateSend(data?.date));
      formData.append('is_checked_series', '1');
      formData.append('narration', data?.narration);
      formData.append('account_ledger_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.bank_account_od_ledger !== 'Not Applicable' ? data?.bank_account_od_ledger_id : data?.cash_in_hand_ledger !== 'Not Applicable' ? data?.cash_in_hand_ledger_id : data?.sundry_creditor_debtors_ledger !== 'Not Applicable' ? data?.sundry_creditor_debtors_ledger_id : data?.party_account_name_id : data?.account_id);
      formData.append('account_ledger_balance', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? '' : data?.account_current_balance);
      formData.append('data', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? '' : JSON?.stringify(valueLoop));
      formData.append('is_proceed', '1');
      // for sale-------------
      formData.append('supplier_ref_no', data?.supplier_inv_ref);
      formData.append('party_bill_to_ledger_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_bill_to_id : '');
      formData.append('data_item', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? JSON?.stringify(item_entry) : '');
      formData.append('sale_ledger_data', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? JSON?.stringify(item_entry_ledger_account) : '');
      formData.append('party_bill_to_mailing_name', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.mailing_name_bill_to : '');
      formData.append('bill_to_address', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.address_bill_to : '');
      formData.append('bill_to_contact', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.contact_bill_to : '');
      formData.append('party_ship_to_ledger_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_ship_to_id : '');
      formData.append('party_ship_to_mailing_name', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.mailing_name_ship_to : '');
      formData.append('ship_to_address', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.addressh_ship_to : '');
      formData.append('ship_to_contact', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.contact_ship_to : '');
      formData.append('sales_ledger_order_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.sale_purchase_ledger_account_id : '');
      formData.append('dispatch_detail', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.dispatch_details : '');
      formData.append('voucher_type', data?.entry_name === 'Sales' ? 'sale' : data?.entry_name === 'Purchase' ? 'purchase' : '');
      formData.append('delivery_note_no', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.delivery_note_no : '');
      formData.append('dispatch_doc_no', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.dispatch_doc_no : '');
      formData.append('dispatch_through', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.dispatched_through : '');
      formData.append('destination', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.destination : '');
      formData.append('carrier_name_agent', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.carrier_name_agent : '');
      formData.append('bill_of_loading', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.bill_of_lading_lr_rr_no : '');
      formData.append('dispatch_date', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.date : '');
      formData.append('order_detail', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.order_details : '');
      formData.append('order_no', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.order_no : '');
      formData.append('mode_term_payment', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.mode_terms_of_payment : '');
      formData.append('other_reference', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.other_references : '');
      formData.append('term_of_delivery', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.terms_of_delivey : '');
      formData.append('location', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.location_id : '');
      formData.append('bank_ac_od_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.bank_account_od_ledger_id : '');
      formData.append('bank_ac_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.bank_account_od_payment : '');
      formData.append('UTR_CHQ', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.utr_chq_no : '');
      formData.append('cash_in_hand_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.cash_in_hand_ledger_id : '');
      formData.append('cash_in_hand_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.cash_in_hand_payment : '');
      formData.append('cash_tendered', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.cash_tendered : '');
      formData.append('sundry_debitor_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.sundry_creditor_debtors_ledger_id : '');
      formData.append('sundry_debitor_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.sundry_creditor_debtors_payment : '');
      formData.append('tran_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.txn_id_ref_no : '');
      formData.append('party_account_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_account_name_id : '');
      formData.append('party_amount', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_account_name_payment : '');
      formData.append('party_balance', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.balance : '');
      formData.append('total_item_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? parseFloat(total_amount) : '');
      dispatch(contraVoucherCreate(formData)).then((response) => {
        if (response.status) {
          settotal_amount('')
          sethashtag(false)
          const serial = new FormData();
          serial.append('voucher_type_name', data?.entry_name)
          serial.append('voucher_number_series_id', data?.voucher_number_series_id)
          serial.append('company_id', userData?.id)
          dispatch(serialNumberList(serial)).then((response) => {
            if (response.status) {
              setValueLoop([{ id: '1', first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '' }])
              setItem_entry([
                {
                  id: '1', item_name: '', item_id: '',
                  godown_location: '',
                  godown_location_id: '',
                  item_qty: '',
                  item_rate: '',
                  per_unit: '',
                  item_amt: '',
                  item_alternate_unit: '',
                  is_unit_available: '',
                  where_unit_num: '',
                  where_alternate_unit_num: '',
                  main_unit_name: '',
                  combination_of_units: ''
                }])

              setItem_entry_ledger_account([
                { id: '1', ledger_account: '', ledger_account_id: '', rate_percentage: '', ledger_amount: '' }])
              setData(
                {
                  ...data,
                  serial_no: response.new_serial_no,
                  account: '',
                  account_id: '',
                  account_current_balance: '',
                  narration: '',
                  supplier_inv_ref: '',
                  date_payment: '',
                  party_bill_to: '',
                  party_bill_to_id: '',
                  partry_bill_to_current_balance: '',
                  mailing_name_bill_to: '',
                  address_bill_to: '',
                  contact_bill_to: '',
                  party_ship_to: '',
                  party_ship_to_id: '',
                  party_ship_to_current_balance: '',
                  mailing_name_ship_to: '',
                  addressh_ship_to: '',
                  contact_ship_to: '',
                  sale_purchase_ledger_account: '',
                  sale_purchase_ledger_account_id: '',
                  sale_purchase_ledger_current_balance: '',
                  dispatch_details: 'Not Available',
                  order_details: 'Not Available',
                  location: '',
                  location_id: '',
                  party_account_name: '',
                  party_account_name_id: '',
                  party_account_name_payment: '',
                  bank_account_od_ledger: '',
                  bank_account_od_payment: '',
                  bank_account_od_ledger_id: '',
                  cash_in_hand_ledger: '',
                  cash_in_hand_payment: '',
                  cash_in_hand_ledger_id: '',
                  sundry_creditor_debtors_ledger: '',
                  sundry_creditor_debtors_payment: '',
                  sundry_creditor_debtors_ledger_id: '',
                  utr_chq_no: '',
                  cash_tendered: '',
                  txn_id_ref_no: '',
                  balance: '',
                  is_unit_available: '',
                  where_unit_num: '',
                  where_alternate_unit_num: '',
                  alternate_unit_name: '',
                  main_unit_name: '',
                  combination_of_units: ''
                })
              settotal_amount(0)
              settotal_amount_credit(0)
              settotal_amount_debit(0)
              settotal_trigger(false)
              settotal_trigger_credit(false)
              settotal_trigger_debit(false)
              setstore_index(0)
              setcurrentIndex(0)
              setCurrentBalance(0)
              setvoucherEntrySubmit('')
              setLoading(false)
              data?.entry_name === 'Contra' ?
                inputRef.current.focus() : supplier_inv_ref_input_ref.current.focus();
            }
          })




          // if (data?.check_num_series_manual_automatic == 'Automatic') {
          //   const serial = new FormData();
          //   serial.append('voucher_type_name', data?.entry_name)
          //   serial.append('voucher_number_series_id', data?.voucher_number_series_id)
          //   serial.append('company_id', userData?.id)
          //   dispatch(serialNumberList(serial)).then((response) => {
          //     if (response.status) {
          //       setValueLoop([{ id: '1', first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '' }])
          //       setData({ ...data, serial_no: response.new_serial_no, account: '', account_id: '', account_current_balance: '', check_num_series_manual_automatic: response?.data, narration: '', supplier_inv_ref: '', date_payment: '' })
          //       settotal_amount(0)
          //       settotal_amount_credit(0)
          //       settotal_amount_debit(0)
          //       settotal_trigger(false)
          //       settotal_trigger_credit(false)
          //       settotal_trigger_debit(false)
          //       setstore_index(0)
          //       setcurrentIndex(0)
          //       setCurrentBalance(0)
          //       setvoucherEntrySubmit('')
          //       data?.entry_name === 'Contra' ?
          //         inputRef.current.focus() : supplier_inv_ref_input_ref.current.focus();
          //     }
          //   })
          // } else {
          //   setValueLoop([{ id: '1', first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '' }])
          //   setData({ ...data, serial_no: '', account: '', account_id: '', account_current_balance: '', check_num_series_manual_automatic: data?.check_num_series_manual_automatic, narration: '', supplier_inv_ref: '', date_payment: '' })
          //   settotal_amount(0)
          //   settotal_amount_credit(0)
          //   settotal_amount_debit(0)
          //   settotal_trigger(false)
          //   settotal_trigger_credit(false)
          //   settotal_trigger_debit(false)
          //   setstore_index(0)
          //   setcurrentIndex(0)
          //   setCurrentBalance(0)
          //   setvoucherEntrySubmit('')
          //   serial_no_input_ref.current.focus()
          // }

        } else {
          console.log("this is response:---", response)
        }
      }).catch((error) => {
        console.log("this is error:--", error)
      })


    } else {
      alert('Fill All Input')
    }




  };
  // when form submit then trigger 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hashtag) {
      if (validateForm()) {
        setLoading(true)
        let formData = new FormData();
        formData.append('voucher_type_id', data?.entry_name_id);
        formData.append('voucher_type_name', data?.entry_name);
        formData.append('voucher_number_series_id', data?.voucher_number_series_id);
        formData.append('serial_no', data?.serial_no);
        formData.append('total_amount', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? total_amount_item : total_amount);
        formData.append('company_id', userData?.id);
        formData.append('create_date', dateFormateSend(data?.date));
        formData.append('is_checked_series', '1');
        formData.append('narration', data?.narration);
        formData.append('account_ledger_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.bank_account_od_ledger !== 'Not Applicable' ? data?.bank_account_od_ledger_id : data?.cash_in_hand_ledger !== 'Not Applicable' ? data?.cash_in_hand_ledger_id : data?.sundry_creditor_debtors_ledger !== 'Not Applicable' ? data?.sundry_creditor_debtors_ledger_id : data?.party_account_name_id : data?.account_id);
        formData.append('account_ledger_balance', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? '' : data?.account_current_balance);
        formData.append('data', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? '' : JSON?.stringify(valueLoop));
        formData.append('is_proceed', '1');
        // for sale-------------
        formData.append('supplier_ref_no', data?.supplier_inv_ref);
        formData.append('party_bill_to_ledger_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_bill_to_id : '');
        formData.append('data_item', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? JSON?.stringify(item_entry) : '');
        formData.append('sale_ledger_data', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? JSON?.stringify(item_entry_ledger_account) : '');
        formData.append('party_bill_to_mailing_name', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.mailing_name_bill_to : '');
        formData.append('bill_to_address', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.address_bill_to : '');
        formData.append('bill_to_contact', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.contact_bill_to : '');
        formData.append('party_ship_to_ledger_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_ship_to_id : '');
        formData.append('party_ship_to_mailing_name', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.mailing_name_ship_to : '');
        formData.append('ship_to_address', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.addressh_ship_to : '');
        formData.append('ship_to_contact', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.contact_ship_to : '');
        formData.append('sales_ledger_order_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.sale_purchase_ledger_account_id : '');
        formData.append('dispatch_detail', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.dispatch_details : '');
        formData.append('voucher_type', data?.entry_name === 'Sales' ? 'sale' : data?.entry_name === 'Purchase' ? 'purchase' : '');
        formData.append('delivery_note_no', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.delivery_note_no : '');
        formData.append('dispatch_doc_no', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.dispatch_doc_no : '');
        formData.append('dispatch_through', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.dispatched_through : '');
        formData.append('destination', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.destination : '');
        formData.append('carrier_name_agent', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.carrier_name_agent : '');
        formData.append('bill_of_loading', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.bill_of_lading_lr_rr_no : '');
        formData.append('dispatch_date', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_dispatch_details?.date : '');
        formData.append('order_detail', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.order_details : '');
        formData.append('order_no', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.order_no : '');
        formData.append('mode_term_payment', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.mode_terms_of_payment : '');
        formData.append('other_reference', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.other_references : '');
        formData.append('term_of_delivery', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? state_order_details?.terms_of_delivey : '');
        formData.append('location', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.location_id : '');
        formData.append('bank_ac_od_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.bank_account_od_ledger_id : '');
        formData.append('bank_ac_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.bank_account_od_payment : '');
        formData.append('UTR_CHQ', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.utr_chq_no : '');
        formData.append('cash_in_hand_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.cash_in_hand_ledger_id : '');
        formData.append('cash_in_hand_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.cash_in_hand_payment : '');
        formData.append('cash_tendered', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.cash_tendered : '');
        formData.append('sundry_debitor_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.sundry_creditor_debtors_ledger_id : '');
        formData.append('sundry_debitor_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.sundry_creditor_debtors_payment : '');
        formData.append('tran_id', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.txn_id_ref_no : '');
        formData.append('party_account_ledger', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_account_name_id : '');
        formData.append('party_amount', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_account_name_payment : '');
        formData.append('party_balance', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.balance : '');
        formData.append('total_item_amt', data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? parseFloat(total_amount.replace(/,/g, '')) : '');
        dispatch(contraVoucherCreate(formData)).then((response) => {
          if (response.status) {
            settotal_amount('')
            sethashtag(false)
            const serial = new FormData();
            serial.append('voucher_type_name', data?.entry_name)
            serial.append('voucher_number_series_id', data?.voucher_number_series_id)
            serial.append('company_id', userData?.id)
            dispatch(serialNumberList(serial)).then((response) => {
              if (response.status) {
                setValueLoop([{ id: '1', first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '' }])
                setItem_entry([
                  {
                    id: '1', item_name: '', item_id: '',
                    godown_location: '',
                    godown_location_id: '',
                    item_qty: '',
                    item_rate: '',
                    per_unit: '',
                    item_amt: '',
                    item_alternate_unit: '',
                    is_unit_available: '',
                    where_unit_num: '',
                    where_alternate_unit_num: '',
                    main_unit_name: '',
                    combination_of_units: ''
                  }])

                setItem_entry_ledger_account([
                  { id: '1', ledger_account: '', ledger_account_id: '', rate_percentage: '', ledger_amount: '' }])
                setData(
                  {
                    ...data,
                    serial_no: response.new_serial_no,
                    account: '',
                    account_id: '',
                    account_current_balance: '',
                    narration: '',
                    supplier_inv_ref: '',
                    date_payment: '',
                    party_bill_to: '',
                    party_bill_to_id: '',
                    partry_bill_to_current_balance: '',
                    mailing_name_bill_to: '',
                    address_bill_to: '',
                    contact_bill_to: '',
                    party_ship_to: '',
                    party_ship_to_id: '',
                    party_ship_to_current_balance: '',
                    mailing_name_ship_to: '',
                    addressh_ship_to: '',
                    contact_ship_to: '',
                    sale_purchase_ledger_account: '',
                    sale_purchase_ledger_account_id: '',
                    sale_purchase_ledger_current_balance: '',
                    dispatch_details: 'Not Available',
                    order_details: 'Not Available',
                    location: '',
                    location_id: '',
                    party_account_name: '',
                    party_account_name_id: '',
                    party_account_name_payment: '',
                    bank_account_od_ledger: '',
                    bank_account_od_payment: '',
                    bank_account_od_ledger_id: '',
                    cash_in_hand_ledger: '',
                    cash_in_hand_payment: '',
                    cash_in_hand_ledger_id: '',
                    sundry_creditor_debtors_ledger: '',
                    sundry_creditor_debtors_payment: '',
                    sundry_creditor_debtors_ledger_id: '',
                    utr_chq_no: '',
                    cash_tendered: '',
                    txn_id_ref_no: '',
                    balance: '',
                    is_unit_available: '',
                    where_unit_num: '',
                    where_alternate_unit_num: '',
                    alternate_unit_name: '',
                    main_unit_name: '',
                    combination_of_units: ''
                  })
                settotal_amount(0)
                settotal_amount_credit(0)
                settotal_amount_debit(0)
                settotal_trigger(false)
                settotal_trigger_credit(false)
                settotal_trigger_debit(false)
                setstore_index(0)
                setcurrentIndex(0)
                setCurrentBalance(0)
                setvoucherEntrySubmit('')
                setLoading(false)
                data?.entry_name === 'Contra' ?
                  inputRef.current.focus() : supplier_inv_ref_input_ref.current.focus();
              }
            })




            // if (data?.check_num_series_manual_automatic == 'Automatic') {
            //   const serial = new FormData();
            //   serial.append('voucher_type_name', data?.entry_name)
            //   serial.append('voucher_number_series_id', data?.voucher_number_series_id)
            //   serial.append('company_id', userData?.id)
            //   dispatch(serialNumberList(serial)).then((response) => {
            //     if (response.status) {
            //       setValueLoop([{ id: '1', first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '' }])
            //       setData({ ...data, serial_no: response.new_serial_no, account: '', account_id: '', account_current_balance: '', check_num_series_manual_automatic: response?.data, narration: '', supplier_inv_ref: '', date_payment: '' })
            //       settotal_amount(0)
            //       settotal_amount_credit(0)
            //       settotal_amount_debit(0)
            //       settotal_trigger(false)
            //       settotal_trigger_credit(false)
            //       settotal_trigger_debit(false)
            //       setstore_index(0)
            //       setcurrentIndex(0)
            //       setCurrentBalance(0)
            //       setvoucherEntrySubmit('')
            //       data?.entry_name === 'Contra' ?
            //         inputRef.current.focus() : supplier_inv_ref_input_ref.current.focus();
            //     }
            //   })
            // } else {
            //   setValueLoop([{ id: '1', first: '', particular_ledger_balance: '', particular_ledger_id: '', particular_ledger_pay_amount: '' }])
            //   setData({ ...data, serial_no: '', account: '', account_id: '', account_current_balance: '', check_num_series_manual_automatic: data?.check_num_series_manual_automatic, narration: '', supplier_inv_ref: '', date_payment: '' })
            //   settotal_amount(0)
            //   settotal_amount_credit(0)
            //   settotal_amount_debit(0)
            //   settotal_trigger(false)
            //   settotal_trigger_credit(false)
            //   settotal_trigger_debit(false)
            //   setstore_index(0)
            //   setcurrentIndex(0)
            //   setCurrentBalance(0)
            //   setvoucherEntrySubmit('')
            //   serial_no_input_ref.current.focus()
            // }

          } else {
            console.log("this is response:---", response)
          }
        }).catch((error) => {
          console.log("this is error:--", error)
        })


      } else {
        alert('Fill All Input')
      }

    }
  }
  // Function to validate form fields---------------------------------------
  const validateForm = () => {
    return (
      userData?.id !== '' &&
        data.entry_name !== '' &&
        data?.entry_name_id !== '' &&
        data.serial_no !== '' &&
        data.date !== '' &&
        data?.entry_name === 'Contra' || data?.entry_name === 'Receipt' || data?.entry_name === 'Payment' ? data?.account !== '' : data?.account === '' &&
          data?.entry_name === 'Contra' ||
          data?.entry_name === 'Receipt' ||
          data?.entry_name === 'Payment' ?
        valueLoop.every(entry => entry?.first !== '' && entry?.particular_ledger_id !== '' && entry?.particular_ledger_pay_amount !== '')
        : item_entry.every(entry =>
          entry?.item_name !== '' && entry?.item_id !== ''
            && entry?.item_amt !== ''
            && data?.location === 'Any' ? entry?.godown_location !== '' : entry?.godown_location === ''
              && entry?.is_unit_available ? entry?.item_qty !== ''
                && entry?.item_qty !== '' ? (entry?.item_rate !== '' && entry?.per_unit !== '') : (entry?.item_rate === '' && entry?.per_unit === '') : entry?.item_qty === '') &&
          //---------------------
          data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_bill_to !== '' && data?.party_bill_to_id !== '' : data?.party_bill_to === '' && data?.party_bill_to_id === '' &&
            data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ? data?.party_ship_to !== '' && data?.party_ship_to_id !== '' : data?.party_ship_to === '' && data?.party_ship_to_id === ''
    );
  };
  if (loading) {
    return <div>Loading...</div>; // Render loading indicator while data is being fetched
  }
  // Render Html content ====================================================================================
  return (
    <div className='section'>
      <div className='detail'>
        {
          voucherEntrySubmit === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {voucherEntrySubmit} ?
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        {
          show_period ? <Period setshow_period={setshow_period} show_period={show_period} setData={setData} data={data} setshow_other_voucher={setshow_other_voucher} displayNameOfVoucherType={displayNameOfVoucherType} /> : null
        }
        {
          change_mode ? <ChangeMode setchange_mode={setchange_mode} setmode={setmode} change_mode={change_mode} /> : null
        }
        {
          show_configure ? <Configure setshow_configure={setshow_configure} show_configure={show_configure} setData={setData} data={data} setshow_other_voucher={setshow_other_voucher} /> : null
        }
        {
          show_voucher_type ? <VoucherTypeNameSelect setshow_voucher_type={setshow_voucher_type} show_voucher_type={show_voucher_type} setData={setData} data={data} /> : null
        }
        {
          show_dispatch_details_modal ? <DispatchDetails ref_name={order_details_input_ref} setshow_dispatch_details_modal={setshow_dispatch_details_modal} setstate_dispatch_details={setstate_dispatch_details} setData={setData} data={data} /> : null
        }
        {
          show_order_details_modal ? <OrderDetails ref_name={item_name_input_ref} setshow_order_details_modal={setshow_order_details_modal} setstate_order_details={setstate_order_details} setData={setData} data={data} /> : null
        }
        {
          show_other_voucher ? <OtherVoucher setshow_voucher_type={setshow_voucher_type} setshow_other_voucher={setshow_other_voucher} show_other_voucher={show_other_voucher} setData={setData} data={data} displayNameOfVoucherType={displayNameOfVoucherType} /> : null
        }
        <div className='container-fluid'>
          <div className='row border border-dark table_date_header' >
            <div className='col-12 m-0 p-0 ps-1  d-flex justify-content-between  table_header_font'>
              <b> Accounting Voucher Creation </b>
              {/* <span>{`${data.day_name}, ${date}`}  </span> */}
            </div>
          </div>
          <form onSubmit={handleSubmit} autoComplete='off'>
            {/* This is top section to all voucher type ======================================================== */}
            <div className='row' >
              <div className='col-10 m-0 p-0 ps-1' style={{ height: 15 }}>
                <div className='row'>
                  <div className='col-6'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab_voucher_entry' style={{ width: '58%' }}>{data?.entry_name} No</label>
                      <label>:</label>
                      <input
                        type='text'
                        name='serial_no'
                        autoFocus={data?.check_num_series_manual_automatic?.method_of_voucher_numbering === 'Manual'}
                        disabled={data?.check_num_series_manual_automatic?.method_of_voucher_numbering === 'Automatic'}
                        id='serial_no'
                        className='voucher_entry_account_input'
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={serial_no_input_ref}
                        value={serial_no || ""} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-2 p-0 m-0 pe-1 container_of_voucher_input'>
                <input
                  type='text'
                  name='date'
                  id='date'
                  className='voucher_entry_account_input'
                  style={{ textAlign: 'right', fontWeight: 'bold' }}
                  onKeyUp={handleChange}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  ref={date_input_ref}
                  value={date || ""} />
              </div>
              {
                data.entry_name === 'Payment' || data.entry_name === 'Receipt' || data.entry_name === 'Purchase' || data.entry_name === 'Sales' || data.entry_name === 'Credit Note' || data.entry_name === 'Debit Note' ?
                  <div className='col-12 p-0' style={{ height: 19.5 }} >
                    <div className='row m-0 p-0'>
                      <div className='col-5 p-0 ps-1'>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab_voucher_entry' style={{ width: '56%' }}>Supplier Inv/Ref No</label>
                          <label> :</label>
                          <input
                            type='text'
                            name='supplier_inv_ref'
                            id='supplier_inv_ref'
                            autoFocus
                            className='voucher_entry_account_input'
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={supplier_inv_ref_input_ref}
                            value={supplier_inv_ref || ""} />
                        </div>
                      </div>
                      <div className='col-3 m-0'>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab_voucher_entry'> Date </label>
                          <label> :</label>
                          <input
                            type='text'
                            name='date_payment'
                            id='date_payment'
                            className='voucher_entry_account_input'
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={date_payment_input_ref}
                            value={date_payment || ""} />
                        </div>
                      </div>
                      {
                        data?.entry_name === 'Payment' || data?.entry_name === 'Receipt' || data?.entry_name === 'Sales' || data?.entry_name === 'Purchase' ?
                          <div className='col-4 m-0 p-0 pe-1' style={{ height: 19.5, textAlign: 'right' }}>
                            {
                              mode === 'Single Mode' ?
                                <span style={{ textAlign: 'right', fontSize: 13 }}>{data.day_name}</span>
                                :
                                null
                            }
                          </div>
                          : null
                      }
                    </div>

                  </div> : null
              }

              {
                mode === 'Single Mode' ?
                  <>
                    {
                      data.entry_name === 'Sales' || data.entry_name === 'Purchase' || data.entry_name === 'Credit Note' || data.entry_name === 'Debit Note' ?
                        <div className='col-12 m-0 p-0 ps-1'>
                          <div className='row mt-0 mb-0'>
                            <div className='col-6 border border-dark border-end-0 pb-1 pt-1'>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Party(Bill To)</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='party_bill_to'
                                  id='party_bill_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={party_bill_to_input_ref}
                                  value={party_bill_to || ""} />
                                <div id="myDropdown-list" className={`dropdown-content-list ${show_party_bill_to_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                  <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                    List Of ledger account
                                  </div>
                                  <div className='col ' style={{ textAlign: 'right' }}>
                                    <Link to='/create-master-ledger' onClick={() => navigate('/create-master-ledger')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                    <div className='border'></div>
                                  </div>
                                  <div id='data'>
                                    {
                                      Array.isArray(filteredDataPartyBillTo) && filteredDataPartyBillTo?.map((key, number) => {
                                        const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
                                        const name = key?.ledger_name
                                        const id = key?.id;
                                        const index = name?.toLowerCase()?.indexOf(party_bill_to?.toLowerCase());
                                        return (
                                          <Link
                                            className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                            id='list'
                                            key={number}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setSelectedItem(number)
                                              if (number === selectedItem) {
                                                setData({ ...data, party_bill_to: name })
                                                setshow_account_list(false)
                                              }
                                            }}
                                          >
                                            {name === 'Not Applicable' ? (
                                              <>
                                                <SuitDiamondFill /> &nbsp;
                                                {name}
                                              </>
                                            ) : index !== -1 ? (
                                              < >
                                                {name?.substring(0, index)}
                                                <span style={{ color: 'red' }}>{name?.substring(index, index + party_bill_to?.length)}</span>
                                                {name?.substring(index + party_bill_to?.length)}
                                              </>
                                            ) : (
                                              name
                                            )}
                                          </Link>
                                        );
                                      })
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry text-muted'>Current Balance</label>
                                <label> :</label>
                                <label
                                  style={{ textAlign: 'left' }}
                                  className={`voucher_entry_account_input ${data?.partry_bill_to_current_balance < 0 ? 'color_red ps-2' : 'text-muted ps-2'}`}>
                                  {currencyFormate(parseFloat(data?.partry_bill_to_current_balance))}
                                  <span
                                    className={data?.partry_bill_to_current_balance < 0 ? 'color_red ps-1' : 'm-0 p-0 ps-1 text-muted'}>
                                    {data?.partry_bill_to_current_balance == 0.00 ? '' : data?.partry_bill_to_current_balance < 0 ? 'Cr' : 'Dr'}
                                  </span>
                                </label>
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Mailing Name</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='mailing_name_bill_to'
                                  id='mailing_name_bill_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={mailing_name_bill_to_input_ref}
                                  value={mailing_name_bill_to || ""} />
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Address</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='address_bill_to'
                                  id='address_bill_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={address_bill_to_input_ref}
                                  value={address_bill_to || ""} />
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Contact No.</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='contact_bill_to'
                                  id='contact_bill_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={contact_bill_to_input_ref}
                                  value={contact_bill_to || ""} />
                              </div>
                            </div>
                            <div className='col-6 border border-dark border-end-0 pb-1 pt-1'>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Party(Ship To)</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='party_ship_to'
                                  id='party_ship_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={party_ship_to_input_ref}
                                  value={party_ship_to || ""} />
                                <div id="myDropdown-list" className={`dropdown-content-list ${show_party_ship_to_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                  <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                    List Of ledger account
                                  </div>
                                  <div className='col ' style={{ textAlign: 'right' }}>
                                    <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                    <div className='border'></div>
                                  </div>
                                  <div id='data'>
                                    {
                                      filteredDataPartyShipTo?.map((key, number) => {
                                        const name = key?.ledger_name;
                                        const id = key?.id;
                                        const index = name?.toLowerCase()?.indexOf(party_ship_to?.toLowerCase());
                                        return (
                                          <Link
                                            className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                            id='list'
                                            key={number}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setSelectedItem(number)
                                              if (number === selectedItem) {
                                                setData({ ...data, party_ship_to: name })
                                                setshow_account_list(false)
                                              }
                                            }}
                                          >
                                            {name === 'Not Applicable' ? (
                                              <>
                                                <SuitDiamondFill /> &nbsp;
                                                {name}
                                              </>
                                            ) : index !== -1 ? (
                                              < >
                                                {name?.substring(0, index)}
                                                <span style={{ color: 'red' }}>{name?.substring(index, index + party_ship_to?.length)}</span>
                                                {name?.substring(index + party_ship_to?.length)}
                                              </>
                                            ) : (
                                              name
                                            )}
                                          </Link>
                                        );
                                      })
                                    }

                                  </div>
                                </div>
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry text-muted'> Current Balance </label>
                                <label> :</label>
                                <label
                                  style={{ textAlign: 'left' }}
                                  className={`voucher_entry_account_input ${data?.party_ship_to_current_balance < 0 ? 'color_red ps-2' : 'text-muted ps-2'}`}>
                                  {currencyFormate(parseFloat(data?.party_ship_to_current_balance))}
                                  <span
                                    className={data?.party_ship_to_current_balance < 0 ? 'color_red ps-1' : 'm-0 p-0 ps-1 text-muted'}>
                                    {data?.party_ship_to_current_balance == 0.00 ? '' : data?.party_ship_to_current_balance < 0 ? 'Cr' : 'Dr'}
                                  </span>
                                </label>
                                {/* <p className='m-0 p-0 voucher_entry_account_balance text-muted'><i>{data?.party_ship_to_current_balance}</i></p> */}
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Mailing Name</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='mailing_name_ship_to'
                                  id='mailing_name_ship_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={mailing_name_ship_to_input_ref}
                                  value={mailing_name_ship_to || ""} />
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Address</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='addressh_ship_to'
                                  id='addressh_ship_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={addressh_ship_to_input_ref}
                                  value={addressh_ship_to || ""} />
                              </div>
                              <div className='container_of_voucher_input'>
                                <label className='lab_voucher_entry'>Contact No.</label>
                                <label> :</label>
                                <input
                                  type='text'
                                  name='contact_ship_to'
                                  id='contact_ship_to'
                                  className='voucher_entry_account_input'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={contact_ship_to_input_ref}
                                  value={contact_ship_to || ""} />
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        null
                    }

                    <div className='col-6 m-0 p-0 ps-1 pt-1'>
                      {data.entry_name === 'Sales' || data.entry_name === 'Purchase' || data.entry_name === 'Credit Note' || data.entry_name === 'Debit Note' ? null :
                        <>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab_voucher_entry'>Account</label>
                            <label className='lab_colun_voucher_entry'> :</label>
                            <input
                              type='text'
                              name='account'
                              id='account'
                              autoFocus
                              className='voucher_entry_account_input'
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={inputRef}
                              value={account || ""} />
                            <div id="myDropdown-list" className={`dropdown-content-list ${show_account_list ? 'show-list' : ''}`} ref={dropdownRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                <p className='text-center font_size'>List Of ledger account</p>
                              </div>
                              <div className='col' style={{ textAlign: 'right' }}>
                                <Link className='list text-end pe-1 ' to='/create-master-ledger' onClick={() => navigate('/create-master-ledger')}>Create</Link>
                                <div className='border'></div>
                              </div>
                              <div id='data'>
                                {
                                  Array.isArray(filteredData) && filteredData?.map((key, number) => {
                                    const name = key?.ledger_name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(account?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setData({ ...data, account: name })
                                            setshow_account_list(false)
                                            first_input_ref?.current[0]?.focus();
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name?.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name?.substring(index, index + account?.length)}</span>
                                            {name?.substring(index + account?.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                }

                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab_voucher_entry text-muted'>Current Balance </label>
                            <label className='lab_colun_voucher_entry'> :</label>
                            <label
                              style={{ textAlign: 'left' }}
                              className={`voucher_entry_account_input ${data?.account_current_balance < 0 ? 'color_red ps-2' : 'text-muted ps-2'}`}>
                              {data?.account_current_balance == 0.00 ? '' : currencyFormate(parseFloat(data?.account_current_balance))}
                              <span
                                className={data?.account_current_balance < 0 ? 'color_red ps-1' : 'm-0 p-0 ps-1 text-muted'}>
                                {data?.account_current_balance == 0.00 ? '' : data?.account_current_balance < 0 ? 'Cr' : 'Dr'}
                              </span>
                            </label>
                          </div>
                        </>
                      }
                    </div>
                    {
                      data?.entry_name === 'Contra' || data?.entry_name === 'Journal' ?
                        <div className='col-1 p-0 pe-1 offset-5' style={{ height: 19.5, textAlign: 'right' }}>
                          {
                            mode === 'Single Mode' ?
                              <span style={{ textAlign: 'right', fontSize: 13 }}>{data.day_name}</span>
                              :
                              null
                          }
                        </div>
                        : null
                    }

                    < div className='col-12'>
                      {
                        data.entry_name === 'Sales' || data.entry_name === 'Purchase' || data.entry_name === 'Credit Note' || data.entry_name === 'Debit Note' ?
                          <>
                            <div className='row'>
                              <div className='col-3 m-0 p-0 ps-1' style={{ width: '20%' }}>
                                <div className='container_of_voucher_input position-relative'>
                                  <label className='lab_voucher_entry'><span><u>L</u>:&nbsp;</span>Location </label>
                                  <label> :</label>
                                  <input
                                    type='text'
                                    id='location'
                                    style={{ fontWeight: 'bold' }}
                                    name='location'
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={location_input_ref}
                                    value={location || ""} />
                                  <div className={`dropdown_location ${show_location ? 'show-list' : ''}`}>
                                    <div className='col background' style={{ position: 'sticky', top: 0 }}>
                                      Select Location
                                    </div>
                                    <div id='data'>
                                      {filteredDataLocation?.length > 0 ? (
                                        filteredDataLocation?.map((key, number) => {
                                          const name = key?.name;
                                          const index = name?.toLowerCase()?.indexOf(location?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setData({ ...data, location: name })
                                                  setshow_location(false)
                                                }
                                              }}
                                            >
                                              {name === 'Any' ? (
                                                <>
                                                  <SuitDiamondFill /> &nbsp;
                                                  {name}
                                                </>
                                              ) : index !== -1 ? (
                                                <>
                                                  {name.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name.substring(index, index + location.length)}</span>
                                                  {name.substring(index + location.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3' style={{ width: '30%' }}>
                                <div className='container_of_voucher_input'>
                                  <label className='lab_voucher_entry' style={{ width: '60%' }}>{data.entry_name === 'Sales' || data.entry_name === 'Credit Note' ? <span><u>S</u>:&nbsp;Sales Ledger</span> : <span><u>P</u>:&nbsp;Purchase Ledger</span>}</label>
                                  <label> :</label>
                                  <input
                                    type='text'
                                    id='sale_purchase_ledger_account'
                                    name='sale_purchase_ledger_account'
                                    className='voucher_entry_account_input'
                                    style={{ fontWeight: 'bold' }}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={sale_purchase_ledger_account_input_ref}
                                    value={sale_purchase_ledger_account || ""} />
                                  <div id="myDropdown-list" className={`dropdown-content-list ${show_sales_purchase_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                      List Of ledger account
                                    </div>
                                    <div className='col ' style={{ textAlign: 'right' }}>
                                      <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                      <div className='border'></div>
                                    </div>
                                    <div id='data'>
                                      {filteredDatSalesPurchase?.length > 0 ? (
                                        filteredDatSalesPurchase?.map((key, number) => {
                                          const name = key?.ledger_name;
                                          const id = key?.id;
                                          const index = name?.toLowerCase()?.indexOf(sale_purchase_ledger_account?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setData({ ...data, sale_purchase_ledger_account: name })
                                                  setshow_account_list(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name?.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name?.substring(index, index + sale_purchase_ledger_account?.length)}</span>
                                                  {name?.substring(index + sale_purchase_ledger_account?.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-2 m-0 p-0 ' style={{ width: '22%' }}>
                                <div className='container_of_voucher_input position-relative'>
                                  <label className='lab_voucher_entry' style={{ width: '86%' }}>{data?.entry_name === 'Sales' || data.entry_name === 'Credit Note' ? <><u>D</u>:&nbsp;<span>Dispatch Details</span></> : <><u>R</u>:&nbsp;<span>Receipt Details</span></>}</label>
                                  <label> :</label>
                                  <input
                                    type='text'
                                    id='dispatch_details'
                                    name='dispatch_details'
                                    style={{ fontWeight: 'bold' }}
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={dispatch_details_input_ref}
                                    value={dispatch_details || ""} />
                                  <div className={`dropdown_dispatch_order ${show_dispatch_order ? 'show-list' : ''}`}>
                                    <div className='col background' style={{ position: 'sticky', top: 0 }}>
                                      Select One
                                    </div>
                                    <div id='data'>
                                      {filteredDataDispatchDetails?.length > 0 ? (
                                        filteredDataDispatchDetails?.map((key, number) => {
                                          const name = key?.name;
                                          const index = name?.toLowerCase()?.indexOf(dispatch_details?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setData({ ...data, dispatch_details: name })
                                                  setshow_dispatch_order(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name?.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name?.substring(index, index + dispatch_details.length)}</span>
                                                  {name?.substring(index + dispatch_details.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-3 m-0 p-0 pe-1' style={{ width: '28%' }}>
                                <div className='container_of_voucher_input position-relative'>
                                  <label className='lab_voucher_entry' style={{ width: '100%', fontSize: 13 }}>{data?.entry_name === 'Sales' || data.entry_name === 'Credit Note' ? <span><u>O</u>:&nbsp;Sales Order Details</span> : <span><u>O</u>:&nbsp;Purchase Order details</span>}</label>
                                  <label> :</label>
                                  <input
                                    type='text'
                                    id='order_details'
                                    name='order_details'
                                    style={{ fontWeight: 'bold' }}
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    ref={order_details_input_ref}
                                    value={order_details || ""} />
                                  <div className={`dropdown_dispatch_order ${show_order_details ? 'show-list' : ''}`}>
                                    <div className='col background' style={{ position: 'sticky', top: 0 }}>
                                      Select One
                                    </div>
                                    <div id='data'>
                                      {filteredDataOrderDetails?.length > 0 ? (
                                        filteredDataOrderDetails?.map((key, number) => {
                                          const name = key?.name;
                                          const index = name?.toLowerCase()?.indexOf(order_details?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setData({ ...data, order_details: name })
                                                  setshow_order_details(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name?.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name?.substring(index, index + order_details.length)}</span>
                                                  {name?.substring(index + order_details.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </>
                          : null
                      }
                    </div>
                  </> : null
              }
            </div>
            {/* This is dynamically render stock item data =============================================================== */}
            {
              mode === 'Single Mode' ?
                <>

                  {
                    data.entry_name === 'Sales' || data.entry_name === 'Purchase' || data.entry_name === 'Credit Note' || data.entry_name === 'Debit Note' ?
                      <>
                        <div className='row border border-dark mt-0 heading_dyn_creation_data'>
                          <div className='col-1 font_size p-0 m-0 ps-1 font-weight-bold border-end border-dark text-start' style={{ width: '3%' }}>Sr</div>
                          <div className={`col-4 font_size p-0 m-0 ps-1 font-weight-bold ${location === 'Any' ? `` : ''}`} style={{ textAlign: 'left' }}>Name of Item</div>
                          <div className={`col-1 font_size p-0 m-0 pe-1 font-weight-bold ${location === 'Any' ? `border-end border-dark` : ''}`} style={{ textAlign: 'right', width: '5%' }}></div>
                          {
                            location === 'Any' ?
                              <div className='col-2 font_size m-0 p-0 ps-1 font-weight-bold border-end border-dark' style={{ textAlign: 'left' }}>Location</div>
                              :
                              <div className='col-2 font_size m-0 p-0 border-end border-dark' style={{ textAlign: 'left' }}></div>
                          }
                          <div className='col-1 font_size m-0 p-0 pe-1 font-weight-bold border-end border-dark ' style={{ textAlign: 'right' }}>Quantity</div>
                          <div className='col-1 font_size m-0 p-0 pe-1 font-weight-bold border-end border-dark ' style={{ textAlign: 'right' }}>(Alt Unit)</div>
                          <div className='col-1 font_size m-0 p-0 pe-1 font-weight-bold border-end border-dark ' style={{ textAlign: 'right', width: '10.1%' }}>Rate</div>
                          <div className='col-1 font_size m-0 p-0 ps-1 font-weight-bold  border-end border-dark' style={{ textAlign: 'left', width: '5%' }}>Per</div>
                          <div className={`col font_size m-0 p-0 pe-1 font-weight-bold text-end`} style={{ textAlign: 'right' }}>Amount</div>
                        </div>
                        <div className='row' style={{ height: '39.8vh', overflow: 'scroll' }}>
                          <div className='col-12'>
                            <div className='row'>


                              {item_entry.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`col-12 border-bottom border-dark`}
                                    style={{ height: 19.5, backgroundColor: 'transparent', }}>
                                    <div className='row' style={{ height: 19.5 }}>
                                      <div className={`col-1 m-0 p-0 ps-1 font-weight-bold text-start ${item?.check_open ? '' : `border-end border-dark`}`} style={{ width: '3%', height: 19.5 }}>{item_entry[index]?.item_name === '' ? '' : item?.id}</div>
                                      <div className={`col-4 m-0  p-0  ${location === 'Any' ? item?.check_open ? '' : `` : ''} container_of_voucher_dynamic_input`}>
                                        <input
                                          type='text'
                                          name={`item_name${index}`}
                                          ref={(input) => (item_name_input_ref.current[index] = input)}
                                          className='voucher_entry_account__dynamic_input'
                                          onKeyUp={(e) => handleKeyUpItemEntry(index, 'item_name', e.target.value, e)}
                                          onChange={(e) => handleChangeItemEntry(index, 'item_name', e.target.value)}
                                          onFocus={handleItemFocus}
                                          onBlur={handleItemBlur}
                                          value={item_entry[index]?.item_name || ''}
                                        />
                                        <div id="myDropdown-list" className={`dropdown-content-list ${show_item_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                          <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                            List Of Items
                                          </div>
                                          <div className='col' style={{ textAlign: 'right' }}>
                                            <Link to='/create-master-invmas-stockitem' onClick={() => navigate('/create-master-invmas-stockitem')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                            <div className='border'></div>
                                          </div>
                                          <div id='data'>
                                            {filteredDataItem?.length > 0 ? (
                                              filteredDataItem?.map((key, number) => {
                                                const name = key?.name;
                                                console.log("this is keyuP====", key.quantity)
                                                const qty = key?.quantity;
                                                const qty_unit = key?.per;
                                                const id = key?.id;
                                                const inner_index = name?.toLowerCase()?.indexOf(item_entry[index]?.item_name?.toLowerCase());
                                                return (
                                                  <Link
                                                    className={`list font-weight-bold  ${number === selectedItem ? 'selected' : ''}`}
                                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 10 }}
                                                    id='list'
                                                    key={number}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setSelectedItem(number)
                                                      if (number === selectedItem) {
                                                        getValueFromDropdownOfItem(key, number)
                                                        setshow_particular_list(false)
                                                      }
                                                    }}
                                                  >
                                                    <div>
                                                      {name === 'End Of List' ? (
                                                        <>
                                                          <SuitDiamondFill /> &nbsp;
                                                          {name}
                                                        </>
                                                      ) : index !== -1 ? (
                                                        < >
                                                          {name?.substring(0, inner_index)}
                                                          <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + item_entry[index]?.item_name?.length)}</span>
                                                          {name?.substring(inner_index + item_entry[index]?.item_name?.length)}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {name}

                                                        </>
                                                      )}
                                                    </div>
                                                    <div>
                                                      <i>
                                                        <span>{qty}</span>
                                                        <span style={{ paddingLeft: 5 }}>{qty_unit}</span>
                                                      </i>
                                                    </div>

                                                  </Link>
                                                );
                                              })
                                            ) : (
                                              <p style={{ color: 'red' }}>No matching items found</p>
                                            )}

                                          </div>
                                        </div>
                                      </div>
                                      <div className={`col-1 font_size p-0 m-0 pe-1 font-weight-bold ${location === 'Any' ? `border-end border-dark` : ''}`} style={{ width: '5%' }}>
                                        {/* <input
                                          type='text'
                                          style={{ textAlign: 'left' }}
                                          name={`per_unit${index}`}
                                          ref={(input) => (item_rate_per_unit_input_ref.current[index] = input)}
                                          className='voucher_entry_account__dynamic_input'
                                          onKeyUp={(e) => handleKeyUpItemEntry(index, 'per_unit', e.target.value, e)}
                                          onChange={(e) => handleChangeItemEntry(index, 'per_unit', e.target.value)}
                                          value={item_entry[index]?.per_unit || ''}
                                        /> */}
                                      </div>
                                      {
                                        item_entry[index]?.item_name !== '' ?
                                          <>
                                            {
                                              location === 'Any' ?
                                                <div className='col-2 m-0 p-0 container_of_voucher_dynamic_input border-end border-dark'>
                                                  <input
                                                    type='text'
                                                    style={{ textAlign: 'left' }}
                                                    name={`godown_location${index}`}
                                                    ref={(input) => (godown_input_ref.current[index] = input)}
                                                    className='voucher_entry_account__dynamic_input'
                                                    onKeyUp={(e) => handleKeyUpItemEntry(index, 'godown_location', e.target.value, e)}
                                                    onChange={(e) => handleChangeItemEntry(index, 'godown_location', e.target.value)}
                                                    onFocus={handleGodownFocus}
                                                    onBlur={handleGodownBlur}
                                                    value={item_entry[index]?.godown_location || ''}
                                                  />
                                                  <div id="myDropdown-list" className={`dropdown-content-list ${show_godown_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                                      List Of ledger account
                                                    </div>
                                                    <div className='col ' style={{ textAlign: 'right' }}>
                                                      <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                                      <div className='border'></div>
                                                    </div>
                                                    <div id='data'>
                                                      {filteredDataLocationItem?.length > 0 ? (
                                                        filteredDataLocationItem?.map((key, number) => {
                                                          const name = key?.name;
                                                          const id = key?.id;
                                                          const inner_index = name?.toLowerCase()?.indexOf(item_entry[index]?.godown_location?.toLowerCase());
                                                          return (
                                                            <Link
                                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                              id='list'
                                                              key={number}
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                setSelectedItem(number)
                                                                if (number === selectedItem) {
                                                                  setItem_entry((prevValueLoop) => {
                                                                    const updatedValueLoop = [...prevValueLoop];
                                                                    updatedValueLoop[index]['godown'] = name;
                                                                    return updatedValueLoop;
                                                                  });

                                                                  setshow_item_list(false)
                                                                  const nextInput = item_quantity_input_ref.current[index];
                                                                  if (nextInput) {
                                                                    nextInput.focus();
                                                                  }
                                                                }
                                                              }}
                                                            >
                                                              {inner_index !== -1 ? (
                                                                < >
                                                                  {name?.substring(0, inner_index)}
                                                                  <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + item_entry[index]?.godown_location?.length)}</span>
                                                                  {name?.substring(inner_index + item_entry[index]?.godown_location?.length)}
                                                                </>
                                                              ) : (
                                                                name
                                                              )}
                                                            </Link>
                                                          );
                                                        })
                                                      ) : (
                                                        <p style={{ color: 'red' }}>No matching items found</p>
                                                      )}

                                                    </div>
                                                  </div>
                                                </div> :
                                                <div className='col-2 m-0 p-0 container_of_voucher_dynamic_input border-end border-dark'></div>
                                            }
                                            <div className={`col-1 m-0 p-0 font-weight-bold container_of_voucher_dynamic_input border-end border-dark`}>
                                              <input
                                                type='text'
                                                disabled={item_entry[index]?.is_unit_available === '' || data?.is_unit_available === 'Not Applicable'}
                                                style={{ textAlign: 'right', paddingRight: 5 }}
                                                name={`item_qty${index}`}
                                                ref={(input) => (item_quantity_input_ref.current[index] = input)}
                                                className='voucher_entry_account__dynamic_input'
                                                onKeyUp={(e) => handleKeyUpItemEntry(index, 'item_qty', e.target.value, e)}
                                                onChange={(e) => handleChangeItemEntry(index, 'item_qty', e.target.value)}
                                                value={item_entry[index]?.item_qty || ''}
                                              />

                                            </div>

                                            <div className='col-1 m-0 border-end border-dark container_of_voucher_dynamic_input p-0'>
                                              <input
                                                type='text'
                                                readOnly
                                                style={{ textAlign: 'right', paddingRight: 5 }}
                                                className='voucher_entry_account__dynamic_input'
                                                value={item_entry[index]?.combination_of_units}
                                              />
                                              {/* <p className='voucher_entry_account_input'>({item_entry[index]?.combination_of_units})</p> */}
                                            </div>
                                            {
                                              item_entry[index]?.item_quantity !== '' ?
                                                <>
                                                  <div className='col-1 m-0 border-end border-dark container_of_voucher_dynamic_input  p-0' style={{ width: '10.1%' }}>
                                                    <input
                                                      type='text'
                                                      style={{ textAlign: 'right', paddingRight: 5 }}
                                                      name={`item_rate${index}`}
                                                      ref={(input) => (item_rate_input_ref.current[index] = input)}
                                                      className='voucher_entry_account__dynamic_input'
                                                      onKeyUp={(e) => handleKeyUpItemEntry(index, 'item_rate', e.target.value, e)}
                                                      onChange={(e) => handleChangeItemEntry(index, 'item_rate', e.target.value)}
                                                      value={item_entry[index]?.item_rate || ''}
                                                    />
                                                  </div>
                                                  <div className='col-1 m-0 border-end border-dark container_of_voucher_dynamic_input  p-0' style={{ width: '5%' }}>
                                                    <input
                                                      type='text'
                                                      style={{ textAlign: 'left' }}
                                                      name={`per_unit${index}`}
                                                      ref={(input) => (item_rate_per_unit_input_ref.current[index] = input)}
                                                      className='voucher_entry_account__dynamic_input'
                                                      onKeyUp={(e) => handleKeyUpItemEntry(index, 'per_unit', e.target.value, e)}
                                                      onChange={(e) => handleChangeItemEntry(index, 'per_unit', e.target.value)}
                                                      value={item_entry[index]?.per_unit || ''}
                                                    />
                                                  </div>
                                                </> : null
                                            }

                                            <div className={`col p-0 container_of_voucher_dynamic_input`}>
                                              <input
                                                type='text'
                                                style={{ textAlign: 'right', paddingRight: 5 }}
                                                name={`item_amt${index}`}
                                                ref={(input) => (item_amount_input_ref.current[index] = input)}
                                                className='voucher_entry_account__dynamic_input'
                                                onKeyUp={(e) => handleKeyUpItemEntry(index, 'item_amt', e.target.value, e)}
                                                onChange={(e) => handleChangeItemEntry(index, 'item_amt', e.target.value)}
                                                value={item_entry[index]?.item_amt || ''}
                                              />
                                            </div>
                                          </> : null
                                      }
                                    </div>

                                  </div>
                                )
                              }
                              )}
                              {
                                item_entry.map((item, index) => {
                                  return (
                                    item?.check_open ?
                                      item_entry_ledger_account?.map((item1, index1) => {
                                        return (
                                          <div key={index1} className={`col-12 ${item1?.check ? "" : `border-bottom border-dark`} `} style={{ height: 24, backgroundColor: 'transparent', }}>
                                            <div className='row' style={{ height: 24 }}>
                                              <div className='col-1' style={{ width: '3%' }}></div>
                                              <div className={`col-10 m-0  p-0 ${item_entry_ledger_account[index1]?.check ? '' : `border-end border-dark`} `}>
                                                <div className='row m-0 p-0' style={{ height: 24 }}>
                                                  <div className='col-4 m-0 p-0 container_of_voucher_dynamic_input' style={{ height: 24 }}>
                                                    <input
                                                      type='text'
                                                      name={`ledger_account${index1}`}
                                                      ref={(input) => (item_ledger_account_input_ref.current[index1] = input)}
                                                      className='voucher_entry_account__dynamic_input'
                                                      onKeyUp={(e) => handleKeyUpItemLedgerAccountEntry(index1, 'ledger_account', e.target.value, e)}
                                                      onChange={(e) => handleChangeItemLedgerAccountEntry(index1, 'ledger_account', e.target.value)}
                                                      onFocus={handleItemLedgerFocus}
                                                      onBlur={handleItemLedgerBlur}
                                                      value={item_entry_ledger_account[index1]?.ledger_account || ''}
                                                    />
                                                    <div id="myDropdown-list" className={`dropdown-content-list ${show_item_ledger_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                                        List Of ledger account
                                                      </div>
                                                      <div className='col ' style={{ textAlign: 'right' }}>
                                                        <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                                        <div className='border'></div>
                                                      </div>
                                                      <div id='data'>
                                                        {filteredDataItemLedger?.length > 0 ? (
                                                          filteredDataItemLedger?.map((key, number) => {
                                                            const name = key?.ledger_name;
                                                            const id = key?.id;
                                                            const inner_index = name?.toLowerCase()?.indexOf(item_entry_ledger_account[index1]?.ledger_account?.toLowerCase());
                                                            return (
                                                              <Link
                                                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                                                id='list'
                                                                key={number}
                                                                onClick={(e) => {
                                                                  e.preventDefault();
                                                                  setSelectedItem(number)
                                                                  if (number === selectedItem) {
                                                                    setValueLoop((prevValueLoop) => {
                                                                      const updatedValueLoop = [...prevValueLoop];
                                                                      updatedValueLoop[index]['first'] = name;
                                                                      return updatedValueLoop;
                                                                    });

                                                                    setshow_particular_list(false)
                                                                    const nextInput = particular_ledger_pay_amount_input_ref.current[index];
                                                                    if (nextInput) {
                                                                      nextInput.focus();
                                                                    }
                                                                  }
                                                                }}
                                                              >
                                                                {name === 'End Of List' ? (
                                                                  <>
                                                                    <SuitDiamondFill /> &nbsp;
                                                                    {name}
                                                                  </>
                                                                ) : index !== -1 ? (
                                                                  < >
                                                                    {name?.substring(0, inner_index)}
                                                                    <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + item_entry_ledger_account[index1]?.ledger_account?.length)}</span>
                                                                    {name?.substring(inner_index + item_entry_ledger_account[index1]?.ledger_account?.length)}
                                                                  </>
                                                                ) : (
                                                                  name
                                                                )}
                                                              </Link>
                                                            );
                                                          })
                                                        ) : (
                                                          <p style={{ color: 'red' }}>No matching items found</p>
                                                        )}

                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {
                                                item_entry_ledger_account[index1]?.check ? null :
                                                  <>
                                                    <div className={'col m-0  p-0 border-end border-dark container_of_voucher_dynamic_input'}>
                                                      <input
                                                        type='text'
                                                        style={{ textAlign: 'right', paddingRight: 5 }}
                                                        name={`ledger_amount${index1}`}
                                                        ref={(input) => (item_ledger_account_amount_input_ref.current[index1] = input)}
                                                        className='voucher_entry_account__dynamic_input'
                                                        onKeyUp={(e) => handleKeyUpItemLedgerAccountEntry(index1, 'ledger_amount', e.target.value, e)}
                                                        onChange={(e) => handleChangeItemLedgerAccountEntry(index1, 'ledger_amount', e.target.value)}
                                                        value={item_entry_ledger_account[index1]?.ledger_amount || ''}
                                                      />
                                                    </div>
                                                  </>
                                              }
                                            </div>
                                          </div>
                                        )
                                      })
                                      : null
                                  )
                                })
                              }

                            </div>
                          </div>
                        </div>
                      </> : null
                  }
                  {/* This is dynamically render transaction ====================================================================== */}
                  {
                    data.entry_name === 'Sales' || data.entry_name === 'Purchase' || data.entry_name === 'Credit Note' || data.entry_name === 'Debit Note' ? null :
                      <>
                        <div className='row border border-white heading_dyn_creation_data mt-0'>
                          <div className='col-4 p-0 m-0 ps-1 font_size font-weight-bold' style={{ textAlign: 'left' }}>Particulars</div>
                          <div className='col-6 p-0 m-0 font_size font-weight-bold' style={{ textAlign: 'left' }}>Current Balance</div>
                          <div className='col-2 p-0 m-0 pe-1 font_size font-weight-bold ' style={{ textAlign: 'right' }}>Amount</div>
                        </div>
                        <div
                          className='row'
                        //  style={data.entry_name === 'Contra' || data.entry_name === 'Journal' ? { height: '63vh', overflowY: 'scroll' } : data.entry_name === 'Purchase' ? { height: '52vh', overflowY: 'scroll' } : { height: '59vh', overflowY: 'scroll' }}
                        >
                          {valueLoop.map((item, index) => (
                            <div
                              key={index}
                              className={`col-12 ${showBorder ? 'border-bottom border-dark' : ''}`}
                              style={{ height: 19.5, backgroundColor: 'transparent' }}
                            >
                              <div className='row' style={{ height: 19.5 }}>
                                <div className='col-4 m-0 p-0 container_of_voucher_input'>
                                  <input
                                    type='text'
                                    name={`first_${index}`}
                                    ref={(input) => (first_input_ref.current[index] = input)}
                                    className='voucher_entry_account_input'
                                    onKeyUp={(e) => handleKeyUp(index, 'first', e.target.value, e)}
                                    onChange={(e) => handleChangeInputLoop(index, 'first', e.target.value)}
                                    onFocus={handleParticularFocus}
                                    onBlur={handleParticularBlur}
                                    value={valueLoop[index]?.first || ''}
                                  />
                                  <div id="myDropdown-list" className={`dropdown-content-list ${show_particular_list ? 'show-list' : ''}`} ref={dropdownRef}>
                                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                      List Of ledger account
                                    </div>
                                    <div className='col ' style={{ textAlign: 'right' }}>
                                      <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                      <div className='border'></div>
                                    </div>
                                    <div id='data'>
                                      {filteredDataParticular?.length > 0 ? (
                                        filteredDataParticular?.map((key, number) => {
                                          const name = key?.ledger_name;
                                          const id = key?.id;
                                          const inner_index = name?.toLowerCase()?.indexOf(valueLoop[index]?.first?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setValueLoop((prevValueLoop) => {
                                                    const updatedValueLoop = [...prevValueLoop];
                                                    updatedValueLoop[index]['first'] = name;
                                                    return updatedValueLoop;
                                                  });

                                                  setshow_particular_list(false)
                                                  const nextInput = particular_ledger_pay_amount_input_ref.current[index];
                                                  if (nextInput) {
                                                    nextInput.focus();
                                                  }
                                                }
                                              }}
                                            >
                                              {name === 'End Of List' ? (
                                                <>
                                                  <SuitDiamondFill /> &nbsp;
                                                  {name}
                                                </>
                                              ) : index !== -1 ? (
                                                < >
                                                  {name?.substring(0, inner_index)}
                                                  <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + valueLoop[index].first.length)}</span>
                                                  {name?.substring(inner_index + valueLoop[index].first.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}

                                    </div>
                                  </div>
                                </div>
                                <div className='col-6'>
                                  {valueLoop[index]?.first !== '' && valueLoop[index]?.first !== 'End Of List' ?
                                    <div className='row' style={{ height: 19.5 }}>
                                      <div className='col-1 p-0 m-0 font_size' style={{ textAlign: 'left', width: '8.5%' }}>
                                        Cur Bal:
                                      </div>
                                      <div className='col-2 m-0 p-0 font_size' style={{ textAlign: 'right', height: 19.5 }}>
                                        <span className={parseFloat(valueLoop[index]?.particular_ledger_balance) < 0 ? 'color_red' : 'text-muted'}>{` ${valueLoop[index]?.particular_ledger_balance}`}</span>
                                      </div>
                                      <div className='col-2 m-0 p-0 ps-1 font_size' style={{ textAlign: 'left', height: 19.5 }}>
                                        <span className={parseFloat(valueLoop[index]?.particular_ledger_balance) < 0 ? 'color_red' : 'text-muted'}>{valueLoop[index]?.particular_ledger_balance == 0 ? '' : parseFloat(valueLoop[index]?.particular_ledger_balance) < 0 ? ` Cr` : ` Dr`}</span>
                                      </div>
                                    </div>
                                    // <p className={'m-0 p-0 text-muted'} style={{ textAlign: 'left' }}>
                                    //   Cur Bal:
                                    //   <span className={parseFloat(valueLoop[index]?.particular_ledger_balance) < 0 ? 'color_red' : 'm-0 p-0 text-muted'}>{` ${valueLoop[index]?.particular_ledger_balance}`}</span>
                                    //   <span className={parseFloat(valueLoop[index]?.particular_ledger_balance) < 0 ? 'color_red' : 'm-0 p-0 text-muted'}>{valueLoop[index]?.particular_ledger_balance == 0 ? '' : parseFloat(valueLoop[index]?.particular_ledger_balance) < 0 ? ` Cr` : ` Dr`}</span>

                                    // </p>
                                    : null}
                                </div>
                                <div className='col-2 m-0 p-0 container_of_voucher_input'>
                                  <input
                                    type='text'
                                    style={{ textAlign: 'right', paddingRight: 5 }}
                                    name={`particular_ledger_pay_amount_${index}`}
                                    ref={(input) => (particular_ledger_pay_amount_input_ref.current[index] = input)}
                                    className='voucher_entry_account_input'
                                    onKeyUp={(e) => handleKeyUp(index, 'particular_ledger_pay_amount', e.target.value, e)}
                                    onChange={(e) => handleChangeInputLoop(index, 'particular_ledger_pay_amount', e.target.value)}
                                    value={valueLoop[index]?.particular_ledger_pay_amount || ''}
                                  />
                                </div>
                              </div>

                            </div>
                          ))}
                        </div>
                      </>
                  }
                  {
                    mode === 'Single Mode' ?
                      <div className='col-3 border-top border-bottom border-dark' style={{ position: 'absolute', height: 19.5, bottom: 118, right: 0 }}>
                        <p className='m-0 pe-2 font_size' style={{ textAlign: 'right' }}>{total_amount > 0 ? total_amount.toFixed(2).replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,") : null}</p>
                      </div>
                      :
                      null
                  }
                  { /* This is for sales voucher type to show the amount allocation of take amount ==================================== */}
                  { /*  Dropdown of payment Allocation ==================================== */}

                  <div id="myDropdown-list" className={`dropdown-content-list ${show_bank_od_list ? 'show-list' : ''}`} ref={dropdownRef}>
                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                      List Of ledger account
                    </div>
                    <div className='col ' style={{ textAlign: 'right' }}>
                      <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                      <div className='border'></div>
                    </div>
                    <div id='data'>
                      {filteredDataBankAccountOdLedger?.length > 0 ? (
                        filteredDataBankAccountOdLedger?.map((key, number) => {
                          const name = key?.ledger_name;
                          const id = key?.id;
                          const inner_index = name?.toLowerCase()?.indexOf(data?.bank_account_od_ledger?.toLowerCase());
                          return (
                            <Link
                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                              id='list'
                              key={number}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {name === 'Not Applicable' ? (
                                <>
                                  <SuitDiamondFill /> &nbsp;
                                  {name}
                                </>
                              ) : inner_index !== -1 ? (
                                < >
                                  {name?.substring(0, inner_index)}
                                  <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + data?.bank_account_od_ledger?.length)}</span>
                                  {name?.substring(inner_index + data?.bank_account_od_ledger?.length)}
                                </>
                              ) : (
                                name
                              )}
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}

                    </div>
                  </div>
                  <div id="myDropdown-list" className={`dropdown-content-list ${show_cash_in_hand_list ? 'show-list' : ''}`} ref={dropdownRef}>
                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                      List Of ledger account
                    </div>
                    <div className='col ' style={{ textAlign: 'right' }}>
                      <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                      <div className='border'></div>
                    </div>
                    <div id='data'>
                      {filteredDataCashInHandLedger?.length > 0 ? (
                        filteredDataCashInHandLedger?.map((key, number) => {
                          const name = key?.ledger_name;
                          const id = key?.id;
                          const inner_index = name?.toLowerCase()?.indexOf(data?.cash_in_hand_ledger?.toLowerCase());
                          return (
                            <Link
                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                              id='list'
                              key={number}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {name === 'Not Applicable' ? (
                                <>
                                  <SuitDiamondFill /> &nbsp;
                                  {name}
                                </>
                              ) : inner_index !== -1 ? (
                                < >
                                  {name?.substring(0, inner_index)}
                                  <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + data?.cash_in_hand_ledger?.length)}</span>
                                  {name?.substring(inner_index + data?.cash_in_hand_ledger?.length)}
                                </>
                              ) : (
                                name
                              )}
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}

                    </div>
                  </div>
                  <div id="myDropdown-list" className={`dropdown-content-list ${show_sundry_deb_cred_list ? 'show-list' : ''}`} ref={dropdownRef}>
                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                      List Of ledger account
                    </div>
                    <div className='col ' style={{ textAlign: 'right' }}>
                      <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                      <div className='border'></div>
                    </div>
                    <div id='data'>
                      {filteredDataSundryDebCredLedger?.length > 0 ? (
                        filteredDataSundryDebCredLedger?.map((key, number) => {
                          const name = key?.ledger_name;
                          const id = key?.id;
                          const inner_index = name?.toLowerCase()?.indexOf(data?.sundry_creditor_debtors_ledger?.toLowerCase());
                          return (
                            <Link
                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                              id='list'
                              key={number}
                              onClick={(e) => {
                                e.preventDefault();

                              }}
                            >
                              {name === 'Not Applicable' ? (
                                <>
                                  <SuitDiamondFill /> &nbsp;
                                  {name}
                                </>
                              ) : inner_index !== -1 ? (
                                < >
                                  {name?.substring(0, inner_index)}
                                  <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + data?.sundry_creditor_debtors_ledger?.length)}</span>
                                  {name?.substring(inner_index + data?.sundry_creditor_debtors_ledger?.length)}
                                </>
                              ) : (
                                name
                              )}
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}

                    </div>
                  </div>

                  <div id="myDropdown-list" className={`dropdown-content-list ${show_party_account_name ? 'show-list' : ''}`} ref={dropdownRef}>
                    <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                      List Of ledger account
                    </div>
                    <div className='col ' style={{ textAlign: 'right' }}>
                      <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                      <div className='border'></div>
                    </div>
                    <div id='data'>
                      {filteredDataPartyAccountName?.length > 0 ? (
                        filteredDataPartyAccountName?.map((key, number) => {
                          const name = key?.ledger_name;
                          const id = key?.id;
                          const inner_index = name?.toLowerCase()?.indexOf(data?.party_account_name?.toLowerCase());
                          return (
                            <Link
                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                              id='list'
                              key={number}
                              onClick={(e) => {
                                e.preventDefault();

                              }}
                            >
                              {name === 'Not Applicable' ? (
                                <>
                                  <SuitDiamondFill /> &nbsp;
                                  {name}
                                </>
                              ) : inner_index !== -1 ? (
                                < >
                                  {name?.substring(0, inner_index)}
                                  <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + data?.party_account_name?.length)}</span>
                                  {name?.substring(inner_index + data?.party_account_name?.length)}
                                </>
                              ) : (
                                name
                              )}
                            </Link>
                          );
                        })
                      ) : (
                        <p style={{ color: 'red' }}>No matching items found</p>
                      )}

                    </div>
                  </div>
                  { /* This is for sales voucher type to show the amount allocation of take amount ==================================== */}
                  {
                    data.entry_name === 'Sales' || data.entry_name === 'Purchase' || data.entry_name === 'Credit Note' || data.entry_name === 'Debit Note' ?
                      <>
                        <div className='row' style={{ position: 'absolute', bottom: 52, width: '100%' }} >
                          <p className='m-0 ps-2'><u>Payment Allocation </u></p>
                          <div className='col-5'>
                            <div className='container_of_voucher_input'>
                              <label className='lab-1'>Bank Account Od Ledger</label>
                              <label>:</label>
                              <input
                                type='text'
                                style={{ height: 19.5, fontWeight: 'bold' }}
                                name='bank_account_od_ledger'
                                disabled={total_amount - cash_in_hand_payment == 0 || total_amount - sundry_creditor_debtors_payment == 0 || total_amount - balance == 0}
                                id='bank_account_od_ledger'
                                className='voucher_entry_account_input'
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={bank_account_od_ledger_input_ref}
                                value={bank_account_od_ledger || ""} />

                            </div>
                            <div className='container_of_voucher_input'>
                              <label className='lab-1' style={{ fontSize: 12 }}>Cash In Hand</label>
                              <label>:</label>
                              <input
                                type='text'
                                style={{ height: 19.5, fontWeight: 'bold' }}
                                name='cash_in_hand_ledger'
                                id='cash_in_hand_ledger'
                                className='voucher_entry_account_input'
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={cash_in_hand_ledger_input_ref}
                                value={cash_in_hand_ledger || ""} />
                            </div>
                            <div className='container_of_voucher_input'>
                              <label className='lab-1' style={{ fontSize: 12 }}>Sundry Debtors/Creditors</label>
                              <label>:</label>
                              <input
                                type='text'
                                style={{ height: 19.5, fontWeight: 'bold' }}
                                name='sundry_creditor_debtors_ledger'
                                id='sundry_creditor_debtors_ledger'
                                disabled={total_amount - cash_in_hand_payment == 0 || total_amount - bank_account_od_payment == 0 || total_amount - balance == 0}
                                className='voucher_entry_account_input'
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={sundry_creditor_debtors_ledger_input_ref}
                                value={sundry_creditor_debtors_ledger || ""} />

                            </div>

                            <div className='container_of_voucher_input'>
                              <label className='lab-1' style={{ fontSize: 12 }}>Partry(Ac Name)</label>
                              <label>:</label>
                              <input
                                type='text'
                                style={{ height: 19.5, fontWeight: 'bold' }}
                                name='party_account_name'
                                disabled={total_amount - cash_in_hand_payment == 0 || total_amount - sundry_creditor_debtors_payment == 0 || total_amount - bank_account_od_payment == 0}
                                id='party_account_name'
                                className='voucher_entry_account_input'
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={party_account_name_input_ref}
                                value={party_account_name || ""} />

                            </div>
                          </div>
                          <div className='col-7'>
                            <div className='row'>
                              <div className='col-2'>
                                <div className='container_of_voucher_input'>
                                  <input
                                    type='text'
                                    style={{ height: 19.5, fontWeight: 'bold' }}
                                    name='bank_account_od_payment'
                                    id='bank_account_od_payment'
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={bank_account_od_payment_input_ref}
                                    value={bank_account_od_payment || ""} />
                                </div>
                                <div className='container_of_voucher_input'>
                                  <input
                                    type='text'
                                    style={{ height: 19.5, fontWeight: 'bold' }}
                                    name='cash_in_hand_payment'
                                    id='cash_in_hand_payment'
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={cash_in_hand_payment_input_ref}
                                    value={cash_in_hand_payment || ""} />
                                </div>
                                <div className='container_of_voucher_input'>
                                  <input
                                    type='text'
                                    style={{ height: 19.5, fontWeight: 'bold' }}
                                    name='sundry_creditor_debtors_payment'
                                    id='sundry_creditor_debtors_payment'
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={sundry_creditor_debtors_payment_input_ref}
                                    value={sundry_creditor_debtors_payment || ""} />
                                </div>

                                <div className='container_of_voucher_input'>
                                  <input
                                    type='text'
                                    style={{ height: 19.5, fontWeight: 'bold' }}
                                    name='party_account_name_payment'
                                    id='party_account_name_payment'
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={party_account_name_payment_input_ref}
                                    value={party_account_name_payment || ""} />
                                </div>

                              </div>
                              <div className='col-5'>
                                <div className='container_of_voucher_input'>
                                  <label className='lab-1' style={{ fontSize: 12, overflow: 'hidden', textOverflow: 'clip', whiteSpace: 'nowrap' }}>UTR/CHQ No.</label>
                                  <label>:</label>
                                  <input
                                    type='text'
                                    style={{ height: 19.5, fontSize: 13 }}
                                    name='utr_chq_no'
                                    id='utr_chq_no'
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={utr_chq_no_input_ref}
                                    value={utr_chq_no || ""} />
                                </div>
                                <div className='container_of_voucher_input'>
                                  <label className='lab-1' style={{ fontSize: 13, overflow: 'hidden', textOverflow: 'clip', whiteSpace: 'nowrap', display: 'flex', flexShrink: 1 }}>Cash Tendered</label>
                                  <label>:</label>
                                  <input
                                    type='text'
                                    style={{ height: 18, fontSize: 12 }}
                                    name='cash_tendered'
                                    id='cash_tendered'
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={cash_tendered_input_ref}
                                    value={cash_tendered || ""} />
                                </div>
                              </div>
                              <div className='col align-self-start pt-3'>
                                <div className='container_of_voucher_input'>
                                  <label className='lab-1' style={{ fontSize: 12, overflow: 'hidden', textOverflow: 'clip', whiteSpace: 'nowrap' }}>Balance</label>
                                  <label>:</label>
                                  <input
                                    type='text'
                                    style={{ height: 18, fontSize: 12 }}
                                    name='balance'
                                    id='balance'
                                    readOnly
                                    className='voucher_entry_account_input'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={balance_input_ref}
                                    value={balance || ""} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      : null
                  }
                </>
                :
                <>
                  <div className='row border border-dark mt-0'>
                    <div className='col-1' style={{ width: '5%' }} ></div>
                    <div className='col-7 font-weight-bold' style={{ textAlign: 'left' }}>Particular</div>
                    <div className='col-2 font-weight-bold ' style={{ textAlign: 'right' }}>Debit</div>
                    <div className='col-2 font-weight-bold ' style={{ textAlign: 'right' }}>Credit</div>
                  </div>
                  <div className='row' style={data.entry_name === 'Sales' ? { height: '37vh', overflowY: 'scroll' } : { height: '70vh', overflowY: 'scroll', display: 'flex', flexDirection: 'column' }}>
                    {double_mode_input.map((item, index) => (
                      <div key={index} className={`col-12 m-0 mt-1`} style={{ height: double_mode_input[index]?.particular_ledger_name ? 45 : 25, paddingLeft: 12, paddingRight: 12 }}>
                        <div className='row'>
                          <div className='col-1' style={{ width: '5%' }}>
                            <input
                              type='text'
                              style={{ textAlign: 'right', paddingRight: 5 }}
                              name={`credit_debit${index}`}
                              ref={(input) => (credit_debit_input_ref.current[index] = input)}
                              className='voucher_entry_double_mode_input'
                              onKeyUp={(e) => handleDoubleKeyUp(index, 'credit_debit', e.target.value, e)}
                              onChange={(e) => handleDoubleModeInputChange(index, 'credit_debit', e.target.value)}
                              value={double_mode_input[index]?.credit_debit || ''}
                            />
                          </div>
                          <div className='col-3'>
                            <input
                              type='text'
                              autoFocus={double_mode_input.length <= 1 ? true : false}
                              name={`particular_ledger_name${index}`}
                              ref={(input) => (particular_ledger_double_name_input_ref.current[index] = input)}
                              className='voucher_entry_double_mode_input'
                              onKeyUp={(e) => handleDoubleKeyUp(index, 'particular_ledger_name', e.target.value, e)}
                              onChange={(e) => handleDoubleModeInputChange(index, 'particular_ledger_name', e.target.value)}
                              value={double_mode_input[index]?.particular_ledger_name || ''}
                            />
                            <div id="myDropdown-list" className={`dropdown-content-list ${show_particular_double_list ? 'show-list' : ''}`} ref={dropdownRef}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of ledger account
                              </div>
                              <div className='col ' style={{ textAlign: 'right' }}>
                                <Link to='/create-master-invmas-stockgroup' onClick={() => navigate('/create-master-invmas-stockgroup')} style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                                <div className='border'></div>
                              </div>
                              <div id='data'>
                                {filteredData?.length > 0 ? (
                                  filteredData?.map((key, number) => {
                                    const name = contra_account_ledger_list[key]?.ledger_name;
                                    const id = contra_account_ledger_list[key]?.id;
                                    const inner_index = name?.toLowerCase()?.indexOf(double_mode_input[index]?.particular_ledger_name?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            // setValueLoop((prevValueLoop) => {
                                            //   const updatedValueLoop = [...prevValueLoop];
                                            //   updatedValueLoop[index]['first'] = name;
                                            //   return updatedValueLoop;
                                            // });

                                            // setshow_particular_list(false)
                                            // const nextInput = particular_ledger_pay_amount_input_ref.current[index];
                                            // if (nextInput) {
                                            //   nextInput.focus();
                                            // }
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name?.substring(0, inner_index)}
                                            <span style={{ color: 'red' }}>{name?.substring(inner_index, inner_index + double_mode_input[index].particular_ledger_name.length)}</span>
                                            {name?.substring(inner_index + double_mode_input[index].particular_ledger_name.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}

                              </div>
                            </div>
                          </div>
                          <div className='col-2 offset-4'>
                            <input
                              type='text'
                              style={{ textAlign: 'right', paddingRight: 5 }}
                              name={`debit_amount${index}`}
                              ref={(input) => (debit_input_ref.current[index] = input)}
                              className='voucher_entry_double_mode_input'
                              onKeyUp={(e) => handleDoubleKeyUp(index, 'debit_amount', e.target.value, e)}
                              onChange={(e) => handleDoubleModeInputChange(index, 'debit_amount', e.target.value)}
                              value={double_mode_input[index]?.debit_amount || ''}
                            />
                          </div>
                          <div className='col-2'>
                            <input
                              type='text'
                              style={{ textAlign: 'right', paddingRight: 5 }}
                              name={`credit_amount${index}`}
                              ref={(input) => (credit_input_ref.current[index] = input)}
                              className='voucher_entry_double_mode_input'
                              onKeyUp={(e) => handleDoubleKeyUp(index, 'credit_amount', e.target.value, e)}
                              onChange={(e) => handleDoubleModeInputChange(index, 'credit_amount', e.target.value)}
                              value={double_mode_input[index]?.credit_amount || ''}
                            />
                          </div>
                        </div>
                        {double_mode_input[index]?.particular_ledger_name !== '' ?
                          <div className='row mt-0 pt-0 pb-0 mb-0'>
                            <div className='col-10 mt-0 pt-0 pb-0 mb-0 offset-2'>
                              <p className={'m-0 p-0 text-muted'}><i>Cur Bal: <span className={double_mode_input[index]?.particular_ledger_balance < 0 ? 'color_red' : 'm-0 p-0 text-muted'}>{double_mode_input[index]?.particular_ledger_balance}</span> <span className={double_mode_input[index]?.particular_ledger_balance < 0 ? 'color_red' : 'm-0 p-0 text-muted'}>{double_mode_input[index]?.particular_ledger_balance == 0 ? '' : double_mode_input[index]?.particular_ledger_balance < 0 ? 'Cr' : 'Dr'}</span></i></p>
                            </div>
                          </div>
                          : null}
                      </div>
                    ))}
                  </div>
                </>
            }
            { /*  This is narration and total amount of all the entry ===========================================================*/}
            <div className='row' style={{ position: 'absolute', bottom: 23.5, width: '100%' }}>
              <div className='col-12 m-0 p-0 ps-1 border-top border-dark'>
                <div className='d-flex justify-content-between align-item-center'>
                  <label>Narration</label>
                  <label>:</label>
                  <textarea
                    type='text'
                    name='narration'
                    id='narration'
                    className='narration_input'
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    ref={narration_input_ref}
                    value={narration || ""} />
                </div>
              </div>

              {
                mode === 'Double Mode' ?
                  <div className='col-4 border offset-4'>
                    <div className='row'>
                      <div className='col-6'>
                        <p className='m-0 p-0' style={{ textAlign: 'right' }}>{total_amount_debit > 0 ? total_amount_debit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                      </div>
                      <div className='col-6'>
                        <p className='m-0 p-0' style={{ textAlign: 'right' }}>{total_amount_credit > 0 ? total_amount_credit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : null}</p>
                      </div>
                    </div>
                  </div>
                  : null
              }
            </div>
            { /* This is buttons  =================================================================================== */}
            {
              show_period || show_voucher_type ?
                null
                : <div className='tomasterfooter'>
                  <div className='footer'>
                    <div className='boxfooter'>
                      <button className='button'>
                        <strong> Q:Quite</strong>
                      </button>
                    </div>
                    <div className='boxfooter'>
                      <button disabled className='button'>
                        <strong></strong>
                      </button>
                    </div>
                    <div className='boxfooter'>
                      <button disabled className='button'>
                        <strong></strong>
                      </button>
                    </div>
                    <div className='boxfooter'>
                      <button type='submit' className='button' onClick={() => { return (sethashtag(true)) }}>
                        <strong><u> <u>A</u></u>:Accept</strong>
                      </button>
                    </div>
                    <div className='boxfooter'>
                      <button disabled className='button'>
                        <strong><u></u></strong>
                      </button>
                    </div>
                    <div className='boxfooter'>
                      <button disabled className='button'></button>
                    </div>
                    <div className='boxfooter'>
                      <button disabled className='button'>
                        <strong> <u>D</u>:Delete</strong>
                      </button>
                    </div>
                    <div className='boxfooter'>
                      <button className='button'>
                        <strong> <u>X</u>:Cancel</strong>
                      </button>
                    </div>
                    <div className='boxfooter'>
                      <button disabled className='button'></button>
                    </div>
                    <div className='boxfooter'>
                      <button disabled className='button'></button>
                    </div>
                  </div>
                </div>}
          </form>
        </div>
      </div >
      { /*  This is help cards ===============================================================================================*/}
      < div className='help' style={{ position: 'relative' }
      }>
        {
          show_voucher_type || show_period || show_other_voucher || change_mode ? null :
            <>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={() => { return (setshow_period(true)) }}>
                  <p className='card-header'>
                    <span>F2:</span>
                    Date
                  </p>
                </button>
              </div>
              <div className='help_button_card mt-3'>
                <button className={`button_padding`} onClick={(e) => {
                  return (
                    e.preventDefault(),
                    displayNameOfVoucherType('Contra', voucher_type_data))
                }}>
                  <p className='card-header'>
                    <span>F4:</span>
                    Contra
                  </p>
                </button>
              </div>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={(e) => {
                  return (
                    e.preventDefault(),
                    displayNameOfVoucherType('Payment', voucher_type_data))
                }}>
                  <p className='card-header'>
                    <span>F5:</span>
                    Payment
                  </p>
                </button>
              </div>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={(e) => {
                  return (
                    e.preventDefault(),
                    displayNameOfVoucherType('Receipt', voucher_type_data))
                }}>
                  <p className='card-header'>
                    <span>F6:</span>
                    Receipt
                  </p>
                </button>
              </div>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={(e) => {
                  return (
                    e.preventDefault(),
                    displayNameOfVoucherType('Journal', voucher_type_data))
                }}>
                  <p className='card-header'>
                    <span>F7:</span>
                    Journal
                  </p>
                </button>
              </div>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={(e) => {
                  return (
                    e.preventDefault(),
                    displayNameOfVoucherType('Sales', voucher_type_data))
                }}>
                  <p className='card-header'>
                    <span>F8:</span>
                    Sales
                  </p>
                </button>
              </div>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={(e) => {
                  return (
                    e.preventDefault(),
                    displayNameOfVoucherType('Purchase', voucher_type_data))
                }}>
                  <p className='card-header'>
                    <span>F9:</span>
                    Purchase
                  </p>
                </button>
              </div>
              <div className='help_button_card' style={{ height: 'auto' }}>
                <button className={`button_padding`} onClick={() => { return (setshow_other_voucher(true)) }} style={{ height: 'auto' }}>
                  <p className='card-header' style={{ height: 'auto' }}>
                    <span><u>F10</u>:</span>
                    Other voucher
                  </p>
                </button>
              </div>
              <div className='help_button_card mt-3'>
                <button className={`button_padding`} onClick={(e) => { return (setchange_mode(true), setshow_account_list(false)) }}>
                  <p className='card-header'>
                    <span><u>H</u>:</span>
                    Change Mode
                  </p>
                </button>
              </div>
              <div className='help_button_card'>
                <button className={`button_padding`} onClick={() => { return (setshow_period(true)) }}>
                  <p className='card-header'>
                    <span><u>L</u>:</span>
                    Post Dated
                  </p>
                </button>
              </div>
              <div className='boxfooter' style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <button className={`button`} style={{ width: '100%' }} onClick={() => {
                  setshow_configure(true);
                  setshow_account_list(false)
                }}>

                  <span>F12:</span>
                  Configure

                </button>
              </div>
            </>
        }
      </div >
    </div >
  )
}

export default Vouchers







