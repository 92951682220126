import React, { useEffect, useState } from 'react';
import './section.css';
import { Form, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Banking from '../../pages/gatewayoftally/utilities/Banking';
import GateWayOfTally from '../../pages/gatewayoftally/GateWayOfTally';
import DisplayMoreReports from '../../pages/gatewayoftally/Reports/DisplayMoreReports';
import Stock_Summary_Help from '../help/stock_summary/Stock_Summary_Help';
import Home_Help from '../help/home/Home_Help';
import ChequePrinting from '../../pages/gatewayoftally/utilities/banking/cheque/ChequePrinting';
import PostDatedSummary from '../../pages/gatewayoftally/utilities/banking/cheque/PostDatedSummary';
import DepositSlip from '../../pages/gatewayoftally/utilities/banking/statement/DepositSlip';
import PaymentAdvice from '../../pages/gatewayoftally/utilities/banking/statement/PaymentAdvice';
import BankReconciliation from '../../pages/gatewayoftally/utilities/banking/statement/BankReconciliation';
import Period_Home from './Period_Home';
import Current_Date from './Current_Date';
import AccountBook from '../../pages/gatewayoftally/Reports/displaymore/AccountBook';
import StatementofAccount from '../../pages/gatewayoftally/Reports/displaymore/StatementofAccount';
import InventoryBook from '../../pages/gatewayoftally/Reports/displaymore/InventoryBook';
import StatementofInventory from '../../pages/gatewayoftally/Reports/displaymore/StatementofInventory';
import GstReport from '../../pages/gatewayoftally/Reports/displaymore/GstReport';
import ExceptionReports from '../../pages/gatewayoftally/Reports/displaymore/ExceptionReports';
import AnalysisVerification from '../../pages/gatewayoftally/Reports/displaymore/AnalysisVerification';
import LedgerBook from '../../pages/gatewayoftally/Reports/displaymore/account_book/LedgerBook';
import { maxDateOfEntry } from '../../redux/actions/action-creator';
import { dateDayShow, dateFormateShow } from '../date_formate/Index';

const Section = () => {
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false)
    const [max_date, setMax_date] = useState('')
    const { company_session } = useSelector(state => state.common);
    const { userData } = useSelector(state => state.auth);
    const [chequePrinting, setChequePrinting] = useState(false);
    const [postDatedSummary, setPostDatedSummary] = useState(false);
    const [depositSlip, setDepositSlip] = useState(false);
    const [paymentAdvice, setPaymentAdvice] = useState(false);
    const [bankReconciliation, setBankReconciliation] = useState(false);
    const location = useLocation();
    const navigation = useNavigate();
    useEffect(() => {

        const formData = new FormData();
        formData.append('company_id', userData?.id)
        dispatch(maxDateOfEntry(formData)).then((response) => {

            if (response.status) {
                console.log("yyyyyy")
                setMax_date(response?.data)
                setloading(false)
            } else {
                console.log('this is ')
                setMax_date('')
                setloading(false)

            }
        })
    }, [])
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'F2') {
                e.preventDefault();
                navigation('/current_date')
            }
            if (e.ctrlKey && e.key === 'F2') {
                e.preventDefault();
                navigation('/period')
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [])
    const handleShowChequePrinting = () => {
        document.getElementById('section').style.filter = 'blur(5px)';
        setChequePrinting(true);
    }
    const handleCloseChequePrinting = () => {
        document.getElementById('section').style.filter = 'none';
        setChequePrinting(false);
    }
    const handleShowPostDatedSummary = () => {
        document.getElementById('section').style.filter = 'blur(5px)';
        setPostDatedSummary(true);
    }
    const handleClosePostDatedSummary = () => {
        document.getElementById('section').style.filter = 'none';
        setPostDatedSummary(false);
    }

    const handleShowDepositSlip = () => {
        document.getElementById('section').style.filter = 'blur(5px)';
        setDepositSlip(true);
    }
    const handleCloseDepositSlip = () => {
        document.getElementById('section').style.filter = 'none';
        setDepositSlip(false);
    }

    const handleShowPaymentAdvice = () => {
        document.getElementById('section').style.filter = 'blur(5px)';
        setPaymentAdvice(true);
    }
    const handleClosePaymentAdvice = () => {
        document.getElementById('section').style.filter = 'none';
        setPaymentAdvice(false);
    }

    const handleShowBankReconciliation = () => {
        document.getElementById('section').style.filter = 'blur(5px)';
        setBankReconciliation(true);
    }
    const handleCloseBankReconciliation = () => {
        document.getElementById('section').style.filter = 'none';
        setBankReconciliation(false)
    }
    const openCurrentPeriod = (e) => { navigation('/period') }
    const openCurrentDate = (e) => { navigation('/current_date') }
    // if (loading) {
    //     return <h1>Loading ...</h1>
    // }
    return (
        <div className='section' >
            <div className='detail'>
                {location.pathname === '/ledgerbook' ? <LedgerBook /> : null}
                {location.pathname === '/current_date' ? <Current_Date /> : null}
                {location.pathname === '/period' ? <Period_Home /> : null}
                {chequePrinting ? <ChequePrinting handleCloseChequePrinting={() => { return (handleCloseChequePrinting) }} /> : null}
                {postDatedSummary ? <PostDatedSummary handleClosePostDatedSummary={() => { return (handleClosePostDatedSummary) }} /> : null}
                {depositSlip ? <DepositSlip handleCloseDepositSlip={() => { return (handleCloseDepositSlip) }} /> : null}
                {paymentAdvice ? <PaymentAdvice handleClosePaymentAdvice={() => { return (handleClosePaymentAdvice) }} /> : null}
                {bankReconciliation ? <BankReconciliation handleCloseBankReconciliation={() => { return (handleCloseBankReconciliation) }} /> : null}
                <div className='detail-item' id='section'>
                    <div className=' detail-1'>
                        <div className='row mt-3'>
                            <div className='col-xl btn home-btn border-0 ' style={{ pointerEvents: 'none' }} >
                                <p className='text-info text-center font_size m-0 mb-1'>MAX. DATE OF ENTRY</p>
                                <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}> {max_date ? dateDayShow(max_date) : ''}{max_date ? ', ' : ''}{max_date ? dateFormateShow(max_date) : ''} </p>
                            </div>
                            <div className='col-xl btn home-btn border-0' onClick={() => { return (openCurrentPeriod()) }}>
                                <p className='text-info text-center font_size m-0 mb-1'><u>F2</u>:CURRENT PERIOD</p>
                                <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}>{company_session.financial_year}</p>
                            </div>
                            <div className='col-xl btn home-btn border-0' onClick={() => { return (openCurrentDate()) }}>
                                <p className='text-info text-center font_size m-0 mb-1'> F2:CURRENT Date</p>
                                <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}>{company_session.current_date}</p>
                            </div>
                        </div>
                        
                        
                        <div className='border-top  pt-5 item-3'>
                            <h5 className='m-0 p-0'>{userData?.company_name}</h5>
                            <p className='m-0 p-0 font_size'>{userData?.address}</p>
                            <p className='m-0 p-0 font_size'>Mobile No.: {userData?.mobile}</p>
                        </div>
                    </div>
                    <div className='detail-2' id='detail-2'>
                        {location.pathname == '/bankingutilities' ? <Banking
                            handleShowChequePrinting={() => { return (handleShowChequePrinting) }}
                            handleShowPostDatedSummary={() => { return (handleShowPostDatedSummary) }}
                            handleShowDepositSlip={() => { return (handleShowDepositSlip) }}
                            handleShowPaymentAdvice={() => { return (handleShowPaymentAdvice) }}
                            handleShowBankReconciliation={() => { return (handleShowBankReconciliation) }}
                        /> : location.pathname == '/displaymorereports' ? <DisplayMoreReports />
                            : location.pathname == '/accountbook' || location.pathname === '/ledgerbook' ? <AccountBook />
                                : location.pathname == '/statementofaccount' ? <StatementofAccount />
                                    : location.pathname == '/inventorybook' ? <InventoryBook />
                                        : location.pathname == '/statementofinventory' ? <StatementofInventory />
                                            : location.pathname == '/gstreport' ? <GstReport />
                                                : location.pathname == '/exceptionreports' ? <ExceptionReports />
                                                    : location.pathname == '/analysisverification' ? <AnalysisVerification />
                                                        : location.pathname == '/current_date' ? null
                                                            : location.pathname == '/period' ? null : <GateWayOfTally />}
                    </div>
                </div>
            </div>
            <div className='help'>
                {location.pathname === '/stocksummary' ? <Stock_Summary_Help /> :
                    <Home_Help
                        chequePrinting={chequePrinting}
                        postDatedSummary={postDatedSummary}
                        depositSlip={depositSlip}
                        paymentAdvice={paymentAdvice}
                        bankReconciliation={bankReconciliation}
                    />}
            </div>
        </div >
    )
}
export default Section;
