import React, { useState } from 'react';
import './stock_summary_help.css';
import Period from './stock_summary_component/Period';
import HelpCard from '../HelpCard';

const Stock_Summary_Help = (props) => {
    const fields = [
        { id: "1", name: "Period", shortcutKey: "F2", state: props.setShow },
        { id: "2", name: "Group Wise", shortcutKey: "F3", state: props.setStockGroup },
        { id: "3", name: "Item Wise", shortcutKey: "F4", },
        { id: "4", name: "Category Wise", shortcutKey: "F5", state: props.setStockCate },
        { id: "5", name: "Company Wise", shortcutKey: "F6", state: props.setStockCompany },
        { id: "6", name: "Godown", shortcutKey: "F7", state: props.setGodownWise },
        { id: "7", name: "Monthly", shortcutKey: "F8", },
        { id: "8", name: "Show Profit", shortcutKey: "F9", },
        { id: "9", name: "Valuation", shortcutKey: "F9", state: props.setValuation },
        { id: "10", name: "Basis of Value", shortcutKey: "F9", state: props.setBasic },
        { id: "11", name: "Change View", shortcutKey: "F9", state: props.setChangeView },
        { id: "12", name: "Exception Reposts", shortcutKey: "F9", state: props.setExceptionReports },
        { id: "13", name: "Save View", shortcutKey: "F9", state: props.setSaveView },
    ]

    return (
        <>
            {/* {
                props.show ? null : props.company ? null : props.basic ? null : props.changeView ? null : props.exceptionReports ? null : props.saveView ? null : props.valuation ? null :
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='help_button_card'>
                            <button className={`button_padding`} onClick={props.setShow(true)}>
                                <p className='card-header'>
                                    <span> <u>F2</u> :</span>
                                    Period
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`} onClick={props.setStockGroup(true)}>
                                <p className='card-header'>
                                    <span>F4</span>: Group Wise
                                </p>
                            </button>
                        </div>

                        <div className='help_button_card'>
                            <button className={`button_padding`}>
                                <p className='card-header'>
                                    <span>F5</span>: Item-Wise
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card' style={{ height: 'auto' }}>
                            <button className={`button_padding`} style={{ height: 'auto' }} onClick={props.setStockCate(true)}>
                                <p className='card-header' style={{ height: 'auto' }}>
                                    <span>F5</span>: Category-Wise
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card' style={{ height: 'auto' }}>
                            <button className={`button_padding`} style={{ height: 'auto' }} onClick={props.setStockCompany(true)}>
                                <p className='card-header' style={{ height: 'auto' }}>
                                    <span>F5</span>: Company-Wise
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`} onClick={props.setGodownWise(true)}>
                                <p className='card-header'>
                                    <span><u>G</u></span>: Godown
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`}>
                                <p className='card-header'>
                                    <span>F6</span>: Monthly
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`}>
                                <p className='card-header'>
                                    <span>F7</span>: Show Profit
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`} onClick={props.setValuation(true)}>
                                <p className='card-header'>
                                    <span>F8</span>: Valuation
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`}>
                                <p className='card-header'>
                                    <span>F9</span>: Other
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`}>
                                <p className='card-header'>
                                    <span>F10</span>:
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card mt-1' style={{ height: 'auto' }}>
                            <button style={{ height: 'auto' }} className={`button_padding`} onClick={props.setBasic(true)}>
                                <p className='card-header' style={{ height: 'auto' }}>
                                    <span>B</span>: Basis of Values
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`} onClick={props.setChangeView(true)}>
                                <p className='card-header'>
                                    <span>H</span>: Change View
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card mt-1' style={{ height: 'auto' }}>
                            <button style={{ height: 'auto' }} className={`button_padding`} onClick={props.setExceptionReports(true)}>
                                <p className='card-header' style={{ height: 'auto' }}>
                                    <span>J</span>: Exception Reports
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`} onClick={props.setSaveView(true)}>
                                <p className='card-header'>
                                    <span>L</span>: Save View
                                </p>
                            </button>
                        </div>
                        <div className='help_button_card'>
                            <button className={`button_padding`}>
                                <p className='card-header'>
                                    <span>L</span>: Valuation
                                </p>
                            </button>
                        </div>


                        <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                            <div className='help_button_card mb-2 ms-1' >
                                <button className={`button_padding`} onClick={
                                    props.setshow_configure(true)
                                }>
                                    <p className='card-header'>
                                        <span>F12</span>:
                                        Configure
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>
            } */}
            {
                fields.map((item, index) => {
                    return (
                        <HelpCard key={item.id} fields={item} />
                    )
                })
            }
            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <div className='help_button_card mb-2 ms-1' >
                    <button className={`button_padding`} onClick={
                        props.setshow_configure(true)
                    }>
                        <p className='card-header'>
                            <span>F12</span>:
                            Configure
                        </p>
                    </button>
                </div>
            </div>

        </>
    )
}

export default Stock_Summary_Help;
