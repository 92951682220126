import '../App.css';
import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AuthRoute from './AuthRoute';
import AppRoute from './AppRoute';
// import { contraLedgerListing } from '../redux/actions/action-creator';
import { LINK_PATH } from '../constant';
const Index = ({ setProgress }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData = null } = useSelector(state => state.auth);
  const location = useLocation();
  useEffect(() => {
    const handleKeyDown = (event) => {
      // List of shortcuts to disable
      if (event.ctrlKey && (event.key === 't' || event.key === 'T')) {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'r') {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'n') {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'p') {
        event.preventDefault();
      } else if (event.ctrlKey && event.key === 'w') {
        event.preventDefault();
      } 
      // else if (event.ctrlKey && event.key === 'a') {
      //   event.preventDefault();
      // }
      //  else if (event.ctrlKey && event.key === 'c') {
      //   event.preventDefault();
      // } 
      else if (event.ctrlKey && (event.key === '=' || event.key === '+')) {
        event.preventDefault();
      } else if (event.ctrlKey && (event.key === '_' || event.key === '-')) {
        event.preventDefault();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        if (location.pathname !== `/`) {
          navigate(-1);
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate, location.pathname]);
  const loginPath = location?.pathname || '';
  return (
    <div className="App">
      <Routes>
        {
          userData?.id 
          ?
            <Route path={`${LINK_PATH}/*`} element={<AppRoute setProgress={setProgress} />} />
            :
            <Route path={`${LINK_PATH}/*`} element={<AuthRoute setProgress={setProgress} />} />
        }
      </Routes>
    </div>
  )
}
export default Index;
