import React from 'react';
import { Link } from 'react-router-dom';

const PaymentAdvice = (props) => {
  return (
    <div className='company-list-data' id='company'>
    <button type="button" class="btn-close" id='company-close' aria-label="Close" onClick={props.handleClosePaymentAdvice()}></button>
    <div className='select-company-search-box'>
        <p>Name of Ledger</p>
        <input class='masterInput' type="text" id='Company' placeholder='Enter Here......' />
    </div>
    <div className='list-of-company' >
        <div className='title'>
            <p>List of Ledger</p>
        </div>
        <div className='container-1'>
            <ul className='master-list'>
                <li>
                    <Link to="/create">
                        Create
                    </Link>
                </li>

            </ul>
            <hr />
            <div className='container-2 row'>
                <div className='col'>
                    <ul>
                        <li>All Items</li>
                        <li>Abcd</li>
                        <li>Av</li>
                        <li>shiv</li>
                    </ul>
               
                </div>

            </div>
        </div>
    </div>
</div>
  )
}

export default PaymentAdvice
